<template>
    <div class="setting" v-if="personal_info">
        <tabs
                :tabs="side_tabs"
                :currentTab="currentTab"
                :wrapper-class="'default-tabs'"
                :tab-class="'default-tabs__item'"
                :tab-active-class="'default-tabs__item_active'"
                :line-class="'default-tabs__active-line'"
                @onClick="sideChange"
        />

        <div v-if="currentTab=='profile'">
            <h1 class="semi profile-new">{{__('Profile Settings')}}</h1>
            <p class="description">{{__('Manage your profile settings')}}</p>
            <PersonalSetting v-bind:personal_info.sync="personal_info.personal"></PersonalSetting>
            <PhoneSetting v-bind:personal_info.sync="personal_info" v-bind:portfolio="portfolio"></PhoneSetting>
        </div>

        <div v-if="currentTab=='security'">
            <h1 class="semi profile-new">{{__('Security Settings')}}</h1>
            <p class="description">{{__('Protect your profile: Change the password in a timely manner and install two-factor authentication.')}}</p>
            <VerificationSetting v-bind:personal_info.sync="personal_info"></VerificationSetting>
            <SecuritySetting v-bind:personal_info.sync="personal_info"></SecuritySetting>
        </div>

        <div v-if="currentTab=='wallets'">
            <WalletsSetting v-bind:personal_info.sync="personal_info"></WalletsSetting>
        </div>

        <div v-if="currentTab=='nft'">
            <WalletsSettingNFT v-bind:personal_info.sync="personal_info"></WalletsSettingNFT>
        </div>

        <div v-else-if="currentTab=='telegram'">
            <SettingBot></SettingBot>
        </div>

    </div>
    <div v-else class="loading">
        <loaderMc></loaderMc>
        {{__('Loading')}}
    </div>
</template>

<script>
import PersonalSetting from "./Settings/PersonalSetting";
import PhoneSetting from "./Settings/PhoneSetting";
import VerificationSetting from "./Settings/VerificationSetting";
import WalletsSettingNFT from "./Settings/WalletsSettingNFT";
import WalletsSetting from "./Settings/WalletsSetting";
import SecuritySetting from "./Settings/SecuritySetting";
import SettingBot from "./Settings/SettingBot";
    import loaderMc from "../SvgComponents/loaderMc";
  export default {
    name: "Settings",
      data () {

          let tabs= {
              // 'profile': {
              //     title: this.__('Profile Settings'),
              //     type: 0,
              // },
              // 'security': {
              //     title: this.__('Security Settings'),
              //     type: 1,
              // },
              // 'wallets': {
              //     title: this.__('Wallets for withdraw'),
              //     type: 2,
              // },
              // 'nft': {
              //     title: this.__('NFT wallets'),
              //     type: 3,
              // },
              // 'telegram': {
              //     title: this.__('Telegram BOT'),
              //     type: 4,
              // },
          };

          let active_tab= null;

          if(this.$route.params.type)  {
              let tab= tabs[this.$route.params.type];
              if(tab) {
                  active_tab= tab.type;
              }
          }

          return {
              side_tabs: [
                  {
                      'title': this.__('Profile Settings'),
                      'value': 'profile'
                  },
                  {
                      'title': this.__('Security Settings'),
                      'value': 'security'
                  },
                  {
                      'title': this.__('Wallets for withdraw'),
                      'value': 'wallets'
                  },
                  {
                      'title': this.__('NFT wallets'),
                      'value': 'nft'
                  },
                  {
                      'title': this.__('Telegram BOT'),
                      'value': 'telegram'
                  },
              ],
              tabs: tabs,
              tab: active_tab,
              personal_info: null
          }
      },
      methods: {
          sideChange(newTab) {
              if(newTab!= this.currentTab) {
                  this.$router.push({name: 'settings_current', params: {'current': newTab}});
              }
          },
          changeTab(tab) {
              this.tab= tab.type
          },
      },
      created () {
          this.$http.get('ajax/setting/personal_info')
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  response= response.data;
                  this.personal_info= response;
              }).catch((err) => {
              this.httpHandler(err);
          });
      },
      computed: {
          currentTab: function () {
              return (this.$route.params.current) ? this.$route.params.current : this.side_tabs[0].value
          },
          portfolio() {
              return this.$store.state.auth.portfolio
          },
      },
      components: {
        loaderMc,
        PersonalSetting,
        PhoneSetting,
        VerificationSetting,
        WalletsSetting,
        WalletsSettingNFT,
        SettingBot,
        SecuritySetting
      }
  }
</script>

<style>

</style>
