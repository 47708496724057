import Vue from "vue";
import Vuetify from "vuetify";
// import  'vuetify/newfrontend/vuetify.min.css'
import  '../assets/css/styles-vuetify.css'


Vue.use(Vuetify);

const opts = {}

export default new Vuetify(opts);
