export default {
    state: {
        school_video_open: {},
        school_video_close: {},
    },
    getters: {
        school_video_open(state){
            return state.school_video_open;
        },
        school_video_close(state){
            return state.school_video_close;
        }
    },
    actions: {
        asyncVideoOpen(context, payload) {
            context.commit('updateVideoOpen', payload);
        },
        asyncVideoClose(context, payload) {
            context.commit('updateVideoClose', payload);
        }
    },
    mutations: {
        updateVideoOpen(state, data) {
            this.state.school.school_video_open = data;
        },
        updateVideoClose(state, data) {
            this.state.school.school_video_close = data;
        }
    }
}