<template>
  <div class="instuction">
        <div class="container">
            <div class="content" v-if="page">
                <div class="left">
                    <a v-for="(category, key) in categories" :key="key" v-on:click="openPage(category)" class="d-block" :class="(category.url== page.url) ? 'active' : ''">
                      {{category.question}}
                    </a>
                </div>
                <div class="right">
                  <h1>{{page.question}}</h1>
                  <div v-html="page.text"></div>
                </div>

            </div>
          <div v-else class="loading">
            <loaderMc></loaderMc>
            {{__('loading')}}
          </div>
        </div>
    </div>
</template>

<script>

import _ from 'lodash';
import loaderMc from "../SvgComponents/loaderMc";

export default {
  name: 'Instructions-cab',
    data: function() {
        return {
            panel: 0,
            categories: null,
            page: null,
        }
  },
  watch: {
      lang() {
          this.getCreate();
      }
  },
  methods:{
      getCreate() {
          this.$http.get('ajax/instruction/'+this.lang)
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  let categories = response.data;

                  this.categories= categories;
                  let page;

                  if(this.$route.query.page) {
                      page= _.find(categories, {'url': this.$route.query.page});
                  } else {
                      page= categories[0];
                  }

                  if(page) {
                      this.page= page;
                  }

              }).catch((err) => {
              this.httpHandler(err);
          });
      },
      openPage(page) {
          if(page.url!= this.page.url) {
              this.page= page;
              this.$router.push({name: this.$route.name, query: {'page': page.url}})
          }
      }
  },
  created() {
      this.getCreate();
  },
  computed: {
      lang() {
          return this.$store.state.language.lang
      }
  },
  mounted() {

  },
  components: {
      loaderMc,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
