<template>
    <div class="supp-form">
      <form>
        <h2>{{__('Contact form')}}</h2>
        <div class="block">
          <p>{{__('Your name:')}}</p>
          <InputLabelTextDefault type="text" disabled :value="portfolio.name"></InputLabelTextDefault>
        </div>
        <div class="block">
          <p>{{__('E-mail:')}}</p>
          <InputLabelTextDefault type="email" disabled :value="portfolio.email"></InputLabelTextDefault>
        </div>
        <div class="block">
          <p>{{__('Subject')}}</p>
          <DropdownDefault
              v-bind:items="items"
              class="filter drop-site"
              classDropdown="drop-list"
              v-model="form.subject"
          ></DropdownDefault>
        </div>
        <div class="block block-area">
          <p>{{__('Message:')}}</p>
          <InputLabelTextDefault type="textarea" v-model="form.text" v-bind:errors="formValidate.text" @change="updateValidate('text')"></InputLabelTextDefault>
        </div>
        <div class="btn-form">
          <v-btn class="btn-site" :loading="submitDisabled" v-on:click="openCaptcha">{{__('Send message')}}</v-btn>
        </div>
      </form>
      <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>

<script>

  import Captcha from "../../modules/Captcha";
  import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
  import DropdownDefault from "../../modules/DropdownDefault";
  import {required} from "vuelidate/lib/validators";

  export default {
      data () {
          return {
            items: [
                    this.__('Investment'),
                    this.__('Withdrawal'),
                    this.__('Other')
            ],
            form: {
                subject: null,
                text: null
            },
            formValidate: {
                subject: null,
                text: null,
            },
            submitDisabled: false,
            captcha: false,
          }
      },
      validations() {
          return {
              form: {
                  subject: {
                      required,
                  },
                  text: {
                      required,
                  },
              }
          }
      },
      methods: {
          updateValidate: function(type) {
              this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
          },
          openCaptcha() {
              let valid= this.$v.form;
              if(valid.$invalid) {
                  let massages= {
                      'subject': [],
                      'text': [],
                  };
                  this.formValidate= this.$formValidate(massages, valid);
              } else {
                  this.captcha= true;
              }
          },
          onSubmit: function (session, arrayData) {
              let valid= this.$v.form;
              if(valid.$invalid) {
                  let massages= {
                      'subject': [],
                      'text': [],
                  };
                  this.formValidate= this.$formValidate(massages, valid);
              } else {
                  this.submitDisabled = true;

                  let response = this.form;
                  response['email']= this.portfolio.email;
                  response['name']= this.portfolio.name;
                  response['challenge']= session;
                  response[session]= arrayData;

                  this.$http.post('ajax/support', response)
                      .then(response => {
                          return response.json()
                      })
                      .then((response) => {
                          this.$store.dispatch('asyncResp', response.resp_tickets);
                          this.form= {
                              subject: null,
                              text: null
                          };
                          this.$notify({
                              group: 'message',
                              title: 'Send message',
                              type: 'success'
                          });
                          this.submitDisabled= false;
                          this.captcha= false;
                      }).catch((err) => {
                          this.captcha= false;
                          this.submitDisabled= false;
                          let response= this.httpHandler(err);

                          if(response.error) {
                              this.formValidate = response.error.message;
                          }
                      });
              }
          },
      },
      computed: {
          portfolio() {
              return this.$store.state.auth.portfolio
          },
      },
      components: {
        DropdownDefault,
        InputLabelTextDefault,
        Captcha
      }
  }
</script>

<style>

</style>
