<template>
    <div class="fin-operation">
      <div class="dep-step1" v-if="payments">
          <Deposit v-bind:payment.sync="payment" @updateDeposit="updateDeposit"></Deposit>
      </div>
      <div v-else class="loading">
          <loaderMc></loaderMc>
         {{__('Loading')}}
      </div>
    </div>
</template>

<script>
    import Deposit from "../Finance/Deposit";
    import loaderMc from "../../SvgComponents/loaderMc";

export default {
  data () {
    return {
        payment: null
    }
  },
  methods:{
      updateDeposit(response) {
          let history= response.history;
          this.$emit('update:transactions', history);
      }
  },
  props: {

  },
  asyncComputed: {
      asyncPayment() {
          if(this.payments) {
              this.payment= this.payments[0];
          }
          return true;
      }
  },
  computed: {
      payments() {
          return this.$store.state.auth.payments
      },
  },
  components: {
      loaderMc,
      Deposit,
  }
}
</script>

<style scoped>

</style>
