<template>
    <div class="security">
      <div class="top">
        <div>
          <img src="/newfrontend/images/pass-new.svg" alt="">
          <p class="semi">{{__('Password')}}</p>
        </div>
        <v-btn
                class="btn-white-new"
                v-on:click="modal_success= 'pass'"
        >
          {{__('update password')}}
        </v-btn>
      </div>

      <ModalDefault v-if="modal_success" v-bind:modal_cancel.sync="modal_success">
        <div class="pass-window window-site" v-if="modal_success=== 'pass'">
          <div class="body">
            <form autocomplete="off">
              <h2>{{__('Password123')}}</h2>
              <div class="block">
                <p class="semi">{{__('Old password')}}:</p>
                <InputLabelTextDefault type="password" :placeholder="__('Enter current password')" class="" v-model="form.current_password" v-bind:errors="formValidate.current_password" @change="updateValidate('current_password')"></InputLabelTextDefault>
              </div>
              <div class="block">
                <p class="semi">{{__('New password')}}:</p>
                <InputLabelTextDefault type="password" :placeholder="__('Enter new password')" class="" v-model="form.password" v-bind:errors="formValidate.password" @change="updateValidate('password'), updateValidate('new_password', 'sameAsPassword')"></InputLabelTextDefault>
              </div>
              <div class="block">
                <p class="semi">{{__('Repeat password')}}:</p>
                <InputLabelTextDefault type="password" :placeholder="__('Enter confirm password')" class="" v-model="form.new_password" v-bind:errors="formValidate.new_password" @change="updateValidate('new_password')"></InputLabelTextDefault>
              </div>
            </form>
          </div>
          <div class="security-btn">
            <v-btn class="btn-green-new" v-on:click="onSubmit" :loading="submitDisabled">{{__('Save changes')}}</v-btn>
          </div>
        </div>
        <div class="text-center pass-window window-site" v-else>
          <h2>{{__('Success save password')}}</h2>
          <div class="text-center text-window">
            <p>{{__('Password success update')}}</p>
            <div class="btn-window">
              <v-btn class="btn-site" v-on:click="modal_success= false">{{__('Ok')}}</v-btn>
            </div>
          </div>
        </div>
      </ModalDefault>

      <ModalDefault class_name="success-window" v-if="modal_setting" v-bind:modal_cancel.sync="modal_setting" @modalCancel="modalCancel">
        <div v-if="settingLoader" class="loading">
          <loaderMc></loaderMc>
          {{__('Loading')}}
        </div>
        <div class="text-center security-window window-site" v-else>
          <h2>{{__('The code was sent to the mail')}}</h2>
          <div class="text-center text-window">
            <p class="text" v-if="modal_setting== 'two-fa'">{{__('Email Fa')}}</p>
            <p class="text" v-else>{{__('With')}}</p>
            <div v-if="timeResend > 0">
              <p>{{__('Re-shipment will be available through')}} {{timeResend}} {{__('sec')}}</p>
            </div>
            <div v-else class="resend">
              <v-btn v-on:click="sendEmail(modal_setting)">{{__('Resend code')}}</v-btn>
            </div>
            <div class="password">
              <InputLabelTextDefault placeholder="code" class="" v-model="two_fa.code" v-bind:errors="formValidate.code" @change="updateValidate('code',false,'two_fa')"></InputLabelTextDefault>
            </div>
            <div class="btn-window">
              <v-btn  v-on:click="onSubmitSetting(modal_setting)" :loading="submitSettingDisabled" class="btn-site">{{__('Ok')}}</v-btn>
              <span class="btn-waiting-cancel btn-grey" v-on:click="modalCancel(modal_setting)">{{__('Cancel')}}</span>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
</template>

<script>

import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
import ModalDefault from "../../modules/ModalDefault";
import {sameAs, required, numeric} from "vuelidate/lib/validators";
  import loaderMc from "../../SvgComponents/loaderMc";
export default {
    data () {
        return {
          form: {
              password: null,
              new_password: null,
              current_password: null,
          },
          formValidate: {
              password: null,
              new_password: null,
              current_password: null,
              code: null
          },
          two_fa: {
              code: null
          },
          setting: this.$cleanObject(this.personal_info.setting),
          submitDisabled: false,
          submitSettingDisabled: false,
          notifications: null,
          payment: null,
          authorization: null,
          modal_success: false,
          settingLoader: false,
          modal_setting: false,
          timeResend: null,
          interval: null
        }
    },
    props: {
        personal_info: {
            type: Object
        }
    },
    validations: {
        form: {
            current_password: {
                required
            },
            password: {
                required
            },
            new_password: {
                required,
                sameAsPassword: sameAs(function() {
                    if(this.form.new_password && this.form.new_password.length) {
                        return (this.form.new_password== this.form.password) ? this.form.new_password : false;
                    } else {
                        return this.form.new_password;
                    }
                }),
            },
        },
        two_fa: {
            code: {
                required,
                numeric
            },
        }
    },
    watch: {
        modal_setting() {
            this.two_fa.code= null;
        }
    },
  methods: {
      modalCancel: function(type) {
          let personal_info= this.$cleanObject(this.personal_info);

          if(type== 'two-fa') {
              this.setting.email_fa= personal_info.setting.email_fa;
          } else {
              this.setting.email_with= personal_info.setting.email_with;
          }
          this.modal_setting= false
      },
      sendEmail: function(type) {
          if(type != 'auth') {
              this.modal_setting= type;
          }
          this.settingLoader= true;

          this.$http.get('ajax/setting/email/'+type)
              .then(response=> {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  this.settingLoader= false;
                  if(type == 'auth') {
                      response= response.data;
                      let personal_info= this.$cleanObject(this.personal_info);
                      personal_info.setting.email_auth= this.setting.email_auth= response.enable;
                  } else {
                      clearInterval(this.interval);
                      let timeResend = 10;
                      if (response.data != 'success') {
                          timeResend = response.data.time;
                      }

                      this.timeResend = timeResend;

                      this.interval = setInterval(() => {
                          timeResend -= 1;

                          if (timeResend >= 0) {
                              this.timeResend = timeResend;
                          } else {
                              clearInterval(this.interval);
                          }
                      }, 1000);
                  }
              }).catch(err => {
                  this.settingLoader= false;
                  let response= this.httpHandler(err);
                  if(response.error) {
                      this.formValidate = response.error.message;
                  }
              });

      },
      updateValidate: function(type, validate= false,  key= 'form') {
          if (validate) {
              let formValidate = this.formValidate;
              formValidate[type] = [];

              if (!this.$v.form[type][validate]) {
                  let msg = this.__(this.$validateMessage[validate]);
                  formValidate[type].push(msg);

              }

              this.formValidate = formValidate;
          } else {
              this.formValidate = this.$changeValidate(this.formValidate, this.$v[key][type], type);
          }
      },
      onSubmitSetting: function (type) {

          let valid= this.$v.setting;
          if(valid.$invalid) {
              let massages= {
                  'code': [],
              };
              this.formValidate= this.$formValidate(massages, valid);
          } else {
              this.submitSettingDisabled = true;

              let response = {
                  'code': this.two_fa.code,
              };
              this.$http.post('ajax/setting/email/'+type, response)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      // response= {
                      //     "data": {
                      //         "success": true,
                      //         "enable": 0
                      //     },
                      //     "status": 200
                      // };
                      response= response.data;
                      let personal_info= this.$cleanObject(this.personal_info);

                      if(response.enable !== undefined) {
                          if(type== 'two-fa') {
                              personal_info.setting.email_fa= this.setting.email_fa= response.enable;

                          } else {
                              personal_info.setting.email_with= this.setting.email_with= response.enable;
                          }
                      }
                      this.$emit('update:personal_info', personal_info);

                      this.modal_setting= false;
                      this.submitSettingDisabled= false;
                  }).catch((err) => {
                  this.submitSettingDisabled= false;
                  this.httpHandler(err);
              });
          }
      },
      onSubmit: function () {
          let valid= this.$v.form;

          if(valid.$invalid) {
              let massages= {
                  'password': [],
                  'current_password': [],
                  'new_password': [],
              };
              this.formValidate= this.$formValidate(massages, valid);
          } else {
              this.submitDisabled= true;
              let response= {
                  'password': this.form.password,
                  'password_confirmation': this.form.new_password,
                  'current_password': this.form.current_password,
              };


              this.$http.post('ajax/setting/password', response)
                  .then(response=> {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      this.submitDisabled= false;

                      this.form= {
                          password: null,
                          new_password: null,
                          current_password: null,
                      };
                      this.modal_success= true;
                  }).catch(err => {
                  this.submitDisabled= false;
                  let response= this.httpHandler(err);
                  if(response.error) {
                      this.formValidate = response.error.message;
                  }
              });
          }
      },
  },
  computed:{

  },

  components: {
    loaderMc,
    InputLabelTextDefault,
    ModalDefault,
  }
}
</script>

<style scoped>

</style>
