<template>
  <div class="wallet-promo">
    <div class="top">
      <p class="name">{{__('Get 1000% one of the first')}}</p>
      <p class="disc">{{__('by purchasing a token of a unique crypto wallet')}}</p>
      <span>{{__('Multiwallet')}}</span>
    </div>
    <div class="body">
      <div class="content">
        <div class="left-block">
          <p class="name">{{__('Total purchase of MW tokens for MC')}}</p>
          <div class="progress">
            <v-progress-linear
                    :value="progress1"
                    striped
                    color="light-blue"
                    class="progress-bar"
                    height="16px"
            >
            </v-progress-linear>
          </div>
          <p class="summ">{{$primNum(info.token_mc_buy,2)}}<span>/{{$primNum(info.token_mc_max,2)}}</span></p>
          <a class="btn-green-new" v-on:click="mc= true">{{__('buy MW for MC')}}</a>
        </div>
        <div class="right-block">
          <p class="name">{{__('Total purchase of MW tokens for USDT')}}</p>
          <div class="progress">
            <v-progress-linear
                    :value="progress2"
                    striped
                    color="light-blue"
                    class="progress-bar"
                    height="16px"
            >
            </v-progress-linear>
          </div>
          <p class="summ">{{$primNum(info.token_usdt_buy,2)}}<span>/{{$primNum(info.token_usdt_max,2)}}</span></p>
          <a class="btn-green-new" v-on:click="usdt= true">{{__('buy MW for usdt')}}</a>
        </div>
      </div>
      <div class="body-text">
        <div class="left-text">
          <img src="/newfrontend/images/multi-wallets.svg" alt="">
          <div>
            <p>{{__('Your tokens')}}</p>
            <span>{{$primNum(info.user_token,2)}} MW</span>
          </div>
        </div>
        <div class="right-text">
          <div class="mc">
            <p>{{__('Invested by MC')}}</p>
            <span>{{$primNum(info.user_invest_mc,2)}}</span>
          </div>
          <div class="usdt">
            <p>{{__('Invested by USDT')}}</p>
            <span>{{$primNum(info.user_invest_usdt,2)}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-block">
      <div>
        <img src="/newfrontend/images/wallet-img.svg" alt="">
      </div>
      <div>
        <a class="href">{{__('What does Multiwallet give?')}}</a>
        <a href="https://docs.google.com/spreadsheets/d/1utr3wXaEYXkN5fMah89zIkuUv1FKXCQftPmJ9oz1-hI/edit#gid=1409530347" target="_blank" class="btn-white-new">{{__('Tokenomics')}}</a>
      </div>
    </div>

    <ModalDefault v-bind:close="false" v-if="mc" v-bind:modal_cancel.sync="mc">
      <div class="window-site window-mc">
        <div class="top">
          <div>
            <p class="buy">{{__('Buy MW with MC')}}</p>
            <p class="mw">{{__('Course: 1 MW')}} = ${{courseMW_MC}}</p>
          </div>
          <p class="text">{{__('Select the number of tokens you buy. The amount will be debited from the MC account.')}}</p>
        </div>
        <div class="block-input">
          <div class="curse">
            <p class="text">{{__('Number of MW tokens')}}</p>
            <p class="balance">{{__('Available balance:')}} <span> {{$primNum(info.balance_available_MC,2)}} MC</span></p>
          </div>
          <div class="input-text">
            <InputLabelTextDefault
                type="number"
                v-model="form1.amount_mc"
                v-bind:errors="formValidate.amount_mc"
                placeholder="0.00 MW"
                v-on:input="updateValidate('form1','amount_mc')"
            ></InputLabelTextDefault>
            <p class="text-mc">{{__('Price:')}} <span>{{$primNum(form1.amount_mc/courseMW_MC_USD, 2)}} MC</span></p>
          </div>
          <div class="btn-block">
            <a class="btn-white-new" v-on:click="mc= false">{{__('Cancel')}}</a>
            <v-btn
                  class="btn-green-new"
                 :disabled="$v.form1.$invalid"
                  v-on:click="onSubmit('form1')"
                  :loading="submitDisabled"
            >{{__('Buy MW tokens')}}</v-btn>
          </div>
        </div>
      </div>
    </ModalDefault>


    <ModalDefault v-bind:close="false" v-if="usdt" v-bind:modal_cancel.sync="usdt">
      <div class="window-site window-mc">
        <div class="top">
          <div>
            <p class="buy">{{__('Buy MW with USDT')}}</p>
            <p class="mw">{{__('Course: 1 MW')}} = ${{courseMW}}</p>
          </div>
          <p class="text">{{__('Select the number of tokens you buy. Also select from which account the amount will be withdrawn - the main, partner or both accounts.')}}</p>
        </div>
        <div class="block-input">
          <div class="curse">
            <p class="text">{{__('Amount MW (main account)')}}</p>
            <p class="balance">{{__('Available balance:')}} <span> {{$primNum(info.balance, 2)}}</span></p>
          </div>
          <div class="input-text">
            <InputLabelTextDefault
                type="number"
                v-model="form2.amount_usdt_main"
                v-bind:errors="formValidate.amount_usdt_main"
                placeholder="0.00 MW"

            ></InputLabelTextDefault>
            <p class="text-mc">{{__('Price:')}} <span>{{$primNum(form2.amount_usdt_main* courseMW, 2)}} USDT</span></p>
          </div>
          <div class="curse">
            <p class="text">{{__('Amount MW (affiliate account)')}}</p>
            <p class="balance">{{__('Available balance:')}} <span> {{$primNum(info.balancePartner, 2)}} USDT</span></p>
          </div>
          <div class="input-text">
            <InputLabelTextDefault
                type="number"
                v-model="form2.amount_usdt_partner"
                v-bind:errors="(formValidate.amount_usdt) ? formValidate.amount_usdt : formValidate.amount_usdt_partner"
                placeholder="0.00 MW"
            ></InputLabelTextDefault>
            <p class="text-mc">{{__('Price:')}} <span>{{$primNum(form2.amount_usdt_partner* courseMW, 2)}} USDT</span></p>
          </div>
          <div class="btn-block">
            <a class="btn-white-new" v-on:click="usdt= false">{{__('Cancel')}}</a>
            <v-btn
                class="btn-green-new"
                :disabled="$v.form2.$invalid"
                v-on:click="onSubmit('form2')"
                :loading="submitDisabled"
            >{{__('Buy MW tokens')}}</v-btn>
          </div>
        </div>
      </div>
    </ModalDefault>

    <ModalDefault width="470" v-bind:close="false" v-if="done" v-bind:modal_cancel.sync="done">
      <div class="window-site window-done">
        <img src="/newfrontend/images/block-success.png" alt="">
        <p class="name">{{__('congratulations on the successful purchase of the MW token')}}</p>
        <div class="btn-block">
          <a class="btn-green-new">{{__('Done')}}</a>
        </div>
      </div>
    </ModalDefault>


  </div>
</template>

<script>


    import ModalDefault from "./ModalDefault";
    import InputLabelTextDefault from "./InputLabelTextDefault";
    import {decimal, maxValue, minValue, required} from "vuelidate/lib/validators";
    export default {
        name: 'WallesPromo',

        data: function() {
            let courseMW= 0.69;
            let courseMW_MC= this.portfolio.courseMC/ courseMW;

            return {
              form1: {
                  amount_mc: null
              },
              form2: {
                  amount_usdt: null,
                  amount_usdt_main: null,
                  amount_usdt_partner: null
              },
              formValidate: {
                  amount_usdt: null,
                  amount_mс: null,
                  amount_usdt_main: null,
                  amount_usdt_partner: null,
              },
              courseMW: 0.79,
              courseMW_MC: courseMW,
              courseMW_MC_USD: courseMW_MC,
              mc: false,
              usdt: false,
              submitDisabled: false,
              done: false
            }
        },
        props: {
            promo: {
                type: [
                    String, Number
                ],
            },
            info: {
                type: Object
            },
            portfolio: {
                type: Object
            }
        },
        validations() {
            return {
                form1: {
                    amount_mc: {
                        required,
                        decimal,
                        minValue: minValue(this.$primNum(10*this.courseMW_MC_USD,2)),
                        // limit: maxValue((200/this.payment.course_usd)- ((this.portfolio.sum_invest) ? this.portfolio.sum_invest : 0))
                        maxValue: maxValue(Number(this.$primNum(this.info.balance_available_MC*this.courseMW_MC_USD, 2)))

                        // maxValue: maxValue(this.payment.max_replenish*this.payment.course_usd)
                    }
                },
                form2: {
                    amount_usdt_main: {
                        decimal,
                        // minValue: minValue(20),
                        // limit: maxValue((200/this.payment.course_usd)- ((this.portfolio.sum_invest) ? this.portfolio.sum_invest : 0))
                        maxValue: maxValue(Number(this.$primNum(this.info.balance/this.courseMW, 2)))

                        // maxValue: maxValue(this.payment.max_replenish*this.payment.course_usd)
                    },
                    amount_usdt_partner: {
                        decimal,
                        // minValue: minValue(20),
                        // limit: maxValue((200/this.payment.course_usd)- ((this.portfolio.sum_invest) ? this.portfolio.sum_invest : 0))
                        maxValue: maxValue(Number(this.$primNum(this.info.balancePartner/this.courseMW, 2)))

                        // maxValue: maxValue(this.payment.max_replenish*this.payment.course_usd)
                    },
                    amount_usdt: {
                        required,
                        decimal,
                        minValue: minValue(this.$primNum(20/this.courseMW, 2)),
                        maxValue: maxValue(this.$primNum(180000/this.courseMW, 2)),
                    }
                }
            }
        },
        computed: {
            progress1() {
                let percent= (this.info.token_mc_max/100);
                let progeres= this.info.token_mc_buy/percent;
                if(progeres > 100) {
                    progeres= 100;
                }

                return progeres;
            },
            progress2() {
                let percent= (this.info.token_usdt_max/100);
                let progeres= this.info.token_usdt_buy/percent;
                if(progeres > 100) {
                    progeres= 100;
                }


                return progeres;
            },
        },
        watch: {
            'form2.amount_usdt_partner'() {
                this.updateValidate('form2', 'amount_usdt_partner');

                this.form2.amount_usdt= Number(this.form2.amount_usdt_partner)+ Number(this.form2.amount_usdt_main);

                this.updateValidate('form2', 'amount_usdt');
            },
            'form2.amount_usdt_main'() {
                this.updateValidate('form2', 'amount_usdt_main');
                this.form2.amount_usdt= Number(this.form2.amount_usdt_partner)+ Number(this.form2.amount_usdt_main);

                this.updateValidate('form2','amount_usdt');
            }
        },

        created() {

        },
        methods: {
            updateValidate: function(form, type) {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v[form][type], type);
            },
            onSubmit(type= 'form1') {
                let valid = this.$v[type];
                if (valid.$invalid) {
                    let massages = {
                        'amount_mc': [],
                        'amount_usdt': [],
                        'amount_usdt_main': [],
                        'amount_usdt_partner': []
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    let form;

                    if(type== 'form1') {
                        form= {
                            amount_mc: this.$primNum(this.form1.amount_mc/this.courseMW_MC_USD, 2),
                            amount_mw: this.$primNum(this.form1.amount_mc, 2),
                        };
                    } else {
                        form= {
                            amount_usdt_main: this.$primNum(this.form2.amount_usdt_main*this.courseMW, 2),
                            amount_usdt_partner: this.$primNum(this.form2.amount_usdt_partner*this.courseMW, 2),
                        };
                    }

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/buy_token_mw', form)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            this.submitDisabled = false;
                            this.mc=  false;
                            this.usdt=  false;

                            this.form1.amount_mc= null;
                            this.form2.amount_usdt_main= null;
                            this.form2.amount_usdt_partner= null;

                            this.$emit('updatePromo7', response.data);
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            this.mc=  false;
                            this.usdt=  false;

                            this.form1.amount_mc= null;
                            this.form2.amount_usdt_main= null;
                            this.form2.amount_usdt_partner= null;

                            this.httpHandler(err);
                        });
                }
            },
        },
        components: {
          InputLabelTextDefault,
          ModalDefault

        }
    }
</script>
<style>

</style>