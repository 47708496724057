<template>
  <div>
<!--    <div v-if="false">-->
    <div v-if="body && body.total > 0">
      <PaginationDefault v-bind:data.sync="body" v-bind:type="type" v-bind:params="params" v-bind:showing="showing" @updateData="updateData">
        <template v-slot="page">
          <ul class="ref-list" v-if="headers">
            <li>
                <div class="list" v-for="(item, key) in headers" :key="key">{{item}}</div>
            </li>
            <li v-for="(item, key) in page" :key="key">
              <slot v-bind="item"></slot>
            </li>
          </ul>
          <component :is="tag" v-else :class="class_name">
            <slot name="header"></slot>
            <slot v-for="(item) in page" v-bind="item"></slot>
          </component>
        </template>
      </PaginationDefault>
    </div>
    <div v-else-if="body && body.total == 0" class="no-result">
      <slot name="no-result">
        <img src="/newfrontend/images/chart.svg" alt="">
        {{__('no result')}}
      </slot>
<!--    <slot name="no-result" v-else-if="body && body.total == 0" class="no-result">-->
    </div>
    <div v-else class="loading">
      <loaderMc></loaderMc>
      {{__('Loading')}}
    </div>
  </div>
</template>

<script>
    import PaginationDefault from "./PaginationDefault";
    import loaderMc from "../SvgComponents/loaderMc";

    export default {
        name: 'TablePagination',
        data () {
          return {
            loading: false,
          }
        },
        created: function () {

        },
        props: {
            headers: {
                type: Array,
                default: null
            },
            body: {
                type: Object,
            },
            type: {
                type: String,
                default: 'post'
            },
            params: {
                type: Object
            },
            showing: {
                type: Boolean,
                default: false
            },
            tag: {
                type: [
                    String
                ],
                default: 'div'
            },
            class_name: {
                type: [
                    String
                ]
            }
        },
        methods:{
            updateData(response) {
                this.$emit('updateData', response);
            }
        },
        computed: {

        },
        components: {
            loaderMc,
            PaginationDefault,
        }
    }
</script>
