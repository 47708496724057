<template>
    <div class="personal" v-if="affiliate">
      <form>
        <div class="top">
          <div class="personal-input">
            <div class="block">
              <p class="semi">{{__('E-mail')}}:</p>
              <InputLabelTextDefault disabled placeholder="" v-model="portfolio.email"></InputLabelTextDefault>
            </div>
            <div class="block">
              <p class="semi">{{__('Full name')}}:</p>
              <InputLabelTextDefault placeholder="" v-model="form.name" v-bind:errors="formValidate.name" @change="updateValidate('name')"></InputLabelTextDefault>
            </div>
            <div class="block" >
              <p class="semi">{{__('My invitee')}}:</p>
              <InputLabelTextDefault disabled :placeholder="(personal_info.upline) ? personal_info.upline : null"></InputLabelTextDefault>
            </div>
          </div>
          <div class="avatar">
            <FileProgressAvatar v-bind:response.sync="response_upload" assets="/assets/uploads/user/" url="/ajax/setting/avatar" v-bind:img="portfolio.photo"></FileProgressAvatar>
          </div>
        </div>
        <div class="personal-btn">
          <v-btn class="btn-green-new" v-on:click="onSecurity" :loading="submitDisabled">{{__('Update profile')}}</v-btn>
        </div>
      </form>
    </div>
    <div v-else class="loading">
      <loaderMc></loaderMc>
      {{__('Loading')}}
    </div>
</template>

<script>

import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import FileProgressAvatar from "../../modules/FileProgressAvatar";
import {required, minLength, maxLength} from "vuelidate/lib/validators";
    import loaderMc from "../../SvgComponents/loaderMc";
export default {
  data () {
      return {
        affiliate: null,
        form: {
            name: this.personal_info.name,
        },
        formValidate: {
            name: null,
        },
        submitDisabled: false,
        response_upload: null,
      }
  },
  props: {
      personal_info: {
          type: Object
      }
  },
  validations: {
      form: {
          name: {
              required,
              minLength: minLength(2),
              maxLength: maxLength(50),
          },
      }
  },
  methods: {
    getAffiliate() {
      this.$http.get('ajax/affiliate')
              .then(response => {
                return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  response=response.data;
                  this.affiliate= response;
              }).catch((err) => {
        this.httpHandler(err);
      });
    },
      updateValidate: function(type, validate= false, key= 'form') {
          if(validate) {
              let formValidate= this.formValidate;
              formValidate[type]= [];

              if(!this.$v.form[type][validate]) {
                  let msg= this.__(this.$validateMessage[validate]);
                  formValidate[type].push(msg);

              }

              this.formValidate= formValidate;
          } else {
              this.formValidate= this.$changeValidate(this.formValidate, this.$v[key][type], type);
          }
      },
      onSecurity: function () {
          let valid = this.$v.form;

          if (valid.$invalid) {
              let massages = {
                  'name': [],
              };
              this.formValidate = this.$formValidate(massages, valid);
          } else {
              this.submitDisabled= true;
              let response= this.form;

              this.$http.post('ajax/setting/personal', response)
                  .then(response=> {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      // response= {
                      //     "data": {
                      //         "success": true,
                      //         "enable": true
                      //     },
                      //     "status": 200
                      // }
                      this.$notify({
                          group: 'message',
                          title: 'Save personal',
                          type: 'success'
                      });

                      let portfolio= this.$cleanObject(this.portfolio);
                      portfolio.name= this.form.name;

                      this.$store.dispatch('asyncPortfolio', portfolio);
                      this.submitDisabled= false;
                  }).catch(err => {
                  this.submitDisabled= false;
                  let response= this.httpHandler(err);
                  if(response.error) {
                      this.formValidate = response.error.message;

                  }
              });
          }
      }
  },
  watch: {
      response_upload: function (payload) {
          this.$store.dispatch('asyncUserAvatar', payload.img);
      },
  },
  computed:{
      portfolio() {
          return this.$store.state.auth.portfolio
      },
  },
  created() {
    this.getAffiliate();
  },
  components: {
    loaderMc,
    InputLabelTextDefault,
    FileProgressAvatar,
  }
}
</script>

<style scoped>

</style>
