<template>
    <div class="media-page-all">
        <div class="container media-page" v-if="blog">
            <!--      <h1>{{__('Media-center')}}</h1>-->
            <div class="content">
                <div class="top">
                    <div class="left">
                        <p class="crypto-text">{{__('Cryptocurrency news')}}</p>
                    </div>
                    <div class="right">
                        <img class="img" src="/newfrontend/images/media-search.svg" alt="">
                        <img class="img-search" src="/newfrontend/images/news-close.svg" alt="" v-if="search" v-on:click="closeSearch">
                        <SearchDefault class_search="media" :placeholder="__('Search by title')" v-model="search" @searching="searching"></SearchDefault>
                    </div>
                </div>
                <PaginationDefault class="body"  v-bind:data="blog" type="post" v-bind:params="params" v-bind:showing="true" @updateData="updateData">
                    <template v-slot="pages" class="body-block" >
                        <div class="block" v-for="(item, key) in pages" :key="key">
                            <router-link tag="div" :style='{backgroundImage: "url("+item.img+")"}' class="block-top" :to="{name: 'news_crypto_page', params: {url: item.url}}">
<!--                                <img class="img" :src="item.img" >-->
                            </router-link>
                            <div class="block-body">
                                <div class="d-flex justify-space-between">
                                    <p>{{item.created_at | moment('MMM DD, YYYY')}}</p>
                                    <span>{{__(category)}}</span>
                                </div>
                                <p class="title">
                                    <router-link :to="{name: 'news_crypto_page', params: {url: item.url}}">
                                        {{item.title}}
                                    </router-link>
                                </p>
                                <div class="body-body">
                                    <!--                      <v-btn class="block-btn" v-on:click="video= item.url" v-if="item.category== 'video'">{{__('Play')}}</v-btn>-->
                                    <v-btn class="block-btn" :to="{name: 'news_crypto_page', params: {url: item.url}}">{{__('read more')}}
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z" />
                                        </svg>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </template>
                </PaginationDefault>
            </div>
        </div>
        <div v-else class="loading">
            <loaderMc></loaderMc>
            {{__('Loading')}}
        </div>
    </div>

</template>

<script>
import SearchDefault from "../modules/SearchDefault";
import PaginationDefault from "../modules/PaginationDefault";
  import loaderMc from "../SvgComponents/loaderMc";
export default {
  name: 'mediaCrypto',
    data: function() {
        return {
            category: 'news_crypto',
            params: {
                search: null,
                lang: null
            },
            video: false,
            search: null,
            blog: null,
        }
  },
  watch: {
      lang(val) {
          this.params.lang= val;
          this.getBlog();
      }
  },
  methods:{
      closeSearch() {
          this.search= null;
          this.searching();
      },
      searching() {
          this.params.search= this.search;

          this.getBlog();
      },
      updateData(response) {
          this.blog= response.blog;
          window.scrollTo(0,0);
      },
      getBlog() {
          let params= this.$cleanObject(this.params);

          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/cryptonews', params)
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  response = response.data;
                  this.blog = response.blog;
                  // if(!this.category) {
                  // }
              }).catch((err) => {
              this.httpHandler(err);
          });
      }
  },
  created() {
      this.params.lang= this.lang;
      this.getBlog();
  },
  computed: {
      lang() {
          return this.$store.state.language.lang
      }
  },
  mounted() {

  },
  components: {
      loaderMc,
      PaginationDefault,
      SearchDefault,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
