<template>
    <div class="team-home" v-if="team">
        <div class="team-home-top">
            <div class="container">
                <p class="h1-text">Meet our team</p>
                <h1>Meet our team</h1>

<!--              <pre>-->
<!--                {{team}}-->
<!--              </pre>-->
                <div>
                    <VueSlickCarousel v-bind="settings" v-bind:initialSlide="30">
                        <div class="block" v-for="(item, key) in teamSlide" :key="key">
                            <div class="team">
                                <div class="img">
                                    <img :src="item.image" alt="">
<!--                                    <img src="/newfrontend/images/NicePng_businessman-png_773911.png" alt="">-->
<!--                                    <a :href="item.url" target="_blank">-->
<!--                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                            <path d="M14.5862 0H5.41382C2.42859 0 0 2.42859 0 5.41382V14.5863C0 17.5714 2.42859 20 5.41382 20H14.5863C17.5714 20 20 17.5714 20 14.5863V5.41382C20 2.42859 17.5714 0 14.5862 0V0ZM18.8275 14.5863C18.8275 16.9249 16.9249 18.8275 14.5862 18.8275H5.41382C3.0751 18.8275 1.17249 16.9249 1.17249 14.5863V5.41382C1.17249 3.0751 3.0751 1.17249 5.41382 1.17249H14.5863C16.9249 1.17249 18.8275 3.0751 18.8275 5.41382V14.5863Z" />-->
<!--                                            <path d="M9.99985 4.53125C6.98441 4.53125 4.53125 6.98441 4.53125 9.99985C4.53125 13.0153 6.98441 15.4684 9.99985 15.4684C13.0153 15.4684 15.4684 13.0153 15.4684 9.99985C15.4684 6.98441 13.0153 4.53125 9.99985 4.53125ZM9.99985 14.296C7.63107 14.296 5.70374 12.3688 5.70374 9.99985C5.70374 7.63107 7.63107 5.70374 9.99985 5.70374C12.3688 5.70374 14.296 7.63107 14.296 9.99985C14.296 12.3688 12.3688 14.296 9.99985 14.296Z"/>-->
<!--                                            <path d="M15.5993 2.58936C14.7082 2.58936 13.9834 3.3143 13.9834 4.20526C13.9834 5.09637 14.7082 5.82132 15.5993 5.82132C16.4904 5.82132 17.2154 5.09637 17.2154 4.20526C17.2154 3.31415 16.4904 2.58936 15.5993 2.58936ZM15.5993 4.64868C15.3549 4.64868 15.1559 4.44971 15.1559 4.20526C15.1559 3.96066 15.3549 3.76184 15.5993 3.76184C15.8439 3.76184 16.0429 3.96066 16.0429 4.20526C16.0429 4.44971 15.8439 4.64868 15.5993 4.64868Z"/>-->
<!--                                        </svg>-->
<!--                                    </a>-->
                                </div>
                                <div class="name">
                                    <p class="description">{{item.name}}</p>
                                    <p class="text-g">{{item.position}}</p>
<!--                                    <p class="text">{{item.description}}</p>-->
                                    <p class="text" v-html="item.description"></p>
                                </div>
                            </div>
                        </div>
                        <template #prevArrow>
                            <div class="custom-arrow">
                                <svg width="7" height="12" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.03125 5.53125C7.3125 5.25 7.3125 4.78125 7.03125 4.5L2.78125 0.25C2.46875 -0.0625003 2 -0.0625003 1.71875 0.25L1 0.9375C0.71875 1.25 0.71875 1.71875 1 2L4.03125 5.03125L1 8.03125C0.71875 8.3125 0.71875 8.78125 1 9.09375L1.71875 9.78125C2 10.0937 2.46875 10.0937 2.78125 9.78125L7.03125 5.53125Z"/>
                                </svg>
                            </div>
                        </template>
                        <template #nextArrow>
                            <div class="custom-arrow">
                                <svg width="7" height="12" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.03125 5.53125C7.3125 5.25 7.3125 4.78125 7.03125 4.5L2.78125 0.25C2.46875 -0.0625003 2 -0.0625003 1.71875 0.25L1 0.9375C0.71875 1.25 0.71875 1.71875 1 2L4.03125 5.03125L1 8.03125C0.71875 8.3125 0.71875 8.78125 1 9.09375L1.71875 9.78125C2 10.0937 2.46875 10.0937 2.78125 9.78125L7.03125 5.53125Z"/>
                                </svg>
                            </div>
                        </template>
                    </VueSlickCarousel>
                </div>
                <div class="top-btn">
                    <router-link :to="{name: 'register'}" class="btn-new">
                        <span>{{__('join the')}} <img src="/newfrontend/images/logo-new.svg" alt=""></span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="team-home-body">
            <div class="container">
                <h2>Our ambassadors</h2>
                <ul>
                    <li v-for="(ambassador, key) in team.ambassador" :key="key">
                        <div class="top">
                            <div class="top-img">
                              <img class="photo" :src="(ambassador.image) ? ambassador.image : '/newfrontend/images/default.png'" alt="">
<!--                              <div class="img">-->
<!--                                  <a :href="ambassador.url" target="_blank">-->
<!--                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                          <path d="M14.5862 0H5.41382C2.42859 0 0 2.42859 0 5.41382V14.5863C0 17.5714 2.42859 20 5.41382 20H14.5863C17.5714 20 20 17.5714 20 14.5863V5.41382C20 2.42859 17.5714 0 14.5862 0V0ZM18.8275 14.5863C18.8275 16.9249 16.9249 18.8275 14.5862 18.8275H5.41382C3.0751 18.8275 1.17249 16.9249 1.17249 14.5863V5.41382C1.17249 3.0751 3.0751 1.17249 5.41382 1.17249H14.5863C16.9249 1.17249 18.8275 3.0751 18.8275 5.41382V14.5863Z" />-->
<!--                                          <path d="M9.99985 4.53125C6.98441 4.53125 4.53125 6.98441 4.53125 9.99985C4.53125 13.0153 6.98441 15.4684 9.99985 15.4684C13.0153 15.4684 15.4684 13.0153 15.4684 9.99985C15.4684 6.98441 13.0153 4.53125 9.99985 4.53125ZM9.99985 14.296C7.63107 14.296 5.70374 12.3688 5.70374 9.99985C5.70374 7.63107 7.63107 5.70374 9.99985 5.70374C12.3688 5.70374 14.296 7.63107 14.296 9.99985C14.296 12.3688 12.3688 14.296 9.99985 14.296Z"/>-->
<!--                                          <path d="M15.5993 2.58936C14.7082 2.58936 13.9834 3.3143 13.9834 4.20526C13.9834 5.09637 14.7082 5.82132 15.5993 5.82132C16.4904 5.82132 17.2154 5.09637 17.2154 4.20526C17.2154 3.31415 16.4904 2.58936 15.5993 2.58936ZM15.5993 4.64868C15.3549 4.64868 15.1559 4.44971 15.1559 4.20526C15.1559 3.96066 15.3549 3.76184 15.5993 3.76184C15.8439 3.76184 16.0429 3.96066 16.0429 4.20526C16.0429 4.44971 15.8439 4.64868 15.5993 4.64868Z"/>-->
<!--                                      </svg>-->
<!--                                  </a>-->
<!--                              </div>-->
                            </div>
                        </div>
                        <div class="body">
                            <p>{{ambassador.name}}</p>
                            <span>{{ambassador.position}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div v-else>
      loading
    </div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
export default {
  name: 'MeetTeam',
    data: function() {
        return {
            team: null,
            settings: {
                "centerMode": true,
                "focusOnSelect": true,
                "infinite": true,
                "dots": false,
                "arrows": true,
                // "dotsClass": "slick-dots custom-dot-class",
                // "edgeFriction": 0.35,
                "speed": 500,
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "responsive": [
                    {
                        "centerMode": true,
                        "breakpoint": 1050,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 1,
                            "infinite": true,
                            "dots": false
                        }
                    },
                    {
                        "breakpoint": 600,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1
                        }
                    }
                ]
            },


        }
  },
  watch: {

  },
  methods:{

  },
  created() {
      this.$http.get('ajax/team')
          .then(response => {
              return response.json()
          })
          .then((response) => {
              this.$store.dispatch('asyncResp', response.resp_tickets);
              this.team= response.data;
          }).catch((err) => {
          this.httpHandler(err);
      });
  },
  computed: {
      teamSlide() {
          let team= this.team.team;
          let c = Math.ceil(60/team.length);
          for (let x= 0; x< c; x++) {
              team= team.concat(this.team.team);
          }
          return team;
      }
  },
  mounted() {

  },
  components: {
      VueSlickCarousel
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
