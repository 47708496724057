<template>
    <div class="deal-page">
        <div class="container" v-if="deals">
            <div class="top-text">
                <h1>{{__('Deals')}} <span>MediaChain</span></h1>
            </div>
            <div class="top-li">
                <div class="all-li">
                   <p>{{info.all}}</p>
                    <span>{{__('Total transactions')}}</span>
                </div>
                <div class="all-open">
                    <p>{{info.open}}</p>
                    <span>{{__('Open deals')}}</span>
                </div>
                <div class="all-close">
                    <p>{{info.close}}</p>
                    <span>{{__('Closed deals')}}</span>
                </div>
                <div class="all-plus">
                    <p>{{info.plus}}</p>
                    <span>{{__('Profitable trades')}}</span>
                </div>
                <div class="all-minus">
                    <p>{{info.minus}}</p>
                    <span>{{__('Losing trades')}}</span>
                </div>
                <div class="all-percent">
                    <p>{{info.plus_prcent}}%</p>
                    <span>{{__('of profitable trades')}}</span>
                </div>
                <div class="all-profit">
                    <p>{{info.total_profit}}%</p>
                    <span>{{__('Total profit')}}</span>
                </div>
            </div>
            <div class="content">
                <div class="top">
                    <div class="left">
                        <a class="all" :class="(params.type== 1) ? 'active' : ''" v-on:click="params.type= 1">{{__('All deals')}}</a>
                        <a class="open" :class="(params.type== 2) ? 'active' : ''" v-on:click="params.type= 2">{{__('Open deals')}}</a>
                        <a class="close" :class="(params.type== 3) ? 'active' : ''" v-on:click="params.type= 3">{{__('Closed deals')}}</a>
                    </div>
                    <div class="right">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8757 12.9327L11.0203 10.0781C11.8718 9.0159 12.3349 7.69474 12.333 6.3334C12.333 3.0214 9.64501 0.333405 6.33301 0.333405C3.02101 0.333405 0.333008 3.0214 0.333008 6.3334C0.333008 9.6454 3.02101 12.3334 6.33301 12.3334C7.69434 12.3353 9.0155 11.8722 10.0777 11.0207L12.9323 13.8761L13.8757 12.9327ZM10.9997 6.3334C11.0016 7.54702 10.5291 8.71333 9.68301 9.5834L9.58301 9.6834C8.71293 10.5295 7.54663 11.002 6.33301 11.0001C3.75434 11.0001 1.66634 8.9114 1.66634 6.3334C1.66634 3.75474 3.75434 1.66674 6.33301 1.66674C8.91101 1.66674 10.9997 3.75474 10.9997 6.3334Z" fill="#687787"></path>
                        </svg>
                        <SearchDefault class_search="deals_search" :placeholder="__('Search pair...')" v-model="search" @searching="searching"></SearchDefault>
                    </div>
                </div>
              <div>

                <PaginationDefault class="body"  v-bind:data="deals" type="post" v-bind:params="params" v-bind:showing="true" @updateData="updateData">
                  <template v-slot="pages">
                    <ul class="deals-list">
                      <li class="top-list">
                        <div class="block">
                          <div class="date">
                            <p>{{__('Date/Time (UTC)')}}</p>
                          </div>
                          <div class="pair">
                            <p>{{__('Trading pair')}}</p>
                          </div>
                          <div class="buy">
                            <p>{{__('Buy/Sell')}}</p>
                          </div>
                          <div class="entry">
                            <p>{{__('Point of entry')}}</p>
                          </div>
                          <div class="exit">
                            <p>{{__('Exit point')}}</p>
                          </div>
                          <div class="profit">
                            <p>{{__('Profit')}}</p>
                          </div>
                        </div>
                      </li>

                      <li class="list" v-for="(page, key) in pages" :key="key">
                        <div class="block">
                          <div class="date">
                              <span class="mob">{{__('Date/Time (UTC)')}}</span>
                            <p>{{page.date | formatDate}}</p>
                          </div>
                          <div class="pair">
                              <span class="mob">{{__('Trading pair')}}</span>
                            <p><span>{{page.coin_from}}</span> / {{page.coin_to}}</p>
                          </div>
                          <div class="buy" v-if="page.type== 1">
                              <span class="mob">{{__('Buy/Sell')}}</span>
                            <p class="buy-buy">{{__('Buy')}}</p>
                          </div>
                          <div class="buy" v-else>
                              <span class="mob">{{__('Buy/Sell')}}</span>
                            <p class="buy-sell">{{__('Sell')}}</p>
                          </div>
                          <div class="entry">
                              <span class="mob">{{__('Point of entry')}}</span>
                            <p v-if="page.point_entry">{{page.point_entry}}$</p>
                            <p v-else>...</p>
                          </div>
                          <div class="exit">
                              <span class="mob">{{__('Exit point')}}</span>
                            <p v-if="page.point_exit">{{page.point_exit}}$</p>
                            <p v-else>...</p>
                          </div>
                          <div class="profit">
                              <span class="mob">{{__('Profit')}}</span>
                            <p v-if="page.profit" :class="(page.profit > 0) ? 'plus' : 'minus'">{{((page.profit > 0) ? '+' : '')+page.profit}}%</p>
                            <p v-else>...</p>
                          </div>
                        </div>
                      </li>
                    </ul>

                  </template>
                </PaginationDefault>
              </div>

<!--                <ul class="deals-list">-->
<!--                    <li class="top-list">-->
<!--                        <div class="block">-->
<!--                            <div class="date">-->
<!--                                <p>Дата</p>-->
<!--                            </div>-->
<!--                            <div class="pair">-->
<!--                                <p>Торговая пара</p>-->
<!--                            </div>-->
<!--                            <div class="buy">-->
<!--                                <p>Покупка/продажа</p>-->
<!--                            </div>-->
<!--                            <div class="entry">-->
<!--                                <p>Точка входа</p>-->
<!--                            </div>-->
<!--                            <div class="exit">-->
<!--                                <p>Точка выхода</p>-->
<!--                            </div>-->
<!--                            <div class="profit">-->
<!--                                <p>Прибыль</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->

<!--                    <li class="list">-->
<!--                        <div class="block">-->
<!--                            <div class="date">-->
<!--                                <p>07.01.2023 21:56</p>-->
<!--                            </div>-->
<!--                            <div class="pair">-->
<!--                                <p><span>ETH</span> / USDT</p>-->
<!--                            </div>-->
<!--                            <div class="buy">-->
<!--                                <p>Покупка</p>-->
<!--                            </div>-->
<!--                            <div class="entry">-->
<!--                                <p>2,300.0983</p>-->
<!--                            </div>-->
<!--                            <div class="exit">-->
<!--                                <p>2,302.0983</p>-->
<!--                            </div>-->
<!--                            <div class="profit">-->
<!--                                <p class="plus">+3.45%</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->

<!--                    <li class="list">-->
<!--                        <div class="block">-->
<!--                            <div class="date">-->
<!--                                <p>07.01.2023 21:56</p>-->
<!--                            </div>-->
<!--                            <div class="pair">-->
<!--                                <p><span>ETH</span> / USDT</p>-->
<!--                            </div>-->
<!--                            <div class="buy">-->
<!--                                <p>Продажа</p>-->
<!--                            </div>-->
<!--                            <div class="entry">-->
<!--                                <p>2,300.0983</p>-->
<!--                            </div>-->
<!--                            <div class="exit">-->
<!--                                <p>2,302.0983</p>-->
<!--                            </div>-->
<!--                            <div class="profit">-->
<!--                                <p class="minus">-3.45%</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->

<!--                    <li class="list">-->
<!--                        <div class="block">-->
<!--                            <div class="date">-->
<!--                                <p>07.01.2023 21:56</p>-->
<!--                            </div>-->
<!--                            <div class="pair">-->
<!--                                <p><span>ETH</span> / USDT</p>-->
<!--                            </div>-->
<!--                            <div class="buy">-->
<!--                                <p>Покупка</p>-->
<!--                            </div>-->
<!--                            <div class="entry">-->
<!--                                <p>2,300.0983</p>-->
<!--                            </div>-->
<!--                            <div class="exit">-->
<!--                                <p>...</p>-->
<!--                            </div>-->
<!--                            <div class="profit">-->
<!--                                <p>...</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <div class="pag-all">&lt;!&ndash;&ndash;&gt;<ul class="pagination"><li class="pagination-item pagination-item&#45;&#45;disable"><button disabled="disabled" class="pagination-link pagination-link&#45;&#45;disable">«</button></li><li class="pagination-item pagination-item&#45;&#45;disable"><button disabled="disabled" class="pagination-link pagination-link&#45;&#45;disable">‹</button></li><li class="pagination-item" style="display: none;"><button class="pagination-link">1</button></li><li class="pagination-item" style="display: none;"><button class="pagination-link">2</button></li><li class="pagination-item pagination-item&#45;&#45;disable" style="display: none;"><span class="pagination-link pagination-link&#45;&#45;disable">...</span></li><li class="pagination-item pagination-item&#45;&#45;active"><button class="pagination-link pagination-link&#45;&#45;active">1</button></li><li class="pagination-item "><button class="pagination-link ">2</button></li><li class="pagination-item pagination-item&#45;&#45;disable" style="display: none;"><span class="pagination-link pagination-link&#45;&#45;disable">...</span></li><li class="pagination-item" style="display: none;"><button class="pagination-link">1</button></li>&lt;!&ndash;&ndash;&gt;<li class="pagination-item "><button class="pagination-link ">›</button></li><li class="pagination-item "><button class="pagination-link ">»</button></li></ul></div>-->
<!--                </ul>-->
            </div>
        </div>
      <div v-else class="container">
        <div class="loading">
          <loaderMc></loaderMc>
          {{__('loading')}}
        </div>
      </div>
    </div>
</template>

<script>

import PaginationDefault from "../modules/PaginationDefault";
import SearchDefault from "../modules/SearchDefault";
import LoaderMc from "../SvgComponents/loaderMc";

export default {
  name: 'deals',
    data: function() {
        return {
            deals: null,
            info: null,
            search: null,
            params: {
                search: null,
                type: 1
            },
        }
  },
  watch: {
    "params.type"() {
        this.getDeals();
    }
  },
  methods:{
      searching() {
          this.params.search= this.search;
          this.getDeals();
      },
      updateData(response) {
          this.deals= response.deals;
          window.scrollTo(0,0);
      },
      getDeals() {
          let params= this.$cleanObject(this.params);
          console.log('params');
          console.log(params);

          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/deals', params)
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  response = response.data;
                  this.deals = response.deals;
                  this.info = response.info;
              }).catch((err) => {
              this.httpHandler(err);
          });
      }
  },
  created() {
      this.getDeals();
  },
  computed: {
      lang() {
          return this.$store.state.language.lang
      }
  },
  mounted() {

  },
  components: {
      LoaderMc,
      PaginationDefault,
      SearchDefault,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
