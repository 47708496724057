<template>
    <div class="wallets">
      <h1>{{__('Wallets for withdraw')}}</h1>
      <p class="description">{{__('Manage your currency wallets')}}</p>
      <WalletsSettingItem v-for="(wallet, key) in wallets" :key="key" v-bind:validations.sync="validations" v-bind:personal_info="personal_info"  v-bind:wallets.sync="wallets" v-bind:index="key" v-bind:wallet="wallet" @updateWallet="updateWallet" v-bind:payment="$payment(wallet.payment_id)" v-bind:modal_delete.sync="modal_delete"/>
      <ModalWalletDelete v-if="modal_delete" v-bind:modal_delete.sync="modal_delete" @updateWallet="updateWallet" v-bind:wallets.sync="personal_info.wallet"></ModalWalletDelete>
      <div v-if="personal_info.google_fa.enable">
        <v-btn class="btn-green-new" v-on:click="modal_security= true" :loading="submitDeleted" :disabled="validate">Update wallets</v-btn>
      </div>
      <div v-else>
        <p>{{__('To add or change go to the Security Settings tab and enable two-factor authentication')}}</p>
      </div>
      <ModalDefault v-if="modal_security" v-bind:modal_cancel.sync="modal_security">
        <div class=" pass-window window-site">
          <h2>{{__('Enable 2FA Authentication')}}</h2>
          <div class=" text-window">
            <div class="block">
              <p class="semi">{{__('Confirm password')}}</p>
              <InputLabelTextDefault
                :placeholder="__('Enter 6-digit code provided by app')"
                v-model="code"
                v-bind:errors="formValidate.code"
                @change="updateValidate('code')"
              ></InputLabelTextDefault>
            </div>
            <div class="btn-window">
              <v-btn class="btn-white" v-on:click="modal_security= false">{{__('cancel')}}</v-btn>
              <v-btn class="btn-site" v-on:click="onSubmit">{{__('enable 2fa')}}</v-btn>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
</template>

<script>

import  WalletsSettingItem from "./WalletsSettingItem";
import ModalWalletDelete from "./ModalWalletDelete";
import ModalDefault from "../../modules/ModalDefault";
import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
import _ from 'lodash';
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
export default {
    data () {
        return {
            modal_security: false,
            submitDeleted: false,
            modal_delete: false,
            code: null,
            wallets: this.personal_info.wallet,
            formValidate: {
                code: null
            },
            validations: {

            },
        }
  },
  validations: {
      code: {
          required,
          numeric,
          maxLength: maxLength(6),
          minLength: minLength(6),
      }
  },
  methods: {
      updateWallet: function (wallets) {
          let personal_info= this.$cleanObject(this.personal_info);

          personal_info.wallet= wallets;
          this.$emit('update:personal_info', personal_info);
      },
      updateValidate: function(type) {
          this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
      },
      onSubmit: function () {
          let valid= this.$v;

          if(valid.$invalid) {
              let massages= {
                  'code': [],
              };
              this.formValidate= this.$formValidate(massages, valid);
          } else {
              let payments = {};
              for (let i = 0; i < this.wallets.length; i++) {
                  let payment = this.wallets[i];
                  payments[payment.payment_id] = {
                      'address': payment.user_wallet,
                      'tag': payment.tag,
                  }
              }

              this.submitDeleted = true;

              let response = {
                  'wallets': payments,
                  'code': this.code,
              };
              this.$http.post('ajax/setting/wallet', response)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      let personal_info= this.$cleanObject(this.personal_info);
                      personal_info.wallet= this.wallets;

                      this.$emit('update:personal_info', personal_info);

                      this.modal_security = false;
                      this.submitDeleted = false;
                  }).catch(err => {
                  this.securityDisabled = false;
                  let response = this.httpHandler(err);
                  if (response.error) {
                      this.formValidate = response.error.message;

                  }
              });
          }
      },
  },
  props: {
      personal_info: {
          type: Object
      }
  },
  computed: {
      payments() {
          return this.$store.state.auth.payments
      },
      validate() {
          return _.sum(_.values(this.validations)) > 0 || this.wallets== this.personal_info.wallet;
      },
  },
  components: {
    WalletsSettingItem,
    ModalWalletDelete,
    InputLabelTextDefault,
    ModalDefault,
  }
}
</script>

<style scoped>

</style>
