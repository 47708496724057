<template>
  <div >
    <div>
      <div class="loading" v-if="loading">
        <loaderMc></loaderMc>
        {{__('Loading')}}
      </div>
      <div v-else-if="data.total== 0" class="no-result"><img src="/newfrontend/images/chart.svg" alt=""> {{__('no result')}}</div>
      <slot v-else v-bind="data.data"></slot>
    </div>
    <div class="pag-all" v-if="data.last_page > 1">
      <div v-if="showing">
        {{__('Showing')}} {{data.to}} {{__('from')}} {{data.total}} {{__('entries')}}
      </div>
      <v-pagination v-if="data.last_page > 1"
                    v-model="currentPage"
                    :page-count="data.last_page"></v-pagination>
    </div>
  </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination';
  import loaderMc from "../SvgComponents/loaderMc";

    export default {
        name: 'PaginationDefault',
        data: function() {
            return {
                currentPage: this.data.current_page,
                loading: false
            }
        },
        props: {
            data: {
                type: Object,
            },
            type: {
                type: String,
                default: 'post'
            },
            showing: {
                type: Boolean,
                default: false
            },
            params: {
                type: Object,
            }
        },
        watch: {
            currentPage(page) {
                this.loading= true;
                // let data= this.data;
                // data.data= null;
                //
                // this.$emit('update:data', data);
                if(this.type== 'post') {
                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get'](this.data.path+'?page='+page, this.params)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response) => {
                            this.$store.dispatch('asyncResp', response.resp_tickets);
                            if(response.data.data) {
                                this.$emit('update:data', response.data);
                            }
                            this.$emit('updateData', response.data);
                            this.loading= false;
                        });
                } else {
                    this.$http.get(this.data.path+'/?page='+page, this.params)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response) => {
                            this.$store.dispatch('asyncResp', response.resp_tickets);
                            this.$emit('update:data', response.data);
                            this.loading= false;
                        });
                }
            }
        },
        components: {
          loaderMc,
            vPagination,
        }
    }
</script>

<style>

</style>
