<template>
    <div class="wallets wallets-nft">
      <h1>{{__('NFT wallets')}}</h1>
      <p class="description">{{__('Manage your NFT wallets')}}</p>
      <div class="block">
        <div class="img">
          <img src="/newfrontend/images/nft-wallet.svg" alt="">
        </div>
        <p class="semi">Metamsk wallet</p>
        <InputLabelTextDefault
                :placeholder="__('Enter address')"
            v-model="wallets"
            :disabled="(personal_info.personal.nft || !personal_info.google_fa.enable) ? true : false"
            v-bind:errors="formValidate.wallets"
            @change="updateValidate('wallets')"
        ></InputLabelTextDefault>
      </div>

      <div class="nft-btn" v-if="personal_info.google_fa.enable">
        <v-btn class="btn-green-new" v-if="!personal_info.personal.nft" v-on:click="modal_security= true" :loading="submitDeleted" :disabled="validate">Update wallets</v-btn>
      </div>
      <div v-else>
        <p>Чтобы добавить или изменить перейдите во вкладку Security Settings и включите two-factor authentication</p>
      </div>
      <ModalDefault v-if="modal_security" v-bind:modal_cancel.sync="modal_security">
        <div class=" pass-window window-site">
          <h2>{{__('Enable 2FA Authentication')}}</h2>
          <div class=" text-window">
            <div class="block">
              <p class="semi">{{__('Confirm password')}}</p>
              <InputLabelTextDefault
                      :placeholder="__('Enter 6-digit code provided by app')"
                      v-model="code"
                      v-bind:errors="formValidate.code"
                      @change="updateValidate('code')"
              ></InputLabelTextDefault>
            </div>
            <div class="btn-window">
              <v-btn class="btn-white" v-on:click="modal_security= false">{{__('cancel')}}</v-btn>
              <v-btn class="btn-site" v-on:click="onSubmit">{{__('enable 2fa')}}</v-btn>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
</template>

<script>

import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
import ModalDefault from "../../modules/ModalDefault";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
export default {
    data () {
        return {
            modal_security: false,
            submitDeleted: false,
            modal_delete: false,
            code: null,
            wallets: this.personal_info.personal.nft,
            formValidate: {
                wallets: null,
                code: null,
            },
            validations: {

            },
        }
  },
    validations: {
        code: {
            required,
            numeric,
            maxLength: maxLength(6),
            minLength: minLength(6),
        },
        wallets: {
            required,
            minLength: minLength(5)
        }
    },
  methods: {
      updateValidate: function(type) {
          this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
      },
      onSubmit: function () {
          let valid= this.$v;

          if(valid.$invalid) {
              let massages= {
                  'code': [],
                  'wallets': [],
              };
              this.formValidate= this.$formValidate(massages, valid);
          } else {
              this.submitDeleted = true;

              let response = {
                  'wallets': this.wallets,
                  'code': this.code,
              };
              this.$http.post('ajax/setting/nft', response)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      let personal_info= this.$cleanObject(this.personal_info);
                      personal_info.personal.nft= this.wallets;

                      this.$emit('update:personal_info', personal_info);

                      this.modal_security = false;
                      this.submitDeleted = false;
                  }).catch(err => {
                  this.securityDisabled = false;
                  let response = this.httpHandler(err);
                  if (response.error) {
                      this.formValidate = response.error.message;

                  }
              });
          }
      },
  },
  props: {
      personal_info: {
          type: Object
      }
  },
  computed: {
      validate() {
          return this.wallets== this.personal_info.personal.nft;
      },
  },
  components: {
    InputLabelTextDefault,
    ModalDefault,
  }
}
</script>

<style scoped>

</style>
