<template>
    <div class="wallets wallets-nft wallets-phone">
      <h1>{{__('Phone or telegram nickname')}}</h1>
      <p class="description">{{__('Enter your phone number or telegram nickname. This is necessary for communication with partners, etc.')}}</p>
      <div class="choice-phone-tg">
          <div class="top-btn">
              <v-btn v-ripple="false" v-on:click="type= 1" :class="type== 1 ? 'active' : ''" >{{__('Phone number')}}</v-btn>
              <v-btn v-ripple="false" v-on:click="type= 2" :class="type== 2 ? 'active' : ''">{{__('Telegram nickname')}}</v-btn>
          </div>

<!--          <p class="semi">{{__('Phone number')}}</p>-->

<!--          <p class="semi">{{__('Telegram nickname')}}</p>-->
          <div class="block block-tel" v-if="type== 1">
              <VuePhoneNumberInput
                      v-model="phone"
                      v-bind:error="errorNumber"
                      v-bind:default-country-code="form.phone_country"
                      @update="updateNumber"
                      :disabled="(!personal_info.google_fa.enable) ? true : false"
              ></VuePhoneNumberInput>
          </div>
          <div v-else class="telega">
              <InputLabelTextDefault
                      :placeholder="__('Enter your telegram nickname')"
                      v-model="teleg"
                      :disabled="(!personal_info.google_fa.enable) ? true : false"
                      v-bind:errors="formValidate.teleg"
                      @change="updateValidate('teleg')"
              ></InputLabelTextDefault>
          </div>
      </div>


      <div class="nft-btn mt-3" v-if="personal_info.google_fa.enable">
        <v-btn class="btn-green-new" v-on:click="validateForm" :loading="submitDeleted">Update wallets</v-btn>
      </div>
      <div v-else>
        <p>{{__('To add or change go to the Security Settings tab and enable two-factor authentication')}}</p>
      </div>
      <ModalDefault v-if="modal_security" v-bind:modal_cancel.sync="modal_security">
        <div class=" pass-window window-site">
          <h2>{{__('Enable 2FA Authentication')}}</h2>
          <div class=" text-window">
            <div class="block">
              <p class="semi">{{__('Confirm password')}}</p>
              <InputLabelTextDefault
                      :placeholder="__('Enter 6-digit code provided by app')"
                      v-model="code"
                      v-bind:errors="formValidate.code"
                      @change="updateValidate('code')"
              ></InputLabelTextDefault>
            </div>
            <div class="btn-window">
              <v-btn class="btn-white-new" v-on:click="modal_security= false">{{__('cancel')}}</v-btn>
              <v-btn class="btn-green-new" v-on:click="onSubmit">{{__('enable 2fa')}}</v-btn>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
</template>

<script>

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
import ModalDefault from "../../modules/ModalDefault";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
export default {
    data () {
        let phone_json= this.portfolio.phone_json;

        return {
            modal_security: false,
            submitDeleted: false,
            modal_delete: false,
            type: (this.portfolio.teleg) ? 2 : 1,
            code: null,
            phone: (phone_json) ? phone_json.phone_number : null,
            teleg: (this.portfolio) ? this.portfolio.teleg : null,
            form: (phone_json) ? {
                phone_number: phone_json.phone_number,
                phone_country: phone_json.phone_country,
                phone_code: phone_json.phone_code,
            } : {
                phone_number: null,
                phone_country: null,
                phone_code: null
            },
            formValidate: {
                phone: null,
                teleg: null,
                code: null,
            },
            phone_number: null,
            errorNumber: null,
            validations: {

            },
        }
    },
    validations() {
        return {
            code: {
                required,
                numeric,
                maxLength: maxLength(6),
                minLength: minLength(6),
            },
            phone: {
                required: (this.type == 1) ? required : true,
                minLength: minLength(3),
                maxLength: maxLength(50)
            },
            teleg: {
                required: (this.type == 2) ? required : true,
                minLength: minLength(3),
                maxLength: maxLength(50)
            }
        }
    },

  methods: {
      updateNumber: function (event) {
          this.errorNumber= false;
          this.phone_number= event;
      },
      updateValidate: function(type) {
          this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
      },
      validateForm: function () {
          console.log('test');
          if(this.type== 1) {
              if(this.phone_number.nationalNumber && !this.phone_number.isValid) {
                  this.errorNumber= true;
              } else {
                  this.modal_security = true;
              }
          } else {
              this.modal_security = true;
          }
      },
      onSubmit: function () {
          let valid= this.$v;

          if(valid.$invalid) {
              let massages= {
                  'code': [],
                  'phone': [],
                  'teleg': [],
              };
              this.formValidate= this.$formValidate(massages, valid);
              this.modal_security = false;
          }  else if(this.type== 1 && this.phone_number.nationalNumber && !this.phone_number.isValid) {
              this.errorNumber= true;
              this.modal_security = false;
          } else {
              this.submitDeleted = true;

              let response = {
                  'code': this.code,
              };

              let phone_json= null;
              let teleg= null;
              let phone= null;

              if(this.type== 1) {
                  let phone_json= {
                      phone_number: this.phone_number.nationalNumber,
                      phone_country: this.phone_number.countryCode,
                      phone_code: this.phone_number.countryCallingCode,
                  }

                  phone= response['phone']= phone_json.phone_code+phone_json.phone_number;
                  response['phone_json']= phone_json;
              } else {
                  teleg= response['telegram']= this.teleg;
              }

              console.log('response');
              console.log(response);

              this.$http.post('ajax/setting/phone', response)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      let portfolio= this.$cleanObject(this.portfolio);
                      portfolio.teleg= teleg;
                      portfolio.phone= phone;
                      portfolio.phone_json= phone_json;


                      this.$store.dispatch('asyncPortfolio', portfolio);

                      this.modal_security = false;
                      this.submitDeleted = false;
                  }).catch(err => {
                  this.securityDisabled = false;
                  let response = this.httpHandler(err);
                  if (response.error) {
                      this.formValidate = response.error.message;

                  }
              });
          }
      },
  },
  props: {
      personal_info: {
          type: Object
      },
      portfolio: {
          type: Object
      }
  },
  watch: {
    'portfolio.phone'(phone) {
        if(phone) {
            this.phone= phone;
        }
    }
  },
  computed: {
      validate() {
          return this.phone== this.portfolio.phone;
      },
  },
  components: {
    InputLabelTextDefault,
    VuePhoneNumberInput,
    ModalDefault,
  }
}
</script>

<style scoped>

</style>
