import { render, staticRenderFns } from "./SettingBot.vue?vue&type=template&id=7da42951&scoped=true&"
import script from "./SettingBot.vue?vue&type=script&lang=js&"
export * from "./SettingBot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7da42951",
  null
  
)

export default component.exports