<template>
    <div class="my-team">
      <div class="my-team-top">
        <div class="rank">
          <div v-if="portfolio.rank == 0">
            <p>{{__('Инвестор')}}</p>
            <img src="/newfrontend/images/rank-1.png" alt="">
          </div>
          <div v-if="portfolio.rank == 1">
            <p>{{__('Инвестор')}}</p>
            <img src="/newfrontend/images/rank-1.png" alt="">
          </div>
          <div v-if="portfolio.rank == 2">
            <p>{{__('Инвестор')}}</p>
            <img src="/newfrontend/images/rank-1.png" alt="">
          </div>
          <div v-if="portfolio.rank == 3">
            <p>{{__('Инвестор')}}</p>
            <img src="/newfrontend/images/rank-1.png" alt="">
          </div>
          <div v-if="portfolio.rank == 4">
            <p>{{__('Инвестор')}}</p>
            <img src="/newfrontend/images/rank-1.png" alt="">
          </div>
          <div v-if="portfolio.rank == 5">
            <p>{{__('Инвестор')}}</p>
            <img src="/newfrontend/images/rank-1.png" alt="">
          </div>
          <div v-if="portfolio.rank == 6">
            <p>{{__('Инвестор')}}</p>
            <img src="/newfrontend/images/rank-1.png" alt="">
          </div>
          <div v-if="portfolio.rank == 7">
            <p>{{__('Бронзовый инвестор')}}</p>
            <img src="/newfrontend/images/rank-2.png" alt="">
          </div>
          <div v-if="portfolio.rank == 8">
            <p>{{__('Бронзовый инвестор')}}</p>
            <img src="/newfrontend/images/rank-2.png" alt="">
          </div>
          <div v-if="portfolio.rank == 9">
            <p>{{__('Бронзовый инвестор')}}</p>
            <img src="/newfrontend/images/rank-2.png" alt="">
          </div>
          <div v-if="portfolio.rank == 10">
            <p>{{__('Бронзовый инвестор')}}</p>
            <img src="/newfrontend/images/rank-2.png" alt="">
          </div>
          <div v-if="portfolio.rank == 11">
            <p>{{__('Бронзовый инвестор')}}</p>
            <img src="/newfrontend/images/rank-2.png" alt="">
          </div>
          <div v-if="portfolio.rank == 12">
            <p>{{__('Золотой инвестор')}}</p>
            <img src="/newfrontend/images/rank-3.png" alt="">
          </div>
          <div v-if="portfolio.rank == 13">
            <p>{{__('Золотой инвестор')}}</p>
            <img src="/newfrontend/images/rank-3.png" alt="">
          </div>
          <div v-if="portfolio.rank == 14">
            <p>{{__('Золотой инвестор')}}</p>
            <img src="/newfrontend/images/rank-3.png" alt="">
          </div>
          <div v-if="portfolio.rank == 15">
            <p>{{__('Золотой инвестор')}}</p>
            <img src="/newfrontend/images/rank-3.png" alt="">
          </div>
          <div v-if="portfolio.rank == 16">
            <p>{{__('Золотой инвестор')}}</p>
            <img src="/newfrontend/images/rank-3.png" alt="">
          </div>
          <div v-if="portfolio.rank == 17">
            <p>{{__('Платиновый инвестор')}}</p>
            <img src="/newfrontend/images/rank-4.png" alt="">
          </div>
          <div v-if="portfolio.rank == 18">
            <p>{{__('Платиновый инвестор')}}</p>
            <img src="/newfrontend/images/rank-4.png" alt="">
          </div>
          <div v-if="portfolio.rank == 19">
            <p>{{__('Партнёр')}}</p>
            <img src="/newfrontend/images/rank-5.png" alt="">
          </div>
          <div v-if="portfolio.rank == 20">
            <p>{{__('Бронзовый партнёр')}}</p>
            <img src="/newfrontend/images/rank-6.png" alt="">
          </div>
          <div v-if="portfolio.rank == 21">
            <p>{{__('Бронзовый партнёр')}}</p>
            <img src="/newfrontend/images/rank-6.png" alt="">
          </div>
          <div v-if="portfolio.rank == 22">
            <p>{{__('Золотой партнёр')}}</p>
            <img src="/newfrontend/images/rank-7.png" alt="">
          </div>
          <div v-if="portfolio.rank == 23">
            <p>{{__('Золотой партнёр')}}</p>
            <img src="/newfrontend/images/rank-7.png" alt="">
          </div>
          <div v-if="portfolio.rank == 24">
            <p>{{__('Платиновый партнёр')}}</p>
            <img src="/newfrontend/images/rank-8.png" alt="">
          </div>
          <div v-if="portfolio.rank == 25">
            <p>{{__('Дистрибьютор')}}</p>
            <img src="/newfrontend/images/rank-9.png" alt="">
          </div>
          <div v-if="portfolio.rank == 26">
            <p>{{__('Бронзовый дистрибьютор')}}</p>
            <img src="/newfrontend/images/rank-10.png" alt="">
          </div>
          <div v-if="portfolio.rank == 27">
            <p>{{__('Золотой дистрибьютор')}}</p>
            <img src="/newfrontend/images/rank-11.png" alt="">
          </div>
          <div v-if="portfolio.rank == 28">
            <p>{{__('Золотой дистрибьютор')}}</p>
            <img src="/newfrontend/images/rank-11.png" alt="">
          </div>
          <div v-if="portfolio.rank == 29">
            <p>{{__('Платиновый дистрибьютор')}}</p>
            <img src="/newfrontend/images/rank-12.png" alt="">
          </div>
          <div v-if="portfolio.rank == 30">
            <p>{{__('Лидер')}}</p>
            <img src="/newfrontend/images/rank-13.png" alt="">
          </div>
          <div v-if="portfolio.rank == 31">
            <p>{{__('Бронзовый лидер')}}</p>
            <img src="/newfrontend/images/rank-14.png" alt="">
          </div>
          <div v-if="portfolio.rank == 32">
            <p>{{__('Золотой лидер')}}</p>
            <img src="/newfrontend/images/rank-14.png" alt="">
          </div>
          <div v-if="portfolio.rank == 33">
            <p>{{__('Платиновый лидер')}}</p>
            <img src="/newfrontend/images/rank-16.png" alt="">
          </div>
          <div v-if="portfolio.rank == 34">
            <p>{{__('Директор')}}</p>
            <img src="/newfrontend/images/rank-17.png" alt="">
          </div>
          <div v-if="portfolio.rank == 35">
            <p>{{__('Бронзовый директор')}}</p>
            <img src="/newfrontend/images/rank-18.png" alt="">
          </div>
          <div v-if="portfolio.rank == 36">
            <p>{{__('Бронзовый директор')}}</p>
            <img src="/newfrontend/images/rank-18.png" alt="">
          </div>
          <div v-if="portfolio.rank == 37">
            <p>{{__('Золотой директор')}}</p>
            <img src="/newfrontend/images/rank-19.png" alt="">
          </div>
          <div v-if="portfolio.rank == 38">
            <p>{{__('Золотой директор')}}</p>
            <img src="/newfrontend/images/rank-19.png" alt="">
          </div>
          <div v-if="portfolio.rank == 39">
            <p>{{__('Платиновый директор')}}</p>
            <img src="/newfrontend/images/rank-20.png" alt="">
          </div>
          <div v-if="portfolio.rank == 40">
            <p>{{__('Акционер')}}</p>
            <img src="/newfrontend/images/rank-21.png" alt="">
          </div>
          <div v-if="portfolio.rank == 41">
            <p>{{__('Акционер')}}</p>
            <img src="/newfrontend/images/rank-21.png" alt="">
          </div>
          <div v-if="portfolio.rank == 42">
            <p>{{__('Бронзовый акционер')}}</p>
            <img src="/newfrontend/images/rank-22.png" alt="">
          </div>
          <div v-if="portfolio.rank == 43">
            <p>{{__('Бронзовый акционер')}}</p>
            <img src="/newfrontend/images/rank-22.png" alt="">
          </div>
          <div v-if="portfolio.rank == 44">
            <p>{{__('Золотой акционер')}}</p>
            <img src="/newfrontend/images/rank-23.png" alt="">
          </div>
          <div v-if="portfolio.rank == 45">
            <p>{{__('Платиновый акционер')}}</p>
            <img src="/newfrontend/images/rank-24.png" alt="">
          </div>

          <p class="rank-text"><span>{{portfolio.rank}} {{__('Rank')}}</span></p>
        </div>
        <div class="balance-bar">
          <!--        {{portfolio}}-->
          <p class="summ">{{$primNum(portfolio.turnover) | formatThousands}} <span>USD</span></p>
          <p class="text">{{__('Amount of team funds in farming')}}</p>
          <v-progress-linear
                  :value="value"
                  striped
                  color="light-blue"
                  class="progress-bar"
                  height="16px"
          >
          </v-progress-linear>
          <p class="text-b">
            <span>{{$primNum(portfolio.rang_turnover) | formatThousands}}</span>
            <span>{{$primNum(portfolio.next_rang_turnover) | formatThousands}}</span>
          </p>
          <div class="right">
          <span>
            <img src="/newfrontend/images/left-link.svg" alt="">
            <InputLabelTextDefault readonly :value="portfolio.ref_url" copy></InputLabelTextDefault>
          </span>
          </div>
        </div>
        <div class="personal">
          <div class="block">
            <div class="img">
            <span>
              <img src="/newfrontend/images/p-1.png" alt="">
            </span>
            </div>
            <div>
              <p>${{$primNum(portfolio.personal_turnover) | formatThousands}}</p>
              <span>{{__('Personal turenover')}}</span>
            </div>
          </div>
          <div class="block">
            <div class="img">
            <span>
              <img src="/newfrontend/images/p-2.svg" alt="">
            </span>
            </div>
            <div>
              <p>${{$primNum(portfolio.turnover) | formatThousands}}</p>
              <span>{{__('Team turenover')}}</span>
            </div>
          </div>
          <div class="block">
            <div class="img">
            <span>
              <img src="/newfrontend/images/p-3.svg" alt="">
            </span>
            </div>
            <div>
              <p>${{Number($primNum(affiliate.partner_profit, 0)).toLocaleString('en')}}</p>
              <span>{{__('referral income')}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="my-team-body">
        <div class="team-body-text">
          <p>{{__('Conditions for receiving a rank achievement award')}}</p>
        </div>
        <ul>
          <li>
            <div class="condition">
              <p>{{__('Condition')}}</p>
            </div>
            <div class="necessary">
              <p>{{__('Necessary')}}</p>
            </div>
            <div class="done">
              <p>{{__('Done for now')}}</p>
            </div>
            <div class="performed">
              <p>{{__('Performed')}}</p>
            </div>
          </li>
          <li>
            <div class="condition">
              <p>{{__('Sales volume in the first line')}}</p>
            </div>
            <div class="necessary">
              <p>{{$primNum(affiliate.bonus_info.turnover_first_need) | formatThousands}}</p>
            </div>
            <div class="done">
              <p>{{$primNum(affiliate.bonus_info.turnover_first) | formatThousands}}</p>
            </div>
            <div class="performed">
              <img v-if="affiliate.bonus_info.turnover_first >= affiliate.bonus_info.turnover_first_need" src="/newfrontend/images/ok-p.svg" alt="">
              <img v-else src="/newfrontend/images/cen-p.svg" alt="">
            </div>
          </li>
          <li>
            <div class="condition">
              <p>{{__('Team sales volume')}}</p>
            </div>
            <div class="necessary">
              <p>{{$primNum(affiliate.bonus_info.turnover_need) | formatThousands}}</p>
            </div>
            <div class="done">
              <p>{{$primNum(affiliate.bonus_info.turnover) | formatThousands}}</p>
            </div>
            <div class="performed">
              <img v-if="affiliate.bonus_info.turnover >= affiliate.bonus_info.turnover_need" src="/newfrontend/images/ok-p.svg" alt="">
              <img v-else src="/newfrontend/images/cen-p.svg" alt="">
            </div>
          </li>
          <li>
            <div class="condition">
              <p>{{__('Personal investment')}}</p>
            </div>
            <div class="necessary">
              <p>{{$primNum(affiliate.bonus_info.deposit_need) | formatThousands}}</p>
            </div>
            <div class="done">
              <p>{{$primNum(affiliate.bonus_info.deposit) | formatThousands}}</p>
            </div>
            <div class="performed">
              <img v-if="affiliate.bonus_info.deposit >= affiliate.bonus_info.deposit_need" src="/newfrontend/images/ok-p.svg" alt="">
              <img v-else src="/newfrontend/images/cen-p.svg" alt="">
            </div>
          </li>
          <li>
            <div class="condition">
              <p>{{__('Rank')}}</p>
            </div>
            <div class="necessary">
              <p>{{affiliate.bonus_info.rank_need}}</p>
            </div>
            <div class="done">
              <p>{{affiliate.bonus_info.rank}}</p>
            </div>
            <div class="performed">
              <img v-if="affiliate.bonus_info.rank >= affiliate.bonus_info.rank_need" src="/newfrontend/images/ok-p.svg" alt="">
              <img v-else src="/newfrontend/images/cen-p.svg" alt="">
            </div>
          </li>
          <li v-for="(partner, key) in affiliate.bonus_info.partner" :key="key">
            <div class="condition">
              <p>{{__('Partners in the first line with the achieved rank')+" "+key}}</p>
            </div>
            <div class="necessary">
              <p>{{partner.need}}</p>
            </div>
            <div class="done">
              <p>{{partner.is}}</p>
            </div>
            <div class="performed">
              <img v-if="partner.is >= partner.need" src="/newfrontend/images/ok-p.svg" alt="">
              <img v-else src="/newfrontend/images/cen-p.svg" alt="">
<!--              <img src="/newfrontend/images/cen-p.svg" alt="">-->
            </div>
          </li>
          <li v-if="affiliate.bonus_info.nft > 0">
            <div class="condition">
              <p>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}}</p>
            </div>
            <div class="necessary">
              <p>{{affiliate.bonus_info.nft_need}}</p>
            </div>
            <div class="done">
              <p>{{affiliate.bonus_info.nft}}</p>
            </div>
            <div class="performed">
              <img v-if="affiliate.bonus_info.nft >= affiliate.bonus_info.nft_need" src="/newfrontend/images/ok-p.svg" alt="">
              <img v-else src="/newfrontend/images/cen-p.svg" alt="">
            </div>
          </li>
          <li>
            <div class="condition">
              <p>{{__('Reward for fulfilling the conditions')}}</p>
            </div>
            <div class="bonus">
              <p>{{affiliate.bonus_info.bonus | formatThousands}}$</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";

    export default {
        components: {InputLabelTextDefault},
        data: function() {
            return {
              value : 0
            }
        },
        created() {
          this.turnoverValue();
        },
      methods:{
        turnoverValue() {

          let start= this.portfolio.rang_turnover;

          let turnover= this.portfolio.turnover;

          let end= this.portfolio.next_rang_turnover;
          this.value= (turnover-start)/((end-start)/100);
        },
      },
        computed: {
            portfolio() {
                return this.$store.state.auth.portfolio
            },
        },
        props: {
            affiliate: {
                type: Object
            }
        }
    }
</script>

<style>

</style>
