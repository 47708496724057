<template>
  <div class="chart" v-if="series[0] && series[0].data.length >= 2">
    <div class="btn-chart-group" v-if="nav">
      <v-btn class="btn-chart" v-on:click="getDate(item.key)" v-for="(item, key) in timeframe" :key="key" :class="(item.key== period) ? 'active' : ''">{{__(item.title)}}</v-btn>
    </div>
    <apexchart type="area" :options="options" :height="height" :width="width" :series="series"></apexchart>
  </div>
  <div v-else class="no-result-chart">
    <img src="/newfrontend/images/chart.svg" alt="">
    <p>{{__('There is still no data on the chart')}}</p>
  </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import moment from 'moment';

    export default {
        name: 'Chart',

        data: function() {
            return {
                period: null,
                timeframe: [
                    {
                        key: '1m',
                        title: '1 Mon',
                    },
                    {
                        key: '6m',
                        title: '6 Mon',
                    },
                    {
                        key: '1y',
                        title: '1 Year',
                    },
                    {
                        key: null,
                        title: 'All',
                    }
                ],
                options: {
                    chart: {
                        type: 'area',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    colors: [this.color],
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: 2,
                        curve: 'smooth'
                    },
                    yaxis: {
                        forceNiceScale: true,
                        decimalsInFloat: 2,
                    },
                    xaxis: {
                        type: 'datetime',
                    },
                  fill: {
                    type: 'gradient',
                    gradient: {
                      // shadeIntensity: 1,
                      opacityFrom: 0.6,
                      opacityTo: 0.3,
                      // stops: [0, 50],
                    }
                  },
                    tooltip: {
                        custom: ({seriesIndex, dataPointIndex})=> {
                            let item= this.series[seriesIndex].data[dataPointIndex];
                            return '<div class="chart-tooltip">' +
                                '<p class="tooltip-date">' + moment.utc(item[0]).format('MMM DD, YYYY') + '</p>' +
                                '<p class="tooltip-amount" style="color: '+this.color+'">'+((this.count) ? item[1] : `$${this.$primNum(item[1])}`) + '</p>' +
                                '</div>'
                        },
                    },
                    grid: {
                        borderColor: "#E5E5E5",
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 15
                        },
                    },
                },
            }
        },
        props: {
            series: {
                type: [
                    Array
                ]
            },
            color: {
                type: [
                    String
                ]
            },
            width: {
                type: [
                    String, Number
                ],
            },
            height: {
                type: [
                    String, Number
                ],
            },
            nav: {
                type: [
                    Boolean
                ],
                default: false
            },
            count: {
                type: [
                    Boolean
                ],
                default: false
            }
        },
        computed: {

        },
        methods: {
            getDate(type= false) {
                let options= this.$cleanObject(this.options);
                let min;
                let max;

                this.period= type;
                switch (type) {
                    case '1m':
                        min= moment.utc().subtract(1, 'M').format('x');
                        max= moment.utc().format('x');
                        break;
                    case '6m':
                        min= moment.utc().subtract(6, 'M').format('x');
                        max= moment.utc().format('x');
                        break;
                    case '1y':
                        min= moment.utc().subtract(1, 'Y').format('x');
                        max= moment.utc().format('x');
                        break;
                }
                options.xaxis.min= (min) ? Number(min) : undefined;
                options.xaxis.max= (max) ? Number(max) : undefined;

                this.options= options;
            }
        },
        components: {
            apexchart: VueApexCharts,
        }
    }
</script>

<style>
  .apexcharts-xaxistooltip {
    display: none;
  }
  .chart-tooltip {
    text-align: center;
    font-size: 16px;
    padding: 5px 10px;
  }
  .apexcharts-canvas .apexcharts-tooltip .chart-tooltip p {
    margin: 0;
    padding: 0;
  }
  .apexcharts-canvas .apexcharts-tooltip .chart-tooltip .tooltip-date {
    font-size: 12px;
    color: rgba(39, 39, 39, 0.7);
  }

  .chart {
    position: relative;
  }
  .btn-chart-group {
    position: absolute;
    right: 0;
    top: -23px;
  }
  .btn-chart-group .btn-chart {
    padding: 4px 7px;
    background: #fff;
    border: 1px solid #E5E5E5;
    color: rgba(39, 39, 39, 0.7);
    border-radius: 5px;
    margin-left: 5px;
  }
  .btn-chart-group .btn-chart.active {
    border: 1px solid #FF0000;
    background: #FF0000;
    color: #fff;
  }
</style>