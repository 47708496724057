<template>
    <div class="supp-form black">
      <h2>{{__('Contact Us')}}</h2>
      <form>
        <div class="top-form">
          <div class="block">
            <p class="semi text">{{__('Name')}}</p>
            <InputLabelTextDefault :placeholder="__('Enter your name')" class="left-input" type="text" v-model="form.name" v-bind:errors="formValidate.name" @change="updateValidate('name')"></InputLabelTextDefault>
          </div>
          <div class="block">
            <p class="semi text">{{__('Email')}}</p>
            <InputLabelTextDefault :placeholder="__('Enter your email')" class="right-input"  type="email" v-model="form.email" v-bind:errors="formValidate.email" @change="updateValidate('email')"></InputLabelTextDefault>
          </div>
        </div>
<!--        <div class="block">-->
<!--          <DropdownDefault-->
<!--              value="Select the subject of your request"-->
<!--              v-bind:items="items"-->
<!--              class="filter drop-site"-->
<!--              classDropdown="drop-list"-->
<!--              text="name"-->
<!--              value_key="value"-->
<!--              v-model="form.subject"-->
<!--          ></DropdownDefault>-->
<!--        </div>-->
        <div class="block block-area">
          <p class="semi text">{{__('Message')}}</p>
          <InputLabelTextDefault :placeholder="__('Enter your message here')" type="textarea" v-model="form.text" v-bind:errors="formValidate.text" @change="updateValidate('text')"></InputLabelTextDefault>
        </div>
        <div class="btn-form">
          <p>{{__('You will be replied to the email you provided. The answer may come in the spam folder')}}</p>
          <v-btn class="btn-new-w" :loading="submitDisabled" v-on:click="openCaptcha">{{__('Send message')}}</v-btn>
        </div>
      </form>
      <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>

<script>

  import Captcha from "../modules/Captcha";
  import InputLabelTextDefault from "../modules/InputLabelTextDefault";
  // import DropdownDefault from "../modules/DropdownDefault";
  import {required} from "vuelidate/lib/validators";

  export default {
      data () {
          return {
            items: [
              {
                name: 'Select the subject of your request',
                value: null
              }, {
                name: 'Investment',
                value: 'Investment'
              }, {
                name: 'Withdrawal',
                value: 'Withdrawal'
              }, {
                name: 'Technical problems',
                value: 'Technical problems'
              },{
                name: 'Other',
                value: 'Other'
              }
            ],
            form: {
              name: null,
              email: null,
              subject: null,
              text: null
            },
            formValidate: {
              name: null,
              email: null,
              subject: null,
              text: null
            },

            submitDisabled: false,
            captcha: false,
          }
      },
    validations() {
      return {
        form: {
          name: {
            required,
          },
          email: {
            required,

          },
          text: {
            required,
          },
        }
      }
    },
    methods: {
      updateValidate: function(type) {
        this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
      },
      openCaptcha() {
        let valid= this.$v.form;
        if(valid.$invalid) {
          let massages= {
            'email': [],
            'text': [],
            'name': [],
          };
          this.formValidate= this.$formValidate(massages, valid);
        } else {
          this.captcha= true;
        }
      },
      onSubmit: function (session, arrayData) {
        let valid= this.$v.form;
        if(valid.$invalid) {
          let massages= {
            'email': [],
            'text': [],
            'name': [],
          };
          this.formValidate= this.$formValidate(massages, valid);
        } else {
          this.submitDisabled = true;

          let response = this.form;
          response['challenge']= session;
          response[session]= arrayData;

          this.$http.post('ajax/support', response)
                  .then(response => {
                    return response.json()
                  })
                  .then((response) => {
                    this.$store.dispatch('asyncResp', response.resp_tickets);
                    this.form= {
                      name: null,
                      email: null,
                      subject: null,
                      text: null,
                    };
                    this.$notify({
                      group: 'message',
                      title: 'Send message',
                      type: 'success'
                    });
                    this.captcha= false;
                    this.submitDisabled= false;
                  }).catch((err) => {
            this.captcha= false;
            this.submitDisabled= false;
            let response= this.httpHandler(err);

            if(response.error) {
              this.formValidate = response.error.message;
            }
          });
        }
      },
    },
      computed: {

      },
      components: {
        // DropdownDefault,
        InputLabelTextDefault,
        Captcha
      }
  }
</script>

<style>

</style>
