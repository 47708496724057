<template>
  <div class="content-site">
    <div v-if="portfolio">
      <NavMobile></NavMobile>
      <div class="container container-site">
        <TopMenu></TopMenu>
        <LeftMenu></LeftMenu>
        <div class="content-media">
<!--          <Rules></Rules>-->
          <v-main>
            <router-view/>
          </v-main>
        </div>
      </div>
      <ModalPhone width="470px" v-if="!portfolio.phone && !portfolio.teleg">
        <div class="modal-phone  window-site">
          <h2>{{__('Phone or telegram nickname')}}</h2>
          <div class=" text-window">
            <p class="sem">{{__('Enter your phone number or telegram nickname. This is necessary for communication with partners, etc.')}}</p>
            <div class="choice-phone-tg">
              <div class="top-btn">
                <v-btn v-ripple="false" v-on:click="type= 1" :class="type== 1 ? 'active' : ''" >{{__('Phone number')}}</v-btn>
                <v-btn v-ripple="false" v-on:click="type= 2" :class="type== 2 ? 'active' : ''">{{__('Telegram nickname')}}</v-btn>
              </div>
              <div class="block block-tel" v-if="type== 1">
                <!--              <v-btn v-on:click="type= 2">Telegram</v-btn>-->

                <VuePhoneNumberInput
                        v-model="phone"
                        v-bind:error="errorNumber"
                        v-bind:default-country-code="form.phone_country"
                        @update="updateNumber"
                ></VuePhoneNumberInput>
              </div>
              <div v-else class="telega">
                <!--              <v-btn v-on:click="type= 1">Phone</v-btn>-->

                <InputLabelTextDefault
                        :placeholder="__('Enter your telegram nickname')"
                        v-model="teleg"
                        v-bind:errors="formValidate.teleg"
                        @change="updateValidate('teleg')"
                ></InputLabelTextDefault>
              </div>
            </div>

            <div class="btn-window">
              <v-btn
                v-on:click="onSubmit"
                class="btn-site"
                :loading="submitDeleted"
                :disabled="$v.$invalid"
              >{{__('Confirm')}}</v-btn>
            </div>
          </div>
        </div>
      </ModalPhone>

<!--      <ModalNew width="470px" v-if="dialogNew" v-bind:modal_cancel.sync="dialogNew">-->
<!--        <div class="modal-new">-->
<!--          <div class="rules-block">-->
<!--            <div class="left">-->
<!--              <img src="/newfrontend/images/rules-img.svg" alt="">-->
<!--              <div>-->
<!--                <p>{{__('Rules of the company during the New Year holidays')}}</p>-->
<!--                <span>{{__('Platform operation during the New Year period from December 23 to January 10')}}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="right">-->
<!--              <a target="_blank" href="https://mediachain.inc/media/news/pravila_rabotyi_kompanii_MediaChain_v_period_novogo_goda" v-if="language == 'ru'" class="btn-white-new">{{__('more details')}}</a>-->
<!--              <a target="_blank" href="https://mediachain.inc/media/news/Reglas_de_trabajo_de_la_empresa_MediaChain_durante_el_per%C3%ADodo_de_A%C3%B1o_Nuevo" v-else-if="language == 'es'" class="btn-white-new">{{__('more details')}}</a>-->
<!--              <a target="_blank" href="https://mediachain.inc/media/news/Rules_of_work_of_MediaChain_company_during_the_New_Year_period" v-else class="btn-white-new">{{__('more details')}}</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ModalNew>-->

    </div>
    <div v-else class="loading">
      <loaderMc></loaderMc>
      {{__('Loading')}}
    </div>
  </div>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    // import Rules from "./modules/Rules";
    import NavMobile from "./modules/NavMobile";
    import ModalPhone from "./modules/ModalPhone";
    // import ModalNew from "./modules/ModalNew";
    import InputLabelTextDefault from "./modules/InputLabelTextDefault";
    import LeftMenu from "./LeftMenu";
    import TopMenu from "./TopMenu";
    import loaderMc from "./SvgComponents/loaderMc";
    import {maxLength, minLength, required} from "vuelidate/lib/validators";
    export default {
        data: function() {
            return {
                dialogNew: false,
                type: 1,
                phone: null,
                teleg: null,
                submitDeleted: false,
                formValidate: {
                    phone: null,
                    teleg: null,
                },
                form: {
                    phone_number: null,
                    phone_country: null,
                    phone_code: null
                },
                phone_number: null,
                errorNumber: null,
            }
        },
        name: 'Office',
        computed: {
          language() {
            return this.$store.state.language.lang
          },
            portfolio() {
                return this.$store.state.auth.portfolio
            },
            updateTime() {
                return this.$store.state.preference.updateTime
            },
        },
        watch: {
            $route() {
                // react to route changes...
                let time= Date.now()-60000;
                if(this.updateTime < time) {
                    this.updateUser();
                }

            }
        },
        validations() {
            return {
              phone: {
                  required: (this.type == 1) ? required : true,
                  minLength: minLength(3),
                  maxLength: maxLength(50)
              },
              teleg: {
                  required: (this.type == 2) ? required : true,
                  minLength: minLength(3),
                  maxLength: maxLength(50)
              }
            }
        },
        methods: {
            updateNumber: function (event) {
                this.errorNumber= false;
                this.phone_number= event;
            },
            updateValidate: function(type) {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit: function () {
                let valid= this.$v;

                if(valid.$invalid) {
                    let massages= {
                        'phone': [],
                        'teleg': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                }  else if(this.type== 1 && this.phone_number.nationalNumber && !this.phone_number.isValid) {
                    this.errorNumber= true;
                    this.modal_security = false;
                } else {
                    this.submitDeleted = true;

                    let response = {};
                    let phone= null;
                    let teleg= null;
                    let phone_json= null;

                    if(this.type== 1) {
                        phone_json= {
                            phone_number: this.phone_number.nationalNumber,
                            phone_country: this.phone_number.countryCode,
                            phone_code: this.phone_number.countryCallingCode,
                        }

                        phone= response['phone']= phone_json.phone_code+phone_json.phone_number;
                        phone_json= response['phone_json']= phone_json;
                    } else {
                        teleg= response['telegram']= this.teleg;
                    }
                    console.log('response');
                    console.log(response);

                    this.$http.post('ajax/save_phone', response)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            this.$store.dispatch('asyncResp', response.resp_tickets);

                            let portfolio= this.$cleanObject(this.portfolio);
                            portfolio.phone= phone;
                            portfolio.teleg= teleg;
                            portfolio.phone_json= this.phone_json;

                            this.$store.dispatch('asyncPortfolio', portfolio);

                            this.modal_security = false;
                            this.submitDeleted = false;
                        }).catch(err => {
                        this.securityDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;

                        }
                    });
                }
            },
            updateUser() {
                this.$store.dispatch('asyncUpdateTime', Date.now());
                this.$store.dispatch('asyncPayments', null);

                this.$http.get('ajax/user_info')
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        this.$store.dispatch('asyncResp', response.resp_tickets);
                        let portfolio = response.data;
                        // if(portfolio.lang) {
                        //     this.$store.dispatch("asyncLanguage", portfolio.lang);
                        // }
                        this.$store.dispatch('asyncPortfolio', portfolio);
                        this.$store.dispatch('asyncPayments', portfolio.payment);

                        if(this.portfolio.window && !localStorage.getItem('dialogNew')) {
                            localStorage.setItem('dialogNew', true);
                            this.dialogNew= true;
                        } else {
                            this.dialogNew= false;
                            if(this.portfolio.window== 0) {
                                localStorage.removeItem('dialogNew');
                            }
                        }
                    }).catch((err) => {
                    this.httpHandler(err);
                });
            }
        },
        created() {
            this.updateUser();
        },
        components: {
            VuePhoneNumberInput,
            InputLabelTextDefault,
            loaderMc,
            ModalPhone,
            NavMobile,
            TopMenu,
            // ModalNew,
            // Rules,
            LeftMenu
        }
    }


</script>

<style scoped>

</style>
