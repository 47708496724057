import { render, staticRenderFns } from "./WalletsSettingNFT.vue?vue&type=template&id=70a86999&scoped=true&"
import script from "./WalletsSettingNFT.vue?vue&type=script&lang=js&"
export * from "./WalletsSettingNFT.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a86999",
  null
  
)

export default component.exports