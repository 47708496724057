<template>
  <div style="white-space: pre-line;">
    <div>
      <div class="d-flex justify-space-between align-center" v-if="message.opp_type== 1">
        <div class="dialog-left">
          <div class="img">
            <img src="/newfrontend/images/support.png" alt="">
          </div>
          <div class="top">
            <p class="name">{{__('Support')}}<span class="time-mess">{{((message.message_at) ? message.message_at : message.created_at) | formatDate}}</span></p>
          </div>
          <div class="body_old">
            <div class="card">
              <!--<span class="time-mess">{{((message.message_at) ? message.message_at : message.created_at) | formatFullTime}}</span>-->
              {{__(message.message, {'username': portfolio.name})}}

            </div>
<!--            <BtnCommandList v-if="user_id== 'bot'" @sendCommand="sendCommand"/>-->
          </div>
        </div>
      </div>
      <div class="dialog-right" v-else>

        <div class="body_old">
          <div class="card">
            {{message.message}}
          </div>
        </div>
        <div class="top">
          <p class="name"><span class="time-mess">{{((message.message_at) ? message.message_at : message.created_at) | formatDate}}</span>{{__('Your message')}} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    // import BtnCommandList from "./BtnCommandList";
    export default {
        name: 'MessengerMessagesListItem',
        data () {
            return {

            }
        },
        props: {
            user_id: {
                type: [String, Number]
            },
            message: {
                type: Object
            },
            index: {
                type: Number
            },
            context_id: {
                type: Number
            }
        },
        watch: {
            edit(val) {
                this.$emit('editMessages', this.message.id, val);
            },
            messages_edit(val) {
                if(!val) {
                    this.edit= false
                }
            },
            context_id(val) {
                if(val != this.message.id) {
                    this.show_menu= false;
                }
            }
        },
        computed: {
            portfolio() {
                return this.$store.state.auth.portfolio
            },
        },
        asyncComputed: {

        },
        created() {

        },
        methods: {

        },
        components: {
            // BtnCommandList,
        },

    }
</script>