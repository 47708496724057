<template>
    <div class="team-page">
        <div class="content" v-if="affiliate && history">
            <div class="team-top">
              <MyTeam v-bind:affiliate="affiliate"></MyTeam>
<!--              <Promo v-bind:affiliate="affiliate"></Promo>-->
            </div>
            <div class="team-chart">
              <div>
                <p class="semi">{{__('Direct partner')}}</p>
                <div>
                  <Chart :series="seriesDirect" height="220" color="#FF0000" count></Chart>
                </div>
              </div>
              <div>
                <p class="semi">{{__('Total partners')}}</p>
                <div>
                  <Chart :series="seriesPartners" height="220" color="#3A63DB" count></Chart>
                </div>
              </div>
                <div>
                    <p class="semi">{{__('Total turnover')}}</p>
                    <div>
                        <Chart :series="seriesTurnover" height="220" color="#2BB596"></Chart>
                    </div>
                </div>
                <div>
                    <p class="semi">{{__('Income by days')}}</p>
                    <div>
                        <ChartBar :nav="true" :series="seriesProfitDay" height="220" color="#2BB596"></ChartBar>
                    </div>
                </div>
            </div>
          <TablePagination class="all-partner" tag="ul" v-bind:body.sync="history" v-bind:params="params">
            <template slot="header">
              <li class="list-name">
                <div class="list-name-top">
                    <div class="line-class">
                        <div class="block">
                            <div class="img">
                                <span>
                                    <img src="/newfrontend/images/p-line.svg" alt="">
                                </span>
                            </div>
                            <div>
                                <p>{{affiliate.first_line}}<span>{{__('partner')}}</span></p>
                                <span class="text">{{__('1st line')}}</span>
                            </div>
                        </div>
                        <div class="block">
                            <div class="img">
                                <span>
                                    <img src="/newfrontend/images/p-line-1.svg" alt="">
                                </span>
                            </div>
                            <div>
                                <p>{{affiliate.all_line}}<span>{{__('partner')}}</span></p>
                                <span class="text">{{__('Total partners')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="upline" v-if="affiliate.parent">
                        <div class="img">
                            <img width="35px" :src="(affiliate.parent.photo) ? '/assets/uploads/user/'+affiliate.parent.photo : '/newfrontend/images/default.png'"/>
                        </div>
                        <div class="right-text">
                            <p class="name-upline">{{affiliate.parent.name}} <span>{{__('Upstream partner')}}</span></p>
<!--                            <p class="email" v-if="affiliate.parent.email">{{affiliate.parent.email}}</p>-->
                            <p class="phone"><span v-if="affiliate.parent.phone">{{affiliate.parent.phone}}</span><span v-else-if="affiliate.parent.telegram">{{affiliate.parent.telegram}}</span></p>
                        </div>
                    </div>
                </div>
                <div class="left">
                    <div class="name d-flex">
<!--                        <div class="img">-->
<!--                            <img width="35px" :src="(portfolio.photo) ? '/assets/uploads/user/'+portfolio.photo : '/newfrontend/images/default.png'"/>-->
<!--                        </div>-->
                        <p>{{__('My Referrals')}}</p>
                    </div>
                    <div class="search">
                        <p class="svg">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8757 12.9327L11.0203 10.0781C11.8718 9.0159 12.3349 7.69474 12.333 6.3334C12.333 3.0214 9.64501 0.333405 6.33301 0.333405C3.02101 0.333405 0.333008 3.0214 0.333008 6.3334C0.333008 9.6454 3.02101 12.3334 6.33301 12.3334C7.69434 12.3353 9.0155 11.8722 10.0777 11.0207L12.9323 13.8761L13.8757 12.9327ZM10.9997 6.3334C11.0016 7.54702 10.5291 8.71333 9.68301 9.5834L9.58301 9.6834C8.71293 10.5295 7.54663 11.002 6.33301 11.0001C3.75434 11.0001 1.66634 8.9114 1.66634 6.3334C1.66634 3.75474 3.75434 1.66674 6.33301 1.66674C8.91101 1.66674 10.9997 3.75474 10.9997 6.3334Z" fill="#687787"/>
                            </svg>
                        </p>
                        <SearchDefault class_search="search_team" :placeholder="__('Search')" v-model="params.search" @searching="getAffiliateHistory"></SearchDefault>
                        <p class="img">
                            <img src="/newfrontend/images/cancel-partner.svg" alt="">
                        </p>
                    </div>
                </div>
              </li>
              <div class="name-new">
                  <div class="name">
                      <p>{{__('Partner')}}</p>
                  </div>
                  <div class="turenover">
                      <p>{{__('Personal turenover')}}</p>
                  </div>
                  <div class="farming">
                      <p>{{__('Personal deposit')}}</p>
                  </div>
                  <div class="rank">
                      <p>{{__('Rank')}}</p>
                  </div>
              </div>
            </template>

            <template v-slot="page">

              <HistoryItem v-bind:item="page" v-bind:position="position"></HistoryItem>
            </template>
          </TablePagination>
        </div>
        <div v-else class="loading">
            <loaderMc></loaderMc>
          {{__('Loading')}}
        </div>
    </div>

</template>

<script>
import MyTeam from "./Team/MyTeam";
import HistoryItem from "./Team/HistoryItem";
import Chart from "../modules/Chart";
import ChartBar from "../modules/ChartBar";
import TablePagination from "../modules/TablePagination";
import loaderMc from "../SvgComponents/loaderMc";
import SearchDefault from "../modules/SearchDefault";

export default {
  name: 'team',
  data () {
    return {
        position: 0,
        affiliate: null,
        history: null,
        params: {
            search: null
        },
        seriesDirect: [],
        seriesTurnover: [],
        seriesDay: [],
        seriesPartners: [],
        seriesProfitDay: [],
        levels: [
            {
                name: 'All',
                level: null,
            },
            {
                name: 'level 1',
                level: 1,
            },
            {
                name: 'level 2',
                level: 2,
            },
            {
                name: 'level 3',
                level: 3,
            }
        ],
        formValidate: {
            level: null,
            search: null
        }
    }
  },
  methods:{
      updateValidate: function(type) {
          this.formValidate= this.$changeValidate(this.formValidate, this.$v.params[type], type);
      },
      getAffiliate() {
          this.$http.get('ajax/affiliate')
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  response=response.data;
                  this.affiliate= response;

                  this.seriesDirect= [{
                      name: '',
                      data: response.charts_direct_partner
                  }];
                  this.seriesTurnover= [{
                      name: '',
                      data: response.charts_total_turnover
                  }];
                  this.seriesPartners= [{
                      name: '',
                      data: response.charts_total_partners
                  }];
                  this.seriesProfitDay= [{
                      name: '',
                      data: response.charts_profit_day
                  }];
              }).catch((err) => {
              this.httpHandler(err);
          });
      },
      getAffiliateHistory() {
          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/affiliate/list', this.params)
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  this.history= response.data;
              }).catch((err) => {
              this.httpHandler(err);
          });
      }
  },
  props: {

  },
  computed: {
      language() {
          return this.$store.state.language.lang;
      },
      portfolio() {
          return this.$store.state.auth.portfolio;
      },
  },
  watch: {
      language() {
          this.getAffiliate();
      }
  },
  created() {
      this.getAffiliate();
      this.getAffiliateHistory();
  },
  components: {
    SearchDefault,
    loaderMc,
    Chart,
    ChartBar,
    TablePagination,
    MyTeam,
    HistoryItem,
  }
}
</script>

<style scoped>

</style>
