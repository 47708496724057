<template>
    <div class="tikets" v-if="ticket">
      <div class="top-t">
        <div class="top">
            <div class="back">
<!--                <router-link class="" :to="{name: 'messages'}"><img src="/newfrontend/images/arrow-left.svg" alt=""></router-link>-->
                    <span class="id" >Ticket #{{ticket.public_id}}</span>
                    <span class="subject">{{ticket.subject}}</span>
            </div>
            <div class="top-right">
                <span class="close" v-if="ticket.status">{{__('Close')}}</span>
                <span class="open" v-else>{{__('Open')}}</span>
                <p> {{ticket.created_at | formatDate}}</p>
            </div>
        </div>
        <div class="top-body">
            <p class="title">{{ticket.title}}</p>
            <p class="description">{{ticket.description}}</p>
            <div>
              <span v-if="!ticket.status" class="btn-grey" v-on:click="dialog= true">{{__('Close ticket')}}</span>
            </div>
        </div>
      </div>
        <div v-if="messages== false" class="loading">
          <loaderMc></loaderMc>
          {{__(("Loading"))}}
        </div>
        <Messages
          v-bind:messages.sync="messages"
          v-bind:messages_id.sync="messages_id"
          v-bind:user_id="user_id"
          v-bind:ticket="ticket"
          v-else-if="messages"
          class="body"
        ></Messages>
<!--          <div class="right-mess">-->
<!--             <div class="text">-->
<!--                asdasd-->
<!--             </div>-->
<!--             <div class="name">-->
<!--                <p>12/12/2021 10:43 <span>Your message</span></p>-->
<!--             </div>-->
<!--          </div>-->
<!--         <div class="left-mess">-->
<!--             <div class="img">-->
<!--                 <img src="/newfrontend/images/user.svg" alt="">-->
<!--             </div>-->
<!--             <div class="user-text">-->
<!--                 <div class="user">-->
<!--                     <p>Alex Lendel (support)<span>12/12/2021 10:43</span></p>-->
<!--                 </div>-->
<!--                 <div class="text">-->
<!--                     Ask any question. I have a many answers from previous customers. Feel free to start playing. Ask any question. I have a many answers from previous customers. Feel free to start playing-->
<!--                 </div>-->
<!--             </div>-->
<!--         </div>-->
      <ModalDefault v-if="dialog" v-bind:modal_cancel.sync="dialog">
        <div class="window-site create-message">
          <div class="top-window text-center">
            <p>{{__('Are you sure you want to close the ticket?')}}</p>
          </div>
          <div class="text">

          </div>
          <div class="create-btn">
            <span class="btn-white" v-on:click="dialog=false">{{__('Cancel')}}</span>
            <v-btn class="btn-site" v-on:click="onSubmit" v-on:loading="submitDisabled">{{__('Close')}}</v-btn>
          </div>
        </div>
      </ModalDefault>
    </div>
    <div v-else class="loading">
        <loaderMc></loaderMc>
        {{__('Loading')}}
    </div>
</template>

<script>
    import Messages from "./Messages";
    import ModalDefault from "../../modules/ModalDefault";
    import loaderMc from "../../SvgComponents/loaderMc";
    export default {
        name: 'Support',
        data: function() {
            return {
                messages: [],
                messages_id: [],
                user_id: this.$route.params.id,
                ticket: null,
                submitDisabled: false,
                dialog: false,
            }
        },
        watch: {
            message(messages) {
                let user_id= this.user_id;
                if(messages instanceof Array) {
                    for (let i= 0; i < messages.length; i++) {
                        let message= messages[i];
                        if(message.user_id== user_id || message.to== user_id) {
                            this.updateMessages(message);
                        }
                    }
                } else {
                    if(messages.user_id== user_id || messages.to== user_id) {
                        this.updateMessages(messages);
                    }
                }
            }
        },
        computed: {
            message() {
                return this.$store.getters.message;
            }
        },
        created() {
            this.$http.get("ajax/chat/"+this.user_id)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    this.$store.dispatch('asyncResp', response.resp_tickets);
                    response= response.data;
                    response.messages.data= response.messages.data.reverse();
                    this.messages= response.messages;
                    let messages_id= [];

                    this.user_id= response.user_id;
                    for(let i=0; i < response.messages.data.length; i++) {
                        let message= response.messages.data[i];
                        messages_id.push(message.id);
                    }
                    this.messages_id= messages_id;
                    this.ticket= response.ticket;
                });
        },
        methods: {
            onSubmit() {
                this.submitDisabled = true;

                let response = {
                    id: this.user_id
                };

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/tickets/close', response)
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        this.$store.dispatch('asyncResp', response.resp_tickets);
                        // response= {
                        //     "data": {
                        //         "address": "0xbe48fe67d24c47f846b6fd9bcdac6d62bc2108f0",
                        //         "amount": "31.00000000",
                        //         "commission": "1",
                        //         "percent_day": 5,
                        //         "percent_total": 155,
                        //         "payment_id": 3,
                        //         "tag": false,
                        //         "time": "2021/05/05 12:10:43",
                        //         "id": 6
                        //     },
                        //     "status": 200
                        // };

                        this.submitDisabled= false;
                        this.$router.push({name: 'messages'});
                    }).catch((err) => {
                    this.submitDisabled= false;
                    this.httpHandler(err);
                });
            },
            updateMessages(message) {
                let messages= this.$cleanObject(this.messages);

                let indexMessage= this.$filterIndexObject(messages.data, message.id);
                message.deleted= null;
                if(indexMessage >= 0) {
                    messages.data[indexMessage]= message;
                } else {
                    this.messages_id.push(message.id);
                    messages.data.push(message);
                    messages.total += 1;
                }
                this.messages= messages;
            },
        },
        components: {
            loaderMc,
            ModalDefault,
            Messages,
        }
    }
</script>
