<template>
  <div class="img-all">

    <my-upload field="img"
       v-if="show"
       @crop-upload-success="cropUploadSuccess"
       @crop-upload-fail="cropUploadFail"
       v-model="show"
       :width="80"
       :height="80"
       :url="this.url"
       :params="params"
       :headers="headers"
       :langType="lang"
       img-format="png"></my-upload>
      <div class="img-upload" @click="toggleShow ">
          <img class="" :src="imgDataUrl">
          <p class="semi">{{__("Upload photo")}}</p>
      </div>
<!--    <v-btn class="default" @click="toggleShow" small>set avatar</v-btn>-->
  </div>
</template>

<script>
    import 'babel-polyfill'; // es6 shim
    import myUpload from 'vue-image-crop-upload';

    export default {
        data () {
            return {
                show: false,
                params: this.post_params,
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                imgDataUrl: (this.img) ? this.assets+this.img : '/newfrontend/images/upload-photo.svg',
                formValidate: null
            }
        },
        computed: {
            lang() {
                return this.$store.state.language.lang
            }
        },
        components: {
            'my-upload': myUpload
        },
        props: {
            img: {
                type: String
            },
            post_params: {
                type: Object,
                default: Object
            },
            url: {
                type: String
            },
            assets: {
                type: String,
                default: '../assets/uploads/store/'
            },
            response: {
                default: null
            },
        },
        methods: {
            toggleShow() {
                this.show = !this.show;
            },
            /**
             * crop success
             *
             * [param] imgDataUrl
             * [param] field
             */
            // cropSuccess(imgDataUrl, field){
            //     // console.log('-------- crop success --------');
            //     // this.imgDataUrl = imgDataUrl;
            //     // console.log('field: ' + field);
            // },
            /**
             * upload success
             *
             * [param] jsonData  server api return data, already json encode
             * [param] field
             */
            cropUploadSuccess(jsonData){
                let data= jsonData.data;
                if(data.status) {
                    this.$emit('update:response', {'img': data.img});
                    this.imgDataUrl= this.assets+data.img;
                    this.show= false;
                } else {
                    if(data.validation) {
                        this.formValidate= data.validation;
                    }

                    if(data.message) {
                        this.$store.commit('updateErrorSnackbars', data.message);
                    }
                }
            },
            /**
             * upload fail
             *
             * [param] status    server api return error status, like 500
             * [param] field
             */
            cropUploadFail(status, field){
                console.log('-------- upload fail --------');
                console.log(status);
                console.log('field: ' + field);
            }
        }
    };
</script>


<style>


</style>
