import Vue from 'vue';
import VueRouter from "vue-router";
import Dashboard from '../components/pages/Dashboard';
import Deposit from '../components/pages/Deposit';
import Operations from "../components/pages/Operations";
import Settings from "../components/pages/Settings";
import Support from "../components/pages/Support";
import Team from "../components/pages/Team";
import Main from "../components/Main";
import Office from "../components/Office";
import Authentication from "../components/Authentication";
import About from "../components/pages/About";
import Contacts from "../components/pages/Contacts";
import AuthSignup from "../components/pages/AuthSignup";
import AuthLogin from "../components/pages/AuthLogin";
import AuthForgot from "../components/pages/AuthForgot";
import AuthResetPass from "../components/pages/AuthResetPass";
import AuthSecurity from "../components/pages/AuthSecurity";
import NotFound from '../components/pages/NotFound';
import Finance from '../components/pages/Finance';
import Media from '../components/pages/Media';
import MediaPage from '../components/pages/MediaPage';
import Messages from '../components/pages/Messages';
import Promo from '../components/pages/Promo';
import Terms from '../components/pages/Terms';
import Instructions from '../components/pages/Instructions';
import Privacy from '../components/pages/Privacy';
// import Farming from '../components/pages/Farming';
import WithdrawCoin from '../components/pages/WithdrawCoin';
// import StakingPartners from '../components/pages/StakingPartners';
// import ComingSoon from '../components/pages/ComingSoon';
import Coming from '../components/pages/Coming';
import MeetTeam from '../components/pages/MeetTeam';
import RoadMap from '../components/pages/RoadMap';
import WowList from '../components/pages/WowList';
import WowPromoList from "../components/pages/WowPromoList";
import Products from '../components/pages/Products';
import InstructionsCab from '../components/pages/InstructionsCab';
import MediaCrypto from '../components/pages/MediaCrypto';
import MediaCryptoPage from '../components/pages/MediaCryptoPage';
import Licenses from '../components/pages/Licenses';
import Marketing from '../components/pages/Marketing';
import HomeNew from '../components/pages/HomeNew';
import Top100 from '../components/pages/Top100';
import Academy from '../components/pages/Academy';
import AcademyPage from '../components/pages/AcademyPage';
import Deals from '../components/pages/Deals';
import Events from '../components/pages/Events';
import EventsPage from '../components/pages/EventsPage';
import AcademyLesson from '../components/pages/AcademyLesson';
import MarchList from '../components/pages/MarchList';
// import Staking from '../components/pages/Staking';


import Ticket from '../components/pages/Messenger/Ticket';

import store from "../store/index";
import middlewarePipeline from "./middlewarePipeline";
import guest from "./middleware/guest";
import auth from "./middleware/auth";
import School from "../components/pages/School";


import DashboardNew from '../components/pages/DashboardNew';
import OperationsNew from '../components/pages/OperationsNew';
import Fund from '../components/pages/Fund';

Vue.use(VueRouter);
const routes= new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            component: Main,
            children: [
                {
                    path: (process.env.NODE_ENV === 'production') ? '/' : '/newfrontend/',
                    name: 'home',
                    component: HomeNew,
                    meta: {
                        title: 'Home',
                    },
                    children: [
                        {
                            path: '/u/:id'
                        }
                    ]
                },
                {
                    path: 'about_us',
                    name: 'about',
                    component: About,
                    meta: {
                        title: 'About',
                    },
                },
                {
                    path: 'trading',
                    name: 'deals',
                    component: Deals,
                    meta: {
                        title: 'Trading',
                    },
                },
                {
                    path: 'coming',
                    name: 'coming',
                    component: Coming,
                    meta: {
                        title: 'Coming soon',
                    },
                },
                {
                    path: 'terms',
                    name: 'terms',
                    component: Terms,
                    meta: {
                        title: 'Terms & Conditions',
                    },
                },
                {
                    path: 'instructions',
                    name: 'instructions',
                    component: Instructions,
                    meta: {
                        title: 'Instructions',
                    },
                },
                {
                    path: 'privacy',
                    name: 'privacy',
                    component: Privacy,
                    meta: {
                        title: 'Privacy Policy',
                    },
                },
                {
                    path: '/contacts',
                    name: 'contacts',
                    component: Contacts,
                    meta: {
                        title: 'Contacts',
                    },
                },
                {
                    path: '/marketing',
                    name: 'marketing',
                    component: Marketing,
                    meta: {
                        title: 'Marketing',
                    },
                },
                {
                    path: '/top30',
                    name: 'top_page',
                    component: Top100,
                    meta: {
                        title: 'Top30',
                    },
                },
                {
                    path: '/roadmap',
                    name: 'roadmap',
                    component: RoadMap,
                    meta: {
                        title: 'RoadMap',
                    },
                },
                {
                    path: '/home_team',
                    name: 'MeetTeam',
                    component: MeetTeam,
                    meta: {
                        title: 'MeetTaem',
                    },
                },
                {
                    path: '/licenses',
                    name: 'Licenses',
                    component: Licenses,
                    meta: {
                        title: 'Licenses',
                    },
                },

                // {
                //     path: '/media/:category?',
                //     name: 'media',
                //     component: Coming,
                //     meta: {
                //         title: 'Media',
                //     },
                // },
                {
                    path: '/media/:category?',
                    name: 'media',
                    component: Media,
                    meta: {
                        title: 'Media',
                    },
                },
                {
                    path: '/news_crypto',
                    name: 'mediaCrypto',
                    component: MediaCrypto,
                    meta: {
                        title: 'Media',
                    },
                },
                {
                    path: '/news_crypto/:url',
                    name: 'news_crypto_page',
                    component: MediaCryptoPage,
                    meta: {
                        title: 'Media',
                    },
                },
                {
                    path: '/media/:category/:url',
                    name: 'media_page',
                    component: MediaPage,
                    meta: {
                        title: 'Media',
                    },
                },
            ]
        },
        {
            path: '/authentication',
            component: Authentication,
            children: [
                {
                    path: '/registration',
                    name: 'register',
                    component: AuthSignup,
                    meta: {
                        title: 'Registration',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/login',
                    name: 'login',
                    component: AuthLogin,
                    meta: {
                        title: 'Login',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/forgot',
                    name: 'forgot',
                    component: AuthForgot,
                    meta: {
                        title: 'Forgot',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/security',
                    name: 'security',
                    component: AuthSecurity,
                    meta: {
                        title: 'Authentication security',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/ajax/password/reset/:token',
                    name: 'password_reset',
                    component: AuthResetPass,
                    meta: {
                        title: 'Reset password',
                        middleware: [
                            guest
                        ]
                    },
                },
            ]
        },
        {
            path: '/office',
            component: Office,
            children: [
                // {
                //     path: '/office/staking',
                //     name: 'staying',
                //     component: Staking,
                //     meta: {
                //         title: 'Staking',
                //         middleware: [
                //             auth
                //         ]
                //     },
                // },
                // {
                //     path: '/office/staking_partners',
                //     name: 'stakingPartners',
                //     component: StakingPartners,
                //     meta: {
                //         title: 'Staking Partners',
                //         middleware: [
                //             auth
                //         ]
                //     },
                // },
                // {
                //     path: '/office/products',
                //     name: 'products',
                //     component: ComingSoon,
                //     meta: {
                //         title: 'Coming Soon',
                //         middleware: [
                //             auth
                //         ]
                //     },
                // },
                {
                    path: '/office',
                    name: 'dashboard',
                    component: Dashboard,
                    meta: {
                        title: 'Dashboard',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office_new',
                    name: 'dashboardNew',
                    component: DashboardNew,
                    meta: {
                        title: 'DashboardNew',
                        middleware: [
                            auth
                        ]
                    },
                },
                // {
                //     path: '/office/farming',
                //     name: 'farming',
                //     component: Farming,
                //     meta: {
                //         title: 'Farming',
                //         middleware: [
                //             auth
                //         ]
                //     },
                // },

                {
                    path: '/office/events',
                    name: 'events',
                    component: Events,
                    meta: {
                        title: 'Events',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/events/:url',
                    name: 'event_page',
                    component: EventsPage,
                    meta: {
                        title: 'EventPage',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/academy',
                    name: 'academy',
                    component: Academy,
                    meta: {
                        title: 'Academy',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/academy/:url',
                    name: 'academy_page',
                    component: AcademyPage,
                    meta: {
                        title: 'Academy Page',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/academy/:category/:url',
                    name: 'academy_lesson',
                    component: AcademyLesson,
                    meta: {
                        title: 'Academy Lesson',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/school',
                    name: 'school',
                    component: School,
                    meta: {
                        title: 'School',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/deposit',
                    name: 'deposit',
                    component: Deposit,
                    meta: {
                        title: 'Deposit',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/messages',
                    name: 'messages',
                    component: Messages,
                    meta: {
                        title: 'Messages',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/message/:id',
                    name: 'ticket',
                    component: Ticket,
                    meta: {
                        title: 'Ticket',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/withdraw',
                    name: 'withdraw',
                    component: Finance,
                    meta: {
                        title: 'Withdraw',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/withdraw/coin',
                    name: 'withdraw_coin',
                    component: WithdrawCoin,
                    meta: {
                        title: 'Withdraw MC',
                        middleware: [
                            auth
                        ]
                    },
                },

                {
                    path: '/office/transactions',
                    name: 'transactions',
                    component: Operations,
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/transactionsNew',
                    name: 'transactionsNew',
                    component: OperationsNew,
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/fund',
                    name: 'fund',
                    component: Fund,
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/settings/:type?',
                    name: 'settings',
                    component: Settings,
                    children: [{
                        path: '/office/settings/:current',
                        name: 'settings_current',
                        meta: 'tab'
                    }],
                    meta: {
                        title: 'Settings',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/support',
                    name: 'support',
                    component: Support,
                    meta: {
                        title: 'Support',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/team',
                    name: 'team',
                    component: Team,
                    meta: {
                        title: 'Team',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/promo',
                    name: 'promo',
                    // component: ComingSoon,
                    component: Promo,
                    meta: {
                        title: 'Promo',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/wow_list',
                    name: 'wow_list',
                    // component: ComingSoon,
                    component: WowList,
                    meta: {
                        title: 'Wow List',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/march_list',
                    name: 'march_list',
                    component: MarchList,
                    meta: {
                        title: 'March List',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/wow_promo_list',
                    name: 'wow_promo_list',
                    // component: ComingSoon,
                    component: WowPromoList,
                    meta: {
                        title: 'Promo List',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/products',
                    name: 'products',
                    // component: ComingSoon,
                    component: Products,
                    meta: {
                        title: 'Products',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/office/instruction',
                    name: 'instruction_cab',
                    // component: ComingSoon,
                    component: InstructionsCab,
                    meta: {
                        title: 'Instruction',
                        middleware: [
                            auth
                        ]
                    },
                },
            ]

        },
        {
            path: '/logout',
            name: 'logout',
        },
        {
            path: '*',
            name: 'not_found',
            component: NotFound,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition || to.meta=='tab') {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});


routes.beforeEach((to, from, next) => {
    let title = "MediaChain";

    if (to.meta.title) {
        document.title = title + ' | ' + to.meta.title
    } else {
        document.title = title
    }


    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store
    };


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});


export default routes;
