<template>
  <ModalDefault  width="650" v-if="dialog" v-bind:modal_cancel.sync="dialog">
    <div class="text-center window-site delete-window video-window">
      <div>
        <span class="btn-waiting-cancel  btn-grey" v-on:click="dialog= false">{{__('')}}</span>
      </div>
      <div class="text-center text-window">
        <iframe ref="video" width="100%" height="315" :src="'https://www.youtube.com/embed/'+video+'?rel=0&showinfo=0&autoplay=1'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </ModalDefault>
</template>

<script>
    import ModalDefault from "./ModalDefault";

    export default {

        name: 'ModalVideo',
        data: function() {
            return {
                dialog: true
            }
        },
        props: {
            video: {
                type: [String]
            }
        },
        mounted() {

        },
        computed: {

        },
        watch: {
            dialog() {
                this.$emit('update:video', false);
            }
        },
        methods: {

        },
        components: {
            ModalDefault
        },
    };
</script>
