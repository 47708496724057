<template>
    <DepositStep2 v-if="deposit_info" v-bind:deposit.sync="deposit_info" @updateDeposit="updateDeposit"></DepositStep2>

    <div class="deposit" v-else>
      <div class="left-content">
        <h1>{{__('Deposit Payment')}}</h1>
        <div class="choose">
          <p class="semi">{{__('Choose a payment method')}}</p>
          <DropdownDefault v-bind:items="filterPayments" v-bind:item_disabled="disableItem" text="name" value_key="payment_id" v-on:change="$emit('update:payment', $payment(form.wallet))" v-model="form.wallet" dropSlot>
            <template v-slot:selection="item">
              <span class="img" :class="item.name_class"></span>
              {{item.name}}
            </template>
            <template v-slot:item="item">
              <span class="img" :class="item.name_class"></span>
              <div v-if="item.active">
                {{item.name}}
              </div>
              <div v-else disabled>
                {{item.name}} <span></span>
              </div>
            </template>
          </DropdownDefault>
        </div>
        <div class="amount" v-if="payment.payment_id!= 7">
          <div class="left">
            <p class="semi">{{__('Amount')}}</p>
            <InputLabelTextDefault
                type="number"
                v-model="form.amount"
                v-bind:errors="formValidate.amount"
                @input="calcSum('give')"
            >
              <template slot="right">
                <span class="usd">USD</span>
              </template>
            </InputLabelTextDefault>
          </div>
          <div class="right">
            <p class="min">{{__('Minimal Deposit: $50')}}</p>
            <InputLabelTextDefault
                type="amount_coin"
                v-model="form.amount_coin"
                v-bind:errors="formValidate.amount_coin"
                @input="calcSum( 'get')"
            >
              <template slot="right">
                <span class="usd">{{payment.currency}}</span>
              </template>
            </InputLabelTextDefault>
          </div>
        </div>
        <div class="amount amount-coin" v-else>
          <div class="left">
            <p class="semi semi-coin">
              {{__('Amount')}}
              <span>
                <span class="mr-1">Max</span>
                <img src="/newfrontend/images/total-ms.svg" alt="">
                {{$primNum(max_deposit_mc, 2)}}
              </span>
            </p>
            <InputLabelTextDefault
                    type="number"
                    v-model="form.amount_coin"
                    v-bind:errors="formValidate.amount_coin"
                    @input="calcSum('get')"
            >

              <template slot="right">
                <span class="usd">MC</span>
              </template>
            </InputLabelTextDefault>
          </div>
          <div class="right">
            <p class="min">{{__('Minimal Deposit: 50 MC')}}</p>
            <InputLabelTextDefault
                    type="amount_coin"
                    :value="$primNum(form.amount, 2)"
                    disabled
                    @input="calcSum( 'give')"
            >
              <template slot="right">
                <span class="usd">USD</span>
              </template>
            </InputLabelTextDefault>
          </div>
        </div>

        <ul class="deposit-text">
<!--          <li>-->
<!--            <p>{{__('Transfer exactly the same amount as indicated in your invoice. Otherwise, your deposit will not be credited')}}</p>-->
<!--          </li>-->
          <li>
            <p>{{__('Bitcoin deposits are available after 3 network confirmations.')}}</p>
          </li>
          <li>
            <p>{{__('Pay attention to the network in which you are transferring funds')}}</p>
          </li>
        </ul>
        <div class="btn-one">
          <v-btn
              v-on:click="onSubmit"
              :loading="submitDisabled"
              class="btn-site"
          >{{__('Next Step')}}</v-btn>
        </div>
      </div>
      <div class="right-content" v-if="info">
        <ul>
          <li>
            <p class="text">{{__('Total deposits')}}</p>
            <p class="text-r">${{$primNum (info.deposit_all, 2) | formatThousands}}</p>
          </li>
          <li>
            <p class="text">{{__('Invested in pharming')}}</p>
            <p class="text-r">${{$primNum (info.invest, 2) | formatThousands}}</p>
          </li>
        </ul>
      </div>
      <div v-else class="loading">
        {{__('loading')}}
      </div>
    </div>
</template>

<script>
import moment from "moment";
import DepositStep2 from "./DepositStep2";
import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
import {required, minValue,maxValue, decimal, numeric} from "vuelidate/lib/validators";
import DropdownDefault from "../../modules/DropdownDefault";
import _ from 'lodash';

export default {
  data () {
    return {
        submitDisabled: false,
        info: null,
        max_deposit_mc: 0,
        deposit_info: null,
        formValidate: {
            wallet: null,
            amount: null,
            amount_coin: null,
        },
        form: {
            wallet: this.payment.payment_id,
            amount: 0,
            amount_coin: 0,
        }
    }
  },
  watch: {
      payment(val) {
          if(val.payment_id== 7) {
              this.form.amount_coin= 50;
              this.calcSum('get');
          } else {
              this.form.amount= 50;
              this.calcSum('give');
          }
      },
  },
  props: {
      payment: {
          type: Object
      },
  },
  computed: {
      portfolio() {
          return this.$store.state.auth.portfolio;
      },
      payments() {
          return this.$store.state.auth.payments
      },
      filterPayments() {
          let payments= this.$cleanArray(this.payments);

          return _.filter(payments, {'active': 1,'replenish':1});
      },
  },
  created() {
      this.$http.get('ajax/deposit')
          .then(response=> {
              return response.json()
          })
          .then((response) => {
              this.$store.dispatch('asyncResp', response.resp_tickets);
              response= response.data;
              this.deposit_info= response.deposit;
              this.info= response.info;
              this.max_deposit_mc= response.max_deposit_mc;
          }).catch((err) => {
          this.httpHandler(err);
      });

  },
  validations() {
      return {
          form: {
              amount_coin: {
                  required,
                  minValue: (this.payment.payment_id== 7) ? minValue(50) : true,
                  maxValue: (this.payment.payment_id== 7) ? maxValue(this.$primNum(this.max_deposit_mc, 6)) : true,
                  decimal
              },
              amount: {
                  required,
                  decimal,
                  minValue: (this.payment.payment_id== 7) ? true : minValue(50),
                  // limit: maxValue((200/this.payment.course_usd)- ((this.portfolio.sum_invest) ? this.portfolio.sum_invest : 0))
                  maxValue: (this.payment.payment_id== 7) ? true : maxValue(this.payment.max_replenish*this.payment.course_usd)
              },
              wallet: {
                  required,
                  numeric
              },
          }
      }
  },
  methods: {
      calcSum(type= 'give') {
          let payment= this.payment;
          if(type== 'get') {
              let val= this.form.amount_coin;


              let calc_get= this.$primNum((val*payment.course_usd), 2);
              this.form.amount= calc_get;
          } else {
              let val= this.form.amount;
              let calc_give= this.$primNum(val/payment.course_usd, payment.sprintf);
              this.form.amount_coin= calc_give;
          }
          if(this.payment.payment_id== 7) {
              this.updateValidate('amount_coin');
          } else {
              this.updateValidate('amount');
          }
      },
      disableItem(item) {
          if (item.disabled === 1) {
              return true;
          } else {
              return false;
          }
      },
      updateDeposit(response) {
          this.$emit('updateDeposit', response)
      },
      moment: function () {
          return moment();
      },
      updateValidate: function(type) {
          this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
      },
      onSubmit() {
          let valid= this.$v.form;
          if(valid.$invalid) {
              let massages= {
                  'amount': [],
              };
              this.formValidate= this.$formValidate(massages, valid);
          } else {
              this.submitDisabled = true;

              let response = {
                  amount: this.$primNum(this.form.amount_coin, this.payment.sprintf),
                  amount_usd: this.$primNum(this.form.amount),
                  payment_id: this.form.wallet
              };

              this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/deposit/create', response)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      response= response.data;
                      let deposit= response.deposit;

                      this.submitDisabled= false;
                      if (response.url) {
                          location.href= response.url;
                      } else if(deposit.action) {
                          let form = document.createElement('form');
                          form.action= deposit.action;
                          form.method= deposit.method;
                          Object.keys(deposit.form).forEach(key => {
                              form.innerHTML += '<input type="hidden" name="'+key+'" value="'+deposit.form[key]+'">';
                          });
                          document.body.append(form);
                          form.submit();
                      } else {
                          this.deposit_info= deposit;
                      }
                      this.$emit('updateDeposit', response)
                  }).catch((err) => {
                      this.submitDisabled= false;
                      this.httpHandler(err);
                  });
          }
      }
  },
  components: {
      DropdownDefault,
      DepositStep2,
      InputLabelTextDefault
  }
}
</script>

<style scoped>

</style>
