<template>
    <div class="my-office">

        <MyOfficeBalance v-bind:info="info"></MyOfficeBalance>


        <ShowPromo v-if="info.show_promo == 4" promo="4" v-bind:info="info.promo4"></ShowPromo>
        <ShowPromo v-else-if="info.show_promo == 5" promo="5" v-bind:info="info.promo5"></ShowPromo>
        <MarchPromo v-else-if="info.show_promo == 6" promo="6" v-bind:info="info.promo6" @updateInfo="updateInfo"></MarchPromo>
        <WallesPromo v-else-if="info.show_promo == 7 && portfolio" promo="7" v-bind:portfolio="portfolio" v-bind:info="info.promo7" @updatePromo7="updatePromo7"></WallesPromo>

      <div class="telegram-2fa">
          <div class="office-2fa" v-if="!portfolio.google_2fa">
              <div>
                  <img src="/newfrontend/images/dash-img.svg" alt="">
                  <p class="semi">{{__('Connect two-factor authentication')}}
                      <span>{{__('We strongly recommend that you include two-factor authentication, otherwise you will not be able to withdraw your funds')}}</span>
                  </p>
              </div>
              <v-btn class="btn-white-new" :to="{name: 'settings_current', params: {current: 'security'}}">{{__('Enable 2fa')}}</v-btn>
          </div>
          <div class="office-2fa" v-if="!portfolio.google_2fa">
              <div>
                  <img src="/newfrontend/images/dash-img-1.svg" alt="">
                  <p class="semi">{{__('Install Telegram BOT')}}
                      <span>{{__('Allows you to receive automatic status messages ...')}}</span>
                  </p>
              </div>
              <v-btn class="btn-white-new" :to="{name: 'settings'}">{{__('Activate')}}</v-btn>
          </div>
      </div>

        <div class="body">
            <div class="left radius left-new">
                <div class="block-1">
                    <div class="img">
                        <span><img src="/newfrontend/images/b-1.svg" alt=""></span>
                    </div>
                    <div>
                        <p class="ttu">{{__('Deposit in the fund')}}</p>
                        <p class="left-dep">{{$primNum(portfolio.dep_fund) | formatThousands}} <span class="usdt">usdt</span></p>
                    </div>
                </div>
                <div class="block-1">
                    <div class="img">
                        <span><img src="/newfrontend/images/b-2.svg" alt=""></span>
                    </div>
                    <div>
                        <p class="ttu">{{__('Profit in the fund')}}</p>
                        <span class="usd">{{$primNum(portfolio.profit_fund) | formatThousands}} <span class="usdt-m">usdt</span></span>
                    </div>
                </div>

            </div>
<!--            <div class="left radius">-->
<!--                <div class="block-1">-->
<!--                    <div class="img">-->
<!--                        <span><img src="/newfrontend/images/b-1.svg" alt=""></span>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <p class="ttu">{{__('your deposit')}}</p>-->
<!--                        <p class="left-dep">{{$primNum(info.deposit) | formatThousands}} <span class="usdt">usdt</span></p>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="block-1">-->
<!--                    <div class="img">-->
<!--                        <span><img src="/newfrontend/images/b-2.svg" alt=""></span>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <p class="ttu">{{__('your profit')}}</p>-->
<!--                        <p class="left-ms">{{$primNum(info.profit_mc) | formatThousands}} <span>mc</span></p>-->
<!--                        <span class="usd">{{$primNum(info.all_profit_usd) | formatThousands}}  <span class="usdt-m">usdt</span></span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="block-1">-->
<!--                    <div class="img">-->
<!--                        <span><img src="/newfrontend/images/b-3.svg" alt=""></span>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <p class="ttu">{{__('profit after exchange ms')}}</p>-->
<!--                        <span class="left-ms">${{$primNum(info.profit_usd) | formatThousands}}</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="right price-soon radius">
                <h2>MediaCoin price</h2>
                 <div class="price-soon-text">
                     <p>Coming soon</p>
                 </div>
<!--                <div class="right-text ">-->
<!--                    <div>-->
<!--                        <h2>MediaCoin price</h2>-->
<!--                        <p class="semi ttu">{{__('1 MC equals')}}</p>-->
<!--                        <p class="stat-text">{{$primNum(portfolio.courseMC) | formatThousands}}<span>USD</span></p>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <p class="semi ttu">{{__('% growth from start')}}</p>-->
<!--                        <p class="percent">+{{$primNum(portfolio.mc_percent) | formatThousands}}<span>%</span></p>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="right-chart">-->
<!--                    <Chart :series="seriesMC" color="#2BB596" :nav="true" height="220"></Chart>-->
<!--                </div>-->
            </div>
        </div>
        <div class="body-office">
          <div class="left radius">
            <p class="d-flex justify-space-between">{{__('Total income')}} <span>${{$primNum(info.income) | formatThousands}}</span></p>
            <Chart :series="seriesIncome" color="#3A63DB" height="220"></Chart>
          </div>
          <div class="right radius">
            <p class="d-flex justify-space-between">{{__('Total withdraw')}} <span>${{$primNum(info.withdraw) | formatThousands}}</span></p>
            <Chart :series="seriesWithdraw" color="#FF0000" height="220"></Chart>
          </div>
        </div>
    </div>
</template>

<script>
    import MyOfficeBalance from "./../Dashboard/MyOfficeBalance";
    import Chart from "../../modules/Chart";
    import ShowPromo from "../../modules/ShowPromo";
    import MarchPromo from "../../modules/MarchPromo";
    import WallesPromo from "../../modules/WalletsPromo";
    export default {
      data: function() {
            return {
                seriesMC: [],
                seriesIncome: [],
                seriesWithdraw: [],
            }
        },
        props: {
            info: {
                type: Object
            }
        },
        created() {
            this.seriesMC.push({
                name: '',
                data: this.info.chart_mc
            });
            this.seriesIncome.push({
                name: '',
                data: this.info.total_income
            });
            this.seriesWithdraw.push({
                name: '',
                data: this.info.total_withdraw
            });
        },
        computed: {
            portfolio() {
                return this.$store.state.auth.portfolio
            },
        },
        methods: {
            updatePromo7(promo) {
                let info= this.$cleanObject(this.info);

                console.log('promo');
                console.log(promo);
                info.promo7= promo;
                this.$emit('update:info', info)
            },
            updateInfo(data) {
                let info= this.$cleanObject(this.info);

                info.promo6= data.promo6;
                info.show_promo= data.show_promo;

                console.log('info');
                console.log(info.promo6);
                this.$emit('update:info', info)
            }
        },
        components: {
            WallesPromo,
            MarchPromo,
            ShowPromo,
            Chart,
            MyOfficeBalance,
        },
    }
</script>

<style lang="scss">

.dashboard .v-progress-linear {
  overflow: unset;
  cursor: pointer;
}

.dashboard .turnoverTooltip {
    /*background: #fff;*/
    position: absolute;
    padding: 6px 8px;
    bottom: calc(100% + 6px);
    transform: translateX(-50%);
    display: none;
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.23);
    backdrop-filter: blur(24px);
    border-radius: 5px;
}

.v-progress-linear:hover .turnoverTooltip {
  display: block;
}
</style>
