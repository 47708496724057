<template>
    <div class="dialog" ref="messages_list"  style="position: unset">
      <div>
        <div class="dialog-content" v-if="messages.total > 0" @scroll="scrollMessages(this)">
          <MessengerMessagesList
              v-bind:messages="messages"
              v-bind:context_id="context_id"
              v-bind:user_id="user_id"
          ></MessengerMessagesList>
        </div>
        <div v-else class="no-result no-result-support">
          {{__("Hello")}} {{portfolio.name}}, {{("Contact me please, I will solve any problem and answer all your questions.")}}
        </div>
      </div>
      <div ref="btn_list" class="send-block">
        <InputLabelTextDefault
                class="input-send"
                ref="message"
                type="textarea"
                v-model="new_message"
                v-on:keydown.enter.prevent.exact="keypressMessages"
                v-on:keydown.shift.enter.prevent.exact="newLine"
                placeholder="Type a message here"
                maxlength="1000"
                :disabled="((ticket.status) ? true : false)"
        >
          <template v-slot:right class="send-btn">
            <v-btn
                class="btn-site"
                :disabled="((ticket.status) ? true : false)"
                v-on:click="sendMessage()"
                :loading="submitDisabled"
            >
              <img src="/newfrontend/images/send.svg" alt="">
            </v-btn>
          </template>
        </InputLabelTextDefault>
      </div>
    </div>

</template>

<script>
    // import DialogWithdraw from "../modules/DialogWithdraw";
    // import DialogMyTeam from "./DialogMyTeam";
    import MessengerMessagesList from "./MessengerMessagesList";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {required, maxLength} from "vuelidate/lib/validators";

    export default {
        name: "Dialog",
        data: function() {
            return {
                openEmoji: false,
                submitDisabled: false,
                loadingPage: false,
                new_message: null,
                context_id: null,
                command: null,
                active_message: null,
                chatHeight: 75
            }
        },
        props: {
            messages: {
                type: Object
            },
            ticket: {
                type: Object
            },
            messages_id: {
                type: Array
            },
            user_id: {
                type: [String, Number]
            }
        },
        validations: {
            new_message: {
                required,
                maxLength: maxLength(1000)
            },
        },
        mounted() {
            this.chat();
        },
        computed: {
            portfolio() {
                return this.$store.state.auth.portfolio
            },
            message() {
                return this.$store.getters.message;
            },
            payments() {
                return this.$store.state.auth.payments
            },
        },
        watch: {
            messages(messages, old) {
              if(messages.total > old.total) {
                  this.chat();
              }
            },
            active_message() {
                this.$nextTick(() => {
                    // let width= (window.screen.width < 991) ? 60 : 0;

                    let height= this.$refs.btn_list.scrollHeight;
                    if(height) {
                        this.chatHeight= height;
                    } else {
                        this.chatHeight= 75;
                    }


                    this.scrollBottom();
                });
            }
        },
        methods: {
            chat() {
                let chat= false;

                if(this.user_id== 'bot') {
                    let messages= this.$cleanObject(this.messages);
                    if(messages) {
                        messages = messages.data.reverse();
                        for (let i = 0; i < messages.length; i++) {
                            let message = messages[i];
                            if (message.opp_type == 1 && message.command && message.command != 'validate' || message.opp_type == 1 && message.message== 'new_user' ) {
                                let message_parse;
                                try {
                                    message_parse= JSON.parse(message.message);
                                } catch (e) {
                                    message_parse= message.message;
                                }
                                message.message= message_parse;
                                chat= message;
                                break;
                            }
                        }
                    }
                }
                this.active_message= chat;
                this.scrollBottom();
            },
            keypressMessages() {
                this.sendMessage(this.edit_message);
            },
            newLine(e) {
                let caret = e.target.selectionStart;
                e.target.setRangeText("\n", caret, caret, "end");
                this.new_message = e.target.value;
            },
            scrollBottom() {
                this.$nextTick(() => {
                    if(this.$refs.messages_list) {
                        setTimeout(()=>{
                            this.$refs.messages_list.scrollTo(0, this.$refs.messages_list.scrollHeight+10000);
                        }, 50);
                    }
                });
            },
            sendMessage() {
                if(!this.submitDisabled) {
                    let valid = this.$v;
                    if (valid.$invalid) {
                        let massages = {
                            new_message: [],
                        };
                        this.formValidate = this.$formValidate(massages, valid);
                    } else if(!this.ticket.status) {
                        let new_message= this.new_message;

                        this.submitDisabled = true;
                        let response = {
                            message: new_message,
                            user_id: this.user_id
                        };

                        this.$http.post("ajax/chat/messages/send", response)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                this.$store.dispatch('asyncResp', response.resp_tickets);
                                this.submitDisabled= false;
                                this.new_message= null;
                                response= response.data;
                                this.$store.dispatch("asyncMessage", response);
                            })
                            .catch((err) => {
                                this.submitDisabled = false;
                                this.httpHandler(err);
                            });
                    }
                }
            },
            scrollMessages() {
                let messages_list= this.$refs.messages_list;
                const progress= messages_list.scrollTop / (messages_list.scrollHeight - messages_list.clientHeight);
                if(!this.loadingPage && this.messages.next_page_url) {
                    if (0.5 >= progress) {
                        this.loadingPage= true;
                        let response= {
                            user_id: this.user_id,
                            messages_id: this.messages_id,
                        };
                        this.$http.post("ajax/chat/messages/open", response)
                            .then(response => {
                                return response.json()
                            })
                            .then((response) => {
                                this.$store.dispatch('asyncResp', response.resp_tickets);
                                let messages= this.$cleanObject(this.messages);
                                response= response.data.messages;
                                response.data= response.data.reverse().concat(messages.data);

                                this.$emit('update:messages', response);


                                let messages_id= this.$cleanObject(this.messages_id);
                                for(let i=0; i < response.data.length; i++) {
                                    let message= response.data[i];
                                    messages_id.push(message.id);
                                }

                                this.$emit('update:messages_id', messages_id);
                                this.loadingPage= false;
                            })
                            .catch((err) => {
                                this.loadingPage= false;
                                this.httpHandler(err);
                            });
                    }
                }
            },
        },
        components: {
            InputLabelTextDefault,
            MessengerMessagesList
            // DialogMyTeam,
            // DialogWithdraw
        }
    }

</script>
