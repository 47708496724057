<template>
  <div class="wow-list" v-if="promo">
      <div class="content">
        <div class="top">
            <router-link :to="{name: 'dashboard'}">
                <img src="/newfrontend/images/wow-left.svg" alt="">
                <span>{{__('back to dashboard')}}</span>
            </router-link>
<!--            <p class="center">{{__('completed a promo')}}</p>-->
            <p class="text">{{__('You received:')}}
              <span v-if="promo.turnover < 20">—</span>
              <span v-else-if="promo.turnover < 50">20</span>
              <span v-else-if="promo.turnover < 100">50</span>
              <span v-else-if="promo.turnover < 250">100</span>
              <span v-else-if="promo.turnover < 500">250</span>
              <span v-else-if="promo.turnover < 1000">500</span>
              <span v-else>1000</span>
            </p>
        </div>
        <ul>
            <li class="top-li">
              <div class="id">
                <p>##</p>
              </div>
              <div class="lvl">
                <p>20</p>
              </div>
              <div class="lvl">
                <p>50</p>
              </div>
              <div class="lvl">
                <p>100</p>
              </div>
              <div class="lvl">
                <p>250</p>
              </div>
              <div class="lvl">
                <p>500</p>
              </div>
                <div class="lvl">
                <p>1000</p>
              </div>

            </li>
            <li class="body-li" v-for="(num, key) in maxPartners" :key="key">
              <div class="id">
                <p>{{num}}</p>
              </div>
              <div class="lvl">
                <p v-if="promo.complete.promo_one[key]" :class="(portfolio.user_id== promo.complete.promo_one[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_one[key].user_public_id}}</p>
                <p v-else>-</p>
              </div>
                <div class="lvl">
                    <p v-if="promo.complete.promo_two[key]" :class="(portfolio.user_id== promo.complete.promo_two[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_two[key].user_public_id}}</p>
                    <p v-else>-</p>
                </div>
                <div class="lvl">
                    <p v-if="promo.complete.promo_three[key]" :class="(portfolio.user_id== promo.complete.promo_three[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_three[key].user_public_id}}</p>
                    <p v-else>-</p>
                </div>
                <div class="lvl">
                    <p v-if="promo.complete.promo_four[key]" :class="(portfolio.user_id== promo.complete.promo_four[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_four[key].user_public_id}}</p>
                    <p v-else>-</p>
                </div>
                <div class="lvl">
                    <p v-if="promo.complete.promo_five[key]" :class="(portfolio.user_id== promo.complete.promo_five[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_five[key].user_public_id}}</p>
                    <p v-else>-</p>
                </div>
                <div class="lvl">
                    <p v-if="promo.complete.promo_six[key]" :class="(portfolio.user_id== promo.complete.promo_six[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_six[key].user_public_id}}</p>
                    <p v-else>-</p>
                </div>
            </li>
        </ul>
      </div>
  </div>
  <div v-else class="loading">
      <loaderMc></loaderMc>
    {{__('loading')}}
  </div>
</template>

<script>
    import _ from 'lodash';
    import loaderMc from "../SvgComponents/loaderMc";
    export default {
        name: 'wow_promo_list',
        data () {
            return {
                promo: null
            }
        },
        computed: {
            maxPartners() {
                let complete= this.promo.complete;
                let partners= [Object.keys(complete.promo_one).length, Object.keys(complete.promo_two).length, Object.keys(complete.promo_three).length, Object.keys(complete.promo_four).length, Object.keys(complete.promo_five).length, Object.keys(complete.promo_six).length];
                return _.max(partners);
            },
            portfolio() {
                return this.$store.state.auth.portfolio
            },
        },
        created() {
            this.$http.get('ajax/dashboard')
                .then(response => {
                    return response.json()
                })
                .then((response) => {
                    this.$store.dispatch('asyncResp', response.resp_tickets);
                    console.log('response.data');
                    console.log(response.data.promo);
                    this.promo= response.data.promo;
                }).catch((err) => {
                this.httpHandler(err);
            });
        },
        asyncComputed: {

        },
        methods: {
        },
        components: {
            loaderMc,
        },

    }
</script>
