<template>
  <div class="academy-lesson" v-if="page">
      <div class="top-block">
          <router-link :to="{name: 'academy_page', params: {url: lesson}}" class="back">
            <img src="/newfrontend/images/arrow-back.svg" alt="">
              <p>{{__('Back')}}</p>
          </router-link>
      </div>
      <div class="content">
          <div class="left-block">
              <iframe :src="'https://www.youtube.com/embed/'+page.lesson.video_url+'?rel=0&showinfo=0&autoplay=1'" ref="video" width="100%" height="340" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <h1>{{page.lesson.title}}</h1>
              <div class="download" v-if="page.lesson.file">
                  <div class="block-name">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.3328 0.666629C13.3062 0.666396 14.2585 0.950281 15.0729 1.48346C15.8872 2.01664 16.5282 2.77592 16.9173 3.66816C17.3063 4.56041 17.4265 5.54681 17.263 6.50636C17.0996 7.4659 16.6596 8.35687 15.997 9.06996L15.8387 9.23163L8.56618 16.5041L8.52202 16.5458L8.47535 16.5833C7.88292 17.094 7.11936 17.362 6.3377 17.3335C5.55604 17.305 4.81402 16.9821 4.26036 16.4296C3.70669 15.8771 3.38229 15.1358 3.35215 14.3542C3.32201 13.5726 3.58837 12.8085 4.09785 12.215L4.22202 12.0783L4.23452 12.0691L10.3095 5.98246C10.4657 5.82598 10.6776 5.73794 10.8986 5.73771C11.1197 5.73748 11.3318 5.82507 11.4883 5.98121C11.6447 6.13736 11.7328 6.34927 11.733 6.57033C11.7333 6.79139 11.6457 7.00348 11.4895 7.15996L5.41452 13.2466L5.40452 13.2533C5.15932 13.5151 5.02115 13.8594 5.01727 14.2181C5.01339 14.5768 5.14407 14.9239 5.38354 15.191C5.62301 15.4581 5.95387 15.6257 6.31087 15.6609C6.66787 15.696 7.02507 15.5961 7.31202 15.3808L7.41368 15.2975L7.41452 15.2983L14.6662 8.04829L14.7987 7.91079C15.3821 7.26951 15.6927 6.42615 15.6647 5.55963C15.6366 4.69312 15.272 3.87164 14.6483 3.26948C14.0245 2.66732 13.1907 2.33187 12.3237 2.33429C11.4568 2.33672 10.6249 2.67683 10.0045 3.28246L9.87535 3.4158L9.86035 3.4258L2.08868 11.2008C2.01126 11.2782 1.91935 11.3395 1.81821 11.3814C1.71707 11.4232 1.60868 11.4448 1.49922 11.4447C1.38977 11.4447 1.28139 11.4231 1.18028 11.3812C1.07917 11.3392 0.987305 11.2778 0.909934 11.2004C0.832564 11.123 0.771201 11.031 0.729349 10.9299C0.687498 10.8288 0.665977 10.7204 0.666016 10.6109C0.666094 10.3899 0.753984 10.1779 0.910351 10.0216L8.66785 2.2608L8.70618 2.22496C9.17294 1.73201 9.73542 1.33954 10.3592 1.07158C10.9829 0.803623 11.6548 0.665827 12.3337 0.666629H12.3328Z" />
                      </svg>
                      <p class="name">{{page.lesson.name_file}}</p>
                  </div>
                  <div class="btn-right">
                      <a :href="page.lesson.file" download>
                          <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 10C2 9.44771 1.55228 9 1 9C0.447715 9 0 9.44771 0 10V14C0 14.5523 0.447715 15 1 15H17C17.5523 15 18 14.5523 18 14V10C18 9.44771 17.5523 9 17 9C16.4477 9 16 9.44771 16 10V13H2V10Z" />
                              <path d="M9.70711 10.7071C9.31658 11.0976 8.68342 11.0976 8.29289 10.7071L4.29289 6.70711C3.90237 6.31658 3.90237 5.68342 4.29289 5.29289C4.68342 4.90237 5.31658 4.90237 5.70711 5.29289L8 7.58579V1C8 0.447715 8.44772 0 9 0C9.55228 0 10 0.447715 10 1V7.58579L12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711L9.70711 10.7071Z"/>
                          </svg>
                          <span>{{__('Download')}}</span>
                      </a>
                  </div>
              </div>
              <div class="left-text" v-html="page.lesson.description"></div>
          </div>

          <ul class="right-block">
              <router-link tag="li" v-for="(item, key) in page.lessons" :key="key" :to="{name: 'academy_lesson', params: {category: lesson, url: item.url}}">
                <p class="lesson">
                      <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.28494L10 4.28472V5.7152L12 6.71498V3.28494ZM11.5883 1.25476C11.8424 1.12782 12.1246 1.06789 12.4083 1.08067C12.692 1.09345 12.9677 1.1785 13.2093 1.32777C13.4509 1.47703 13.6504 1.68555 13.7888 1.93354C13.9271 2.18154 13.9998 2.46078 14 2.74476V7.25462C13.9998 7.53861 13.9271 7.81838 13.7888 8.06637C13.6504 8.31436 13.4509 8.52289 13.2093 8.67215C12.9677 8.82141 12.692 8.90647 12.4083 8.91925C12.1246 8.93202 11.8424 8.8721 11.5883 8.74516L8.55287 7.22776C8.21404 7.05838 8 6.71209 8 6.33329V3.66662C8 3.28782 8.21404 2.94153 8.55286 2.77216L11.5883 1.25476Z"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.33333 2C2.14924 2 2 2.14924 2 2.33333V7.66667C2 7.85076 2.14924 8 2.33333 8H7.66667C7.85076 8 8 7.85076 8 7.66667V2.33333C8 2.14924 7.85076 2 7.66667 2H2.33333ZM0 2.33333C0 1.04467 1.04467 0 2.33333 0H7.66667C8.95533 0 10 1.04467 10 2.33333V7.66667C10 8.95533 8.95533 10 7.66667 10H2.33333C1.04467 10 0 8.95533 0 7.66667V2.33333Z" />
                      </svg>
                      <span>{{__('Lesson')}} {{item.lessons}}</span>
                  </p>
                  <a class="name">{{item.title}}</a>
              </router-link>
          </ul>
      </div>

    </div>
    <div v-else class="loading">
        <loaderMc></loaderMc>
        {{__('Loading')}}
    </div>
</template>

<script>
    import loaderMc from "../SvgComponents/loaderMc";

// import InputLabelTextDefault from "../modules/InputLabelTextDefault";
export default {
  name: 'AcademyLesson',
    data: function() {
        return {
            page: null,
            lesson: this.$route.params.category,
            url: this.$route.params.url
        }
  },
  watch: {

  },
  methods:{
      getCreate() {
          this.$http[(process.env.NODE_ENV === 'production') ? 'get' : 'get']('ajax/academy/courses/'+this.lesson+'/'+this.url)
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.page = response.data;
                  // }
              }).catch((err) => {
              this.httpHandler(err);
          });
      }
  },
  beforeRouteUpdate (to, from, next) {
      // обрабатываем изменение параметров маршрута...
      // не забываем вызвать next()
      this.lesson= to.params.category;
      this.url= to.params.url;

      this.getCreate();
      return next();
  },
  created() {
      this.getCreate();
  },
  computed: {
      lang() {
          return this.$store.state.language.lang
      }
  },
  mounted() {

  },
  components: {
      loaderMc
      // InputLabelTextDefault
  }
}
</script>

<style scoped>

</style>
