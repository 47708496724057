<template>
  <v-app id="app" >
      <router-view></router-view>
      <notifications group="message" class="notise" position="bottom right" />
  </v-app>
</template>

<script>
// import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
export default {
  name: 'App',
  data: function() {
      return {
      }
  },
  watch: {

  },
    methods:{
        // handleSCroll () {
        //     let header = document.querySelector(".home-menu");
        //     if (window.scrollY > 80) {
        //         console.log(window.scrollY);
        //         header.classList.add("active");
        //     }
        //     else {
        //         console.log(window.scrollY);
        //         header.classList.remove("active");
        //     }
        // }
    },
  computed: {

  },
  created () {
      // window.addEventListener('scroll', this.handleSCroll);
      this.$http.get('ajax/languages')
          .then(response => {
              return response.json()
          })
          .then((response) => {
              this.$store.dispatch('asyncResp', response.resp_tickets);
              this.$store.dispatch('asyncLanguages', response);
          }).catch((err) => {
          this.httpHandler(err);
      });
      if (window.userAuth) {
          this.$store.dispatch('asyncUpdateTime', Date.now());
          this.$http.get('ajax/user_info')
          .then(response => {
              return response.json()
          })
          .then((response) => {
              this.$store.dispatch('asyncResp', response.resp_tickets);
              let portfolio = response.data;
              // if(portfolio.lang) {
              //     this.$store.dispatch("asyncLanguage", portfolio.lang);
              // }
              this.$store.dispatch('asyncPortfolio', portfolio);
              this.$store.dispatch('asyncPayments', portfolio.payment);
          }).catch((err) => {
              this.httpHandler(err);
          });
      }

      this.$router.beforeEach((to, from, next) => {
          this.$removeAllListeners(window,'scroll');

          return next();
      });
  },
    destroyed () {
        // window.removeEventListener('scroll', this.handleSCroll);

    },
  components: {

  },
}
</script>

<style>

</style>

