<template>
  <div class="products-page">
      <div class="content">
         <div class="block block-1">
             <div class="left">
                 <h2>{{__('Product marketplace')}}</h2>
                 <div class="top-left">
                    <p>{{__('Purchase of any products of media personalities with discounts from market')}}</p>
                     <div>
                         <span>from</span>
                         <img src="/newfrontend/images/percent-10.png" alt="">
                     </div>
                     <div>
                         <span>to</span>
                         <img src="/newfrontend/images/percent-50.png" alt="">
                     </div>
                 </div>
                 <div class="body">
                     <a href="https://market.mediachain.inc/" target="_blank"><img src="/newfrontend/images/img-link.svg" alt=""></a>
                 </div>

             </div>
             <div class="right">
                 <img src="/newfrontend/images/product-1.png" alt="">
             </div>
             <div class="block-btn">
                 <div class="block-left">
                     <DropdownDefault
                             v-model="lang1"
                             v-bind:items="languages1"
                             value_key="lang"
                             text="full_lang"
                             dropSlot
                             disabled="disabled"
                     >
                         <template v-slot:selection="item">
                             <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                             {{__(item.full_lang)}}
                         </template>
                         <template v-slot:item="item">
                             <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                             {{__(item.full_lang)}}
                         </template>
                     </DropdownDefault>
                     <div>
                         <a disabled="disabled"  class="btn-white-new">download</a>
                         <a disabled="disabled"  class="btn-green-new">open</a>
                     </div>
                 </div>
                 <div class="block-right">
                     <a disabled="disabled" class="btn-white-new">INSTRUCTION</a>
                 </div>
             </div>
         </div>
          <div class="block block-2">
              <div class="left">
                  <h2>{{__('Create and sell')}} <span>{{__('your NFT-tokens')}}</span></h2>
                  <p>{{__('Buy and sell NFTs from the worlds top artists. More than 1.000 premium digital artworks are available to be yours!')}}</p>
                  <div class="body">
                      <a href="https://nft.mediachain.inc/" target="_blank"><img src="/newfrontend/images/img-link.svg" alt=""></a>
                  </div>
              </div>
              <div class="right">
                  <img src="/newfrontend/images/product-2.png" alt="">
              </div>
              <div class="block-btn">
                  <div class="block-left">
                      <DropdownDefault
                              v-model="lang2"
                              v-bind:items="languages2"
                              value_key="lang"
                              text="full_lang"
                              dropSlot
                              disabled="disabled"
                      >
                          <template v-slot:selection="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                          <template v-slot:item="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                      </DropdownDefault>
                      <div>
                          <a disabled="disabled"  class="btn-white-new">download</a>
                          <a disabled="disabled"  class="btn-green-new">open</a>
                      </div>
                  </div>
                  <div class="block-right">
                      <a disabled="disabled" class="btn-white-new">INSTRUCTION</a>
                  </div>
              </div>
          </div>
          <div class="block block-3">
              <div class="left">
                  <img src="/newfrontend/images/logo-wallet.svg" alt="">
                 <h2>MediaChain Wallet <span>and Bank</span></h2>
                 <p>{{__('Crypto wallet and bank in one system')}}</p>
                  <div class="body-btn">
                      <a href="https://cryptowallet.llc/" target="_blank" class="btn-new-w"><span>Multiwallet</span></a>
                  </div>
              </div>
              <div class="right">
                  <img src="/newfrontend/images/product-3.png" alt="">
              </div>
              <div class="block-btn">
                  <div class="block-left">
                      <DropdownDefault
                              v-model="lang3"
                              v-bind:items="languages3"
                              value_key="lang"
                              text="full_lang"
                              dropSlot
                              disabled="disabled"
                      >
                          <template v-slot:selection="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                          <template v-slot:item="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                      </DropdownDefault>
                      <div>
                          <a disabled="disabled"  class="btn-white-new">download</a>
                          <a disabled="disabled"  class="btn-green-new">open</a>
                      </div>
                  </div>
                  <div class="block-right">
                      <a disabled="disabled" class="btn-white-new">INSTRUCTION</a>
                  </div>
              </div>
          </div>
          <div class="block block-4">
              <div class="left">
                  <h2>MediaChain
                      <span>Crypto School</span>
                  </h2>
                  <div class="body-btn">
                      <router-link :to="{name: 'school'}" class="btn-new"><span>{{__('Go to Crypto School')}}</span></router-link>
                  </div>
              </div>
              <div class="right">
                  <img src="/newfrontend/images/product-4.png" alt="">
              </div>
              <div class="block-btn">
                  <div class="block-left">
                      <DropdownDefault
                              v-model="lang4"
                              v-bind:items="languages4"
                              value_key="lang"
                              text="full_lang"
                              dropSlot
                              disabled="disabled"
                      >
                          <template v-slot:selection="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                          <template v-slot:item="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                      </DropdownDefault>
                      <div>
                          <a disabled="disabled"  class="btn-white-new">download</a>
                          <a disabled="disabled"  class="btn-green-new">open</a>
                      </div>
                  </div>
                  <div class="block-right">
                      <a disabled="disabled" class="btn-white-new">INSTRUCTION</a>
                  </div>
              </div>
          </div>

          <div class="block block-5">
              <div class="left">
                  <img src="/newfrontend/images/logo-sys.svg" alt="">
                  <h2>Easy and Fast <span>Exchange</span></h2>
                  <p>Fast, automatic and no hidden fees. The easiest way to sell or buy cryptocurrency online.</p>
                  <div class="body-btn">
                      <a href="https://mediasystem.ai" target="_blank" class="btn-new-w"><span>go to MediaSystem</span></a>
                  </div>
              </div>
              <div class="right">
                  <img src="/newfrontend/images/right-sys.png" alt="">
              </div>
              <div class="block-btn">
                  <div class="block-left">
                      <DropdownDefault
                              v-model="lang5"
                              v-bind:items="languages5"
                              value_key="lang"
                              text="full_lang"
                              dropSlot
                              disabled="disabled"
                      >
                          <template v-slot:selection="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                          <template v-slot:item="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                      </DropdownDefault>
                      <div>
                          <a disabled="disabled"  class="btn-white-new">download</a>
                          <a disabled="disabled"  class="btn-green-new">open</a>
                      </div>
                  </div>
                  <div class="block-right">
                      <a disabled="disabled" class="btn-white-new">INSTRUCTION</a>
                  </div>
              </div>
          </div>

          <div class="block block-6">
              <div class="left">
<!--                  <img src="/newfrontend/images/product-6.png" alt="">-->
<!--                  <h2>Stiko Family</h2>-->
<!--                  <p>Let your blockchain adventure begin!</p>-->
                  <div class="body-btn">
                      <a href="https://game.stikofamily.com/" target="_blank" class="btn-new"><span>go to stiko Family</span></a>
                  </div>
              </div>
              <div class="right">
<!--                  <img src="/newfrontend/images/product-6-right.png" alt="">-->
              </div><div class="block-btn">
              <div class="block-left">
                  <DropdownDefault
                          v-model="lang6"
                          v-bind:items="languages6"
                          value_key="lang"
                          text="full_lang"
                          dropSlot
                          disabled="disabled"
                  >
                      <template v-slot:selection="item">
                          <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                          {{__(item.full_lang)}}
                      </template>
                      <template v-slot:item="item">
                          <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                          {{__(item.full_lang)}}
                      </template>
                  </DropdownDefault>
                  <div>
                      <a disabled="disabled"  class="btn-white-new">download</a>
                      <a disabled="disabled"  class="btn-green-new">open</a>
                  </div>
              </div>
              <div class="block-right">
                  <a disabled="disabled" class="btn-white-new">INSTRUCTION</a>
              </div>
          </div>
          </div>

          <div class="block block-7">
              <div class="left">
                  <img src="/newfrontend/images/logo-promo-7.png" alt="">
                  <h2>MediaChain <span>Mediaverse</span> </h2>
                  <p class="text">Log in only through google chrome with metamask installed</p>
                  <p>Create a virtual version of yourself!</p>
                  <div class="body-btn">
                      <a href="https://mediaversedev.arkvar.com/" target="_blank" class="btn-new"><span>connect to mediaverse</span></a>
                  </div>
              </div>
              <div class="right">
              </div>
              <div class="block-btn">
                  <div class="block-left">
                      <DropdownDefault
                              v-model="lang7"
                              v-bind:items="languages7"
                              value_key="lang"
                              text="full_lang"
                              dropSlot
                              disabled="disabled"
                      >
                          <template v-slot:selection="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                          <template v-slot:item="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                      </DropdownDefault>
                      <div>
                          <a disabled="disabled"  class="btn-white-new">download</a>
                          <a disabled="disabled"  class="btn-green-new">open</a>
                      </div>
                  </div>
                  <div class="block-right">
                      <a disabled="disabled" class="btn-white-new">INSTRUCTION</a>
                  </div>
              </div>
          </div>
          <div class="block block-8">
              <div class="left">
                  <div class="body-btn">
                      <router-link :to="{name: 'academy'}" class="btn-new">
                          <span>go to leadership academy</span>
                      </router-link>
<!--                      <a href="https://ipactcoach.com/leadership_academy_mediacoin" target="_blank" class="btn-new"><span>go to leadership academy</span></a>-->
                  </div>
              </div>
              <div class="right">
              </div>
              <div class="block-btn">
                  <div class="block-left">
                      <DropdownDefault
                              v-model="lang8"
                              v-bind:items="languages8"
                              value_key="lang"
                              text="full_lang"
                              dropSlot
                              disabled="disabled"
                      >
                          <template v-slot:selection="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                          <template v-slot:item="item">
                              <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
                              {{__(item.full_lang)}}
                          </template>
                      </DropdownDefault>
                      <div>
                          <a disabled="disabled"  class="btn-white-new">download</a>
                          <a disabled="disabled"  class="btn-green-new">open</a>
                      </div>
                  </div>
                  <div class="block-right">
                      <a disabled="disabled" class="btn-white-new">INSTRUCTION</a>
                  </div>
              </div>
          </div>


<!--        <div class="block block-market">-->
<!--            <div class="left">-->
<!--                <img src="/newfrontend/images/logo-m.svg"/>-->
<!--                <p class="text">-->
<!--&lt;!&ndash;                    Purchase of any products of media personalities with discounts from 10 to 50%&ndash;&gt;-->
<!--                    {{__('product text market')}}-->
<!--                </p>-->
<!--                <a class="btn-site" href="https://market.mediacoin.inc/" target="_blank">-->
<!--                    {{__('go to mediacoin marketplace')}}-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="right">-->
<!--                <img src="/newfrontend/images/icon-prod-n.png" alt="">-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="block block-nft">-->
<!--            <div class="left">-->
<!--                <img src="/newfrontend/images/logo-n.svg" alt="">-->
<!--                <p class="text">-->
<!--&lt;!&ndash;                    Create and sell your NFT-tokens&ndash;&gt;-->
<!--                    {{__('product text nft')}}-->
<!--                </p>-->
<!--                <a class="btn-site" href="https://nft.mediacoin.inc/" target="_blank">-->
<!--                    {{__('go to mediacoin nft')}}-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="right">-->
<!--                <img src="/newfrontend/images/icon-prod-m.png" alt="">-->
<!--            </div>-->
<!--        </div>-->
      </div>
  </div>
</template>

<script>
    import DropdownDefault from "../modules/DropdownDefault";

    export default {
        name: 'products',
        data () {
            return {
                lang1: null,
                languages1: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang2: null,
                languages2: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang3: null,
                languages3: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang4: null,
                languages4: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang5: null,
                languages5: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang6: null,
                languages6: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang7: null,
                languages7: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang8: null,
                languages8: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang9: null,

                languages9: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang10: null,
                languages10: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang11: null,
                languages11: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang12: null,
                languages12: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang13: null,
                languages13: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
                lang14: null,
                languages14: [
                    {
                        "id": 1,
                        "lang": "en",
                        "full_lang": "English",
                        "active": 1
                    },
                    {
                        "id": 2,
                        "lang": "ru",
                        "full_lang": "Russian",
                        "active": 1
                    },
                    {
                        "id": 3,
                        "lang": "hi",
                        "full_lang": "हिन्दी",
                        "active": 1
                    },
                    {
                        "id": 4,
                        "lang": "es",
                        "full_lang": "Español",
                        "active": 1
                    },
                    {
                        "id": 5,
                        "lang": "it",
                        "full_lang": "Italiano",
                        "active": 1
                    },
                    {
                        "id": 6,
                        "lang": "uz",
                        "full_lang": "Оʻzbek",
                        "active": 0
                    },
                    {
                        "id": 7,
                        "lang": "kk",
                        "full_lang": "қазақ",
                        "active": 0
                    }, {
                        "id": 8,
                        "lang": "tr",
                        "full_lang": "Türkçe",
                        "active": 0
                    }, {
                        "id": 9,
                        "lang": "pt",
                        "full_lang": "Português",
                        "active": 1
                    }, {
                        "id": 10,
                        "lang": "ar",
                        "full_lang": "Arab",
                        "active": 1
                    }, {
                        "id": 11,
                        "lang": "ro",
                        "full_lang": "Romanian",
                        "active": 1
                    }, {
                        "id": 15,
                        "lang": "zh",
                        "full_lang": "中文",
                        "active": 1
                    }
                ],
            }
        },
        computed: {
            language() {
                return this.$store.state.language.lang
            },
        },
        asyncComputed:{
            asyncPortfolio() {
                let portfolio= this.portfolio;

                if(portfolio && !this.lang) {
                    this.lang= portfolio.lang;
                }
                return true;
            }
        },
        created() {

        },

        methods: {
        },
        components: {
            DropdownDefault
        },

    }
</script>
