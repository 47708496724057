<template>
  <ModalDefault v-if="dialog" v-bind:close="false" width="470">
    <div class="window-site withdraw-window">
      <div class="top">
        <h1>{{__('Withdraw confirmation')}}</h1>
        <p>{{__('Check the data correctness and confirm the application application')}}</p>
      </div>
      <Payment :payment_id="withdraw.payment_id" class="window-body">
        <template v-slot="payment">
          <div class="list">
            <div>
              <p class="semi">{{__('Amount')}}</p>
              <p>${{$primNum(withdraw.amount) | formatThousands}}</p>
            </div>
          </div>
          <div class="list mt-4">
            <div>
              <p class="semi">{{__('Commission:')}}</p>
              <p class="text">{{$primNum(commission_coin, payment.sprintf) | formatThousands}} {{payment.currency.toUpperCase()}}</p>
            </div>
            <div>
              <p class="semi">{{__('Charity:')}}</p>
              <p class="text">{{$primNum(comission_free, payment.sprintf) | formatThousands}} {{payment.currency.toUpperCase()}}</p>
            </div>
            <div>
              <p class="semi">{{__('Sum:')}}</p>
              <p>{{$primNum(withdraw.amount_coin-(commission_coin+comission_free), payment.sprintf) | formatThousands}} {{payment.currency.toUpperCase()}}</p>
            </div>
          </div>
          <div class="address">
            <div>
              <p class="semi">{{__('Address:')}}</p>
              <p class="text">{{addresses.address}}</p>
            </div>
            <div v-if="addresses.tag">
              <p class="semi">{{__('Tag')}}</p>
              <p class="text">{{addresses.tag}}</p>
            </div>
          </div>
          <div class="withdraw-btn">
            <v-btn class="btn-white" v-on:click="modalCancel">{{__('Cancel')}}</v-btn>
            <v-btn class="btn-site" v-on:click="onSubmit" :loading="submitDisabled">{{__('Confirm the output')}}</v-btn>
          </div>
        </template>
      </Payment>
    </div>
  </ModalDefault>
</template>

<script>
  import ModalDefault from '../../modules/ModalDefault';
  import Payment from "../../modules/Payment";

  export default {
    data () {
      return {
        dialog: true,
        submitDisabled: false
      }
    },
    props: {
        withdraw: {
            type: Object
        },
        addresses: {
            type: Object
        },
        commission_coin: {
            type: Number
        },
        comission_free: {
            type: Number
        }
    },
    methods:{
        modalCancel() {
            this.$emit('update:withdraw', null);
        },
        onSubmit() {
            this.$emit('updateDeposit');
        }
    },
    created: function () {

    },
    watch: {

    },
    computed: {

    },
    components: {
      Payment,
      ModalDefault
    }
  }
</script>

<style scoped>

</style>
