<template>
    <div class="contact-page">
        <div class="container">
            <div class="container-pe-quote left-quote">
                <div class="pp-quote li-quote-1 " >
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-2" >
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-3" >
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-4" >
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-5" >
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-6" >
                    <div class="img animated bounce"></div>
                </div>
            </div>
            <div class="content">
                <div class="support-text">
                    <div class="left">
                        <p class="text-r">{{__('keep in touch')}}</p>
                        <h1>{{__('Contact Us1')}}</h1>
                        <p class="text">{{__('Please send all feedback, questions, or requests for more information to')}} </p>
                        <a>{{__('info@mediacoin.com')}}</a>
                    </div>
                    <div class="center">
                        <p>or</p>
                    </div>
                    <div class="right">
                        <FormContact></FormContact>
                    </div>
                </div>
            </div>
            <div class="container-pe-quote right-quote">
                <div class="pp-quote li-quote-7">
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-8">
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-9">
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-10">
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-11">
                    <div class="img animated bounce"></div>
                </div>
                <div class="pp-quote li-quote-13">
                    <div class="img animated bounce"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import FormContact from "../modules/FormContact";

export default {
  name: 'contact',
    data: function() {
      return {

      }
  },
  watch: {

  },
  computed: {

  },

  created() {

  },
  methods: {

  },
  components: {
      FormContact
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
