<template>
  <div class="withdraw">
    <div class="left-content">
      <h1 class="description description-coin">{{__('Withdraw MC')}}
        <img src="/newfrontend/images/total-ms.svg" alt="">
      </h1>
      <div>
        <div class="amount amount-coin">
          <div class="left">
            <p class="semi semi-coin">
              {{__('MC for withdraw')}}
              <span v-on:click="balance">
                <img src="/newfrontend/images/total-ms.svg" alt="">
                {{$primNum(portfolio.balance_available_MC, 2) | formatThousands}}
              </span>
            </p>
            <InputLabelTextDefault
                type="number"
                v-model="form.amount"
                v-bind:errors="formValidate.amount"
                @input="calcSum('give')"
            >

              <template slot="right">
                <span class="usd">MC</span>
              </template>
            </InputLabelTextDefault>
          </div>
          <div class="right">
            <p class="min">{{__('Minimal withdraw: $50')}}</p>
            <InputLabelTextDefault
                type="amount_coin"
                :value="$primNum(form.amount_coin- (comissionFree), 2)"
                disabled
                v-bind:errors="formValidate.amount_coin"
                @input="calcSum( 'get')"
            >
              <template slot="right">
                <span class="usd">USD</span>
              </template>
            </InputLabelTextDefault>
          </div>
        </div>
        <div>
          <div class="wallet">
            <p class="semi">{{__('MC address')}}</p>
            <InputLabelTextDefault  v-model="form.address"></InputLabelTextDefault>
          </div>
          <ul class="deposit-text">
            <li>
              <p>{{__('Upon withdrawal, 1% goes to charity.')}}</p>
            </li>
          </ul>
          <div class="btn-one btn-coin">
            <router-link
                :to="{name: 'transactions'}"
                class="btn-white"
            >{{__('back to wallet')}}</router-link>
            <v-btn
                v-on:click="onSubmit"
                :loading="submitDisabled"
                :disabled="$v.$invalid || (form.amount_coin- (comissionFree) < 0)"
                class="btn-site"
            >{{__('withdraw funds')}}</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="right-content ">
      <ul>
        <li>
          <p class="text">{{__('Available for withdrawal')}}</p>
          <p class="text-r text-r-coin">{{$primNum (wallets.info.available, 2) | formatThousands}} MC</p>
          <p class="text-coin">${{$primNum (wallets.info.available*portfolio.courseMC) | formatThousands}}</p>
        </li>
        <li>
          <p class="text">{{__('everything was sent')}}</p>
          <p class="text-r text-r-coin">${{$primNum (wallets.info.withdraw, 2) | formatThousands}} MC
          <p class="text-coin">${{$primNum (wallets.info.withdraw*portfolio.courseMC) | formatThousands}}</p>
        </li>
        <li>
          <p class="text">{{__('in processing for withdrawal')}}</p>
          <p class="text-r">${{$primNum (wallets.info.pending, 2) | formatThousands}} MC</p>
          <p>${{$primNum (wallets.info.pending*portfolio.courseMC) | formatThousands}}</p>
        </li>
      </ul>
    </div>
    <WithdrawCoinStep2 v-if="withdraw_info" v-bind:withdraw.sync="withdraw_info" v-bind:comission_free="comissionFree" @updateDeposit="updateDeposit" v-bind:form="form"></WithdrawCoinStep2>
  </div>
</template>

<script>
    import moment from "moment";
    import WithdrawCoinStep2 from "./WithdrawCoinStep2";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {required, minValue,maxValue, decimal} from "vuelidate/lib/validators";

    export default {
        data () {
            return {
                submitDisabled: false,
                withdraw_info: null,
                formValidate: {
                    amount: null,
                    amount_coin: null,
                    address: null,
                },
                form: {
                    amount: 50,
                    amount_coin: 0,
                    address: null,
                }
            }
        },
        watch: {

        },
        created() {
            this.calcSum();
        },
        props: {
            wallets: {
                type: Object
            }
        },
        computed: {
            comissionFree() {
                return Number(this.$primNum(this.form.amount_coin/100, 2))
            },
            portfolio() {
                return this.$store.state.auth.portfolio;
            },
            // payments() {
            //     return this.$store.state.auth.payments
            // },
            // addresses() {
            //     let index= this.$filterIndexObject(this.wallets.payment, this.form.payment_id, 'payment_id');
            //
            //     return this.wallets.payment[index];
            // },
        },
        validations() {
            return {
                form: {
                    amount_coin: {
                        required,
                        decimal
                    },
                    address: {
                        required,
                    },
                    amount: {
                        required,
                        decimal,
                        minValue: minValue(50),
                        // limit: maxValue((200/this.payment.course_usd)- ((this.portfolio.sum_invest) ? this.portfolio.sum_invest : 0))
                        maxValue: maxValue(this.portfolio.balance_available_MC)
                        // maxValue: maxValue(this.payment.max_replenish*this.payment.course_usd)
                    },
                }
            }
        },
        methods: {
          balance(){
            this.form.amount = (this.$primNum(this.wallets. balance, 2));
            this.calcSum('give');
          },
            calcSum(type= 'give') {
                let portfolio= this.portfolio;
                if(type== 'get') {
                    let val= this.form.amount_coin;


                    let calc_get= this.$primNum((val/portfolio.courseMC), 2);
                    this.form.amount= calc_get;
                } else {
                    let val= this.form.amount;
                    let calc_give= this.$primNum((val*portfolio.courseMC), 2);
                    this.form.amount_coin= Number(calc_give);
                }
                this.updateValidate('amount');
            },
            disableItem(item) {
                if (item.disabled === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            updateDeposit() {

                let valid= this.$v.form;
                if(valid.$invalid) {
                    let massages= {
                        'amount': [],
                        'amount_coin': [],
                        'address': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;

                    let form= this.form;

                    let response = form;

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/'+('withdraw_coin/create'), response)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            this.$store.dispatch('asyncResp', response.resp_tickets);
                            this.withdraw_info= null;
                            this.$router.push({name: 'transactions'});
                        }).catch((err) => {
                        this.submitDisabled= false;
                        this.httpHandler(err);

                        if(response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                }
            },
            moment: function () {
                return moment();
            },
            updateValidate: function(type) {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },
            onSubmit() {
                let valid= this.$v.form;
                if(valid.$invalid) {
                    let massages= {
                        'amount': [],
                        'amount_coin': [],
                        'address': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.withdraw_info= this.form;
                }
            }
        },
        components: {
            WithdrawCoinStep2,
            InputLabelTextDefault
        }
    }
</script>

<style scoped>

</style>
