<template>
    <div class="finance">
        <div class="content">
          <DepositOperation v-bind:transactions.sync="transactions"></DepositOperation>
        </div>
    </div>

</template>

<script>

import DepositOperation from "./Finance/DepositOperation";

export default {
  name: 'Deposit',
    data () {
        return {
            transactions: null,
            params: {},
            // deposit_info: null
        }
  },

  created () {
      // this.$http.get('ajax/payments')
      //     .then(response => {
      //         return response.json()
      //     })
      //     .then(response => {
      //         response= response.data;
      //         let payment= response.payment;
      //
      //         let portfolio= this.$cleanObject(this.portfolio);
      //         portfolio['balance_usd']= response['balance_usd'];
      //         this.$store.dispatch('asyncPortfolio', portfolio);
      //         this.$store.dispatch('asyncPayments', payment);
      //     }).catch((err) => {
      //     this.httpHandler(err);
      // });
      // this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/transaction', this.params)
      //     .then(response => {
      //         return response.json()
      //     })
      //     .then(response => {
      //         response= response.data;
      //         this.transactions= response;
      //     }).catch((err) => {
      //     this.httpHandler(err);
      // });
      // this.$http.get('ajax/deposit/info')
      //     .then(response => {
      //         return response.json()
      //     })
      //     .then(response => {
      //         response= response.data;
      //         this.deposit_info= response;
      //     }).catch((err) => {
      //     this.httpHandler(err);
      // });
  },
  methods: {

  },
  computed:{
      portfolio() {
          return this.$store.state.auth.portfolio
      },
  },

  components: {
      DepositOperation,
      // PulseLoader
  }
}
</script>

<style scoped>

</style>
