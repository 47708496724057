<template>
    <div class="faq-home">
        <div class="container">


        </div>
    </div>
</template>

<script>

export default {
  name: 'about',
    data: function() {
        return {
        }
  },
  watch: {

  },

    methods:{

    },
  computed: {

  },
  mounted() {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
