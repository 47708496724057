<template>
  <div class="filter-chuse">
    <div v-for="(param, key) in params" :key="key">
      <v-btn class="btn-grey mr-2" v-if="param">
        <span v-if="key== 'from'">
          {{__('From:')}} {{param}}
        </span>
        <span v-else-if="key== 'to'">
          {{__('To:')}} {{param}}
        </span>
        <span v-else-if="key== 'payment'">
          <Payment :payment_id="param">
            <template v-slot="payment">
              {{payment.name}}
            </template>
          </Payment>
        </span>
        <span v-else-if="key== 'wallet'">
          <span v-if="param== 1">
            USDT
          </span>
          <span v-else>
            MC
          </span>
        </span>
<!--        <span v-else-if="key== 'operation'">-->
<!--          <span v-if="param== 1">-->
<!--            {{__('Deposit')}}-->
<!--          </span>-->
<!--          <span v-else>-->
<!--            {{__('Withdraw')}}-->
<!--          </span>-->
<!--        </span>-->
        <span v-else-if="key== 'operation'">
          <span v-if="param== 1">
            {{__('Deposit')}}
          </span>
          <span v-else-if="param== 2">
            {{__('Withdraw')}}
          </span>
          <span v-else>
            {{__('Exchange')}}
          </span>
        </span>
        <span default v-else-if="param">{{key}}</span>

        <span v-on:click="clearParams(key)" class="ml-1">X</span>
      </v-btn>
    </div>
    <v-btn class="btn-grey clear" v-if="Object.keys(params).length" v-on:click="clearFilter">{{__('Clear all filters')}}</v-btn>
  </div>
</template>

<script>
    import Payment from "./Payment";
    import _ from "lodash";

    export default {

        name: 'FiltersParams',
        data: function() {
            return {

            }
        },
        props: {
            params: {
                type: [Object]
            }
        },
        computed: {

        },
        watch: {

        },
        methods: {
            clearParams(key) {
                let params= this.$cleanObject(this.params);
                params[key]= false;
                _.unset(params, [key]);

                // if(key== 'from') {
                //     if(params['to']) {
                //         params['from']= params['to'];
                //
                //         params['to']= false;
                //         _.unset(params, ['to']);
                //
                //     }
                // }

                this.$emit('update:params', params);
                this.$emit('onFilter', params);
            },
            clearFilter() {
                let params= {};
                this.$emit('update:params', params);
                this.$emit('onFilter', params);
            }
        },
        components: {
            Payment
        },
    };
</script>
