<template>
  <div>

<!--    <div class="promo-usdt" v-if="promo == 2">-->
<!--      <div class="top">-->
<!--        <div class="left">-->
<!--          <img src="/newfrontend/images/logo-new.svg" alt="">-->
<!--          <router-link :to="{name: 'wow_promo_list'}" class="btn-site">{{__('completed a promo')}}</router-link>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <div class="text">-->
<!--            <p>{{__('To end')}} <span>{{__('promo')}}</span>{{__('left')}}:</p>-->
<!--          </div>-->
<!--          <div class="block-timer">-->
<!--            <div class="block-timer__item">-->
<!--              <span class="num">{{daysP}}</span>-->
<!--              <p class="txt">{{__('days')}}</p>-->
<!--            </div>-->
<!--            <div class="block-timer__item">-->
<!--              <span class="num">{{hoursP}}</span>-->
<!--              <p class="txt">{{__('hours')}}</p>-->
<!--            </div>-->
<!--            <div class="block-timer__item">-->
<!--              <span class="num">{{minutesP}}</span>-->
<!--              <p class="txt">{{__('minutes')}}</p>-->
<!--            </div>-->
<!--            <div class="block-timer__item">-->
<!--              <span class="num">{{secondsP}}</span>-->
<!--              <p class="txt">{{__('seconds')}}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="body-promo">-->
<!--        <div class="left">-->
<!--          <span class="subtitle-txt">{{__('Only 3 month promo!')}}</span>-->
<!--          <p class="title-txt">{{__('Create your own team and get more real usdt to balance!')}}</p>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <div class="date">-->
<!--            <p class="top-text">{{__('august')}}</p>-->
<!--            <p class="number">16</p>-->
<!--            <p class="start">{{__('start date')}}</p>-->
<!--          </div>-->
<!--          <div class="date">-->
<!--            <p class="top-text">{{__('november')}}</p>-->
<!--            <p class="number">16</p>-->
<!--            <p class="start">{{__('finish date')}}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="week-body">-->
<!--        <div class="body-bar body-bar-top">-->
<!--          <p :class="((status > 1) ? 'completed': (status== 1) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 2) ? 'completed': (status== 2) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 3) ? 'completed': (status== 3) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 4) ? 'completed': (status== 4) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 5) ? 'completed': (status== 5) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 6) ? 'completed': (status== 6) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--            <img src="/newfrontend/images/promo-img-1.svg" alt="">-->
<!--          </p>-->
<!--        </div>-->
<!--        <v-progress-linear-->
<!--                :value="value"-->
<!--                striped-->
<!--                color="light-blue"-->

<!--                class="progress-bar"-->
<!--                height="16px"-->
<!--        >-->
<!--          <div ref="turnoverTooltip" class="turnoverTooltip" :style="{left: tooltipX+'px'}">-->
<!--            $ {{info.turnover}}-->
<!--          </div>-->
<!--        </v-progress-linear>-->
<!--        <div class="body-bar">-->
<!--          <p :class="((status > 1) ? 'completed': (status== 1) ? 'active': '')">20</p>-->
<!--          <p :class="((status > 2) ? 'completed': (status== 2) ? 'active': '')">50</p>-->
<!--          <p :class="((status > 3) ? 'completed': (status== 3) ? 'active': '')">100</p>-->
<!--          <p :class="((status > 4) ? 'completed': (status== 4) ? 'active': '')">250</p>-->
<!--          <p :class="((status > 5) ? 'completed': (status== 5) ? 'active': '')">500</p>-->
<!--          <p :class="((status > 6) ? 'completed': (status== 6) ? 'active': '')">1000</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="promo-footer">-->
<!--        <div class="left">-->
<!--          <div class="column">-->
<!--            <div class="item">-->
<!--              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00065 0.7677C3.4569 0.7677 0.583984 3.64062 0.583984 7.18437C0.583984 10.7281 3.4569 13.601 7.00065 13.601C10.5444 13.601 13.4173 10.7281 13.4173 7.18437C13.4173 3.64062 10.5444 0.7677 7.00065 0.7677ZM9.78198 6.09937C9.8332 6.04082 9.87219 5.97263 9.89666 5.8988C9.92113 5.82497 9.93059 5.74698 9.92448 5.66944C9.91836 5.5919 9.89681 5.51636 9.86107 5.44727C9.82533 5.37819 9.77614 5.31694 9.71639 5.26715C9.65663 5.21735 9.58752 5.18001 9.51312 5.15732C9.43873 5.13463 9.36054 5.12705 9.28317 5.13502C9.20579 5.14299 9.1308 5.16635 9.06259 5.20374C8.99438 5.24112 8.93433 5.29177 8.88598 5.3527L6.37765 8.36212L5.07973 7.06362C4.96972 6.95736 4.82237 6.89856 4.66942 6.89989C4.51647 6.90122 4.37016 6.96257 4.26201 7.07072C4.15385 7.17888 4.0925 7.32518 4.09117 7.47813C4.08985 7.63108 4.14864 7.77843 4.2549 7.88845L6.0049 9.63845C6.06222 9.69573 6.13084 9.74044 6.20639 9.76974C6.28195 9.79903 6.36277 9.81226 6.44372 9.80858C6.52467 9.8049 6.60397 9.7844 6.67656 9.74838C6.74914 9.71236 6.81343 9.66161 6.86532 9.59937L9.78198 6.09937Z" fill="#00C868"/>-->
<!--              </svg>-->
<!--              <p>{{__('Only new partners in the 1st line')}}</p>-->
<!--            </div>-->

<!--            <div class="item">-->
<!--              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00065 0.7677C3.4569 0.7677 0.583984 3.64062 0.583984 7.18437C0.583984 10.7281 3.4569 13.601 7.00065 13.601C10.5444 13.601 13.4173 10.7281 13.4173 7.18437C13.4173 3.64062 10.5444 0.7677 7.00065 0.7677ZM9.78198 6.09937C9.8332 6.04082 9.87219 5.97263 9.89666 5.8988C9.92113 5.82497 9.93059 5.74698 9.92448 5.66944C9.91836 5.5919 9.89681 5.51636 9.86107 5.44727C9.82533 5.37819 9.77614 5.31694 9.71639 5.26715C9.65663 5.21735 9.58752 5.18001 9.51312 5.15732C9.43873 5.13463 9.36054 5.12705 9.28317 5.13502C9.20579 5.14299 9.1308 5.16635 9.06259 5.20374C8.99438 5.24112 8.93433 5.29177 8.88598 5.3527L6.37765 8.36212L5.07973 7.06362C4.96972 6.95736 4.82237 6.89856 4.66942 6.89989C4.51647 6.90122 4.37016 6.96257 4.26201 7.07072C4.15385 7.17888 4.0925 7.32518 4.09117 7.47813C4.08985 7.63108 4.14864 7.77843 4.2549 7.88845L6.0049 9.63845C6.06222 9.69573 6.13084 9.74044 6.20639 9.76974C6.28195 9.79903 6.36277 9.81226 6.44372 9.80858C6.52467 9.8049 6.60397 9.7844 6.67656 9.74838C6.74914 9.71236 6.81343 9.66161 6.86532 9.59937L9.78198 6.09937Z" fill="#00C868"/>-->
<!--              </svg>-->
<!--              <p>{{__('Only 100$ investment')}}</p>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="promo-p" :class="'promo-item__'+promo" v-if="promo == 4" >
      <div class="top">
        <div class="left">
          <img src="/newfrontend/images/logo.svg" alt="">
        </div>
        <div class="center">
          <p class="text-g">{{__('New Year promo from December 23 to January 10')}}</p>
          <p class="text-b">{{__('Fulfill the volume of sales in the first line')}}</p>
          <p class="text-r">{{__('and get the Journey at the price 10.000$')}}</p>
        </div>
        <div class="right">
          <div class="text">
            <div class="bg"><img src="/newfrontend/images/promo-cir.svg" alt=""></div>
            <p>15</p>
            <span>{{__('prizes')}}</span>
          </div>
        </div>
      </div>
      <div class="body-promo">
        <ul>
          <li>
            <div class="img">
              <img src="/newfrontend/images/promo-m.png" alt="">
              <p>1-5 <span>{{__('place')}}</span></p>
            </div>
            <div class="block">
              <p>{{__('A trip with the top 30 to Mexico')}}
                <span>{{__('Trip price 10.000$')}}</span></p>
            </div>
          </li>
          <li>
            <div class="img">
              <img src="/newfrontend/images/promo-m-1.png" alt="">
              <p>6-10 <span>{{__('place')}}</span></p>
            </div>
            <div class="block">
              <p>{{__('Trip to the Maldives for 5 days')}}
                <span>{{__('Trip price 5.000$')}}</span></p>
            </div>
          </li>
          <li>
            <div class="img">
              <img src="/newfrontend/images/promo-m-2.png" alt="">
              <p>11-15 <span>{{__('place')}}</span></p>
            </div>
            <div class="block">
              <p>{{__('Travel to Turkey for 5 days')}}
                <span>{{__('Trip price 3.000$')}}</span></p>
            </div>
          </li>
        </ul>
      </div>
      <div class="week-body">
        <div class="body-bar body-bar-top">
          <p :class="((status > 1) ? 'completed': (status== 1) ? 'active': '')">
            <img src="/newfrontend/images/flag-for-turkey.svg" alt="">
          </p>
          <p :class="((status > 2) ? 'completed': (status== 2) ? 'active': '')">
            <img src="/newfrontend/images/flag-for-maldives.svg" alt="">
          </p>
          <p :class="((status > 3) ? 'completed': (status== 3) ? 'active': '')">
            <img src="/newfrontend/images/flag-for-mexico.svg" alt="">
          </p>
        </div>
        <v-progress-linear
                :value="value"
                striped
                color="light-blue"

                class="progress-bar"
                height="16px"
        >
          <div ref="turnoverTooltip" class="turnoverTooltip" :style="{left: tooltipX+'px'}">
            $ {{info.turnover}}
          </div>
        </v-progress-linear>
        <div class="body-bar">
          <p :class="((status > 1) ? 'completed': (status== 1) ? 'active': '')">30,000 $</p>
          <p :class="((status > 2) ? 'completed': (status== 2) ? 'active': '')">60,000 $</p>
          <p :class="((status > 3) ? 'completed': (status== 3) ? 'active': '')">100,000 $</p>
        </div>
      </div>
    </div>

    <div class="promo-p-1" :class="'promo-item__'+promo" v-else-if="promo == 5">
      <div class="top">
        <div class="left">
          <img src="/newfrontend/images/logo.svg" alt="">
        </div>
        <div class="center">
          <p class="text-g">{{__('Promoción para la academia de líderes fuera de línea')}}</p>
          <p class="text-b">{{__('Promo for offline academy leader')}}</p>
          <p class="text-r">{{__('промо на оффлайн академию лидеров')}} </p>
        </div>
        <div class="right">
          <div class="text">
            <p>{{__('To end')}} <span>{{__('promo')}} </span>{{__('left')}}:</p>
          </div>
          <div class="block-timer">
            <div class="block-timer__item">
              <span class="num">{{days}}</span>
              <p class="txt">{{__('days')}}</p>
            </div>
            <div class="block-timer__item">
              <span class="num">{{hours}}</span>
              <p class="txt">{{__('hours')}}</p>
            </div>
            <div class="block-timer__item">
              <span class="num">{{minutes}}</span>
              <p class="txt">{{__('minutes')}}</p>
            </div>
            <div class="block-timer__item">
              <span class="num">{{seconds}}</span>
              <p class="txt">{{__('seconds')}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="body-promo">
        <ul>
          <li>
            <div class="img">
              <img src="/newfrontend/images/pr-4.png" alt="">
            </div>
            <div class="block">
              <p>{{__('Republic of Peru')}}
                <span>{{__('25 - 29 February')}}</span></p>
            </div>
          </li>
          <li>
            <div class="img">
              <img src="/newfrontend/images/pr-1.png" alt="">
            </div>
            <div class="block">
              <p>{{__('Turkey')}}
                <span>{{__('11 - 16 march')}}</span></p>
            </div>
          </li>
          <li>
            <div class="img">
              <img src="/newfrontend/images/pr-3.png" alt="">
            </div>
            <div class="block">
              <p>{{__('ghana')}}
                <span>{{__('20 - 24 march')}}</span></p>
            </div>
          </li>
        </ul>
      </div>
      <div class="week-body">
        <div class="body-bar body-bar-top">
          <p :class="((status > 1) ? 'completed': (status== 1) ? 'active': '')">
            <img src="/newfrontend/images/promo-n1.svg" alt="">
          </p>
          <p :class="((status > 2) ? 'completed': (status== 2) ? 'active': '')">
            <img src="/newfrontend/images/promo-n2.svg" alt="">
          </p>
          <p :class="((status > 3) ? 'completed': (status== 3) ? 'active': '')">
            <img src="/newfrontend/images/promo-n3.svg" alt="">
          </p>
        </div>
        <v-progress-linear
                :value="value"
                striped
                color="light-blue"

                class="progress-bar"
                height="16px"
        >
          <div ref="turnoverTooltip" class="turnoverTooltip" :style="{left: tooltipX+'px'}">
            $ {{info.turnover}}
          </div>
        </v-progress-linear>
        <div class="body-bar">
          <p :class="((status > 1) ? 'completed': (status== 1) ? 'active': '')">30,000 $</p>
          <p :class="((status > 2) ? 'completed': (status== 2) ? 'active': '')">60,000 $</p>
          <p :class="((status > 3) ? 'completed': (status== 3) ? 'active': '')">120,000 $</p>
        </div>
      </div>
    </div>

<!--    <div class="promo-new" v-else-if="promo == 3" >-->
<!--      <div class="top">-->
<!--        <div class="left">-->
<!--          <img src="/newfrontend/images/logo-white-new.svg" alt="">-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <div class="text">-->
<!--            <p>{{__('To end')}} <span>{{__('promo')}} </span>{{__('left')}}:</p>-->
<!--          </div>-->
<!--          <div class="block-timer">-->
<!--            <div class="block-timer__item">-->
<!--              <span class="num">{{days}}</span>-->
<!--              <p class="txt">{{__('days')}}</p>-->
<!--            </div>-->
<!--            <div class="block-timer__item">-->
<!--              <span class="num">{{hours}}</span>-->
<!--              <p class="txt">{{__('hours')}}</p>-->
<!--            </div>-->
<!--            <div class="block-timer__item">-->
<!--              <span class="num">{{minutes}}</span>-->
<!--              <p class="txt">{{__('minutes')}}</p>-->
<!--            </div>-->
<!--            <div class="block-timer__item">-->
<!--              <span class="num">{{seconds}}</span>-->
<!--              <p class="txt">{{__('seconds')}}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="center">-->
<!--          <router-link :to="{name: 'wow_list'}" class="btn-white-new">{{__('completed a promo')}}</router-link>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="body-promo">-->
<!--        <div class="left-bg">-->
<!--          <img src="/newfrontend/images/promo-left.png" alt="">-->
<!--        </div>-->
<!--        <div class="center">-->
<!--          <p class="text">{{__('from november 10 to december 20')}}</p>-->
<!--          <p class="name">{{__('New Year promo')}}</p>-->
<!--          <p class="text-r">{{__('from Mediachain!')}}</p>-->
<!--          <p class="text-b">{{__('Make the sales volume in the first line and get all the prizes!')}}</p>-->
<!--        </div>-->
<!--        <div class="right-bg">-->
<!--          <img src="/newfrontend/images/promo-right.png" alt="">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="week-body">-->
<!--        <div class="body-bar body-bar-top">-->
<!--          <p :class="((status > 1) ? 'completed': (status== 1) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-1-img.png" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 2) ? 'completed': (status== 2) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-2-img.png" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 3) ? 'completed': (status== 3) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-3-img.png" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 4) ? 'completed': (status== 4) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-4-img.png" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 5) ? 'completed': (status== 5) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-5-img.png" alt="">-->
<!--          </p>-->
<!--          <p :class="((status > 6) ? 'completed': (status== 6) ? 'active': '')">-->
<!--            <img src="/newfrontend/images/promo-6-img.png" alt="">-->
<!--          </p>-->
<!--        </div>-->
<!--        <v-progress-linear-->
<!--                :value="value"-->
<!--                striped-->
<!--                color="light-blue"-->

<!--                class="progress-bar"-->
<!--                height="16px"-->
<!--        >-->
<!--          <div ref="turnoverTooltip" class="turnoverTooltip" :style="{left: tooltipX+'px'}">-->
<!--            $ {{info.turnover}}-->
<!--          </div>-->
<!--        </v-progress-linear>-->
<!--        <div class="body-bar">-->
<!--          <p :class="((status > 1) ? 'completed': (status== 1) ? 'active': '')">20,000 $</p>-->
<!--          <p :class="((status > 2) ? 'completed': (status== 2) ? 'active': '')">50,000 $</p>-->
<!--          <p :class="((status > 3) ? 'completed': (status== 3) ? 'active': '')">100,000 $</p>-->
<!--          <p :class="((status > 4) ? 'completed': (status== 4) ? 'active': '')">250,000 $</p>-->
<!--          <p :class="((status > 5) ? 'completed': (status== 5) ? 'active': '')">500,000 $</p>-->
<!--          <p :class="((status > 6) ? 'completed': (status== 6) ? 'active': '')">1,000,000 $</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->



  </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'ShowPromo',

        data: function() {
            return {
                tooltipX: 0,
                value: 0,
                days: 0,
                daysP: 0,
                hours: 0,
                hoursP: 0,
                minutes: 0,
                minutesP: 0,
                seconds: 0,
                secondsP: 0,
                timeinterval: null,
                status: 0,
            }
        },
        props: {
            promo: {
                type: [
                    String, Number
                ],
            },
            info: {
                type: Object
            }
        },
        computed: {

        },
        created() {
            this.initializeClock();
            this.initializeClockP();
            this.updateClock();
            this.updateClockP();

            if(this.promo == 5) {
                this.turnoverValue();
            } else if(this.promo == 4) {
                this.turnoverValuePromo();
            }
        },
        methods: {
            initializeClock() {
                this.timeinterval = setInterval(this.updateClock, 1000);
            },
            initializeClockP() {
                this.timeinterval = setInterval(this.updateClockP, 1000);
            },

            updateClock() {
                let x = moment.utc('2023-03-06 00:00:00').format('x') - moment.utc().format('x');
                let seconds = Math.floor((x / 1000) % 60);
                let minutes = Math.floor((x / 1000 / 60) % 60);
                let hours = Math.floor((x / (1000 * 60 * 60)) % 24);
                let days = Math.floor(x / (1000 * 60 * 60 * 24));

                let t = {
                    'total': x,
                    'days': days,
                    'hours': hours,
                    'minutes': minutes,
                    'seconds': seconds
                };


                this.days = t.days;
                this.hours = t.hours;
                this.minutes = t.minutes;
                this.seconds = t.seconds;


                if (t.total <= 0) {
                    clearInterval(this.timeinterval);
                }
            },
            updateClockP() {
                let x = moment.utc('2022-11-16 00:00:00').format('x') - moment.utc().format('x');
                let seconds = Math.floor((x / 1000) % 60);
                let minutes = Math.floor((x / 1000 / 60) % 60);
                let hours = Math.floor((x / (1000 * 60 * 60)) % 24);
                let days = Math.floor(x / (1000 * 60 * 60 * 24));

                let t = {
                    'total': x,
                    'days': days,
                    'hours': hours,
                    'minutes': minutes,
                    'seconds': seconds
                };


                this.daysP = t.days;
                this.hoursP = t.hours;
                this.minutesP = t.minutes;
                this.secondsP = t.seconds;


                if (t.total <= 0) {
                    clearInterval(this.timeinterval);
                }
            },
            turnoverValue() {
              let turnover= this.info.turnover;
              // turnover= 30000;

              let value= 0;
              let end= 30000;
              let step= 0;


              console.log('turnover');
              console.log(turnover);

              if(turnover < 30000) {
                this.status= 0;
              } else if(turnover < 60000) {
                value= 25;
                end= 60000;
                step= 30000;
                this.status= 1;
              }
              else if(turnover < 120000) {
                value= 25*2;
                end= 120000;
                step= 60000;
                this.status= 2;
              }
              else {
                value= 25*3;
                end= 1000000;
                step= 120000;
                this.status= 3;
              }


              value= value+((turnover-step)/((end-step)/25));


              this.$nextTick(() => {
                this.tooltipX= document.querySelector('.promo-item__'+this.promo+' .v-progress-linear__determinate').clientWidth;
              });

              this.value= value;
            },
            turnoverValuePromo() {
                let turnover= this.info.turnover;
              // turnover= 30000;

                let value= 0;
                let end= 30000;
                let step= 0;


                console.log('turnover');
                console.log(turnover);

                if(turnover < 30000) {
                    this.status= 0;
                } else if(turnover < 60000) {
                    value= 25;
                    end= 60000;
                    step= 30000;
                    this.status= 1;
                }
                else if(turnover < 100000) {
                  value= 25*2;
                  end= 100000;
                  step= 60000;
                  this.status= 2;
                }
                else {
                    value= 25*3;
                    end= 1000000;
                    step= 100000;
                    this.status= 3;
                }


                value= value+((turnover-step)/((end-step)/25));


                this.$nextTick(() => {
                    this.tooltipX= document.querySelector('.promo-item__'+this.promo+' .v-progress-linear__determinate').clientWidth;
                });

                this.value= value;
            },
        },
        components: {

        }
    }
</script>
