<template>
    <div class="sign-in">
            <div class="content">
                <div class="right">
                    <div class="wrap-head">
                        <router-link :to="{name: 'home'}"><img src="/newfrontend/images/logo-black.svg" class="logo" alt=""></router-link>
                        <h1 class="text-darker ">{{__('Forgot password')}}</h1>
                    </div>
                    <div>
                        <div class="wrap-input">
                            <p class="text semi">{{__('Email address')}}</p>
                            <InputLabelTextDefault
                                    :placeholder="__('Enter your email')"
                                    color="main"
                                    v-model="email"
                                    v-bind:errors="formValidate.email"
                                    @change="updateValidate('email')"
                            ></InputLabelTextDefault>
                        </div>
                        <div class="input-sing">
                            <v-btn v-if="submitDisabled" block default disabled>{{__('loading...')}}</v-btn>
                            <!--        <v-btn-->
                            <!--          v-else-->
                            <!--          class="btn-main btn-main-center btn-register white&#45;&#45;text"-->
                            <!--          v-ripple="{ class:'secondary&#45;&#45;text'}"-->
                            <!--          block-->
                            <!--          text-->
                            <!--          v-on:click="onSubmit"-->
                            <!--        >-->
                            <!--          <img src="/newfrontend/images/frontend/sign.svg" alt />-->
                            <!--          {{__('Reset Password')}}-->
                            <!--        </v-btn>-->
                            <div class="wrap-sing" v-else >
                                <v-btn
                                    height="46"
                                    class="btn-site"
                                    v-on:click="openCaptcha"
                                >
                                  <span>
                                    {{__('Password123')}}
                                  </span>
                                </v-btn>
                            </div>
                            <div class="social-mob-login">
                                <p>
                                    <router-link
                                      :to="{name: 'login'}"
                                    >
                                      {{__('Back to login')}}
                                    </router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
      <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>


<script>

    import Captcha from "../modules/Captcha";
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import { required, email } from "vuelidate/lib/validators";

    export default {
        name: "AuthForgot",
        data() {
            return {
                submitDisabled: false,
                email: null,
                formValidate: {
                    email: null,
                },

                captcha: false,
                rules: this.validateMessage,
                dateUpdate: null,
            };
        },
        props: {
            modal: {
                type: Object,
            },
        },
        validations: {
            email: {
                required,
                email,
            },
        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },
            openCaptcha() {
                let valid = this.$v;
                if (valid.$invalid) {
                    let massages = {
                        email: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.captcha= true;
                }
            },
            onSubmit: function (session, arrayData) {
                let valid = this.$v;
                if (valid.$invalid) {
                    let massages = {
                        email: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = {
                        email: this.email,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http
                        .post("ajax/password/email", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            this.$store.dispatch('asyncResp', response.resp_tickets);
                            this.submitDisabled = false;
                            this.$router.push({name: 'home'});
                            this.$notify({
                                group: "message",
                                text: this.__("The letter was successfully sent to the mail"),
                            });
                            this.captcha= false;
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                            this.captcha= false;
                        });
                }
            },
        },
        components: {
            InputLabelTextDefault,
            Captcha
        },
    };
</script>

<style lang="scss" scoped>


</style>