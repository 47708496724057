import { render, staticRenderFns } from "./DashboardNew.vue?vue&type=template&id=0b37ad06&scoped=true&"
import script from "./DashboardNew.vue?vue&type=script&lang=js&"
export * from "./DashboardNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b37ad06",
  null
  
)

export default component.exports