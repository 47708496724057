<template>
    <div class="top100">
        <div class="bg-top-100">
            <img src="/newfrontend/images/bg-top-page.png" alt="">
        </div>
        <div class="container">
            <div class="top-block">
                <h1>{{__('top-30')}}</h1>
                <p>{{__('Best MediaChain Leaders')}}</p>
            </div>
            <div class="block-text">
                <div class="left">
                    <p class="text-b">{{__('The formula for calculating the leaders efficiency coefficient:')}}</p>
                    <img src="/newfrontend/images/top-left-1.png" alt="">
                    <p class="text-b">{{__('Indicators:')}}</p>
                    <ul>
                        <li>
                            <p class="text-r">X</p>
                            <p class="text">{{__('every')}} <span>{{__('10.000$')}}</span> {{__('in farming in the first line which gives')}}<span> {{__('0.1 points')}}</span></p>
                        </li>
                        <li>
                            <p class="text-r">P</p>
                            <p class="text">{{__('each partner who invested in farming')}} <span>{{__('in the first 10 lines')}}</span> {{__('and gives')}} <span>{{__('0.001 points')}}</span></p>
                        </li>
                        <li>
                            <p class="text-r">М</p>
                            <p class="text">{{__('every')}} <span>{{__('10.000$')}}</span> {{__('in team farming, excluding your first line and gives')}} <span>{{__('0.025 points')}}</span></p>
                        </li>
                        <li>
                            <p class="text-r">N</p>
                            <p class="text">{{__('every')}} <span>{{__('10.000$')}}</span> {{__('of your personal investment in farming (count up to $100,000) which gives')}} <span>{{__('0.3 points')}}</span></p>
                        </li>
                        <li>
                            <p class="text-r">4</p>
                            <p class="text">{{__('number of all key indicators of the leader')}}</p>
                        </li>
                        <li>
                            <p class="text-r">5</p>
                            <p class="text">{{__('efficiency multiplier')}}</p>
                        </li>
                    </ul>
                    <div class="text-text">
                        <p class="text">{{__('The Top 30 leaderboard will be updated every Monday after 14:00 UTC.')}}</p>
                        <p class="text">{{__('To select a participant in the Top 30 and receive rewards, loyalty to the MediaChain company will also be taken into account.')}}</p>
                        <p class="text-r">{{__('Loyalty to MediaChain is attendance at all events by the leader, work with the team, attendance at all webinars from the company, and other indicators.')}}</p>
                    </div>
                </div>
                <div class="right">

                </div>
            </div>
            <div class="body-block">
                <ul v-if="top">
                    <li v-for="(item, key) in top" :key="key">
                        <div class="num-block">
                            <div class="rank-num">{{key}}</div>
                            <div class="name">
                                <p class="text-b">{{item.name}}
                                    <span v-if="item.location != null">
                                        <img  :src="'/newfrontend/images/flag/'+item.location+'.png'"/>
                                    </span>
                                </p>
                                <p class="text-m">{{__('User Name')}}</p>
                            </div>
                        </div>
                        <div class="id">
                            <p class="text-b">{{item.id}}</p>
                            <p class="text-m">{{__('User ID')}}</p>
                        </div>
                        <div class="rank">
                            <div v-if="item.rank == 0">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-1.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 1">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-1.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 2">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-1.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 3">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-1.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 4">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-1.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 5">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-1.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 6">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-1.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 7">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-2.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 8">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-2.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 9">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-2.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 10">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-2.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 11">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-2.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 12">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-3.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 13">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-3.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 14">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-3.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 15">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-3.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 16">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-3.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 17">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-4.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Платиновый инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 18">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-4.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Платиновый инвестор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 19">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-5.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Партнёр')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 20">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-6.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый партнёр')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 21">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-6.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый партнёр')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 22">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-7.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой партнёр')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 23">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-7.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой партнёр')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 24">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-8.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Платиновый партнёр')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 25">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-9.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Дистрибьютор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 26">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-10.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый дистрибьютор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 27">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-11.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой дистрибьютор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 28">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-11.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой дистрибьютор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 29">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-12.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Платиновый дистрибьютор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 30">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-13.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Лидер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 31">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-14.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый лидер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 32">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-14.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой лидер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 33">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-16.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Платиновый лидер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 34">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-17.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Директор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 35">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-18.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый директор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 36">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-18.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый директор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 37">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-19.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой директор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 38">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-19.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой директор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>
                            </div>
                            <div v-if="item.rank == 39">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-20.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Платиновый директор')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>
                            </div>
                            <div v-if="item.rank == 40">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-21.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Акционер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>

                            </div>
                            <div v-if="item.rank == 41">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-21.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Акционер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>
                            </div>
                            <div v-if="item.rank == 42">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-22.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый акционер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>
                            </div>
                            <div v-if="item.rank == 43">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-22.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Бронзовый акционер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>
                            </div>
                            <div v-if="item.rank == 44">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-23.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Золотой акционер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>
                            </div>
                            <div v-if="item.rank == 45">
                                <div class="rank-img">
                                    <img src="/newfrontend/images/rank-24.png" alt="">
                                </div>

                                <div class="text-block">
                                    <p :style="{color: color(item)}">{{__('Платиновый акционер')}}</p>
                                    <p class="rank-text" :style="{color: color(item)}"><span>{{item.rank}} {{__('Rank')}}</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="coefficient">
                            <p class="block-coefficient" :style="{background: bg_color(item)}">{{__('Coefficient')}} = {{item.coefficient}}</p>
<!--                            <p class="coefficient-left" :style="{background: bg_color(item)}">{{__('Coefficient')}}</p>-->
<!--                            <p class="coefficient-right" :style="{background: bg_color(item)}">{{item.coefficient}}</p>-->
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: 'top_page',
    data: function() {
        return {
            top: null
        }
  },
  watch: {

  },
  methods:{
      bg_color(item) {
          let bg = 'linear-gradient(0deg, #FF0000, #FF0000), #893027';

          if (+item.coefficient <= 5) {
              bg = 'linear-gradient(0deg, #0066FF, #0066FF), #893027';
          } else if (+item.coefficient <= 10) {
              bg = 'linear-gradient(0deg, #FB5F08, #FB5F08), #893027';
          } else if (+item.coefficient <= 20) {
              bg = 'linear-gradient(0deg, #55D4E2, #55D4E2), #893027';
          } else if (+item.coefficient <= 30) {
              bg = 'linear-gradient(0deg, #00C868, #00C868), #893027';
          } else {
              bg = 'linear-gradient(0deg, #FF0000, #FF0000), #893027';
          }

          return bg
      },

      color(item) {
          let color = '#FF0000';

          if (+item.rank <= 18) {
              color='#70767D';
          } else if (+item.rank <= 24) {
              color = '#FDD023';
          } else if (+item.rank <= 29) {
              color = '#00C868';

          } else if (+item.rank <= 33) {
              color = '#0066FF';
          } else if (+item.rank <= 39) {
              color = '#BC00FF';
          } else {
              color = '#FF0000';
          }

          return color
      }
  },
  created() {

      this.$http['get']('ajax/top', this.params)
          .then(response => {
              return response.json()
          })
          .then((response) => {
              this.top= response.data.users;
          }).catch((err) => {
          this.httpHandler(err);
      });

  },
    computed: {
      lang() {
          return this.$store.state.language.lang
      },
  },
  mounted() {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
