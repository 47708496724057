<template>
    <div class="events">
        <div class="container" v-if="eventnews">
            <div class="content">
                <div class="top">
                    <div class="left">
                        <h2>{{__('Events')}}</h2>
                    </div>
                    <div class="right">
                        <img class="img" src="/newfrontend/images/media-search.svg" alt="">
                        <img class="img-search" src="/newfrontend/images/news-close.svg" alt="" v-if="search" v-on:click="closeSearch">
                        <SearchDefault class_search="media" :placeholder="__('Find an event...')" v-model="search" @searching="searching"></SearchDefault>
                    </div>
                </div>
                <PaginationDefault class="body"  v-bind:data="eventnews" type="post" v-bind:params="params" v-bind:showing="true" @updateData="updateData">
                    <template v-slot="pages" class="body-block" >
                        <div class="block" v-for="(item, key) in pages" :key="key">
                            <router-link class="block-b" :to="{name: 'event_page', params: {url: item.url}}">
                                <router-link tag="div" :style='{backgroundImage: "url("+item.img+")"}' class="block-top" :to="{name: 'event_page', params: {url: item.url}}">
                                </router-link>
                                <div class="block-body">
                                    <div class="d-flex justify-space-between">
                                        <p>{{item.created_at | moment('MMM DD, YYYY')}}</p>
                                    </div>
                                    <p class="titles">
                                        {{item.title}}
                                    </p>
                                </div>
                            </router-link>
                        </div>
                    </template>
                </PaginationDefault>
            </div>
        </div>
        <div v-else class="loading">
            <loaderMc></loaderMc>
            {{__('Loading')}}
        </div>
    </div>
</template>

<script>

    import SearchDefault from "../modules/SearchDefault";
    import PaginationDefault from "../modules/PaginationDefault";
    import loaderMc from "../SvgComponents/loaderMc";
    export default {
        name: 'events',
        data: function() {
            return {
                params: {
                    category: (this.$route.params.category) ? this.$route.params.category : null,
                    search: null,
                    lang: null
                },
                video: false,
                search: null,
                eventnews: null,
                category: null,
            }
        },
        watch: {
            lang(val) {
                this.params.lang= val;
                this.getEventnews();
            }
        },
        methods:{
            closeSearch() {
                this.search= null;
                this.searching();
            },
            changeCategory(category) {
                this.params.category= category;
                this.$router.push({name: 'events', params: {category: category}});
                this.getEventnews();
            },
            searching() {
                this.params.search= this.search;

                this.getEventnews();
            },
            updateData(response) {
                this.eventnews= response.eventnews;
                window.scrollTo(0,0);
            },
            getEventnews() {
                let params= this.$cleanObject(this.params);

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/eventnews', params)
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        this.$store.dispatch('asyncResp', response.resp_tickets);
                        response = response.data;
                        this.eventnews = response.eventnews;
                        // if(!this.category) {
                        this.category = response.category;
                        // }
                    }).catch((err) => {
                    this.httpHandler(err);
                });
            }
        },
        created() {
            this.params.lang= this.lang;
            this.getEventnews();
        },
        computed: {
            lang() {
                return this.$store.state.language.lang
            }
        },
        mounted() {

        },
        components: {
            loaderMc,
            PaginationDefault,
            SearchDefault,
        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
