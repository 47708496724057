<template>
<div class="privacy ">
        <div class="container">
            <div class="content">
                <h1>{{__('Privacy Policy')}}</h1>
                <p class="text">{{__('Date of last update: November 1, 2021')}}</p>
                <p class="text">{{__('By using by visiting our website Mediacoin.inc, you agree to the use, disclosure and procedures set out in this Privacy Policy. We are not responsible for sites located on domains other than the above.')}}</p>
                <p class="text">{{__('We use the information only to ensure and improve the operation of the Site. We do not ask for any information that could reveal your identity. By using the Site, you agree to the collection and use of information in accordance with this policy.')}}</p>
                <p class="text">{{__('We record technical data about your use of the Products, including the type of browser and version you are using, your account ID, the time you last accessed your account, the IP address used to create your account, and the last IP address. the address used to access offices. We also collect information about the device you use to access your account, including hardware model, operating system and version, as well as unique device identifiers, but this information is anonymous and not tied to any particular person.')}}</p>
                <p class="text">{{__('We use several physical and electronic safeguards to keep your information safe, including encrypted user passwords, two-factor password authentication where possible, and securing all connections with industry transport layer security standards.')}}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'privacy',
    data: function() {
        return {
            tab: null,
        }
  },
  watch: {

  },

    methods:{

    },
  computed: {

  },
  mounted() {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
