<template>
    <div class="operation-list">
      <div v-if="transactions">
        <PaginationDefault v-bind:data.sync="transactions" type="post" v-bind:params="params" @updateData="updateData">
          <template v-slot="page">
            <ul>
              <li class="top-list">
                <div class="date">
                  <p>{{__('Date')}}</p>
                </div>
                <div class="type">
                  <p>{{__('Type')}}</p>
                </div>
                <div class="payment">
                  <p>{{__('Payment system')}}</p>
                </div>
                <div class="status">
                  <p>{{__('Status')}}</p>
                </div>
                <div class="details">
                  <p>{{__('Details')}}</p>
                </div>
                <div class="summ">
                  <p>{{__('Amount')}}</p>
                </div>
              </li>
              <li v-for="(item, key) in page" :key="key">
                <Payment :payment_id="item.payment_id" v-if="[1,2].includes(item.opp_type)" class="d-flex justify-space-between" style="width: 100%">
                  <template v-slot="payment">
                    <div class="date">
                      <span class="mob-span">{{__('Date')}}</span>
                      <p>{{item.created_at | formatDate}}</p>
                    </div>
                    <div class="type">
                      <span class="mob-span">{{__('Type')}}</span>
                      <p>
                        <span :class="item.income== 1 ? 'plus': 'minus'" v-if="item.opp_type== 1">{{__('Deposit')}}</span>
                        <span :class="item.income== 1 ? 'plus': 'minus'" v-else-if="item.opp_type== 2">{{__('Withdraw')}}</span>
                        <span :class="item.income== 1 ? 'plus': 'minus'" v-else-if="item.opp_type== 3">{{__('старт фарминг')}}</span>
                        <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 4">{{__('Profit')}}</span>
                        <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 5">{{__('Referral')}}</span>
                        <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 6">{{__('Exchange token')}}</span>
                        <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 7">{{__('Closed')}}</span>
                        <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 8">{{__('Withdraw MC')}}</span>
                        <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 9">{{__((item.income== 1) ? 'Buy product' : 'Sell product')}}</span>
                        <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 10">{{__('Transfer to p2p')}}</span>
                      </p>
                    </div>
                    <div class="payment">
                      <span class="mob-span">{{__('Payment system')}}</span>
                      <div>
                        <div class="img" :class="payment.name_class"></div>
                        <p>{{payment.name}}</p>
                      </div>
                    </div>
                    <div class="status" v-if="item.opp_type== 2 || item.opp_type== 8 || item.opp_type== 9">
                      <span class="mob-span">{{__('Status')}}</span>
                      <span class="wait" v-if="item.status== '2' || item.status== '3'">
                        <v-btn class="btn-site1" v-on:click="modal_cancel= item"></v-btn>
                      </span>
                      <span class="complete" v-else-if="item.status== 1" >{{__('Сomplete')}}</span>
                      <span class="wait" v-else-if="item.status== 2">{{__('Wait')}}</span>
                      <span class="wait" v-else-if="item.status== 3">{{__('Wait')}}</span>
                      <span class="cancel" v-else-if="item.status== 4">{{__('Сancel')}}</span>
                      <span class="cancel" v-else-if="item.status== 5">{{__('Wait')}}</span>
                      <span class="cancel" v-else-if="item.status== 6">{{__('Error')}}</span>
                    </div>
                    <div class="status" v-else>
                      <span class="mob-span">{{__('Status')}}</span>
                      <span class="complete" v-if="item.status== 1">{{__('Сomplete')}}</span>
                      <span class="cancel" v-else-if="item.status== 2">{{__('Error')}}</span>
                      <span class="wait" v-else-if="item.status== 3">{{__('Wait')}}</span>
                      <span class="wait" v-else-if="item.status== 4">{{__('Confirmation')}}</span>
                      <span class="cancel" v-else-if="item.status== 5">{{__('Сancel')}}</span>
                      <span class="cancel" v-else-if="item.status== 6">{{__('End time')}}</span>
                      <span class="wait" v-else-if="item.status== 7">{{__('Wait')}}</span>
                    </div>
                    <div class="details">
                      <span class="mob-span">{{__('Details')}}</span>
                      <a target="_blank" v-if="item.tx_system && item.opp_type != 1" :href="$txLink[payment.name_class]+item.tx_system">{{item.tx_system}}</a>
                      <a target="_blank" v-else-if="item.tx && item.opp_type != 1" :href="$paymentLink[payment.name_class]+item.tx">{{item.tx}}</a>
                      <a v-else>-</a>
                    </div>
                    <div class="summ" v-if="item.opp_type== 1 && item.wallet== 2">
                      <span class="mob-span">{{__('Amount')}}</span>
                      <p>{{$primNum(item.amount_unit) | formatThousands}} MC</p>
                      <span v-if="item.rate_amount">$ {{$primNum(item.rate_amount) | formatThousands}}</span>
                    </div>
                    <div class="summ" v-else>
                      <span class="mob-span">{{__('Amount')}}</span>
                      <p>$ {{$primNum(item.amount) | formatThousands}}</p>
                      <span>{{$primNum(item.amount_unit, payment.sprintf) | formatThousands}} {{payment.name_class.toUpperCase()}}</span>
                    </div>
                  </template>
                </Payment>
                <div v-else class="d-flex" style="width: 100%">
                  <div class="date">
                    <span class="mob-span">{{__('Date')}}</span>
                    <p>{{item.created_at | formatDate}}</p>
                  </div>
                  <div class="type">
                    <span class="mob-span">{{__('Type')}}</span>
                    <p>
                      <span :class="item.income== 1 ? 'plus': 'minus'" v-if="item.opp_type== 1">{{__('Deposit')}}</span>
                      <span :class="item.income== 1 ? 'plus': 'minus'" v-else-if="item.opp_type== 2">{{__('Withdraw')}}</span>
                      <span :class="item.income== 1 ? 'plus': 'minus'" v-else-if="item.opp_type== 3">{{__('Start farming')}}</span>
                      <span :class="item.income== 1 ? 'plus': 'minus'" v-else-if="item.opp_type== 4">{{__('Profit')}}</span>
                      <span :class="item.income== 1 ? 'plus': 'minus'" v-else-if="item.opp_type== 5">{{__('Referral')}}</span>
                      <span :class="item.income== 1 ? 'plus': 'minus'" v-else-if="item.opp_type== 6">{{__('Exchange token')}}</span>
                      <span :class="item.income== 1 ? 'plus': 'minus'" v-else-if="item.opp_type== 7">{{__('Closed')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 8">{{__('Withdraw MC')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 9">{{__((item.income== 1) ? 'Sell product' : 'Buy product')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 10">{{__('Transfer to p2p')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 11">{{__('Bonus')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 12">{{__('Promo')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 13">{{__('Staking')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 14">{{__('Token MW')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 15">{{__('Referral staking')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 16">{{__('Invest fund')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 17">{{__('Profit fund')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 18">{{__('In Payment Account')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 21">{{__('Refund invest')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 22">{{__('Breakeven')}}</span>
                      <span :class="item.income== 1 ? 'plus' : 'minus'" v-else-if="item.opp_type== 23">{{__('Part breakeven')}}</span>
                    </p>
                  </div>
                  <div class="payment">
                    <span class="mob-span">{{__('Payment system')}}</span>
                    <p class="usd" v-if="item.wallet== 1 || item.wallet== 3 || item.wallet== 4"><img src="/newfrontend/images/usd.svg" alt=""> USDT</p>
                    <p v-else class="ms">
                      <img src="/newfrontend/images/total-ms.svg" alt=""> MediaCoin
                    </p>
                  </div>
                  <div class="status" v-if="item.opp_type== 2 || item.opp_type== 8 || item.opp_type== 9">
                    <span class="mob-span">{{__('Status')}}</span>
<!--                    <span class="wait" v-if="item.status== '2' || item.status== '3' || item.status== '5'">-->
<!--                      <v-btn class=" btn-site" v-on:click="modal_cancel= item">{{__('Cancel')}}</v-btn>-->
<!--                    </span>-->
                    <span class="complete" v-if="item.status== 1" >{{__('Сomplete')}}</span>
                    <span class="wait" v-else-if="item.status== 2">{{__('Wait')}}</span>
                    <span class="wait" v-else-if="item.status== 3">{{__('Wait')}}</span>
                    <span class="cancel" v-else-if="item.status== 4">{{__('Сancel')}}</span>
                    <span class="cancel" v-else-if="item.status== 5">{{__('Wait')}}</span>
                    <span class="cancel" v-else-if="item.status== 6">{{__('Error')}}</span>
                  </div>
                  <div class="status" v-else>
                    <span class="mob-span">{{__('Status')}}</span>
                    <span class="complete" v-if="item.status== 1">{{__('Сomplete')}}</span>
                    <span class="cancel" v-else-if="item.status== 2">{{__('Error')}}</span>
                    <span class="wait" v-else-if="item.status== 3">{{__('Wait')}}</span>
                    <span class="wait" v-else-if="item.status== 4">{{__('Confirmation')}}</span>
                    <span class="cancel" v-else-if="item.status== 5">{{__('Сancel')}}</span>
                    <span class="cancel" v-else-if="item.status== 6">{{__('End time')}}</span>
                    <span class="wait" v-else-if="item.status== 7">{{__('Wait')}}</span>
                  </div>
                  <div class="details">
                    <span class="mob-span">{{__('Details')}}</span>
                    <a v-if="[6, 12, 14].includes(item.opp_type)">-</a>
                    <a v-else-if="[3,4,7].includes(item.opp_type)">{{item.plan_user_id}}</a>
                    <a v-else-if="[8].includes(item.opp_type)">
                      <a target="_blank" v-if="item.tx_system" :href="$txLink['bnb_bep20']+item.tx_system">{{item.tx_system}}</a>
                      <a target="_blank" v-else-if="item.tx" :href="$paymentLink['bnb_bep20']+item.tx">{{item.tx}}</a>
                    </a>
                    <a v-else-if="[11].includes(item.opp_type)" >{{item.tx}}</a>
                    <a v-else-if="[13, 15, 16,17,18,21,22,23].includes(item.opp_type)" >{{item.plan_user_id}}</a>
                    <a v-else-if="[9].includes(item.opp_type)" target="_blank" :href="`https://market.mediacoin.inc/product/`+item.tx">{{item.tx}}</a>
                    <a v-else-if="item.transfer">{{item.transfer.public_id}}</a>
                    <a v-else>{{item.transfer_user_id}}</a>
                  </div>
                  <div class="summ" v-if="item.wallet== 1 || item.wallet== 3">
                    <span class="mob-span">{{__('Amount')}}</span>
                    <p>{{$primNum(item.amount) | formatThousands}} USDT</p>
                  </div>
                  <div class="summ" v-else-if="[6, 11, 12, 13, 14, 15].includes(item.opp_type)">
                    <span class="mob-span">{{__('Amount')}}</span>
                    <p>{{$primNum(item.amount) | formatThousands}} MC</p>
                  </div>
                  <div class="summ" v-else-if="[16, 17, 18, 21, 22, 23].includes(item.opp_type)">
                    <span class="mob-span">{{__('Amount')}}</span>
                    <p>{{$primNum(item.amount) | formatThousands}} USDT</p>
                  </div>
                  <div class="summ" v-else-if="item.opp_type== 5">
                    <span class="mob-span">{{__('Amount')}}</span>
                    <p>$ {{$primNum(item.amount_unit) | formatThousands}}</p>
                  </div>
                  <div class="summ" v-else>
                    <span class="mob-span">{{__('Amount')}}</span>
                    <p>{{$primNum(item.amount_unit) | formatThousands}} MC</p>
                    <span v-if="item.rate_amount">$ {{$primNum(item.rate_amount) | formatThousands}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </PaginationDefault>
      </div>

      <ModalDefault class="delete-window" width="400" v-if="modal_cancel" v-bind:modal_cancel.sync="modal_cancel">
        <div class="text-center window-site cancel-withdrawal-window">
          <div>
            <h2>{{__('Cancel withdrawal')}}</h2>
          </div>
          <div class="text-center text-window">
            <p>{{__('Are you sure you want to cancel the operation?')}}</p>
            <div class="btn-window">
              <span class="btn-white" v-on:click="modal_cancel= false">{{__('Close')}}</span>
              <v-btn :loading="submitDisabled" class="btn-site" v-on:click="submitCancel(modal_cancel)">{{__('Ok')}}</v-btn>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
</template>

<script>
import ModalDefault from '../../modules/ModalDefault';
import Payment from "../../modules/Payment";
import PaginationDefault from "../../modules/PaginationDefault";
export default {
  data () {
    return {
        modal_cancel: false,
        submitDisabled: false
    }
  },
  methods:{
      updateData(response) {
          this.$emit('updateData', response);
      },
      submitCancel(item) {
          this.submitDisabled= true;
          let response= {
              'id': item.id
          };
          this.params= response;
          this.$http.post('ajax/withdraw_cancel', response)
          .then(response=> {
              return response.json()
          })
            .then((response) => {
                this.$store.dispatch('asyncResp', response.resp_tickets);
              let transactions= this.$cleanObject(this.transactions);
              let index= this.$filterIndexObject(transactions.data, item.id);
              transactions.data[index].status= 4;
              this.modal_cancel= false;
              this.$emit('update:transactions', transactions);
              this.submitDisabled= false;
          }).catch(() => {
              this.submitDisabled= false;
          });
      },
  },
  props: {
      transactions: {
          type: Object
      },
      params: {
          type: Object
      }
  },
  computed: {

  },
  components: {
      Payment,
      ModalDefault,
      PaginationDefault,
  }
}
</script>

<style scoped>

</style>
