<template>
  <div v-if="dialog" class="">
    <v-row justify="center">
      <v-dialog
        v-model="dialog" persistent max-width="430"
        v-on:click:outside="$emit('update:modal_delete', null)"
      >
        <div class="white delete-window" style="position: relative">
          <div class="text-center">
            <h2>{{__('Deleting a wallet')}}</h2>
            <span class="close" v-on:click="$emit('update:modal_delete', null)"></span>
          </div>
          <div class="text-center text-window">
            <p>{{__('Enter the code to confirm')}}</p>
            <div class="password">
              <div class="pin">
                <PincodeInput
                    v-model="code"
                    @change="updateValidate('code')"
                    placeholder="0"
                />
              </div>
              <div>
                <p class="red--text" v-if="formValidate.code">{{formValidate.code[0]}}</p>
              </div>
            </div>
            <div class="btn-window">
              <v-btn v-on:click="onSubmit" :loading="submitDisabled" class="btn-waiting-ok btn-site">{{__('Ok')}}</v-btn>
              <span class="btn-waiting-cancel btn-grey" v-on:click="$emit('update:modal_delete', null)">{{__('Cancel')}}</span>
            </div>
          </div>
        </div>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
    // import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import PincodeInput from 'vue-pincode-input';
    import {required, numeric, minLength} from "vuelidate/lib/validators";

    export default {

        name: 'ModalPortfolio',
        data: function() {
            return {
                dialog: true,
                submitDisabled: false,
                time: false,
                code: '',
                formValidate: {
                    code: null,
                },
            }
        },
        props: {
            modal_delete: Object,
            wallets: Array
        },
        computed: {

        },
        validations() {
            return {
                code: {
                    required,
                    minLenght: minLength(4),
                    numeric
                },
            }
        },
        created() {

        },
        methods: {
            updateValidate: function(type) {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit: function () {
                let valid= this.$v;
                if(valid.$invalid) {
                    let massages= {
                        'code': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;

                    let response = {
                        'payment_id': this.modal_delete.payment_id,
                        'pin': this.code,
                    };
                    this.$http.post('ajax/setting/delete', response)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            this.$store.dispatch('asyncResp', response.resp_tickets);
                            this.submitDisabled= false;
                            let wallets= this.$cleanObject(this.wallets);
                            let index= this.$filterIndexObject(wallets, this.modal_delete.payment_id, 'payment_id');
                            let wallet= wallets[index];

                            wallet.user_wallet= null;
                            wallet.tag= (wallet.tag=== false) ? false : null;
                            wallet.send_code= '';

                            wallets[index]= wallet;

                            this.$emit('update:modal_delete', null);
                            this.$emit('updateWallet', wallets);
                        }).catch((err) => {
                            this.submitDisabled= false;
                            this.httpHandler(err);
                        });
                }
            }
        },
        components: {
            // InputLabelTextDefault,
            PincodeInput
        },
    };
</script>
<style>
    .delete-window{

    }
    .btn-window{
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
    .text-window{
        margin-top: 20px;
    }
    .btn-window span{
    }
</style>