<template>
  <div class="support">
    <div class="content all-page">
      <div class="top-block">
        <img src="/newfrontend/images/support.png" alt="">
        <p>{{__('Technical support')}}</p>
      </div>
      <div class="support-text">
        <div class="left">
          <div class="body-text">
            <p>{{__('Using the ticket system, you can expect to receive a professional answer to a question of any complexity and in the shortest possible time by mail.')}}</p>
            <p>{{__('Support response time from 2-48 hours')}}</p>
          </div>
        </div>
        <div class="right">
          <FormSupport></FormSupport>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FormSupport from "./Support/FormSupport";
    export default {
        name: 'Support',
        data: function() {
            return {
            }
        },
        watch: {

        },
        created() {

        },
        methods: {

        },
        components: {
          FormSupport
        }
    }
</script>

<style scoped>

</style>
