<template>
    <div class="home-page home-new">
      <div class="header">
        <HomeSvg class="home-canvas"></HomeSvg>
        <img class="img-1 img-bg-1" src="/newfrontend/images/logo-1-center.png" alt="">
        <div class="bg-header">

          <img class="img-2 " src="/newfrontend/images/logo-1-1-left.png" alt="">
          <img class="img-3 " src="/newfrontend/images/logo-1-2-left.png" alt="">
          <img class="img-4 " src="/newfrontend/images/logo-1-right.png" alt="">
<!--          <div class="logo-block" >-->
<!--            <div class="logo-block-1"></div>-->
<!--            <div class="logo-block-2" ></div>-->
<!--            <div class="logo-block-3" ></div>-->
<!--          </div>-->
        </div>
        <div class="container">
          <div class="left">
            <div class="left-text">
              <p class="text-r">Welcome to</p>
              <h1>Connecting and "Contented" <span>Economy</span></h1>
              <p class="text-m">Mediachain is a huge multi-platform specially created for content creators, social media influencers and their communities to monetize earnings in the crypto-virtual and real world.</p>
              <v-btn class=" btn-new" :to="{name: 'dashboard'}">connect to mediachain </v-btn>
            </div>

            <div v-if="home" class="left-stat">
              <div>
                <img src="/newfrontend/images/total.svg" alt="">
                <div>
                  <p class="semi ttu">{{Number($primNum(home.total_coin, 0)).toLocaleString('en')}} <span>MC</span></p>
                  <p class="stat-text ">{{__('everything will be mined')}}</p>
                </div>
              </div>
              <div>
                <img src="/newfrontend/images/farm-img.svg" alt="">
                <div>
                  <p class="semi ttu">{{Number($primNum(home.farming, 0)).toLocaleString('en')}} <span>MC</span></p>
                  <p class="stat-text">{{__('total mined')}}</p>
                </div>
              </div>
              <div>
                <img src="/newfrontend/images/total-home-1.svg" alt="">
                <div>
                  <p class="semi ttu">{{Number($primNum(home.burned, 0)).toLocaleString('en')}} <span>MC</span></p>
                  <p class="stat-text">{{__('total burned')}}</p>
                </div>
              </div>
            </div>
            <div v-else class="loading">
              <loaderMc></loaderMc>
              {{__('loading')}}
            </div>
          </div>
        </div>
      </div>
<!--      <div class="stats">-->
<!--        <div class="container">-->
<!--            <div class="left">-->
<!--              <ul>-->
<!--                <li class="block">-->
<!--                  <div class="lefts">-->
<!--                    <img src="/newfrontend/images/logo-store.svg" alt="">-->
<!--                  </div>-->
<!--                  <div class="rights">-->
<!--                    <a href="https://market.mediachain.inc/" target="_blank"><img src="/newfrontend/images/img-link.svg" alt=""></a>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="block">-->
<!--                  <div class="lefts">-->
<!--                    <img src="/newfrontend/images/logo-n-n.svg" alt="">-->
<!--                  </div>-->
<!--                  <div class="rights">-->
<!--                    <a href="https://nft.mediachain.inc/" target="_blank"><img src="/newfrontend/images/img-link.svg" alt=""></a>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="block-last">-->
<!--                  <div class="lefts">-->
<!--                    &lt;!&ndash;                <img src="/newfrontend/images/link-right.svg" alt="">&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div class="rights">-->
<!--                    <p class="block-hint">-->
<!--                      <img src="/newfrontend/images/block-hint.svg" alt="">-->
<!--                      <span>{{__('To go to the product or NFT pages, you can click on this button')}}</span>-->
<!--                    </p>-->
<!--                  </div>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--            <div class="right">-->
<!--              <div class="block">-->
<!--                <div class="right-text">-->
<!--                  <h2>Mediacoin price</h2>-->
<!--                  <div>-->
<!--                    <p class="semi ttu">{{__('1 MC equals')}}</p>-->
<!--                    <p class="stat-text">{{$primNum(home.token_price) | formatThousands}}<span>USD</span></p>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <p class="semi ttu">{{__('% growth from start')}}</p>-->
<!--                    <p class="percent">+{{$primNum(home.mc_percent) | formatThousands}}<span>%</span></p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="right-chart">-->
<!--                  <Chart height="240" :series="seriesMC" color="#00C868" :nav="true"></Chart>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="video-block">
        <div class="container">
          <div class="left">
            <div class="left-top">
              <p class="text-logo">about</p>
              <img class="logo" src="/newfrontend/images/logo.svg" alt="">
            </div>
            <div class="block">
              <p class="description">Unifying media platform</p>
              <p class="text">We strive to create an ecosystem that will unite the crypto-virtual and real world. A system that will change the scenario of the current business model for online content, making it more efficient, cost-effective and fair for both media content representatives and consumers of media content.</p>
              <p class="text">Our goal is to create the first true “contented” economy, an ecosystem where everyone is rewarded for their positive contributions to the network.</p>
            </div>
          </div>
          <div class="right">
            <div class="play" v-on:click="video= 'T7eiguNJTN8'">

                <video class="video-top" playsinline width="100%" height="100%" loop="loop" muted="muted" autoplay="autoplay">
                  <source src="/newfrontend/video/animation.mp4">
               </video>
              <div class="img">
                <div class="loader">
                  <div class="circle"></div>
                  <div class="circle"></div>
                  <div class="circle"></div>
                </div>
                <img class="img" src="/newfrontend/images/1.png" alt="">
              </div>
              <p><img src="/newfrontend/images/img-video.svg" alt=""> <span>{{__('watch video about us')}}</span></p>
            </div>
            <p class="text-right">your content / your freedom / your power</p>
          </div>
        </div>
      </div>
      <div>
        <div class="home-news" v-if="home && home.media">
          <div class="container">
            <ul>
              <li v-if="home.media.video">
                <div class="top-news">
                  <h2>{{__('Video')}}</h2>
                  <router-link :to="{name: 'media', params: {category: 'video'}}">
                    <span>{{__('All videos')}}</span>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z" />
                    </svg>
                  </router-link>
                </div>
                <div class="li">
                  <div class="block-video" v-for="(item, key) in home.media.video" :key="key">
                    <router-link :style='{backgroundImage: "url("+item.img+")"}' :to="{name: 'media_page', params: {url: item.url, category: item.category}}" class="video" >
                      <img class="play" src="/newfrontend/images/media-play.svg" alt="" v-if="item.category== 'video'">
                    </router-link>
                    <div class="body-news">
                      <p class="date">{{item.created_at | moment('MMM DD, YYYY')}}</p>
                      <p class="title">
                        <router-link :to="{name: 'media', params: {category: 'video'}}">
                          {{item.title}}
                        </router-link>
                      </p>
                      <v-btn :to="{name: 'media_page', params: {url: item.url, category: item.category}}">
                        {{__('Read more')}}
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z" />
                        </svg>
                      </v-btn>
                    </div>
                  </div>
                </div>


                <!--              <div class="play" v-on:click="video= home.media.video.url">{{__('Video modal')}}</div>-->

              </li>
              <li v-if="home.media.news.length">
                <div class="top-news">
                  <h2>{{__('News')}}</h2>
                  <router-link :to="{name: 'media', params: {category: 'news'}}">
                    <span>{{__('All news')}}</span>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z" />
                    </svg>
                  </router-link>
                </div>
                <div >
                  <div class="body-news" v-for="(item, key) in home.media.news" :key="key">
                    <p class="date">{{item.created_at | moment('MMM DD, YYYY')}}</p>
                    <p class="title">
                      <router-link :to="{name: 'media_page', params: {url: item.url, category: item.category}}">
                        {{item.title}}
                      </router-link>
                    </p>
                    <router-link :to="{name: 'media_page', params: {url: item.url, category: item.category}}">
                      {{__('Read more')}}
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z"/>
                      </svg>
                    </router-link>
                  </div>
                </div>
              </li>
<!--              <li v-if="home.media.blog.length">-->
<!--                <div class="top-news">-->
<!--                  <h2>{{__('Blog')}}</h2>-->
<!--                  <router-link  :to="{name: 'media', params: {category: 'blog'}}">-->
<!--                    <span>{{__('All blogs')}}</span>-->
<!--                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z" />-->
<!--                    </svg>-->
<!--                  </router-link>-->
<!--                </div>-->
<!--                <div >-->
<!--                  <div class="body-news" v-for="(item, key) in home.media.blog" :key="key">-->
<!--                    <p class="date">{{item.created_at | moment('MMM DD, YYYY')}}</p>-->
<!--                    <p class="title">-->
<!--                      <router-link :to="{name: 'media_page', params: {url: item.url, category: item.category}}">-->
<!--                        {{item.title}}-->
<!--                      </router-link>-->
<!--                    </p>-->
<!--                    <router-link :to="{name: 'media_page', params: {url: item.url, category: item.category}}">-->
<!--                      {{__('Read more')}}-->
<!--                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z" />-->
<!--                      </svg>-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </li>-->
            </ul>
          </div>
        </div>
      </div>
      <div class="tokenomics">
        <div class="container">
          <div class="content">
            <h2>{{__('Tokenomics MEDIACOIN')}}</h2>
            <VueSlickCarousel v-bind="settings" >
              <div class="block">
                  <div class="img">
                    <img src="/newfrontend/images/slide1.png" alt="">
                  </div>
              </div>
              <div class="block">
                <div class="img">
                  <img src="/newfrontend/images/slide2.png" alt="">
                </div>
              </div>
              <template #prevArrow>
                <div class="custom-arrow">
                  <svg width="7" height="12" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.03125 5.53125C7.3125 5.25 7.3125 4.78125 7.03125 4.5L2.78125 0.25C2.46875 -0.0625003 2 -0.0625003 1.71875 0.25L1 0.9375C0.71875 1.25 0.71875 1.71875 1 2L4.03125 5.03125L1 8.03125C0.71875 8.3125 0.71875 8.78125 1 9.09375L1.71875 9.78125C2 10.0937 2.46875 10.0937 2.78125 9.78125L7.03125 5.53125Z"/>
                  </svg>
                </div>
              </template>
              <template #nextArrow>
                <div class="custom-arrow">
                  <svg width="7" height="12" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.03125 5.53125C7.3125 5.25 7.3125 4.78125 7.03125 4.5L2.78125 0.25C2.46875 -0.0625003 2 -0.0625003 1.71875 0.25L1 0.9375C0.71875 1.25 0.71875 1.71875 1 2L4.03125 5.03125L1 8.03125C0.71875 8.3125 0.71875 8.78125 1 9.09375L1.71875 9.78125C2 10.0937 2.46875 10.0937 2.78125 9.78125L7.03125 5.53125Z"/>
                  </svg>
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
      <div class="contact-home">
        <div class="container">
          <div class="container-pe-quote left-quote">
            <div class="pp-quote li-quote-1 " >
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-2" >
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-3" >
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-4" >
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-5" >
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-6" >
              <div class="img animated bounce"></div>
            </div>
          </div>

          <div class="content">
            <div class="support-text">
              <div class="left">
                <p class="text-r">{{__('keep in touch')}}</p>
                <h1>{{__('Contact Us1')}}</h1>
                <p class="text">{{__('Please send all feedback, questions, or requests for more information to')}} </p>
                <a>{{__('info@mediacoin.com')}}</a>
              </div>
              <div class="center">
                <p>or</p>
              </div>
              <div class="right">
                <FormContact></FormContact>
              </div>
            </div>
          </div>

          <div class="container-pe-quote right-quote">
            <div class="pp-quote li-quote-7">
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-8">
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-9">
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-10">
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-11">
              <div class="img animated bounce"></div>
            </div>
            <div class="pp-quote li-quote-13">
              <div class="img animated bounce"></div>
            </div>
          </div>
        </div>
      </div>

      <ModalVideo v-if="video" v-bind:video.sync="video" ></ModalVideo>

    </div>
</template>

<script>
  import loaderMc from "../SvgComponents/loaderMc";
  import HomeSvg from "../SvgComponents/HomeSvg";
  import FormContact from "../modules/FormContact";
import ModalVideo from "../modules/ModalVideo";
  // import Chart from "../modules/Chart";
  import VueSlickCarousel from 'vue-slick-carousel'
export default {
  name: 'homeNew',
    data: function() {
      return {
        seriesMC: [],
          video: false,
          home: null,
        settings: {
          "dots": false,
          "edgeFriction": 0.35,
          "infinite": true,
          "speed": 500,
          "slidesToShow": 1,
          "slidesToScroll": 1
        },
      }
  },
  watch: {
      lang() {
        this.getCreate();
    }
  },
  created() {
      this.getCreate();

      // this.$http.get('ajax/payments')
      //     .then(response => {
      //         return response.json()
      //     })
      //     .then(response => {
      //         response= response.data;
      //         let payments= response.payment;
      //         this.info= response.info;
      //         this.payments= payments;
      //
      //         this.payment= this.$filterArray(payments, 3, 'payment_id');
      //     }).catch((err) => {
      //         this.httpHandler(err);
      //     });

  },
  methods:{
      getCreate() {
          this.$http.get('ajax/home/'+this.lang)
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  this.home= response.data;


                  this.seriesMC.push({
                      name: '',
                      data: this.home.charts_course
                  });
              }).catch((err) => {
              this.httpHandler(err);
          });
      }
  },
  computed: {
      portfolio() {
          return this.$store.state.auth.portfolio
      },
      lang() {
          return this.$store.state.language.lang
      }
  },
  mounted() {

  },
  components: {
    // HomeSvg,
    // Chart,
    FormContact,
      ModalVideo,
    HomeSvg,
    VueSlickCarousel,
    loaderMc

  }
}
</script>

<style>


</style>