<template>
    <div class="tg-bot">
        <div class="tg-bot-top">
          <h2>{{__('Telegram BOT')}}</h2>
          <p class="text">{{__('Protect your profile: change your login password in a timely manner and set up two-factor authentication')}}</p>
          <div class="top">
            <div>
              <img src="/newfrontend/images/tg-img.svg" alt="">
              <div>
                <p>{{__('Install Telegram BOT')}}</p>
                <span>{{__('Allows you to receive automatic status messages...')}}</span>
              </div>
            </div>
            <p v-on:click="bot= true" v-if="portfolio.telegram" class="btn-white-new">{{__('Deactivate telegram bot')}}</p>
            <p v-on:click="bot= true" v-else class="btn-white-new">{{__('Activate telegram bot')}}</p>
          </div>
        </div>

        <ModalDefault width="470" v-if="bot" v-bind:modal_cancel.sync="bot">
          <div class="window-bot">
            <div v-if="portfolio.telegram">
              <h3>{{__('Confirm deactivation of telegram bot')}}</h3>
              <p class="text-d">{{__('Are you sure you want to deactivate the telegram bot?')}}</p>
            </div>
            <div v-else>
              <div class="top-block">
                <h3>{{__('Activate Telegram BOT')}}</h3>
                <div class="step">
                  <span>{{__('Step 1')}}</span>
                  <p class="description">{{__('Scan QR code using your smartphone')}}</p>
                </div>
                <div class="qr">
                  <VueQrcode value="https://t.me/media_coin_bot" width="190" />
                  <p class="text-qr">{{__('or follow the link')}}</p>
                  <a href="https://t.me/media_coin_bot" target="_blank">
                    {{__('https://t.me/media_coin_bot')}}
                  </a>
                </div>
                <div class="step step-1">
                  <span>{{__('Step 2')}}</span>
                  <p class="description"> {{__('Click the start button in the chat with the bot, after which the bot will send you an activation code')}}</p>
                </div>
                <div class="step">
                  <span>{{__('Step 3')}}</span>
                  <p class="description">{{__('Enter the code received from the bot in the text below')}}</p>
                </div>
              </div>
              <div class="input-bot">
                <InputLabelTextDefault :placeholder="__('Enter the chat ID received from BOT')" :disabled="(portfolio.telegram) ? true : false" v-model="chat_id" ></InputLabelTextDefault>
              </div>
            </div>
            <div class="qr-bot">
              <span class="btn-white" v-on:click="bot=false">{{__('cancel')}}</span>
              <v-btn class="btn-white" v-if="portfolio.telegram" v-on:click="onSubmit" :loading="submitDisabled">{{__('Deactivate bot')}}</v-btn>
              <v-btn class="btn-site" v-else  v-on:click="onSubmit" :loading="submitDisabled">{{__('activate telegram bot')}}</v-btn>
            </div>
          </div>
        </ModalDefault>
    </div>
</template>

<script>

import VueQrcode from '@chenfengyuan/vue-qrcode';
import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
import {required, numeric, minLength,maxLength} from "vuelidate/lib/validators";
import ModalDefault from "@/components/modules/ModalDefault";
export default {
  data () {
      return {
          bot: false,
          chat_id: null,
          formValidate: {
              chat_id: null
          },
          submitDisabled: false
      }
  },
  props: {

  },
  watch: {

  },
  validations() {
      return {
          chat_id: {
              required,
            numeric,
            minLength: minLength(5),
            maxLength: maxLength(10),
          },
      }
  },
  methods: {
      updateValidate: function(type) {
          this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
      },
      onSubmit() {
          let valid= this.$v;
          if(valid.$invalid) {
              let massages= {
                  'chat_id': [],
              };
              this.formValidate= this.$formValidate(massages, valid);
          } else {
              this.submitDisabled = true;

              let response = {
                  chat_id: this.chat_id,
              };

              this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/setting/telegram', response)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      this.submitDisabled = false;
                      this.chat_id= (response.data) ? response.data : null;
                      this.bot= false;
                      let portfolio= this.$cleanObject(this.portfolio);
                      portfolio.telegram= this.chat_id;

                      this.$store.dispatch('asyncPortfolio', portfolio);
                  }).catch((err) => {
                  this.submitDisabled= false;
                  let response= this.httpHandler(err);

                  if (response.error) {
                      this.formValidate = response.error.message;
                  }
              });
          }
      }
  },
  asyncComputed: {
      asyncPortfolio() {
          if(this.portfolio && !this.chat_id) {
              this.chat_id= this.portfolio.telegram;
          }
          return true;
      }
  },
  computed: {
      portfolio() {
          return this.$store.state.auth.portfolio
      },
  },
  components: {
    VueQrcode,
    ModalDefault,
    InputLabelTextDefault
  }
}
</script>

<style scoped>

</style>
