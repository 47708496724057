<template>
  <component :is="tag">
    <slot v-bind="payment"></slot>
  </component>
</template>

<script>
    export default {
        name: 'Payment',

        data: function() {
            return {

            }
        },
        props: {
            payment_id: {
                type: [
                    Number, String
                ]
            },
            tag: {
                type: [
                    String
                ],
                default: 'div'
            }
        },
        computed: {
            payment() {
                return this.$payment(this.payment_id);
            }
        }
    }
</script>

<!--&lt;!&ndash; Add "scoped" attribute to limit CSS to this component only &ndash;&gt;-->
<style scoped>
</style>
