<template>
    <div class="window-dep-step">
      <div class="top">
        <h1>{{__('Deposit Payment')}}</h1>
<!--          <p>{{__('Waiting for your payment')}}...</p>-->
        <span v-if="waitingTime"><img src="/newfrontend/images/timer.svg" alt=""> {{waitingTime}}</span>
      </div>
      <Payment v-bind:payment_id="deposit.payment_id">
        <template v-slot="payment">
          <div v-if="step== 1">
            <div>
              <p class="semi">{{__('Choose a payment method1')}}</p>
            </div>
            <DropdownDefault v-bind:items="payments" disabled text="name" value_key="payment_id" :value="payment.payment_id" class="mb-4" dropSlot>
              <template v-slot:selection="item">
                <span class="img" :class="item.name_class"></span>
                {{item.name}}
              </template>
            </DropdownDefault>
            <div class="dep-amount">
              <p class="semi">{{__('Amount')}}</p>
              <InputLabelTextDefault
                      :value="deposit.amount"
                      disabled
              >
                <template slot="right">
                  <span class="usd">{{payment.currency}}</span>
                </template>
              </InputLabelTextDefault>
            </div>
            <div class="body">
              <div class="qr-block">
                <VueQrcode :value="deposit.address" :options="{ width: 190, margin: 1 }"></VueQrcode>
              </div>
              <div>
                <p class="text">{{payment.name}} {{__('address12')}}</p>
                <p class="addres">{{deposit.address}}</p>
                <v-btn class="btn-white" v-on:click="$copyMessage(deposit.address)">
                  <img src="/newfrontend/images/copy.svg" alt="">{{__('copy address')}}</v-btn>
              </div>
            </div>
            <ul class="deposit-text">
              <li>
                {{__('Transfer exactly the same amount as indicated in your invoice. Otherwise, your deposit will not be credited2')}}
              </li>
              <li>
                {{__('Deposits are available after network confirmations.')}}
              </li>
            </ul>
            <div class="btn-dep-2">
              <v-btn class="btn-white" v-on:click="modalCancel">{{__('Close window')}}</v-btn>
              <v-btn class="btn-site" v-on:click="paidPayment" :loading="submitDisabled">{{__('I have paid')}}</v-btn>
            </div>
          </div>
          <div v-else class="dep-step-3">
            <div class="content">
              <div class="block">
                <div v-if="step== 2">
                  <img src="/newfrontend/images/step-wait.svg" alt="">
                  <p class="text">{{__('Your payment is pending ...')}}</p>
                </div>
                <div v-else>
                  <img src="/newfrontend/images/step-ok.svg" alt="">
                  <p class="text">{{__('Funds are credited to your balance')}}</p>
                </div>
                <p class="text-t">{{__('The payment details are shown below')}}</p>
              </div>
              <ul class="d-flex justify-space-between">
                <li>
                  <p>{{__('Payment method')}}</p>
                  <span>{{payment.name}}</span>
                </li>
                <li>
                  <p>{{__('Date of creation')}}</p>
                  <span>{{deposit.date | formatYMD}}</span>
                </li>
                <li>
                  <p>{{__('Amount')}}</p>
                  <span v-if="step== 2">{{$primNum(deposit.amount, payment.sprintf) | formatThousands}} {{payment.currency.toUpperCase()}}</span>
                  <span v-else>{{$primNum(deposit_info.amount_coin, payment.sprintf) | formatThousands}} {{payment.currency.toUpperCase()}}</span>
                </li>
              </ul>
              <div class="address">
                <p>{{__('Wallet address:')}}</p>
                <span>{{deposit.address}}</span>
              </div>
            </div>
            <div class="btn-dep-2-step">
              <v-btn class="btn-white" v-on:click="modalCancel">{{__('Back to wallet')}}</v-btn>
            </div>
          </div>
        </template>
      </Payment>
    </div>
</template>

<script>
    import moment from "moment";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import DropdownDefault from "../../modules/DropdownDefault";
    import Payment from "../../modules/Payment";
    import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  data () {
    return {
        waitingTime: '-',
        status:  null,
        modal_cancel: false,
        cancelDisabled: false,
        dialog: true,
        intervalCheck: null,
        step: this.deposit.step,
        deposit_info: this.deposit.step,
        submitDisabled: false
    }
  },
  props: {
    deposit: {
        type: Object
    }
  },
  watch: {
      step(val) {
          if(val== 2) {
              this.startInterval();
          } else {
              clearInterval(this.intervalCheck);
          }
      }
  },
  methods:{
      startInterval() {
        this.intervalCheck= setInterval( ()=> {
            this.checkPayment();
        }, 60000);
    },
      modalCancel() {
          this.$emit('update:deposit', null);
      },
      paidPayment() {
          this.submitDisabled = true;

          this.$http.get('ajax/deposit/paid/' + this.deposit.id)
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  this.step= 2;
              }).catch(err => {
                  this.submitDisabled= false;
                  this.httpHandler(err);
              });
      },
      checkPayment() {
          this.submitDisabled= true;

          this.$http.get('ajax/deposit/check/'+this.deposit.id)
              .then(response=> {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  response= response.data;

                  this.submitDisabled= false;
                  let message= response.message;
                  // if(message== 'wait') {
                  //     Vue.notify({
                  //         group: 'message',
                  //         title: this.__('We not see your transaction'),
                  //         type: 'warn'
                  //     });
                  // } else if(message== 'success') {
                  //     Vue.notify({
                  //         group: 'message',
                  //         title: this.__('Transaction success'),
                  //         type: 'success'
                  //     });
                  //     this.$emit('update:deposit', null);
                  // } else if(message== 'confirmation') {
                  //     Vue.notify({
                  //         group: 'message',
                  //         title: this.__('We see your transaction, after confirming the network, the funds will be transferred to your account'),
                  //         type: 'warn'
                  //     });
                  // } else {
                  //     Vue.notify({
                  //         group: 'message',
                  //         title: this.__('Transaction error'),
                  //         type: 'error'
                  //     });
                  // }
                  //
                  // if(response.data.history) {
                  //     this.$emit('updateDeposit', response.data);
                  // }
                  if(message== 'success') {
                      console.log('dsadsasadasdsad');
                      this.step = 3;
                      this.deposit_info= response.deposit_info;
                      this.waitingTime= null;
                  }

                  if(response.user_info) {
                      let portfolio = response.user_info;
                      // if(portfolio.lang) {
                      //     this.$store.dispatch("asyncLanguage", portfolio.lang);
                      // }
                      this.$store.dispatch('asyncPortfolio', portfolio);
                      this.$store.dispatch('asyncPayments', portfolio.payment);
                  }

              }).catch(err => {
                  this.submitDisabled= false;
                  this.httpHandler(err);
              });
      },
      timer() {
          let time = moment.utc().format('X');
          let timeCreated = moment.utc(this.deposit.time).format('X');
          let timestamp = ((timeCreated - time)).toFixed(0);
          return {
              time: this.$toTime(timestamp),
              timestamp: timestamp
          }
      },
      cancelDeposit() {
          this.cancelDisabled= true;

          this.$http.get('ajax/deposit/cancel/'+this.deposit.id)
          .then(response=> {
              return response.json()
          })
          .then((response) => {
              this.$store.dispatch('asyncResp', response.resp_tickets);
              this.cancelDisabled= false;

              this.$emit('update:deposit', null);
          }).catch(err => {
              this.cancelDisabled= false;
              this.httpHandler(err);
          });
      }
  },
  created: function () {
      this.startInterval();

      let timer= this.timer();
      if(timer.timestamp > 0) {
          this.waitingTime = timer.time;
          let interval = setInterval(() => {
              let timer = this.timer();
              if(this.waitingTime=== null) {
                  clearInterval(interval);
              } else if (timer.timestamp > 0) {
                  this.waitingTime = timer.time;
              } else {
                  this.waitingTime = 'Time is over';
                  clearInterval(interval);
              }
          }, 1000);
      } else {
          this.waitingTime = 'Time is over';
      }

  },
  computed: {
      payments() {
          return this.$store.state.auth.payments
      },
  },
  components: {
      DropdownDefault,
      Payment,
      VueQrcode,
      InputLabelTextDefault,
  }
}
</script>

<style scoped>

</style>
