<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }" >
      <v-btn
        class="btn-filter"
        v-bind="attrs"
        v-on="on"
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5 5.83332H2.5V4.16666H17.5V5.83332ZM11.5083 12.5H8.33333V14.1667H10.9083C11.0083 13.5667 11.2167 13.0083 11.5083 12.5ZM15 8.33332H5V9.99999H15V8.33332ZM15 11.6667V14.1667H12.5V15.8333H15V18.3333H16.6667V15.8333H19.1667V14.1667H16.6667V11.6667H15Z"/>
        </svg>
        {{__('Filters')}}
      </v-btn>
    </template>
    <v-card class="filter-site">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{__("Filter")}}</v-list-item-title>
            <v-list-item-subtitle>
              <div>
                <p class="semi">{{__('Payment method')}}</p>
                <DropdownDefault v-bind:items="paymentsList" text="name" value="payment" value_key="payment_id" v-model="form.payment" :disabled="form.wallet=== '2'" dropSlot>
                  <template v-slot:selection="item">
                    <div class="img" :class="(item.name_class) ? item.name_class : ''"></div>
                    {{item.name}}
                  </template>
                  <template v-slot:item="item">
                    <div class="img" :class="(item.name_class) ? item.name_class : ''"></div>
                    <div v-if="item.active">
                      {{item.name}}
                    </div>
                    <div v-else disabled>
                      {{item.name}} <span></span>
                    </div>
                  </template>
                </DropdownDefault>
              </div>
<!--              <div class="coin-block">-->
<!--                <p class="semi">{{__('Coin')}}</p>-->
<!--                <div class="checkbox-site">-->
<!--&lt;!&ndash;                  <div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <v-checkbox v-model="form.wallet" value="1" :label="__('USD')"></v-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                  <div>-->
<!--                    <v-checkbox v-model="form.wallet" value="2" :label="__('MC')"></v-checkbox>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="type-block">
                <p class="semi">{{__('Transaction Type')}}</p>
<!--                <div class=" checkbox-site">-->
<!--                  <div>-->
<!--                    <v-checkbox v-model="form.operation" value="1" :label="__('Deposit')"></v-checkbox>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                    <v-checkbox v-model="form.operation" value="2" :label="__('Withdraw')"></v-checkbox>-->
<!--                  </div>-->
<!--                </div>-->
                <div class=" checkbox-site">
                  <div>
                    <v-checkbox v-model="form.operation" value="1" :label="__('Deposit')"></v-checkbox>
                  </div>
                  <div>
                    <v-checkbox v-model="form.operation" value="2" :label="__('Withdraw')"></v-checkbox>
                  </div>
                  <div>
                    <v-checkbox v-model="form.operation" value="6" :label="__('Exchange')"></v-checkbox>
                  </div>
                  <div>
                    <v-checkbox v-model="form.operation" value="5" :label="__('Referrals')"></v-checkbox>
                  </div>
                </div>
              </div>
              <div class="data-block">
                <p class="semi">{{__('Range date')}}</p>
                <v-menu
                    ref="menu"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="All time"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
<!--                    <v-text-field>-->
<!--                      test-->
<!--                      <v-btn v-on:click="form.dates= []">testasdasd</v-btn>-->
<!--                    </v-text-field>-->
                  </template>
                  <v-date-picker
                    v-model="dates"
                    no-title
                    scrollable
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menuDate = false"
                    >
                      {{__('Cancel')}}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="updateDate(dates)"
                    >
                      {{__('OK')}}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div>
                <v-btn class="btn-site" v-on:click="onFilter">{{__('Apply filter')}}</v-btn>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
    import DropdownDefault from './DropdownDefault';

    export default {

        name: 'FilterDefault',
        data: function() {
            return {
                form: this.$cleanObject(this.params),
                menuDate: false,
                menu: false,
                dates: [],
            }
        },
        props: {
            params: {
                type: [Object]
            }
        },
        computed: {
            payments() {
                return this.$store.state.auth.payments
            },
            paymentsList() {
                let payments= [{
                    name: 'All',
                    payment_id: null
                }];

                return payments.concat(this.payments);
            },
            dateRangeText () {
                if(this.dates) {
                    return this.dates.join(' - ');
                } else {
                    return null;
                }
            },
        },
        watch: {
            params(val) {
                let params= this.$cleanObject(val);
                let date= [];

                if(params['from']) {
                    date.push(params['from']);
                }
                if(params['to'] && params['to'] != params['from']) {
                    date.push(params['to']);
                }
                this.dates= date;
                this.form= params;
            }
        },
        methods: {
            onFilter() {
                this.menu= false;
                let form= this.$cleanObject(this.form);
                let props= {};


                for (let key in form) {
                    if(key!='payment' || key=='payment' && this.form.wallet!= 2) {
                        let prop= form[key];

                        if(prop) {
                            props[key]= prop;
                        }
                    }
                }

                if(this.dates) {
                    let dates= this.dates;
                    if(dates.length) {
                        props.from= dates[0];
                        if(dates[1] && dates[0] != dates[1]) {
                            props.to= dates[1];
                        }
                    }
                }

                this.$emit('onFilter', props);
            },
            updateDate(dates) {
                let to= dates[0];
                let from= dates[1];


                let response= [];

                if(to > from) {
                    [from, to]= [to, from];
                }

                if(from) {
                    response.push(from);
                    if(to && to!= from) {
                        response.push(to);
                    }
                }

                this.dates= response;

                this.$refs.menu.save(dates);

            },
        },
        components: {
            DropdownDefault
        },
    };
</script>
