<template>
    <div v-if="eventnews" class="event-page">
        <div class="content">
            <div class="top">
                <v-btn class="btn-media"  :to="{name: 'events'}">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z" />
                    </svg>
                    {{__('Back to events')}}
                </v-btn>
            </div>
            <div class="center">
                <h1>{{eventnews.title}}</h1>
                <div class="">
                    <iframe v-if="eventnews.video_url" ref="video" width="100%" height="315" :src="'https://www.youtube.com/embed/'+eventnews.video_url+'?rel=0&showinfo=0&autoplay=1'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div class="img" v-else>
                        <img :src="eventnews.img"/>
                    </div>
                </div>
                <div class="text" v-html="eventnews.text"></div>
            </div>
        </div>
    </div>
    <div v-else class="loading">
        <loaderMc></loaderMc>
        {{__('loading')}}
    </div>
</template>

<script>
    import loaderMc from "../SvgComponents/loaderMc";
    export default {
        name: 'MediaPage',
        data: function() {
            return {

                eventnews: null
            }
        },
        methods:{
            handleSCroll () {
                let header = document.querySelector(".btn-media");
                // console.log(window.scrollY);
                // console.log(document.querySelector('.v-main').offsetHeight);
                // console.log(document.querySelector('.v-main').offsetHeight- (window.outerHeight -50));
                if (window.scrollY > document.querySelector('.v-main').offsetHeight- (window.outerHeight -50)) {
                    header.classList.add("is-visible");
                }
                else {
                    header.classList.remove("is-visible");
                }
            },
            getCreate(params) {
                this.$http.get(`ajax/eventnews/${this.lang}/${params.url}`, this.params)
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        this.$store.dispatch('asyncResp', response.resp_tickets);
                        response = response.data;
                        this.eventnews = response;
                    }).catch((err) => {
                    this.httpHandler(err);
                });
            }
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.getCreate(to.params);
            return next();
        },
        watch: {
            lang() {
                this.getCreate(this.$route.params);
            }
        },
        created() {
            window.addEventListener('scroll', this.handleSCroll);
            this.getCreate(this.$route.params);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleSCroll);
        },
        computed: {
            lang() {
                return this.$store.state.language.lang
            }
        },
        mounted() {

        },
        components: {
            loaderMc,

        }
    }
</script>