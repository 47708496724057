<template>
    <div class="marketing">
        <div class="marketing-new">
            <div class="bg-marketing"></div>
            <div class="container">
                <div class="top-page">
                        <v-tabs  v-model="activeTabD" >
                            <v-tab @click="goToAnchorD('#marketing')" >{{__('Marketing')}}</v-tab>
                            <v-tab @click="goToAnchorD('#conditions')" >{{__('Условия достижения бонусов за ранги')}}</v-tab>
                            <v-tab @click="goToAnchorD('#bonus')" >{{__('Бонусная программа')}}</v-tab>
                            <v-tab @click="goToAnchorD('#example')" >{{__('Пример работы партнерской пргограммы')}}</v-tab>
                        </v-tabs>
                </div>
                <div class="marketing-page">
                    <div class="anchor-itemD" id="marketing"></div>
                        <div class="head-m">
                            <h1>{{__('Маркетинг Mediacoin')}}</h1>
                        </div>
                        <div class="line-m">
                            <img class="img-l" src="/newfrontend/images/line-m-new.svg" alt="">
                            <img class="img-r" src="/newfrontend/images/line-m-new.svg" alt="">
                            <ul class="rank-6">
                                <li>
                                    <div class="left">
                                        <p class="summ">$1,000,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">45 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-24.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Платиновый акционер')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ {{__('специальный бонус')}}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">$750,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">44 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-23.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Золотой акционер')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ {{__('специальный бонус')}}</p>
                                    </div>
                                </li>

                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $500,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">42-43 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-22.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Бронзовый акционер')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ {{__('специальный бонус')}}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $300,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">40-41 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-21.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Акционер')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ {{__('специальный бонус')}} <br> <span class="spec">{{__('после достижения платинового бронзового директора')}}</span></p>
                                    </div>
                                </li>
                            </ul>

                            <ul class="rank-5">
                                <li>
                                    <div class="left top">
                                        <p class="summ">$150,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">39 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-20.png" alt="">
                                    </div>
                                    <div class="right top">
                                        <p class="text-b">{{__('Платиновый директор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ {{__('специальный бонус')}}</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $125,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">37-38 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-19.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Золотой директор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ {{__('специальный бонус')}} <br> <span class="spec">{{__('после достижения платинового бронзового директора')}}</span></p>
                                    </div>
                                </li>

                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $75,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">35-36 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-18.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Бронзовый директор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $1,500,000 <br>
                                            <span>+ {{__('Вход в состав директоров')}}</span><br>
                                            <span>+ {{__('Вилла DUBAI MEDIACOIN')}}</span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">$25,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">34 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-17.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Директор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $1,000,000<br> <span>+  {{__('до 50% прибыли NFT Marketplace')}}</span>
                                        </p>
                                    </div>
                                </li>
                            </ul>

                            <ul class="rank-4">
                                <li>
                                    <div class="left">
                                        <p class="summ">$15,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">33 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-16.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Платиновый лидер')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $600,000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $10,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">32 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-15.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Золотой лидер')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $400,000 <br> <span>+ {{__('Авто MEDIACOIN')}}</span></p>
                                    </div>
                                </li>

                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $7,500,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">31 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-14.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Бронзовый лидер')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">$5,000,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">30 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-13.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Лидер')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $200,000<br> <span>+ {{__('до 10% прибыли компании')}}<br>
                                + {{__('часы MEDIACOIN')}}</span>
                                        </p>
                                    </div>
                                </li>
                            </ul>

                            <ul class="rank-3">
                                <li>
                                    <div class="left">
                                        <p class="summ">$2,500,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">29 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-12.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Платиновый дистрибьютор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $150,000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $1,250,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">27-28 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-11.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Золотой дистрибьютор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $50,000</p>
                                    </div>
                                </li>

                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $750,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">26 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-10.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Бронзовый дистрибьютор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">

                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">$500,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">25 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-9.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Дистрибьютор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $25,000</p>
                                    </div>
                                </li>
                            </ul>

                            <ul class="rank-2">
                                <li>
                                    <div class="left">
                                        <p class="summ">$250,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">24 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-8.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Платиновый партнёр')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $10,000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $125,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">22-23 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-7.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Золотой партнёр')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $5,000</p>
                                    </div>
                                </li>

                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $75,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">20-21 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-6.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Бронзовый партнёр')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $2,000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">$25,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">19 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-5.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Партнёр')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $1,000</p>
                                    </div>
                                </li>
                            </ul>

                            <ul class="rank-1">
                                <li>
                                    <div class="left">
                                        <p class="summ">$15,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">17-18 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-4.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Платиновый инвестор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                        <p class="bonus">+ $500 <span>({{__('бонус')}})</span></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $9,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">12-16 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-3.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Золотой инвестор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $4,500</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">7-11 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-2.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Бронзовый инвестор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <p class="summ">{{__('до')}} $2,000</p>
                                        <img src="/newfrontend/images/arrow-left.png" alt="">
                                        <p class="text">1-6 {{__('ранг')}}</p>
                                    </div>
                                    <div class="center">
                                        <img src="/newfrontend/images/rank-1.png" alt="">
                                    </div>
                                    <div class="right">
                                        <p class="text-b">{{__('Инвестор')}}</p>
                                        <img src="/newfrontend/images/arrow-right.png" alt="">
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
                <div class="conditions">
                    <div class="anchor-itemD" id="conditions"></div>
                        <div class="head-m">
<!--                            <img src="/newfrontend/images/arrow-arrow.png" alt="">-->
                            <h1>{{__('Условия достижения бонусов за ранги')}}</h1>
<!--                            <img src="/newfrontend/images/arrow-arrow.png" alt="">-->
                        </div>
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-4.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Платиновый инвестор')}}</p>
                                    <div class="block">
                                        <p class="rank">17 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$5,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-5.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Партнёр')}}</p>
                                    <div class="block">
                                        <p class="rank">19 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$10,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-6.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Бронзовый партнёр')}}</p>
                                    <div class="block">
                                        <p class="rank">20 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$20,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-7.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Золотой партнёр')}}</p>
                                    <div class="block">
                                        <p class="rank">22 {{__('ранг')}} </p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$35,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('2 партнера 19 ранга в первой линии')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-8.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Платиновый партнёр')}}</p>
                                    <div class="block">
                                        <p class="rank">24 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$65,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('1 партнер 22 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнер 19 ранга в первой линии')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$5,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-9.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Дистрибьютор')}}</p>
                                    <div class="block">
                                        <p class="rank">25 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$100,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 23 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнер 19 ранга в первой линии')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$10,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-11.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Золотой дистрибьютор')}}</p>
                                    <div class="block">
                                        <p class="rank">27 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$200,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 24 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('1 партнер 22 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('4 партнера 19 ранга')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$20,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                        <div class="b-2">
                                            <p>50</p>
                                            <span>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-12.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Платиновый дистрибьютор')}}</p>
                                    <div class="block">
                                        <p class="rank">29 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$350,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 26 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('1 партнер 22 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('4 партнера 19 ранга')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$35,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                        <div class="b-2">
                                            <p>150</p>
                                            <span>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-13.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Лидер')}}</p>
                                    <div class="block">
                                        <p class="rank">30 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$500,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 27 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('4 партнера 24 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('5 партнеров 20 ранга')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$50,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                        <div class="b-2">
                                            <p>450</p>
                                            <span>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-14.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Бронзовый лидер')}}</p>
                                    <div class="block">
                                        <p class="rank">31 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$750,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('1 партнер 29 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 28 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('5 партнеров 22 ранга')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$75,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                        <div class="b-2">
                                            <p>800</p>
                                            <span>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-15.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Золотой лидер')}}</p>
                                    <div class="block">
                                        <p class="rank">32 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$1,000,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 29 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('5 партнеров 25 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('6 партнеров 20 ранга')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$100,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                        <div class="b-2">
                                            <p>1200</p>
                                            <span>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-16.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Платиновый лидер')}}</p>
                                    <div class="block">
                                        <p class="rank">33 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$1,500,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('5 партнеров 29 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('2 партнера 25 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('4 партнера 22 ранга')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$150,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                        <div class="b-2">
                                            <p>2000</p>
                                            <span>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-17.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Директор')}}</p>
                                    <div class="block">
                                        <p class="rank">34 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$2,500,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block block-img">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 30 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('4 партнера 22 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 27 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('5 партнеров 20 ранга')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$250,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                        <div class="b-2">
                                            <p>3000</p>
                                            <span>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="/newfrontend/images/rank-18.png" alt="">
                                </div>
                                <div class="center">
                                    <p class="name">{{__('Бронзовый директор')}}</p>
                                    <div class="block">
                                        <p class="rank">35 {{__('ранг')}}</p>
                                    </div>
                                    <p class="text">{{__('необходимо')}}</p>
                                    <p class="summ">$5,000,000</p>
                                    <span>{{__('Объем продаж в первой линии')}}</span>
                                </div>
                                <div class="right">
                                    <p class="text">{{__('Дополнительные условия')}}:</p>
                                    <div class="block block-img">
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 32 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('5 партнеров 24 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('2 партнера 30 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('3 партнера 22 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('4 партнера 27 ранга')}}</p>
                                        </div>
                                        <div class="stat">
                                            <img src="/newfrontend/images/rank-li.png" alt="">
                                            <p>{{__('5 партнеров 20 ранга')}}</p>
                                        </div>
                                    </div>
                                    <div class="bonus">
                                        <div class="b-1">
                                            <p>$500,000</p>
                                            <span>{{__('Личная инвестиция')}}</span>
                                        </div>
                                        <div class="b-2">
                                            <p>5000</p>
                                            <span>{{__('NFT куплено')}} {{__('( или другие покупки на лаунчпаде, бирже , кошельке от mediacoin )')}} </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                </div>
                <div class="bonus-page ">
                    <div class="anchor-itemD" id="bonus"></div>
                        <div class="head-m">
<!--                            <img src="/newfrontend/images/arrow-arrow.png" alt="">-->
                            <h1>{{__('Бонусная программа')}}</h1>
<!--                            <img src="/newfrontend/images/arrow-arrow.png" alt="">-->
                        </div>
                        <div class="bonus-ul">
                            <ul>
                                <li class="name">
                                    <div class="rank">
                                        <p>{{__('Ранг')}}</p>
                                    </div>
                                    <div class="bon">
                                        <p>{{__('Бонусный %')}} </p>
                                    </div>
                                    <div class="summ">
                                        <p>{{__('Сумма средств')}} <br> {{__('команды в фарминге')}} </p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>1</p>
                                    </div>
                                    <div class="bon">
                                        <p>5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$500</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>2</p>
                                    </div>
                                    <div class="bon">
                                        <p>5.25</p>
                                    </div>
                                    <div class="summ">
                                        <p>$750</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>3</p>
                                    </div>
                                    <div class="bon">
                                        <p>5.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$1,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>4</p>
                                    </div>
                                    <div class="bon">
                                        <p>5.75</p>
                                    </div>
                                    <div class="summ">
                                        <p>$1,250</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>5</p>
                                    </div>
                                    <div class="bon">
                                        <p>6</p>
                                    </div>
                                    <div class="summ">
                                        <p>$1,500</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>6</p>
                                    </div>
                                    <div class="bon">
                                        <p>6.25</p>
                                    </div>
                                    <div class="summ">
                                        <p>$2,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>7</p>
                                    </div>
                                    <div class="bon">
                                        <p>6.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$2,500</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>8</p>
                                    </div>
                                    <div class="bon">
                                        <p>6.75</p>
                                    </div>
                                    <div class="summ">
                                        <p>$3,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>9</p>
                                    </div>
                                    <div class="bon">
                                        <p>7</p>
                                    </div>
                                    <div class="summ">
                                        <p>$3,500</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>10</p>
                                    </div>
                                    <div class="bon">
                                        <p>7.25</p>
                                    </div>
                                    <div class="summ">
                                        <p>$4,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>11</p>
                                    </div>
                                    <div class="bon">
                                        <p>7.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$4,500</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>12</p>
                                    </div>
                                    <div class="bon">
                                        <p>7.75</p>
                                    </div>
                                    <div class="summ">
                                        <p>$5,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>13</p>
                                    </div>
                                    <div class="bon">
                                        <p>8</p>
                                    </div>
                                    <div class="summ">
                                        <p>$6,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>14</p>
                                    </div>
                                    <div class="bon">
                                        <p>8.25</p>
                                    </div>
                                    <div class="summ">
                                        <p>$7,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>15</p>
                                    </div>
                                    <div class="bon">
                                        <p>8.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$8,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>16</p>
                                    </div>
                                    <div class="bon">
                                        <p>8.75</p>
                                    </div>
                                    <div class="summ">
                                        <p>$9,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>17</p>
                                    </div>
                                    <div class="bon">
                                        <p>9</p>
                                    </div>
                                    <div class="summ">
                                        <p>$10,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>18</p>
                                    </div>
                                    <div class="bon">
                                        <p>9.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$15,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>19</p>
                                    </div>
                                    <div class="bon">
                                        <p>10</p>
                                    </div>
                                    <div class="summ">
                                        <p>$25,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>20</p>
                                    </div>
                                    <div class="bon">
                                        <p>10.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$50,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>21</p>
                                    </div>
                                    <div class="bon">
                                        <p>11</p>
                                    </div>
                                    <div class="summ">
                                        <p>$75,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>22</p>
                                    </div>
                                    <div class="bon">
                                        <p>11.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$100,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>23</p>
                                    </div>
                                    <div class="bon">
                                        <p>12</p>
                                    </div>
                                    <div class="summ">
                                        <p>$125,000</p>
                                    </div>
                                </li>
                            </ul>
                            <ul>
                                <li class="name">
                                    <div class="rank">
                                        <p>{{__('Ранг')}}</p>
                                    </div>
                                    <div class="bon">
                                        <p>{{__('Бонусный %')}} </p>
                                    </div>
                                    <div class="summ">
                                        <p>{{__('Сумма средств')}} <br> {{__('команды в фарминге')}} </p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>24</p>
                                    </div>
                                    <div class="bon">
                                        <p>12.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$250,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>25</p>
                                    </div>
                                    <div class="bon">
                                        <p>13</p>
                                    </div>
                                    <div class="summ">
                                        <p>$500,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>26</p>
                                    </div>
                                    <div class="bon">
                                        <p>13.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$750,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>27</p>
                                    </div>
                                    <div class="bon">
                                        <p>14</p>
                                    </div>
                                    <div class="summ">
                                        <p>$1,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>28</p>
                                    </div>
                                    <div class="bon">
                                        <p>14.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$1,250,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>29</p>
                                    </div>
                                    <div class="bon">
                                        <p>15</p>
                                    </div>
                                    <div class="summ">
                                        <p>$2,500,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>30</p>
                                    </div>
                                    <div class="bon">
                                        <p>15.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$5,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>31</p>
                                    </div>
                                    <div class="bon">
                                        <p>16</p>
                                    </div>
                                    <div class="summ">
                                        <p>$7,500,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>32</p>
                                    </div>
                                    <div class="bon">
                                        <p>16.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$10,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>33</p>
                                    </div>
                                    <div class="bon">
                                        <p>17</p>
                                    </div>
                                    <div class="summ">
                                        <p>$15,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>34</p>
                                    </div>
                                    <div class="bon">
                                        <p>17.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$25,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>35</p>
                                    </div>
                                    <div class="bon">
                                        <p>18</p>
                                    </div>
                                    <div class="summ">
                                        <p>$50,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>36</p>
                                    </div>
                                    <div class="bon">
                                        <p>18.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$75,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>37</p>
                                    </div>
                                    <div class="bon">
                                        <p>19</p>
                                    </div>
                                    <div class="summ">
                                        <p>$100,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>38</p>
                                    </div>
                                    <div class="bon">
                                        <p>19.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$125,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>39</p>
                                    </div>
                                    <div class="bon">
                                        <p>20</p>
                                    </div>
                                    <div class="summ">
                                        <p>$150,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>40</p>
                                    </div>
                                    <div class="bon">
                                        <p>20.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$200,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>41</p>
                                    </div>
                                    <div class="bon">
                                        <p>21</p>
                                    </div>
                                    <div class="summ">
                                        <p>$300,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>42</p>
                                    </div>
                                    <div class="bon">
                                        <p>21.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$400,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>43</p>
                                    </div>
                                    <div class="bon">
                                        <p>22</p>
                                    </div>
                                    <div class="summ">
                                        <p>$500,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>44</p>
                                    </div>
                                    <div class="bon">
                                        <p>22.5</p>
                                    </div>
                                    <div class="summ">
                                        <p>$750,000,000</p>
                                    </div>
                                </li>
                                <li class="list">
                                    <div class="rank">
                                        <p>45</p>
                                    </div>
                                    <div class="bon">
                                        <p>23</p>
                                    </div>
                                    <div class="summ">
                                        <p>$1,000,000,000</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
                <div class="example">
                    <div class="anchor-itemD" id="example"></div>
                        <div class="head-m">
<!--                            <img src="/newfrontend/images/arrow-arrow.png" alt="">-->
                            <h1>{{__('Пример работы партнерской пргограммы')}}</h1>
<!--                            <img src="/newfrontend/images/arrow-arrow.png" alt="">-->
                        </div>
                        <div class="content">
                            <img src="/newfrontend/images/img-marketing-1-new.png" alt="">
                        </div>
                </div>
            </div>
        </div>

        <div class="bottom-text">
            <div class="container">

                <div class="content">
                    <div>
                        <div class="left">
                            <p class="text-block text-block-b">{{__('Бонус за достижения ранга и выполнения условий начисляется в течении 48 часов , после выполнения условий.')}}</p>
                            <p class="text-block">{{__('В течении 48 часов команда Mediacoin проверяет правильность выполнения условий лидером по партнерской программе.')}}</p>
                            <p class="text-block">{{__('Бонусы за выполнения условий и достижений рангов начисляются в виде инвестиций в фарминг по добычи монеты МС . Так как это настоящие деньги которая компания инвестирует в партнёров они так же идут в оборот для повышения курса монеты, но они не идут в оборот по партнерской программе.')}}</p>
                        </div>
                        <div class="right">
                            <p class="text-block">{{__('После достижения товарооборота 1.000.000$ и достижения ранга 27 ( Золотой Дистрибьютор) и выполнения всех условий и получение премии +50.000$,')}} <span class="text-block-b"> {{__('партнёр платформы Mediacoin в течении 1 месяца должен приехать в официальное представительство Mediacoin')}}</span>{{__('( Дубаи, Острова Кайман , позже будет больше представительств ) на подписание отдельного партнерского договора.')}}</p>
                            <p class="text-block text-block-b">{{__('Согласования на поездку в офис проходит через Тикет систему в личном кабинете.')}}</p>
                        </div>
                    </div>
                    <p class="text-block text-block-red">{{__('Для начисления бонусов так же, учитывается лояльность лидера к платформе Mediacoin, знание всех продуктов, и работа только с Mediacoin ( отдельные требования будут прописаны в партнерском соглашении для каждого партнёра )')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
  name: 'marketing',
    data: function() {
      return {
          activeTabD: 0,
      }
  },
  watch: {

  },
  computed: {

  },

  methods: {
      goToAnchorD(anchor) {
          this.activatedAnchorFlag = true;
          this.activeAnchorD = anchor;
          this.$vuetify.goTo(anchor);
          setTimeout(() => {
              this.activatedAnchorFlag = false;
              this.activeAnchorD = null;
          }, 1000);
      },
      changeActiveTabD() {
          let scrollBlocks = document.getElementsByClassName('anchor-itemD');
          let rootHeight = document.documentElement.clientHeight;

          for(let i = 0; i < scrollBlocks.length; i++) {

              if (!this.activatedAnchorFlag) {
                  let positionTop = scrollBlocks[i].getBoundingClientRect().top ;
                  let percentInVisible = scrollBlocks[i].getBoundingClientRect().bottom - rootHeight / 100 * 95;

                  if (positionTop >= -100 && positionTop <= 100) {
                      this.activeTabD = i;
                  }

                  if (scrollBlocks[i].getBoundingClientRect().height > rootHeight) {

                      if (percentInVisible >= -100 && percentInVisible <= 100) {
                          this.activeTabD = i;
                      }

                  }
              }

          }

      },

  },
    created() {
        document.addEventListener('scroll', this.changeActiveTabD);
    },
    destroyed() {
        document.removeEventListener('scroll', this.changeActiveTabD);
    },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
