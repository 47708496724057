export default {
    state: {
        auth: null,
        resp_tickets: null,
        updateTime: Date.now(),
    },
    getters: {

    },
    actions: {
        asyncAuth(context, payload) {
            context.commit('updateAuth', payload);
        },
        asyncResp(context, payload) {
            context.commit('updateResp', payload);
        },
        asyncUpdateTime(context, payload) {
            context.commit('updateTime', payload);
        },
    },
    mutations: {
        updateAuth(state, data) {
            this.state.preference.auth= data;
        },
        updateTime(state, data) {
            this.state.preference.updateTime= data;
        },
        updateResp(state, data) {
            if([0,1].includes(data)) {
                this.state.preference.resp_tickets= data;
            }
        },
    }
}
