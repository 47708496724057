<template>
  <div class="block">
    <div class="img" :class="payment.name_class">
<!--      <img :src="'/newfrontend/images/img-'+payment.name_class+'.svg'" alt="">-->
    </div>
    <p class="semi">{{__(':name wallet', {name: payment.name})}}</p>
    <InputLabelTextDefault :placeholder="__('Enter address')"  class="block-input" :name="wallet.name_class" :class="wallet.name_class+'-input-left'" v-model="address" :disabled="(personal_info.user_wallet || !personal_info.google_fa.enable) ? true : false" v-bind:errors="formValidate.address" @input="updateValidate('address')">
      <template slot="right">
        <InputLabelTextDefault class="xrp-input" :placeholder="__('Tag:')" v-model="tag" :disabled="(personal_info.user_wallet) ? true : false" v-bind:errors="formValidate.tag" @input="updateValidate('tag')" v-if="tag !== false"/>
        <v-btn class="btn-grey btn" v-on:click="onDelete" :loading="submitDeleted" v-if="personal_info.user_wallet">{{__('Delete')}}</v-btn>
<!--        <v-btn  class="btn-site btn" v-on:click="onSubmit" :loading="submitDisabled"  v-if="!wallet.user_wallet" :disabled="(address && address != wallet.user_wallet && (tag=== false || tag && tag != wallet.tag)) ? false : true">{{__('Save')}}</v-btn>-->
      </template>
    </InputLabelTextDefault>
  </div>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {required, requiredIf, minLength} from "vuelidate/lib/validators";
    export default {
        name: 'WalletsSettingItem',
        data () {
          return {
              address: this.wallet.user_wallet,
              tag: this.wallet.tag,
              formValidate: {
                  tag: null,
                  address: null
              },
              submitDisabled: false,
              submitDeleted: false,
          }
        },
        props: {
          index: {
              type: Number
          },
          wallet: {
              type: Object
          },
          personal_info: {
              type: Object
          },
          wallets: {
              type: Array
          },
          payment: {
              type: Object
          },
          validations: {
              type: Object
          }
        },
        asyncComputed: {

        },
        watch: {
            wallet(val) {
                this.address= val.user_wallet;
                this.tag= val.tag;
            },
            address(val) {
                let wallets= this.$cleanObject(this.wallets);
                wallets[this.index].user_wallet= val;
                this.$emit('update:wallets', wallets);
            },
            tag(val) {
                let wallets= this.$cleanObject(this.wallets);
                wallets[this.index].tag= val;
                this.$emit('update:wallets', wallets);
            }
        },
        validations() {
            return {
              address: {
                  required,
                  minLength: minLength(5)
              },
              tag: {
                  required: requiredIf(()=> {
                      return (this.wallet.tag !== false);
                  })
              }
            }
        },
        methods:{
            updateValidate: function(type) {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);

                let validations= this.$cleanObject(this.validations);
                validations[this.wallet.payment_id]= this.$v.$invalid;

                this.$emit('update:validations', validations);
            },
            // onSubmit: function () {
            //     let valid= this.$v;
            //     if(valid.$invalid) {
            //         let massages= {
            //             'address': [],
            //             'tag': [],
            //         };
            //         this.formValidate= this.$formValidate(massages, valid);
            //     } else {
            //         this.submitDisabled= true;
            //         let response= {
            //             'address': this.address,
            //             'tag': (this.tag) ? this.tag : null,
            //             'payment_id': this.payment.payment_id
            //         };
            //         let wallet= this.wallet;
            //         this.$http.post('ajax/setting/wallet', response)
            //             .then(response=> {
            //                 return response.json()
            //             })
            //             .then(()=> {
            //                 this.submitDisabled= false;
            //                 wallet['tag']= this.tag;
            //                 wallet['user_wallet']= this.address;
            //
            //                 let wallets= this.$cleanObject(this.wallets);
            //                 let index= this.$filterIndexObject(wallets, wallet.payment_id, 'payment_id');
            //
            //                 wallets[index]= wallet;
            //
            //                 this.$emit('updateWallet', wallets);
            //             }).catch(err => {
            //                 this.submitDisabled= false;
            //                 let response= this.httpHandler(err);
            //                 if(response.error) {
            //                     this.formValidate = response.error.message;
            //                 }
            //             });
            //     }
            // },
            onDelete: function () {
                // this.submitDeleted= true;
                // let wallet= this.wallet;

                this.$emit('update:modal_delete', this.payment);
                // this.submitDeleted= false;
                // if(wallet.send_code) {
                //     this.$emit('update:modal_delete', this.payment);
                //     this.submitDeleted= false;
                // } else {
                //     let response= {
                //         'payment_id': this.payment.payment_id
                //     };
                //     this.$http.post('ajax/setting/sendCode', response)
                //         .then(response=> {
                //             return response.json()
                //         })
                //         .then((response)=> {
                //             if(response.data== 'success') {
                //                 wallet.send_code = true;
                //
                //                 let wallets= this.$cleanObject(this.wallets);
                //                 let index= this.$filterIndexObject(wallets, wallet.payment_id, 'payment_id');
                //
                //                 console.log(index);
                //                 console.log(wallets);
                //                 wallets[index]= wallet;
                //
                //
                //                 this.$emit('updateWallet', wallets);
                //                 this.$emit('update:modal_delete', this.payment);
                //             }
                //
                //             this.submitDeleted= false;
                //         });
                // }
            }
        },
        computed: {

        },
        components: {
            InputLabelTextDefault


        }
    }
</script>
<style>

</style>