<template>
  <div class="wow-list" v-if="promo">
      <div class="content">
        <div class="top">
            <router-link :to="{name: 'dashboard'}">
                <img src="/newfrontend/images/wow-left.svg" alt="">
                <span>{{__('back to dashboard')}}</span>
            </router-link>
<!--            <p class="center">{{__('completed a promo')}}</p>-->
            <p class="text">{{__('You received:')}}
                <span v-if="promo.turnover < 20000">—</span>
                <span v-else-if="promo.turnover < 50000">20000</span>
                <span v-else-if="promo.turnover < 100000">50000</span>
                <span v-else-if="promo.turnover < 250000">100000</span>
                <span v-else-if="promo.turnover < 500000">250000</span>
                <span v-else-if="promo.turnover < 1000000">500000</span>
                <span v-else>1000000</span>
            </p>
        </div>
        <ul>
            <li class="top-li">
              <div class="id">
                <p>##</p>
              </div>
              <div class="lvl">
                <p>$20.000</p>
              </div>
              <div class="lvl">
                <p>$50.000</p>
              </div>
              <div class="lvl">
                <p>$100.000</p>
              </div>
                <div class="lvl">
                    <p>$250.000</p>
                </div>
                <div class="lvl">
                    <p>$500.000</p>
                </div>
                <div class="lvl">
                    <p>$1.000.000</p>
                </div>
            </li>
            <li class="body-li" v-for="(num, key) in maxPartners" :key="key">
              <div class="id">
                <p>{{num}}</p>
              </div>
              <div class="lvl">
                <p v-if="promo.complete.promo_one[key]" :style="(promo.complete.promo_one[key].sort >= 64) ? 'background: #ffcfcf;' : ''" :class="(portfolio.user_id== promo.complete.promo_one[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_one[key].user_public_id}}</p>
                <p v-else>-</p>
              </div>
              <div class="lvl">
                <p v-if="promo.complete.promo_two[key]" :style="(promo.complete.promo_two[key].sort >= 64) ? 'background: #ffcfcf;' : ''" :class="(portfolio.user_id== promo.complete.promo_two[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_two[key].user_public_id}}</p>
                <p v-else>-</p>
              </div>
              <div class="lvl">
                <p v-if="promo.complete.promo_three[key]" :style="(promo.complete.promo_three[key].sort >= 64) ? 'background: #ffcfcf;' : ''" :class="(portfolio.user_id== promo.complete.promo_three[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_three[key].user_public_id}}</p>
                <p v-else>-</p>
              </div>
                <div class="lvl">
                    <p v-if="promo.complete.promo_four[key]" :class="(portfolio.user_id== promo.complete.promo_four[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_four[key].user_public_id}}</p>
                    <p v-else>-</p>
                </div>
                <div class="lvl">
                    <p v-if="promo.complete.promo_five[key]" :class="(portfolio.user_id== promo.complete.promo_five[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_five[key].user_public_id}}</p>
                    <p v-else>-</p>
                </div>
                <div class="lvl">
                    <p v-if="promo.complete.promo_six[key]" :class="(portfolio.user_id== promo.complete.promo_six[key].user_public_id) ? 'active' : ''">#{{promo.complete.promo_six[key].user_public_id}}</p>
                    <p v-else>-</p>
                </div>
            </li>
        </ul>
      </div>
  </div>
  <div v-else class="loading">
      <loaderMc></loaderMc>
    {{__('loading')}}
  </div>
</template>

<script>
    import _ from 'lodash';
    import loaderMc from "../SvgComponents/loaderMc";
    export default {
        name: 'wow_list',
        data () {
            return {
                promo: null
            }
        },
        computed: {
            maxPartners() {
                let complete= this.promo.complete;
                let partners= [Object.keys(complete.promo_one).length, Object.keys(complete.promo_two).length, Object.keys(complete.promo_three).length, Object.keys(complete.promo_four).length, Object.keys(complete.promo_five).length, Object.keys(complete.promo_six).length];
                return _.max(partners);
            },
            portfolio() {
                return this.$store.state.auth.portfolio
            },
        },
        created() {
            this.$http.get('ajax/dashboard')
                .then(response => {
                    return response.json()
                })
                .then((response) => {
                    this.$store.dispatch('asyncResp', response.resp_tickets);
                    console.log('response.data');
                    console.log(response.data.promo3);
                    this.promo= response.data.promo3;
                }).catch((err) => {
                this.httpHandler(err);
            });
        },
        asyncComputed: {

        },
        methods: {
        },
        components: {
            loaderMc,
        },

    }
</script>
