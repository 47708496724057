<template>
    <div class="messages-page">
        <div class="top">
            <v-tabs>
                <v-tab v-for="(tab, key) in tabs" :key="key" v-on:click="changeTab(tab)">
                  {{__(tab.title)}}
                </v-tab>
                <a class="btn-site" v-on:click="dialog= true"><span>{{__('Create a ticket')}}</span></a>
                <v-tab-item :value="tab">
                    <v-card flat>
                        <v-card-text class="">
                            <div class="body-list" v-if="tickets">
                                <TablePagination tag="ul" v-bind:body.sync="tickets" v-bind:showing="true" class="list">
                                  <template v-slot="page" >
                                    <div class="all-mess">
                                        <div class="top-mess">
                                            <router-link class="li-mess" :to="{name: 'ticket', params: {
                                                  id: page.public_id
                                                }}">
                                                {{__('Ticket')}} #{{page.public_id}}
                                            </router-link>
                                            <div class="d-flex justify-space-between">
                                                <div class="status close" v-if="page.status">
                                                    <p>{{__('Closed')}}</p>
                                                </div>
                                                <div class="status open" v-else>
                                                    <p>{{__('Open')}}</p>
                                                </div>
                                                <span>
                                                  {{page.created_at | moment('MMM DD, YYYY')}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="body-mess">
                                            <router-link class="title" :to="{name: 'ticket', params: {
                                                  id: page.public_id
                                              }}">
                                                {{page.title}}
                                            </router-link>
                                            <p>{{page.description | truncate(150)}}</p>
                                        </div>
                                    </div>
<!--                                    <router-link tag="li" class="li-mess" :to="{name: 'ticket', params: {-->
<!--                                        id: page.public_id-->
<!--                                    }}">-->
<!--                                      <div class="img">-->
<!--                                        <img :src="'/newfrontend/images/mess-close.svg'" alt="" v-if="page.status">-->
<!--                                        <img :src="'/newfrontend/images/'+((page.open) ? 'mess-not-read' : 'mess-read')+'.svg'" alt="" v-else>-->
<!--                                      </div>-->
<!--                                      <div class="name">-->
<!--                                        <p>{{page.title}}</p>-->
<!--                                      </div>-->
<!--                                      <div class="topic">-->
<!--                                        <p>{{page.subject}}</p>-->
<!--                                      </div>-->
<!--                                      <div class="date">-->
<!--                                        <p>{{page.created_at | formatDate}}</p>-->
<!--                                      </div>-->
<!--                                      <div class="status close" v-if="page.status">-->
<!--                                        <p>{{__('Closed')}}</p>-->
<!--                                      </div>-->
<!--                                      <div class="status open" v-else>-->
<!--                                        <p>{{__('Open')}}</p>-->
<!--                                      </div>-->
<!--                                    </router-link>-->
                                  </template>
                                  <template slot="no-result">
                                    <img src="/newfrontend/images/chart.svg" alt="">
                                    {{__('At the moment, you have not made a single call to our support service')}}
                                  </template>
                                </TablePagination>
                            </div>
                            <div v-else class="loading">
                                <loaderMc></loaderMc>
                                {{__('Loading')}}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </div>

<!--        <Tiket></Tiket>-->

        <ModalDefault v-if="dialog" v-bind:modal_cancel.sync="dialog">
            <div class="window-site create-message" v-if="open_id">
              <div class="top-window ">
                <p>{{__('You already have an open ticket')}}</p>
              </div>
                <p class="text-mess">{{__('In order to create a new ticket, you must have closed all previous tickets')}}</p>
                <div class="create-btn">
                    <span class="btn-white" v-on:click="dialog=false">{{__('cancel')}}</span>
                    <router-link :to="{'name': 'ticket', params: {'id': open_id}}" class="btn-site">{{__('Go to ticket')}}</router-link>
                </div>
            </div>
            <div class="window-site create-message" v-else>
                <div class="top-window ">
                    <p>{{__('Create Ticket')}}</p>
                </div>
                <div class="name">
                    <p>{{__('Title')}}</p>
                  <InputLabelTextDefault :placeholder="__('Enter title for ticket')" v-model="form.title" v-bind:errors="formValidate.title" @change="updateValidate('title')"></InputLabelTextDefault>
                </div>
                <div class="ticket">
                    <p>{{__('Departments')}}</p>
                    <div class="input-group" :class="(formValidate.subject) ? 'errors' : ''">
                        <DropdownDefault
                            v-bind:items="tickets_category"
                            value_key="name"
                            text="name"
                            v-model="form.subject"
                            :placeholder="__('Department')"
                            dropSlot
                        >
                          <template v-slot:selection="item">
                            {{__(item.name)}}
                          </template>
                          <template v-slot:item="item">
                            {{__(item.name)}}
                          </template>
                        </DropdownDefault>
                        <div v-if="formValidate.subject">
                          <p class="red--text">{{formValidate.subject[0]}}</p>
                        </div>
                    </div>
                </div>
                <div class="text">
                    <p>{{__('Enter your message')}}</p>
                    <InputLabelTextDefault type="textarea" :placeholder="__('Enter ticket details')" v-model="form.description" v-bind:errors="formValidate.description" @change="updateValidate('description')"></InputLabelTextDefault>
                </div>
                <div class="create-btn">
                    <span class="btn-white" v-on:click="dialog=false">{{__('cancel')}}</span>
                    <v-btn class="btn-site" v-on:click="onSubmit" v-on:loading="submitDisabled">{{__('create ticket')}}</v-btn>
                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>
import ModalDefault from "../modules/ModalDefault";
import DropdownDefault from "../modules/DropdownDefault";
import TablePagination from "../modules/TablePagination";
import InputLabelTextDefault from "../modules/InputLabelTextDefault";
import {required} from "vuelidate/lib/validators";
    import loaderMc from "../SvgComponents/loaderMc";
// import Tiket from "./Messenger/Tiket";
export default {
  name: 'messages',
    data: function() {
        return {
            tab: null,
            type: null,
            tabs: [
                {
                    title: "All tickets",
                    type: 1,
                    value: null
                },
                {
                    title: "Open tickets",
                    type: 2,
                    value: 0
                },
                {
                    title: "Closed tickets",
                    type: 3,
                    value: 1
                }
            ],
            open_id: null,
            tickets: false,
            tickets_category: false,
            dialog: false,
            form: {
                title: null,
                subject: null,
                description: null,
            },
            formValidate: {
                title: null,
                subject: null,
                description: null,
            },
            submitDisabled: false,
        }
  },
  watch: {

  },

  validations: {
      form: {
          title: {
              required
          },
          subject: {
              required
          },
          description: {
              required
          },
      }
  },
  created() {
      this.getTickets();
  },
  methods: {
      changeTab(tab) {
          if(this.tab != tab.type -1) {
              this.tab= tab.type -1;
              this.type= tab.value;
              this.getTickets();
          }
      },
      getTickets() {
          let response= {
              type: this.type,
          };

          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/tickets', response)
              .then(response=> {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  response= response.data;

                  this.tickets= response.tickets;
                  this.open_id= response.open_id;
                  this.tickets_category= response.tickets_category;
              }).catch((err) => {
              this.httpHandler(err);
          });
      },
      updateValidate: function(type, validate= false, key= 'form') {
          if(validate) {
              let formValidate= this.formValidate;
              formValidate[type]= [];

              if(!this.$v.form[type][validate]) {
                  let msg= this.__(this.$validateMessage[validate]);
                  formValidate[type].push(msg);

              }

              this.formValidate= formValidate;
          } else {
              this.formValidate= this.$changeValidate(this.formValidate, this.$v[key][type], type);
          }
      },
      onSubmit: function () {
          let valid = this.$v.form;

          if (valid.$invalid) {
              let massages = {
                  'title': [],
                  'subject': [],
                  'description': []
              };
              this.formValidate = this.$formValidate(massages, valid);
          } else {
              this.submitDisabled= true;
              let response= this.form;

              this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/tickets/create', response)
                  .then(response=> {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      response= response.data;

                      this.dialog= false;
                      this.$router.push({name: 'ticket', params: {
                          id: response.id
                      }});
                  }).catch(err => {
                  this.submitDisabled= false;
                  let response= this.httpHandler(err);
                  if(response.error) {
                      this.formValidate = response.error.message;
                  }
              });
          }
      }
  },
  computed: {

  },
  mounted() {

  },
  components: {
      loaderMc,
      TablePagination,
      // Tiket,
      DropdownDefault,
      InputLabelTextDefault,
      ModalDefault,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
