<template>
  <div class="march-promo">
      <img class="bg" src="/newfrontend/images/march-f.png" alt="">
    <div class="top">
      <div class="left">
        <img src="/newfrontend/images/logo.svg" alt="">
      </div>
      <div class="center">
        <p class="text-g">{{__('In honor of March 8')}}</p>
        <p class="text-b">{{__('Win NFT and iPhone')}}</p>
        <p class="text-r">{{__('for 8 MC')}}</p>
      </div>
      <div class="right">
        <router-link :to="{name: 'march_list'}" class="btn-white-new">{{__('Who bought')}}</router-link>
      </div>
    </div>
    <div class="body-march">
      <div class="left">
        <div class="block">
          <p>88888</p>
          <span>{{__('Total tickets')}}</span>
        </div>
      </div>

      <div class="right">
        <div class="block">
          <p>{{info.tickets_left}}</p>
          <span>{{__('tickets left')}}</span>
          <a class="btn-green-new" v-on:click="dialog= true">{{__('buy a ticket')}}</a>
        </div>
      </div>
    </div>
    <div class="mob">
      <a class="btn-green-new btn-mob" v-on:click="dialog= true">{{__('buy a ticket')}}</a>
    </div>


    <ModalDefault width="940" v-if="dialogNew" v-bind:modal_cancel.sync="dialogNew">
      <div class="march-promo window-promo">
        <img class="bg" src="/newfrontend/images/march-f.png" alt="">
        <div class="top">
          <div class="left">
            <img src="/newfrontend/images/logo.svg" alt="">
          </div>
          <div class="center">
            <p class="text-g">{{__('In honor of March 8')}}</p>
            <p class="text-b">{{__('Win NFT and iPhone')}}</p>
            <p class="text-r">{{__('for 8 MC')}}</p>
          </div>
          <div class="right">
            <router-link :to="{name: 'march_list'}" class="btn-white-new">{{__('Who bought')}}</router-link>
          </div>
        </div>
        <div class="body-march">
          <div class="left">
            <div class="block">
              <p>88888</p>
              <span>{{__('Total tickets')}}</span>
            </div>
          </div>

          <div class="right">
            <div class="block">
              <p>88888</p>
              <span>{{__('tickets left')}}</span>
              <a class="btn-green-new" v-on:click="dialog= true">{{__('buy a ticket')}}</a>
            </div>
          </div>
        </div>
        <div class="mob">
          <a class="btn-green-new btn-mob"  v-on:click="dialog= true">{{__('buy a ticket')}}</a>
        </div>
      </div>
    </ModalDefault>

    <ModalDefault width="570" v-if="dialog" v-bind:modal_cancel.sync="dialog">
      <div class="window-ticket window-site">
         <div class="text">
           <p class="name">{{__('Buy a ticket')}}</p>
           <p class="desc">{{__('Select the number of tickets you buy. You can buy 88 tickets in total. The amount will be debited from the MC account',{'buy': info.user_count})}}</p>
         </div>
        <div>
          <div class="block">
            <p class="name">{{__('Number of tickets')}}</p>
            <p class="desc">{{__('Available balance:')}} <span>{{portfolio.balance_available_MC}} MC</span></p>
          </div>
          <div class="block-input">
            <InputLabelTextDefault
                type="number"
                v-model="count"
                v-bind:errors="formValidate.count"
                @change="updateValidate('count')"
            ></InputLabelTextDefault>
            <p class="name">{{__('Price:')}} <span>{{price*count}} MC</span></p>
          </div>
          <div class="btn-ticket">
            <a class="btn-white-new" v-on:click="dialog= false">{{__('Cancel')}}</a>
            <v-btn
              class="btn-green-new"
              v-on:click="onSubmit"
              :loading="submitDisabled"
            >{{__('To buy tickets')}}</v-btn>
          </div>
        </div>
      </div>
    </ModalDefault>
    <ModalDefault width="370" v-if="success" v-bind:modal_cancel.sync="success">
      <div class="window-site window-site-ticket">
        <div class="block-success" v-if="success== 'success'">
          <img src="/newfrontend/images/block-success.png" alt="">
          <p>{{__('You have successfully purchased')}}
                    {{count}}
            {{__('tickets! We wish you to win the lottery!')}}</p>
          <div class="btn-site-ticket">
            <a class="btn-green-new" v-on:click="closeDialog">{{__('ready')}}</a>
          </div>
        </div>
        <div class="block-error" v-else>
          <img src="/newfrontend/images/block-error.png" alt="">
          <p>{{__('An error has occurred! You can try more!')}}</p>
          <div class="btn-site-ticket">
            <a class="btn-white-new" v-on:click="closeDialog">{{__('Cancel')}}</a>
            <a class="btn-green-new" v-on:click="success= false">{{__('try again')}}</a>
          </div>
        </div>
      </div>
    </ModalDefault>


  </div>
</template>

<script>

    import ModalDefault from "./ModalDefault";
    import InputLabelTextDefault from "./InputLabelTextDefault";
    import {required, minValue,maxValue, decimal} from "vuelidate/lib/validators";

    export default {
        name: 'MarchPromo',

        data: function() {
            return {
              count: 1,
              price: 8,
              dialog: false,
              success: false,
              submitDisabled: false,
              dialogNew: localStorage.getItem('dialogNew'),
              formValidate: {
                  count: null
              },
            }
        },
        props: {
            promo: {
                type: [
                    String, Number
                ],
            },
            info: {
                type: Object
            }
        },
        computed: {
            portfolio() {
                return this.$store.state.auth.portfolio
            },
        },
        watch: {
            dialogNew() {
                localStorage.removeItem('dialogNew');
            }
        },
        validations() {
            let count= 88-this.info.user_count;
            let balance_available_MC= this.portfolio.balance_available_MC;


            if(balance_available_MC <= count*8) {
                count= this.$primNum(balance_available_MC/8, 0);
            }


            return {
                count: {
                    required,
                    decimal,
                    minValue: minValue(1),
                    // limit: maxValue((200/this.payment.course_usd)- ((this.portfolio.sum_invest) ? this.portfolio.sum_invest : 0))
                    maxValue:  maxValue(count),
                    // maxValue: maxValue(this.payment.max_replenish*this.payment.course_usd)
                },
            }
        },
        created() {

        },
        methods: {
            closeDialog: function() {
                this.dialog= false;
                this.success= false;
                this.count= 1;
            },
            updateValidate: function(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit() {
                let valid= this.$v;
                if(valid.$invalid) {
                    let massages= {
                        'count': []
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;

                    let response = {
                        count: this.count
                    };

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/promo/buyPromo', response)
                        // this.$http.post("ajax/deposit/create", response)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            // response = {
                            //     "data": {
                            //         "deposit": {
                            //             "address": "0x6563cd7f6e2e9a9cda61d0527232b6d67aa09904",
                            //             "amount": "100",
                            //             "amount_usd": "100",
                            //             "payment_id": 2,
                            //             "commission": 0,
                            //             "tag": false,
                            //             "time": "2022/03/17 11:43:49",
                            //             "step": 1,
                            //             "id": 1
                            //         }
                            //     },
                            //     "status": 200
                            // };

                            this.submitDisabled= false;
                            this.success= 'success';
                            this.$emit('updateInfo', response.data);
                        }).catch((err) => {
                            this.submitDisabled= false;
                            this.success= true;
                            this.httpHandler(err);
                        });
                }
            }
        },
        components: {
          InputLabelTextDefault,
          ModalDefault

        }
    }
</script>
<style>
  .v-dialog .close{
    z-index: 2;
  }
</style>