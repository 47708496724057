<template>
    <div class="media-page-all">
        <div class="container media-page" v-if="blog">
            <!--      <h1>{{__('Media-center')}}</h1>-->
            <div class="content">
                <div class="top">
                    <div class="left">
                        <v-btn class="left-btn" v-on:click="changeCategory(null)" :class="(!params.category) ? 'active' : ''">{{__('All publications')}}</v-btn>
                        <v-btn
                                class="left-btn"
                                :class="(params.category== item.category) ? 'active' : ''"
                                v-for="(item, key) in category"
                                :key="key"
                                v-on:click="changeCategory(item.category)"
                        >
                            <svg v-if="item.id == 2" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.2 0V9.65555C2.2 9.81763 2.13562 9.97307 2.02101 10.0877C1.9064 10.2023 1.75097 10.2667 1.58889 10.2667C1.42681 10.2667 1.27137 10.2023 1.15677 10.0877C1.04216 9.97307 0.977778 9.81763 0.977778 9.65555V2.44444H0V9.65555C0.000477149 10.0768 0.168031 10.4807 0.465902 10.7785C0.763773 11.0764 1.16764 11.244 1.58889 11.2444H13.0778C13.499 11.244 13.9029 11.0764 14.2008 10.7785C14.4986 10.4807 14.6662 10.0768 14.6667 9.65555V0H2.2ZM13.6889 9.65555C13.6887 9.81757 13.6243 9.97291 13.5097 10.0875C13.3951 10.202 13.2398 10.2665 13.0778 10.2667H3.05556C3.13645 10.0731 3.178 9.86535 3.17778 9.65555V0.977778H13.6889V9.65555Z" />
                                <path d="M8.55527 1.95557H4.15527V6.84447H8.55527V1.95557ZM7.5775 5.86669H5.13305V2.93335H7.5775V5.86669Z" />
                                <path d="M9.5332 1.95557H12.711V2.93334H9.5332V1.95557Z" />
                                <path d="M9.5332 3.91113H12.711V4.88891H9.5332V3.91113Z" />
                                <path d="M9.5332 5.8667H12.711V6.84449H9.5332V5.8667Z" />
                            </svg>
                            <svg v-else width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.33301 8.16675H5.83301V9.33342H2.33301V8.16675Z" />
                                <path d="M2.33301 10.5H8.16634V11.6667H2.33301V10.5Z" />
                                <path d="M12.8333 0H1.16667C0.857342 0.000308861 0.560776 0.123324 0.34205 0.34205C0.123324 0.560776 0.000308861 0.857342 0 1.16667V12.8333C0.000308861 13.1427 0.123324 13.4392 0.34205 13.658C0.560776 13.8767 0.857342 13.9997 1.16667 14H12.8333C13.1427 13.9997 13.4392 13.8767 13.658 13.658C13.8767 13.4392 13.9997 13.1427 14 12.8333V1.16667C13.9997 0.857342 13.8767 0.560776 13.658 0.34205C13.4392 0.123324 13.1427 0.000308861 12.8333 0ZM8.16667 1.16667V3.5H5.83333V1.16667H8.16667ZM1.16667 12.8333V1.16667H4.66667V4.66667H9.33333V1.16667H12.8333L12.8339 12.8333H1.16667Z" />
                            </svg>
                            <span>{{__(item.category)}}</span></v-btn>
                    </div>
                    <div class="right">
                        <img class="img" src="/newfrontend/images/media-search.svg" alt="">
                        <img class="img-search" src="/newfrontend/images/news-close.svg" alt="" v-if="search" v-on:click="closeSearch">
                        <SearchDefault class_search="media" :placeholder="__('Search by title')" v-model="search" @searching="searching"></SearchDefault>
                    </div>
                </div>
                <PaginationDefault class="body"  v-bind:data="blog" type="post" v-bind:params="params" v-bind:showing="true" @updateData="updateData">
                    <template v-slot="pages" class="body-block" >
                        <div class="block" v-for="(item, key) in pages" :key="key">
                            <router-link tag="div" :style='{backgroundImage: "url("+item.img+")"}' class="block-top" :to="{name: 'media_page', params: {url: item.url, category: item.category}}">
                                <img class="play" src="/newfrontend/images/media-play.svg" alt="" v-if="item.category== 'video'">
<!--                                <img class="img" :src="item.img" >-->
                            </router-link>
                            <div class="block-body">
                                <div class="d-flex justify-space-between">
                                    <p>{{item.created_at | moment('MMM DD, YYYY')}}</p>
                                    <span>{{__(item.category)}}</span>
                                </div>
                                <p class="title">
                                    <router-link :to="{name: 'media_page', params: {url: item.url, category: item.category}}">
                                        {{item.title}}
                                    </router-link>
                                </p>
                                <div class="body-body">
                                    <!--                      <v-btn class="block-btn" v-on:click="video= item.url" v-if="item.category== 'video'">{{__('Play')}}</v-btn>-->
                                    <v-btn class="block-btn" :to="{name: 'media_page', params: {url: item.url, category: item.category}}">{{__('read more')}}
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.99981C0 4.83405 0.0658482 4.67508 0.183058 4.55787C0.300269 4.44066 0.45924 4.37481 0.625 4.37481H7.86625L5.1825 1.69231C5.06514 1.57495 4.99921 1.41578 4.99921 1.24981C4.99921 1.08384 5.06514 0.924671 5.1825 0.807313C5.29986 0.689955 5.45903 0.624023 5.625 0.624023C5.79097 0.624023 5.95014 0.689955 6.0675 0.807313L9.8175 4.55731C9.8757 4.61537 9.92188 4.68434 9.95339 4.76027C9.9849 4.8362 10.0011 4.9176 10.0011 4.99981C10.0011 5.08202 9.9849 5.16342 9.95339 5.23935C9.92188 5.31529 9.8757 5.38426 9.8175 5.44231L6.0675 9.19231C5.95014 9.30967 5.79097 9.3756 5.625 9.3756C5.45903 9.3756 5.29986 9.30967 5.1825 9.19231C5.06514 9.07495 4.99921 8.91578 4.99921 8.74981C4.99921 8.58384 5.06514 8.42467 5.1825 8.30731L7.86625 5.62481H0.625C0.45924 5.62481 0.300269 5.55896 0.183058 5.44175C0.0658482 5.32454 0 5.16557 0 4.99981Z" />
                                        </svg>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </template>
                </PaginationDefault>
            </div>
        </div>
        <div v-else class="loading">
            <loaderMc></loaderMc>
            {{__('Loading')}}
        </div>
    </div>

</template>

<script>
import SearchDefault from "../modules/SearchDefault";
import PaginationDefault from "../modules/PaginationDefault";
  import loaderMc from "../SvgComponents/loaderMc";
export default {
  name: 'media',
    data: function() {
        return {
            params: {
                category: (this.$route.params.category) ? this.$route.params.category : null,
                search: null,
                lang: null
            },
            video: false,
            search: null,
            blog: null,
            category: null,
        }
  },
  watch: {
      lang(val) {
          this.params.lang= val;
          this.getBlog();
      }
  },
  methods:{
      closeSearch() {
          this.search= null;
          this.searching();
      },
      changeCategory(category) {
          this.params.category= category;
          this.$router.push({name: 'media', params: {category: category}});
          this.getBlog();
      },
      searching() {
          this.params.search= this.search;

          this.getBlog();
      },
      updateData(response) {
          this.blog= response.blog;
          window.scrollTo(0,0);
      },
      getBlog() {
          let params= this.$cleanObject(this.params);

          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/blog', params)
              .then(response => {
                  return response.json()
              })
              .then((response) => {
                  this.$store.dispatch('asyncResp', response.resp_tickets);
                  response = response.data;
                  this.blog = response.blog;
                  // if(!this.category) {
                  this.category = response.category;
                  // }
              }).catch((err) => {
              this.httpHandler(err);
          });
      }
  },
  created() {
      this.params.lang= this.lang;
      this.getBlog();
  },
  computed: {
      lang() {
          return this.$store.state.language.lang
      }
  },
  mounted() {

  },
  components: {
      loaderMc,
      PaginationDefault,
      SearchDefault,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
