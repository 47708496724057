<template>
    <div class="licenses-page team-home" >
        <div class="team-home-top">
            <div class="container">
                <h1>Our licenses</h1>
                <div class="block-all">
<!--                    <div class="block">-->
<!--                        <div class="team">-->
<!--                            <div class="img">-->
<!--                                <img v-on:click="dialog = true" src="/newfrontend/s/sertificate-1.png" alt="">-->
<!--                            </div>-->
<!--                            <div class="name">-->
<!--                                <p class="description">The license in the free economic zone Silicon Oasis was issued to Mediavers for the development of its Metaverse (Mediaverse).</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="block">
                        <div class="team">
                            <div class="img">
                                <img v-on:click="dialog1 = true" src="/newfrontend/s/sertificate-2.png" alt="">
                            </div>
                            <div class="name">
                                <p class="description">Cryptocurrency exchange license</p>
                                <p class="description">Crypto wallet and custodian services license</p>
                            </div>
                        </div>
                    </div>
<!--                    <div class="block">-->
<!--                        <div class="team">-->
<!--                            <div class="img">-->
<!--                                <img v-on:click="dialog2 = true" src="/newfrontend/s/sertificate-3.jpg" alt="">-->
<!--                            </div>-->
<!--                            <div class="name">-->
<!--                                <p class="description">MEDIACHAIN FOR PARTIES & EVENTS FILMING CO. L.L.C</p>-->
<!--&lt;!&ndash;                                <p class="description">Crypto wallet and custodian services license</p>&ndash;&gt;-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="block">
                        <div class="team">
                            <div class="img">
                                <img v-on:click="dialog3 = true" src="/newfrontend/s/sertificate-4.png" alt="">
                            </div>
                            <div class="name">
                                <p class="description">Media System Venture Capital LTD</p>
                            </div>
                        </div>
                    </div>
                </div>

<!--                <ModalVideo v-if="video">-->
<!--                    <img src="/newfrontend/s/sertificate-3.jpg" alt="">-->
<!--                </ModalVideo>-->
                <ZoomImage v-if="dialog" v-bind:modal_cancel.sync="dialog">
                    <img src="/newfrontend/s/sertificate-1.png" alt="">
                </ZoomImage>

                <ZoomImage v-if="dialog1" v-bind:modal_cancel.sync="dialog1">
                    <img src="/newfrontend/s/sertificate-2.png" alt="">
                </ZoomImage>

                <ZoomImage v-if="dialog2" v-bind:modal_cancel.sync="dialog2">
                    <img src="/newfrontend/s/sertificate-3.jpg" alt="">
                </ZoomImage>

                <ZoomImage v-if="dialog3" v-bind:modal_cancel.sync="dialog3">
                    <img src="/newfrontend/s/sertificate-4.png" alt="">
                </ZoomImage>
            </div>
        </div>

    </div>

</template>

<script>
    import ZoomImage from "../modules/ZoomImages";
    // import ModalDefault from "../modules/ModalDefault";
    // import ModalVideo from "../modules/ModalVideo";
    // import VueSlickCarousel from 'vue-slick-carousel'
    export default {
        name: 'MeetTeam',
        data: function() {
            return {
                dialog: false,
                dialog1: false,
                dialog2: false,
                dialog3: false,
                team: null,
                settings: {
                    "centerMode": true,
                    "focusOnSelect": true,
                    "infinite": true,
                    "dots": false,
                    "arrows": true,
                    // "dotsClass": "slick-dots custom-dot-class",
                    // "edgeFriction": 0.35,
                    "speed": 500,
                    "slidesToShow": 3,
                    "slidesToScroll": 1,
                    "responsive": [
                        {
                            "centerMode": true,
                            "breakpoint": 1050,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 1,
                                "infinite": true,
                                "dots": false
                            }
                        },
                        {
                            "breakpoint": 600,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1
                            }
                        }
                    ]
                },


            }
        },
        watch: {

        },
        methods:{
        },
        created() {

            // this.$http.get('ajax/team')
            //     .then(response => {
            //         return response.json()
            //     })
            //     .then((response) => {
            //         this.team= response.data;
            //     }).catch((err) => {
            //     this.httpHandler(err);
            // });
        },
        computed: {

            // teamSlide() {
            //     let team= this.team.team;
            //     let c = Math.ceil(60/team.length);
            //     for (let x= 0; x< c; x++) {
            //         team= team.concat(this.team.team);
            //     }
            //     return team;
            // }
        },
        mounted() {

        },
        components: {
            // ModalVideo,
            // ModalDefault,
            ZoomImage
            // VueSlickCarousel
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    img {

        display: block;
        max-width: 100%;
        margin: auto;
    }
    /*.image {*/
    /*    width: 600px;*/
    /*    overflow: hidden;*/
    /*}*/
    /*.image img {*/
    /*    margin: auto;*/
    /*    display: block;*/
    /*    transition: opacity 0.5s;*/
    /*    width: 600px;*/
    /*}*/
    /*.image img:hover {*/
    /*    opacity: 0;*/
    /*}*/
    /*.image__wrap {*/
    /*    background-position: 50% 50%;*/
    /*    position: relative;*/
    /*    width: 100%;*/
    /*    overflow: hidden;*/
    /*    cursor: zoom-in;*/
    /*}*/
</style>
