<template>
    <div class="roadmap">
        <div class="container">
            <div class="top">
                <div class="block">
                    <p>roadmap</p>
                    <h1>roadmap</h1>
                </div>
            </div>
<!--            <ul class="body" v-if="roadmap">-->
<!--                <li v-for="(item, key) in roadmap" :key="key" :class="(item.active== 1) ? 'active '+((roadmap[key+1] && roadmap[key+1].active== 0) ? 'last-active' : '') : ''">-->
<!--                  <div class="block">-->
<!--                    <div class="top-block">-->
<!--                      <span>{{item.date}}</span>-->
<!--                      <div v-html="item.text"></div>-->
<!--&lt;!&ndash;                      <p>{{item.text}}</p>&ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </li>-->
<!--            </ul>-->
            <div v-if="roadmap">
                <VueSlickCarousel v-bind="settings" >
                    <div class="block" v-for="(item, key) in roadmap" :key="key">
                        <div class="top-block">
                            <img class="img" :src="`/newfrontend/images/roadmap/${getRandomImg(1,42)}.svg`" alt="">
                            <p class="done" v-if="item.active== 1">
                                <img src="/newfrontend/images/road-done.svg" alt="">
                                <span>Done</span>
                            </p>
                            <p class="soon" v-if="item.active== 0">
                                <img src="/newfrontend/images/road-soon.svg" alt="">
                                <span>coming soon</span>
                            </p>
                        </div>
                        <div class="name">
                            <p class="description">{{item.date}}</p>
                            <p class="text-g" v-html="item.text"></p>
                        </div>
                    </div>
                    <template #prevArrow>
                        <div class="custom-arrow btn-new" >
                            <span>
                                <img src="/newfrontend/images/road-left.svg" alt="">
                                prev
                            </span>
                        </div>
                    </template>
                    <template #nextArrow>
                        <div class="custom-arrow btn-new">
                            <span>
                                Next
                                <img src="/newfrontend/images/road-right.svg" alt="">
                            </span>
                        </div>
                    </template>
                </VueSlickCarousel>
            </div>

        </div>
    </div>

</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
export default {
  name: 'roadmap',
    data: function() {
        return {
          roadmap: null,
            settings: {
              // "initialSlide	": 33,
              // "edgeFriction": 10,
              //   "draggable": false,
                "centerMode": true,
                // "focusOnSelect": true,
                "infinite": false,
                "dots": false,
                "arrows": true,
                // "dotsClass": "slick-dots custom-dot-class",
                // "edgeFriction": 0.35,
                "speed": 500,
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "responsive": [
                    {
                        "centerMode": true,
                        "breakpoint": 1080,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 1,
                            "infinite": true,
                            "dots": false
                        }
                    },
                    {
                        "breakpoint": 900,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1
                        }
                    }
                ]
            },
        }
      },
      watch: {
        lang() {
          this.getRoadmap();
        }
      },
      methods:{
          getRoadmap() {
              this.$http.get('ajax/roadmap/'+this.lang)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      this.roadmap= response.data;
                  }).catch((err) => {
                  this.httpHandler(err);
              });
          },


          getRandomImg(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
          }
      },
      created() {
        this.getRoadmap();
      },
      computed: {
          // roadmap() {
          //     let team= this.roadmap.roadmap;
          //     let c = Math.ceil(60/team.length);
          //     for (let x= 0; x< c; x++) {
          //         team= team.concat(this.roadmap.roadmap);
          //     }
          //     return team;
          // },
          lang() {
              return this.$store.state.language.lang;
          }
      },
      mounted() {

      },
      components: {
          VueSlickCarousel
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
