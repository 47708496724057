<template>
    <ModalDefault v-if="dialog" v-bind:close="false" width="470">
        <div class="window-site withdraw-window modal-withdraw-closed">

            <p class="modal-withdraw-closed__title semi">{{__('Only 1 withdrawal request is generated per day')}}</p>

<!--            <p class="modal-withdraw-closed__title semi">{{__('modal_withdraw_closed_title')}}</p>-->
<!--            <p class="modal-withdraw-closed__desc semi">{{__('modal_withdraw_closed_description_morning')}}</p>-->
<!--            <p class="modal-withdraw-closed__desc-last semi">{{__('modal_withdraw_closed_description_evening')}}</p>-->


            <div class="modal-withdraw-closed__btn mt-5">
                <v-btn class="btn-site" v-on:click="modalCancel">{{__('modal_withdraw_closed_btn')}}</v-btn>
            </div>

        </div>
    </ModalDefault>
</template>

<script>
    import ModalDefault from '../../modules/ModalDefault';

    export default {
        data () {
            return {
                dialog: true,
                submitDisabled: false
            }
        },
        props: {
            withdraw_closed: {
                type: Boolean
            },
        },
        methods:{
            modalCancel() {
                this.$emit('update:withdraw_closed', false);
            }
        },
        created: function () {

        },
        watch: {

        },
        computed: {

        },
        components: {
            ModalDefault
        }
    }
</script>

<style scoped>

</style>
