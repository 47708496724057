<template>
  <ul class="social-list list d-flex">
    <li v-for="(item, key) in socials" :key="key">
      <a class="social-btn" @click="Share(item.type, (item.url) ? item.url : url, title, img, description)">
        <slot v-bind="item">
          {{item.name}}
        </slot>
      </a>
    </li>
  </ul>
</template>

<script>
    // Utilities

    export default {
        name: 'SocialShare',
        data: function () {
            return {

            }
        },
        props: {
            socials: {
                type: [
                    Array
                ],
            },
            url: {
                type: [
                    String
                ],
            },
            title: {
                type: [
                    String
                ],
            },
            img: {
                type: [
                    String
                ],
            },
            description: {
                type: [
                    String
                ],
            },
        },
        methods: {
            Share(type, purl, ptitle, pimg, text) {
                let url= '';

                switch (type) {
                    case 'vk': {
                        url  = 'http://vkontakte.ru/share.php?';
                        if(purl) {
                            url += 'url='          + encodeURIComponent(purl);
                        }
                        if(ptitle) {
                            url += '&title='       + encodeURIComponent(ptitle);
                        }
                        if(text) {
                            url += '&description=' + encodeURIComponent(text);
                        }
                        if(pimg) {
                            url += '&image='       + encodeURIComponent(pimg);
                        }
                        url += '&noparse=true';
                        break;
                    }
                    case 'facebook': {
                        url  = 'http://www.facebook.com/sharer.php?s=100';
                        if(ptitle) {
                            url += '&p[title]='     + encodeURIComponent(ptitle);
                        }
                        if(text) {
                            url += '&p[summary]='   + encodeURIComponent(text);
                        }
                        if(purl) {
                            url += '&p[url]='       + encodeURIComponent(purl);
                        }
                        if(pimg) {
                            url += '&p[images][0]=' + encodeURIComponent(pimg);
                        }
                        break;
                    }
                    case 'twitter': {
                        url = 'http://twitter.com/share?';
                        if(ptitle) {
                            url += 'text=' + encodeURIComponent(ptitle);
                        }
                        if(purl) {
                            url += '&url=' + encodeURIComponent(purl);
                        }
                        break;
                    }
                    case 'telegram': {
                        url  = 'https://t.me/share/url?';
                        if(purl) {
                            url += 'url='      + encodeURIComponent(purl);
                        }
                        if(ptitle) {
                            let content= (text) ? text : ptitle;

                            if(content.length > 255) {
                                content= content.substr(0, 255)+'...';
                            }

                            console.log(content);
                            url += '&text='      + encodeURIComponent(content);
                        }
                        break;
                    }
                }


                console.log(url);
                this.popup(url);
            },
            popup(url) {
                window.open(url,'','toolbar=0,status=0,width=626,height=436');
            }
        }
    }
</script>
