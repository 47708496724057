<template>
  <div v-if="loading">
    loading
  </div>
  <div style="background:transparent;" class="language" v-else>
    <v-menu offset-y v-if="languages && active_language" v-model="languages_menu">
      <template v-slot:activator="{ on }">
        <v-list class="pa-0 block-lang" style="background:transparent;">
          <v-list-item-action v-on="on">
            <!--                    <v-btn text>-->
            <v-list-item class="text-right">
              <img class="img-leng" :src="`/newfrontend/images/flags/${active_language.lang}.svg`" />
              <p class="language-full">
                <svg class="top-leng" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.2581 2H7.96774V4.90323H9.12903V3.16129H17.2581C17.6065 3.16129 17.8387 3.39355 17.8387 3.74194V10.129C17.8387 10.4774 17.6065 10.7097 17.2581 10.7097H9.70968V14.7742H6.63226L4.48387 16.4581V14.7742H2.74194C2.39355 14.7742 2.16129 14.5419 2.16129 14.1935V7.80645C2.16129 7.45807 2.39355 7.22581 2.74194 7.22581H10.2903V6.06452H2.74194C1.75484 6.06452 1 6.81936 1 7.80645V14.1935C1 15.1806 1.75484 15.9355 2.74194 15.9355H3.32258V18.8968L6.98065 15.9355H10.871V11.871H17.2581C18.2452 11.871 19 11.1161 19 10.129V3.74194C19 2.75484 18.2452 2 17.2581 2Z"/>
                  <path d="M3.43848 13.5548H4.83203L5.18041 12.6258H6.98041L7.3288 13.5548H8.72235L6.74815 8.38708H5.35461L3.43848 13.5548ZM6.05138 9.8387L6.63202 11.6387H5.47073L6.05138 9.8387Z"/>
                  <path d="M11.4517 10.1289C12.0904 10.1289 12.9613 9.95472 13.7742 9.54827C14.5871 9.95472 15.5162 10.1289 16.0968 10.1289V8.96763C16.0968 8.96763 15.5162 8.96763 14.8775 8.73537C15.5742 8.0386 16.0968 6.99343 16.0968 5.48376V4.90311H14.3549V3.74182H13.1936V4.90311H11.4517V6.0644H14.8775C14.7613 7.10956 14.2968 7.74827 13.7742 8.15472C13.4259 7.8644 13.0775 7.45795 12.8452 6.93537H11.6259C11.8581 7.69021 12.2065 8.27085 12.671 8.73537C12.0904 8.96763 11.5678 8.96763 11.4517 8.96763V10.1289Z" />
                </svg>
                <span>Language</span>
                {{active_language.full_lang}}
              </p>

              <img
                      :class="((languages_menu) ? 'active' : '')"
                      class="chevron"
                      src="/newfrontend/images/down-leng.svg"
                      alt
              />
            </v-list-item>
            <!--                    </v-btn>-->
          </v-list-item-action>
        </v-list>
      </template>
      <v-list class="block-all-lang ">
        <v-list-item
                class="text-right"
                v-for="(language, key) in languages"
                :key="key"
                v-on:click="selectLanguages(language.lang)"
                :class="(language.lang == active_language.lang) ? 'd-none' : ''"
        >
          <img
                  class=""
                  :src="'/newfrontend/images/flags/'+language.lang+'.svg'"
          />
          <p>{{language.full_lang}}</p>
<!--          {{language.full_lang}}-->
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else class="loading"></div>
  </div>
  <!-- <v-app  id="inspire">-->
  <!--      <v-overflow-btn-->
  <!--              class="dropdown"-->
  <!--              :items="languagesDropdown"-->
  <!--              target="#dropdown-example"-->
  <!--              v-model="active_languages"-->
  <!--              label="English"-->
  <!--              input-value="en"-->
  <!--      >-->
  <!--      </v-overflow-btn>-->
  <!--    </v-app> -->
</template>

<script>
    export default {
        name: "DropdownLanguages",
        data() {
            return {
                languages_menu: false,
                active_languages: null,
                loading: false,
                // dropdown_font: ["English", "Russian"],
            };
        },
        computed: {
            languages() {
                return this.$store.state.language.list;
            },
        },
        asyncComputed: {
            active_language() {
                let language = this.$store.state.language.lang,
                    languages = this.languages;

                if (language && languages) {
                    return this.getLanguage(language);
                } else {
                    return null;
                }
            },
        },
      components:{
      },
        methods: {
            selectLanguages: function (event) {
                let response= {
                    lang: event
                };
                // this.loading= true;
                localStorage.language= event;
                this.$store.commit("updateLanguage", event);
                this.languages_menu = false;
                this.$http
                    .post("ajax/set_locale", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        this.$store.dispatch('asyncResp', response.resp_tickets);
                        // this.loading= false;
                    })
                    .catch((err) => {
                        // this.loading= false;
                        this.httpHandler(err);
                    });




            },
            getLanguage: function (event) {
                let languages = this.languages;

                let language = languages.filter((item) => {
                    return item.lang == event;
                });
                return language.length ? language[0] : languages[0];
            },
        },
    };
</script>