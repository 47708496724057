<template>
  <div>
    <MessengerMessagesListItem @editMessages="editMessages" v-for="(message, key) in messages.data" :key="message.id" v-bind:message="message" v-bind:user_id="user_id" v-bind:index="key" v-bind:context_id="context_id" @openContextmenu="openContextmenu" @actionOptions="actionOptions"></MessengerMessagesListItem>
  </div>
</template>

<script>
    import MessengerMessagesListItem from "./MessengerMessagesListItem";

    export default {
        name: 'MessengerMessagesList',
        data () {
            return {
            }
        },
        props: {
            user_id: {
                type: [String, Number]
            },
            messages: {
                type: Object
            },
            context_id: {
                type: Number
            },
        },
        computed: {

        },
        asyncComputed: {

        },
        created() {
        },
        methods: {
            editMessages(id, val) {
                this.$emit('editMessages', id, val);
            },
            openContextmenu(index) {
                this.$emit('openContextmenu', index);
            },
            actionOptions(option) {
                this.$emit('actionOptions', option);
            },
        },
        components: {
            MessengerMessagesListItem
        },

    }
</script>
