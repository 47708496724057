<template>
  <div class="march-list" v-if="promo">
      <div class="content">
        <div class="top">
            <router-link :to="{name: 'dashboard'}">
                <img src="/newfrontend/images/wow-left.svg" alt="">
                <span>{{__('back to dashboard')}}</span>
            </router-link>
            <p class="center">{{__('Lottery participants')}}</p>
            <p class="text">{{__('You bought tickets')}}:
                <span>{{resp_tickets}}</span>
            </p>
        </div>
        <ul>
          <PaginationDefault v-bind:data.sync="promo" type="post" @updateData="updateData">
            <template v-slot="page">
              <li class="list" v-for="(item, key) in page" :key="key" :class="((item.user_public_id== portfolio.user_id) ? 'active' : '')">
                <div class="num">
                  <p># {{item.id}}</p>
                </div>
                <div class="id">
                  <p>{{String(item.user_public_id).substr(0,3)}}...{{String(item.user_public_id).substr(-3)}}</p>
                </div>
              </li>
            </template>

          </PaginationDefault>
        </ul>
      </div>
  </div>
  <div v-else class="loading">
      <loaderMc></loaderMc>
    {{__('loading')}}
  </div>
</template>

<script>
    import loaderMc from "../SvgComponents/loaderMc";
    import PaginationDefault from "../modules/PaginationDefault";
    export default {
        name: 'march_list',
        data () {
            return {
                promo: null,
                resp_tickets: null,
            }
        },
        computed: {

            portfolio() {
                return this.$store.state.auth.portfolio
            },
        },
        created() {
            this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/promo/list')
                .then(response => {
                    return response.json()
                })
                .then((response) => {
                    response= response.data;

                    this.promo= response.promo;
                    this.resp_tickets= response.resp_tickets;
                }).catch((err) => {
                this.httpHandler(err);
            });
        },
        asyncComputed: {

        },
        methods: {
            updateData(response) {
                this.promo= response.promo;
            },
        },
        components: {
            PaginationDefault,
            loaderMc,
        },

    }
</script>
