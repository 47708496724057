<template>
    <v-dialog v-model="dialog" max-width="650px" content-class="modal-school-video">
        <span class="close-video" v-on:click="cancelModal">
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71209 3.71209C4.0782 3.34597 4.6718 3.34597 5.03791 3.71209L10 8.67418L14.9621 3.71209C15.3282 3.34597 15.9218 3.34597 16.2879 3.71209C16.654 4.0782 16.654 4.6718 16.2879 5.03791L11.3258 10L16.2879 14.9621C16.654 15.3282 16.654 15.9218 16.2879 16.2879C15.9218 16.654 15.3282 16.654 14.9621 16.2879L10 11.3258L5.03791 16.2879C4.6718 16.654 4.0782 16.654 3.71209 16.2879C3.34597 15.9218 3.34597 15.3282 3.71209 14.9621L8.67418 10L3.71209 5.03791C3.34597 4.6718 3.34597 4.0782 3.71209 3.71209Z" fill="currentColor"/>
            </svg>
        </span>
        <div class="modal-video">
            <iframe ref="video" width="100%" height="315" :src="'https://www.youtube.com/embed/'+video+'?rel=0&showinfo=0&autoplay=1'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: 'ModalVideo',
        data: function() {
            return {
                dialog: true,
            }
        },
        props: {
            video: {
                type: [String]
            },
            open: {
                type: [Boolean]
            },
        },
        mounted() {

        },
        computed: {

        },
        watch: {
            dialog() {
                this.cancelModal();
            }
        },
        methods: {
            cancelModal() {
                this.$emit("update:open", false);
            }
        },
        components: {
        },
    };
</script>
