<template>
  <div class="academy">

    <div class="top-block">
        <div class="left">
            <router-link :to="{name: 'products'}" class="back">
                <img src="/newfrontend/images/arrow-back.svg" alt="">
                <p>{{__('Back')}}</p>
            </router-link>
        </div>
        <div class="center">
            <div class="center-content">
                <a v-for="(item, key) in courses_list" :key="key" v-on:click="params.type = item.type" :class="(params.type== item.type) ? 'active': ''">{{item.name}}</a>
            </div>
        </div>
        <div class="right" v-if="authors">
            <DropdownDefault
                    class-dropdown="school-drop"
                    v-model="author"
                    v-bind:items="authors"
                    value_key="id"
                    text="name"
                    dropSlot
            >
              <template v-slot:selection="item">
                {{item.name}}
              </template>
              <template v-slot:item="item">
                {{item.name}}
              </template>
            </DropdownDefault>
        </div>
    </div>
    <div v-if="loading">
      <div class="not-open" v-if="course[params.type+'_'+params.author] && course[params.type+'_'+params.author]== 'close'">
        <img src="/newfrontend/images/noun-lock.png" alt="">
        <p>{{__('This is a closed section of the site!')}}</p>
        <span class="text">{{__('In order to access this section, write to us in our telegram chat')}}</span>
        <a href="https://t.me/mediachaininc" target="_blank">
          <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4809 0.691699C15.6868 0.605024 15.9122 0.575131 16.1336 0.60513C16.355 0.635128 16.5643 0.723922 16.7398 0.86227C16.9152 1.00062 17.0504 1.18346 17.1312 1.39177C17.212 1.60007 17.2355 1.82623 17.1992 2.0467L15.3092 13.5109C15.1259 14.6167 13.9125 15.2509 12.8984 14.7C12.05 14.2392 10.79 13.5292 9.6567 12.7884C9.09004 12.4175 7.3542 11.23 7.56754 10.385C7.75087 9.66253 10.6675 6.94753 12.3342 5.33336C12.9884 4.6992 12.69 4.33336 11.9175 4.9167C9.99837 6.36503 6.9192 8.56753 5.90087 9.18753C5.00254 9.7342 4.5342 9.82753 3.9742 9.7342C2.95254 9.5642 2.00504 9.30087 1.2317 8.98003C0.186704 8.5467 0.237537 7.11003 1.23087 6.6917L15.4809 0.691699Z" fill="white"/>
          </svg>
          <span>{{__('write to telegram')}}</span>
        </a>
      </div>
      <PaginationDefault v-bind:data="course[params.type+'_'+params.author]" type="post" v-bind:params="params" v-bind:showing="true" @updateData="updateData" v-else-if="course[params.type+'_'+params.author]">
        <template v-slot="pages">
          <ul  class="body-block">
            <li v-for="(item, key) in pages" :key="key">
                <router-link :to="{name: 'academy_page', params: {url: item.url}}" >
                  <div class="img" :style="{'background-image':'url('+item.img+')', 'border-radius': '8px', 'background-size': 'cover'}"></div>
                  <div class="text">
                        <span>{{__('Lecture course')}}</span>
                        <router-link tag="p" :to="{name: 'academy_page', params: {url: item.url}}">{{item.title}}</router-link>
                        <span v-on:click="author= item.author.id">{{item.author.name}}</span>
                    </div>
              </router-link>
            </li>
          </ul>
        </template>
      </PaginationDefault>
    </div>
      <div v-else class="loading">
          <loaderMc></loaderMc>
          {{__('Loading')}}
      </div>
  </div>
</template>

<script>

    import loaderMc from "../SvgComponents/loaderMc";
  import PaginationDefault from "../modules/PaginationDefault";
import DropdownDefault from "../modules/DropdownDefault";
export default {
  name: 'Academy',
    data: function() {
        return {
            course: {

            },
            author: null,
            authors: null,
            courses_list: [
                {
                    name: this.__('open_with_deposit'),
                    type: 1
                },
                {
                    name: this.__('Closed to 19 lvl'),
                    type: 3
                },
                {
                    name: this.__('leadership_council'),
                    type: 2
                },
            ],
            close: true,
            loading: false,
            params: {
                type: 1,
                author: null,
            },
        }
  },
  watch: {
      author(val) {
          this.params.author= val;
          this.getCreate();
      },
      'params.type'() {
          this.getCreate();
      }
  },
  methods:{

      updateData(response) {
          let params= this.params;

          this.course[params.type+'_'+params.author]= response.course;
          window.scrollTo(0,0);
      },
      getCreate() {
          let params= this.$cleanObject(this.params);


          if(!this.course[params.type+'_'+params.author]) {
              this.loading= false;

              this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/academy/courses', params)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.loading= true;
                      this.close= false;
                      response = response.data;
                      this.course[params.type+'_'+params.author] = response.course;

                      // }
                  }).catch((err) => {
                      this.loading= true;
                      this.close= true;
                      this.course[params.type+'_'+params.author] = 'close';
                      this.httpHandler(err);
                  });
          }
      }

  },
  created() {
      this.$http[(process.env.NODE_ENV === 'production') ? 'get' : 'get']('ajax/academy/authors')
          .then(response => {
              return response.json()
          })
          .then((response) => {
              response = response.data;

              let authors= response.author;

              authors.unshift({
                  id: null,
                  name: this.__('All authors')
              });

              this.authors = authors;
              // }
          }).catch((err) => {
          this.httpHandler(err);
      });

      this.getCreate();
  },
  computed: {
      lang() {
          return this.$store.state.language.lang
      }
  },
  mounted() {
  },
  components: {
      loaderMc,
      PaginationDefault,
      DropdownDefault
  }
}
</script>

<style scoped>

</style>
