<template>
<div class="privacy">
        <div class="container">
            <div class="content">
                <h1>{{__('TERMS OF SERVICE')}}</h1>
                <p class="text">{{__('Date of last update: November 1, 2021')}}</p>
                <p class="text">{{__('By accessing or using any of the services provided by Mediacoin through the website (HTTPS) or any other related products or applications provided by Mediacoin (collectively "Services" or "Products"), you agree that you have read, understood, and have accepted all the terms and conditions contained in this Agreement, as well as our Privacy Policy. If you do not agree, you must not access or use the Services.')}}</p>
                <p class="description">{{__('Changes to Terms')}}</p>
                <p class="text">{{__('Mediacoin reserves the right to change or modify the Terms at its sole discretion at any time. Any modifications or changes to these Terms will be effective immediately upon posting on the website. You are required to update your contact information, including but not limited to the email address you provide to us; failure to do so may result in you not receiving notice of any such changes to the Terms.')}}</p>
                <p class="description">{{__('Description of services')}}</p>
                <p class="text">{{__('A detailed list of currently available Services and their specifications can be found below in this section. We reserve the right to change and update the list of available products and their content at any time.')}}</p>
                <p class="description">{{__('Mediacoin platform')}}</p>
                <p class="text">{{__('Provides the hosted user interface provided by Mediacoin. The platform provides access to potential interaction opportunities for virtual currency exchange in the form of real-time data and other products of the Company.')}}</p>
                <p class="description">{{__('Platform awards')}}</p>
                <p class="text">{{__('We reserve the right to provide users with various types of rewards in the form of the respective virtual currency or in any form at our discretion and without prior notice.')}}</p>
                <p class="description">{{__('User')}}</p>
                <p class="text">{{__('Account An “Account” is a user account accessible after the registration process and through the Products, where virtual currencies can be stored and exchanged by the User. To access and use our products, you must register for an account.')}}</p>
                <p class="description">{{__('Registration')}}</p>
                <p class="text">{{__('To register an account, the User must provide a valid email address, his first and last name and referral code, as well as accept the Terms of Service and Privacy Policy. The information provided during registration is used to verify the user and may be used for communication purposes, product updates, etc.')}}</p>
                <p class="description">{{__('Accuracy of information')}}</p>
                <p class="text">{{__('You hereby represent and warrant that any information you provide through the Products is accurate and complete. You understand and acknowledge that we are not responsible for any errors or omissions that you personally make in connection with any transactions initiated using the Products.')}}</p>
                <p class="description">{{__('Compliance obligations')}}</p>
                <p class="text">{{__('Products may not be available or may not be suitable for use in all jurisdictions. By accessing or using the Services, you agree that you are solely and solely responsible for complying with all laws and regulations that may apply to you. You also agree that we are under no obligation to inform you of any potential obligations or violations of law or regulation that may arise in connection with your access to and use of the Interface, and that we are not responsible in any way for any failure by you to comply with any applicable law or regulation.')}}</p>
                <p class="description">{{__('Eligibility Criteria')}}</p>
                <p class="text">{{__('By using or accessing our Products, you represent and represent that:')}}</p>
                <p class="text">{{__('- You are at least 18 years of age or of legal age to enter into a binding contract under applicable law in accordance with your local jurisdiction;')}}</p>
                <p class="text">{{__('- You are an individual with full legal capacity and the right to enter into these Terms;')}}</p>
                <p class="text">{{__('- You will not use our Products or stop using them immediately if any applicable law in your country prohibits you from doing so at any time;')}}</p>
                <p class="description">{{__('Usage Guidelines')}}</p>
                <p class="text">{{__('When you access or use the Products, you agree that you will not take any action in such a way that:')}}</p>
                <p class="text">{{__('- Violates the Terms;')}}</p>
                <p class="text">{{__('- Infringes or infringes any copyrights, trademarks, service marks, patents, publicity, privacy or other property or intellectual property rights as required by law;')}}</p>
                <p class="text">{{__('- Seeks to interfere with or compromise the integrity, security, or proper functioning of any computer, server, network, personal device or other information technology system, including but not limited to the deployment of viruses and denial of service attacks;')}}</p>
                <p class="text">{{__('- Attempts in any way to obtain a private key, password, account or other security information from any other user, including such digital wallet information;')}}</p>
                <p class="text">{{__('- Seeks to defraud us or any other person or entity, including, but not limited to, providing false, inaccurate or misleading information in order to illegally obtain the property of another person;')}}</p>
                <p class="text">{{__('- Masks or in any way interferes with the IP address of the computer you use to access or use the Interface, or otherwise prevents us from correctly identifying the IP address of the computer you use to access the Interface;')}}</p>
                <p class="text">{{__('- Transfers, exchanges or otherwise supported by direct or indirect proceeds from criminal or fraudulent activities;')}}</p>
                <p class="text">{{__('- Promotes or facilitates any of the above activities.')}}</p>
                <p class="description">{{__('Risks associated with virtual currencies')}}</p>
                <p class="text">{{__('By accessing and using the Services, you thereby acknowledge that you understand the inherent risks associated with using distributed ledger networks and that you have a working knowledge of the use and intricacies of virtual currencies. You hereby agree that we are not responsible for the operation, functionality or security of networks with distributed ledger technology and are not responsible for any loss of token value that you may experience due to any operational changes, malfunctions or failures in such networks. ... You also understand that the markets for these digital tokens are highly volatile and that there are significant risks associated with digital tokens, including loss of value. As with any asset, the value of virtual currencies is subject to dramatic changes and can rise or fall, which gives a significant chance of making a profit, as well as losing money when buying, selling, storing or investing in virtual currencies. When entering into any transaction, you represent that you were, are and will be solely responsible for conducting your own independent assessment of the risks of the transaction and the underlying digital asset.')}}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Terms',
    data: function() {
        return {
            tab: null,
        }
  },
  watch: {

  },

    methods:{

    },
  computed: {

  },
  mounted() {

  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
