<template>
    <li class="line-p" style="width: 100%;  position: relative">
      <div class="line-partner">
        <div class="btn-partner">
          <v-btn class="" v-on:click="getNextHistory" v-if="item.ref_count">
            <span class="close" v-if="show">
            </span>
            <span class="open" v-else>
            </span>
          </v-btn>
        </div>

        <div class="name">
          <span class="mob-span">{{__('Partner')}}</span>
          <div class="name-body">
            <div class="img">
              <img  :src="(item.photo) ? '/assets/uploads/user/'+item.photo : '/newfrontend/images/default.png'"/>
            </div>
            <div>
              <p class="item-name">{{item.name}}</p>
              <p class="more-block" v-on:click="dialog= true">Подробнее</p>
              <p class="email" > <span class="phone" v-if="item.phone">{{item.phone}}</span><span class="phone" v-else-if="item.telegram">{{item.telegram}}</span></p>
            </div>
          </div>
        </div>
        <div class="summ">
          <p>{{$primNum(item.turnover) | formatThousands}} <span>usdt</span></p>
          <span class="mob">{{__('Volum in farming')}}</span>
        </div>
        <div class="dep">
          <p>{{$primNum(item.deposit) | formatThousands}} <span>usdt</span></p>
          <span class="mob">{{__('Personal deposit')}}</span>
        </div>
        <div class="bon">
          <span class="mob-span">{{__('rank')}}</span>
            <div v-if="item.rank == 0">
              <img src="/newfrontend/images/rank-1.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 1">
              <img src="/newfrontend/images/rank-1.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 2">
              <img src="/newfrontend/images/rank-1.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 3">
              <img src="/newfrontend/images/rank-1.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 4">
              <img src="/newfrontend/images/rank-1.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 5">
              <img src="/newfrontend/images/rank-1.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 6">
              <img src="/newfrontend/images/rank-1.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 7">
              <img src="/newfrontend/images/rank-2.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 8">
              <img src="/newfrontend/images/rank-2.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 9">
              <img src="/newfrontend/images/rank-2.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 10">
              <img src="/newfrontend/images/rank-2.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 11">
              <img src="/newfrontend/images/rank-2.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 12">
              <img src="/newfrontend/images/rank-3.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 13">
              <img src="/newfrontend/images/rank-3.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 14">
              <img src="/newfrontend/images/rank-3.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 15">
              <img src="/newfrontend/images/rank-3.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 16">
              <img src="/newfrontend/images/rank-3.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 17">
              <img src="/newfrontend/images/rank-4.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 18">
              <img src="/newfrontend/images/rank-4.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 19">
              <img src="/newfrontend/images/rank-5.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 20">
              <img src="/newfrontend/images/rank-6.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 21">
              <img src="/newfrontend/images/rank-6.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 22">
              <img src="/newfrontend/images/rank-7.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 23">
              <img src="/newfrontend/images/rank-7.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 24">
              <img src="/newfrontend/images/rank-8.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 25">
              <img src="/newfrontend/images/rank-9.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 26">
              <img src="/newfrontend/images/rank-10.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 27">
              <img src="/newfrontend/images/rank-11.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 28">
              <img src="/newfrontend/images/rank-11.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 29">
              <img src="/newfrontend/images/rank-12.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 30">
              <img src="/newfrontend/images/rank-13.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 31">
              <img src="/newfrontend/images/rank-14.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 32">
              <img src="/newfrontend/images/rank-14.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 33">
              <img src="/newfrontend/images/rank-16.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 34">
              <img src="/newfrontend/images/rank-17.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 35">
              <img src="/newfrontend/images/rank-18.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 36">
              <img src="/newfrontend/images/rank-18.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 37">
              <img src="/newfrontend/images/rank-19.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 38">
              <img src="/newfrontend/images/rank-19.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>
            </div>
            <div v-if="item.rank == 39">
              <img src="/newfrontend/images/rank-20.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>
            </div>
            <div v-if="item.rank == 40">
              <img src="/newfrontend/images/rank-21.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>

            </div>
            <div v-if="item.rank == 41">
              <img src="/newfrontend/images/rank-21.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>
            </div>
            <div v-if="item.rank == 42">
              <img src="/newfrontend/images/rank-22.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>
            </div>
            <div v-if="item.rank == 43">
              <img src="/newfrontend/images/rank-22.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>
            </div>
            <div v-if="item.rank == 44">
              <img src="/newfrontend/images/rank-23.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>
            </div>
            <div v-if="item.rank == 45">
              <img src="/newfrontend/images/rank-24.png" alt="">
              <div class="text-block">
                <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
              </div>
            </div>
            <!--              <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>-->

<!--          <p><img src="/newfrontend/images/king-team.svg" alt="">  </p>-->
        </div>
      </div>

      <History v-bind:history.sync="history" :params="params" v-if="show" v-bind:position="position+1"></History>
      <ModalDefault width="400px" v-if="dialog" v-bind:modal_cancel.sync="dialog">
        <div class="modal-partner  window-site">
          <div class="top">
            <div class="name">
              <span class="mob-span">{{__('Partner')}}</span>
              <div class="name-body">
                <div class="img">
                  <img width="35px" :src="(item.photo) ? '/assets/uploads/user/'+item.photo : '/newfrontend/images/default.png'"/>
                </div>
                <div>
                  <p>{{item.name}}</p>
                </div>
              </div>
            </div>

          </div>
          <div class="body">
            <div class="summ">
              <p>${{$primNum(item.turnover) | formatThousands}}</p>
              <span>{{__('Volum in farming')}}</span>
            </div>
            <div class="dep">
              <p>${{$primNum(item.deposit) | formatThousands}}</p>
              <span>{{__('Personal deposit')}}</span>
            </div>
          </div>
          <div class="bon">
            <div class="rank">
              <div v-if="item.rank == 0">
                <img src="/newfrontend/images/rank-1.png" alt="">
                <div class="text-block">
                  <p>{{__('Инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 1">
                <img src="/newfrontend/images/rank-1.png" alt="">
                <div class="text-block">
                  <p>{{__('Инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 2">
                <img src="/newfrontend/images/rank-1.png" alt="">
                <div class="text-block">
                  <p>{{__('Инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 3">
                <img src="/newfrontend/images/rank-1.png" alt="">
                <div class="text-block">
                  <p>{{__('Инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 4">
                <img src="/newfrontend/images/rank-1.png" alt="">
                <div class="text-block">
                  <p>{{__('Инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 5">
                <img src="/newfrontend/images/rank-1.png" alt="">
                <div class="text-block">
                  <p>{{__('Инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 6">
                <img src="/newfrontend/images/rank-1.png" alt="">
                <div class="text-block">
                  <p>{{__('Инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 7">
                <img src="/newfrontend/images/rank-2.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 8">
                <img src="/newfrontend/images/rank-2.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 9">
                <img src="/newfrontend/images/rank-2.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 10">
                <img src="/newfrontend/images/rank-2.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 11">
                <img src="/newfrontend/images/rank-2.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 12">
                <img src="/newfrontend/images/rank-3.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 13">
                <img src="/newfrontend/images/rank-3.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 14">
                <img src="/newfrontend/images/rank-3.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 15">
                <img src="/newfrontend/images/rank-3.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 16">
                <img src="/newfrontend/images/rank-3.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 17">
                <img src="/newfrontend/images/rank-4.png" alt="">
                <div class="text-block">
                  <p>{{__('Платиновый инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 18">
                <img src="/newfrontend/images/rank-4.png" alt="">
                <div class="text-block">
                  <p>{{__('Платиновый инвестор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 19">
                <img src="/newfrontend/images/rank-5.png" alt="">
                <div class="text-block">
                  <p>{{__('Партнёр')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 20">
                <img src="/newfrontend/images/rank-6.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый партнёр')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 21">
                <img src="/newfrontend/images/rank-6.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый партнёр')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 22">
                <img src="/newfrontend/images/rank-7.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой партнёр')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 23">
                <img src="/newfrontend/images/rank-7.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой партнёр')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 24">
                <img src="/newfrontend/images/rank-8.png" alt="">
                <div class="text-block">
                  <p>{{__('Платиновый партнёр')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 25">
                <img src="/newfrontend/images/rank-9.png" alt="">
                <div class="text-block">
                  <p>{{__('Дистрибьютор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 26">
                <img src="/newfrontend/images/rank-10.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый дистрибьютор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 27">
                <img src="/newfrontend/images/rank-11.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой дистрибьютор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 28">
                <img src="/newfrontend/images/rank-11.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой дистрибьютор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 29">
                <img src="/newfrontend/images/rank-12.png" alt="">
                <div class="text-block">
                  <p>{{__('Платиновый дистрибьютор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 30">
                <img src="/newfrontend/images/rank-13.png" alt="">
                <div class="text-block">
                  <p>{{__('Лидер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 31">
                <img src="/newfrontend/images/rank-14.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый лидер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 32">
                <img src="/newfrontend/images/rank-14.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой лидер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 33">
                <img src="/newfrontend/images/rank-16.png" alt="">
                <div class="text-block">
                  <p>{{__('Платиновый лидер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 34">
                <img src="/newfrontend/images/rank-17.png" alt="">
                <div class="text-block">
                  <p>{{__('Директор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 35">
                <img src="/newfrontend/images/rank-18.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый директор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 36">
                <img src="/newfrontend/images/rank-18.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый директор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 37">
                <img src="/newfrontend/images/rank-19.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой директор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 38">
                <img src="/newfrontend/images/rank-19.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой директор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>
              </div>
              <div v-if="item.rank == 39">
                <img src="/newfrontend/images/rank-20.png" alt="">
                <div class="text-block">
                  <p>{{__('Платиновый директор')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>
              </div>
              <div v-if="item.rank == 40">
                <img src="/newfrontend/images/rank-21.png" alt="">
                <div class="text-block">
                  <p>{{__('Акционер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>

              </div>
              <div v-if="item.rank == 41">
                <img src="/newfrontend/images/rank-21.png" alt="">
                <div class="text-block">
                  <p>{{__('Акционер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>
              </div>
              <div v-if="item.rank == 42">
                <img src="/newfrontend/images/rank-22.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый акционер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>
              </div>
              <div v-if="item.rank == 43">
                <img src="/newfrontend/images/rank-22.png" alt="">
                <div class="text-block">
                  <p>{{__('Бронзовый акционер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>
              </div>
              <div v-if="item.rank == 44">
                <img src="/newfrontend/images/rank-23.png" alt="">
                <div class="text-block">
                  <p>{{__('Золотой акционер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>
              </div>
              <div v-if="item.rank == 45">
                <img src="/newfrontend/images/rank-24.png" alt="">
                <div class="text-block">
                  <p>{{__('Платиновый акционер')}}</p>
                  <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>
                </div>


              </div>
<!--              <p class="rank-text"><span>{{item.rank}} {{__('Rank')}}</span></p>-->
            </div>
          </div>
          <div class="block">
            <div class="email-block">
<!--              <InputLabelTextDefault copy :value="item.email"></InputLabelTextDefault>-->
<!--              <p class="email" v-if="item.email">{{item.email}} </p>-->
            </div>
            <div class="phone-block" v-if="item.phone">
              <InputLabelTextDefault copy :value="item.phone"></InputLabelTextDefault>
<!--              <span class="phone">{{item.phone}}</span>-->
            </div>
            <div class="phone-block" v-else-if="item.telegram">
              <InputLabelTextDefault copy :value="item.telegram"></InputLabelTextDefault>
<!--              <span class="phone">{{item.phone}}</span>-->
            </div>
            <div class="btn-partner">
              <v-btn class="btn-green-new" v-on:click="dialog= false">Close</v-btn>
            </div>
          </div>
        </div>
      </ModalDefault>
    </li>

</template>

<script>
    import ModalDefault from "../../modules/ModalDefault";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    export default {
        name: 'HistoryItem',
        components: {
          InputLabelTextDefault,
          ModalDefault,
            History: () => import('./History.vue')
        },
        data: function() {
            return {
                history: null,
                show: false,
                dialog: false,
                params: {
                    user_id: this.item.id
                },
            }
        },
        created() {

        },
        computed: {

        },
        methods: {
            getNextHistory() {
                this.show= !this.show;

                if (this.show && !this.history) {
                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/affiliate/list', this.params)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            this.$store.dispatch('asyncResp', response.resp_tickets);
                            this.history= response.data;
                        }).catch((err) => {
                        this.httpHandler(err);
                    });
                }
            }
        },
        props: {
            item: {
                type: Object
            },
            position: {
                type: Number
            }
        }
    }
</script>

<style>

</style>
