<template>
    <div v-if="dialog" style="position: relative">
        <v-row justify="center">

            <v-dialog
                    content-class="dialog-s"
                    v-model="dialog" persistent :max-width="width"
                    v-on:click:outside="modalClose"
            >

                <div class="white " style="position: relative">
                    <span class="close" v-if="close" v-on:click="modalClose"></span>
                    <slot></slot>
                </div>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    export default {

        name: 'ModalDefault',
        data: function() {
            return {
                dialog: true,
                submitDisabled: false
            }
        },
        props: {
            modal_cancel: {
                type: [Boolean, Object, Array, String, Number]
            },
            width: {
                type: String,
                default: '560px'
            },
            close: {
                type: Boolean,
                default: true
            }

        },
        computed: {

        },
        watch: {

        },
        methods: {
            modalClose: function () {
                console.log('test');
                this.$emit('modalCancel', this.modal_cancel);
                this.$emit('update:modal_cancel', false);
            }
        },
        components: {
        },
    };
</script>
<style scoped>
    .dialog-s{
        position: relative;
    }
    .v-dialog .close-s {
        position: absolute;
        width: 20px;
        right: 0px;
        top: 20px;
        height: 20px;
        cursor: pointer;
        background-color: #fff;
        opacity: 0.6;
        background-size: 14px;
        background-image: url(../../../public/images/close-window-s.svg);
    }
</style>