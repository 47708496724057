<template>
  <div class="material">
    <div class="presentation">
      <div class="left">
        <p>{{__('Presentations')}}</p>
      </div>
      <div class="right">
       <DropdownDefault
           v-model="lang"
           v-bind:items="languages"
           value_key="lang"
           text="full_lang"
           dropSlot
         >
         <template v-slot:selection="item">
           <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
           {{__(item.full_lang)}}
         </template>
         <template v-slot:item="item">
           <img class="pres-drop" :src="`/newfrontend/images/flags/${item.lang}.svg`">
           {{__(item.full_lang)}}
         </template>
       </DropdownDefault>
        <div>
<!--          <a class="btn-white">{{__('Open-promo')}}</a>-->
<!--          <a download class="btn-site">{{__('Download')}}</a>-->
          <a :href="`https://mediachain.inc/promo/mediacoin-${lang}.pdf`" target="_blank" class="btn-white">{{__('Open-promo')}}</a>
          <a :href="`https://mediachain.inc/promo/mediacoin-${lang}.pdf`" download class="btn-site">{{__('Download')}}</a>
        </div>
       </div>
    </div>
    <div class="b-offline">
      <div class="left">
        <p>{{__('Offline banner')}}</p>
      </div>
      <div class="right" v-if="banners_offline">
        <DropdownDefault
          v-model="banner_offline"
          v-bind:items="banners_offline[language]"
          value_key="value"
          text="title"
          dropSlot
        >
          <template v-slot:selection="item">
            {{__(item.title)}}
          </template>
          <template v-slot:item="item">
            {{__(item.title)}}
          </template>
        </DropdownDefault>
        <div>
          <a :href="`https://mediachain.inc/banners/${banner_offline}`" download class="btn-site">{{__('Download')}}</a>
        </div>
      </div>
    </div>
    <div class="promo">
      <p class="description">{{__('Web banner')}}</p>
<!--      <pre>{{bannersKey}}</pre>-->
<!--      <div class="promo-coming">-->
<!--        <p>{{__('Coming soon!')}}</p>-->
<!--        <img src="/newfrontend/images/coming-soon.png" alt="">-->
<!--      </div>-->
      <v-tabs
              v-model="tabs"
              centered
      >
        <v-tab
            v-for="item in bannersKey"
            :key="item.tab"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item v-for="(item, key) in banners" :key="key">
          <v-card flat>
            <v-card-text>
              <p class="text">{{__('Banner size')}} {{item.width}}×{{item.height}}</p>
<!--              <img width='160' height='600' src='https://mediacoin.inc/b/160_600.gif'/>-->
              <div class="block-img">
                <div :style="'min-width:' + item.width + 'px'" class="wrap-img" v-html="item.img"></div>
              </div>
              <div class="material-text">
                <p class="text">{{__('HTML Code')}}</p>
                <span>{{__('Can be used to display banner on websites or blogs.')}}</span>
              </div>
              <InputLabelTextDefault readonly :value="item.href" copy></InputLabelTextDefault>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!--<v-tab-item>
          <v-card flat>
            <v-card-text>
              <p class="text">{{__('Banner size')}} 468x60</p>
              <img class="img img-468" src="/newfrontend/b/468-60.gif" alt="">
              <div class="material-text">
                <p class="text">{{__('HTML Code')}}</p>
                <span>{{__('Can be used to display banner on websites or blogs.')}}</span>
              </div>
              <InputLabelTextDefault readonly :value="'<a target=\'_blank\' href=\''+portfolio.ref_url+'\'><img width=\'468\' height=\'60\' src=\'https://Media.network/newfrontend/b/468-60.gif\'/></a>'" copy></InputLabelTextDefault>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p class="text">{{__('Banner size')}} 300x350</p>
              <img class="img " src="/newfrontend/b/300-350.gif" alt="">
              <div class="material-text">
                <p class="text">{{__('HTML Code')}}</p>
                <span>{{__('Can be used to display banner on websites or blogs.')}}</span>
              </div>
              <InputLabelTextDefault readonly :value="'<a target=\'_blank\' href=\''+portfolio.ref_url+'\'><img width=\'300\' height=\'350\' src=\'https://Media.network/newfrontend/b/300-350.gif\'/></a>'" copy></InputLabelTextDefault>
            </v-card-text>
          </v-card>
        </v-tab-item>
        &lt;!&ndash;<v-tab-item>&ndash;&gt;
        &lt;!&ndash;<v-card flat>&ndash;&gt;
        &lt;!&ndash;<v-card-text>&ndash;&gt;
        &lt;!&ndash;<p class="text">{{__('Banner size')}} 300x600</p>&ndash;&gt;
        &lt;!&ndash;<img class="img " src="/newfrontend/b/300-600.gif" alt="">&ndash;&gt;
        &lt;!&ndash;<div class="material-text">&ndash;&gt;
        &lt;!&ndash;<p class="text">{{__('HTML Code')}}</p>&ndash;&gt;
        &lt;!&ndash;<span>{{__('Can be used to display banner on websites or blogs.')}}</span>&ndash;&gt;
        &lt;!&ndash;</div>&ndash;&gt;
        &lt;!&ndash;<InputLabelTextDefault readonly :value="'<a target=\'_blank\' href=\''+portfolio.ref_url+'\'><img width=\'300\' height=\'600\' src=\'https://Media.network/newfrontend/b/300-600.gif\'/></a>'" copy></InputLabelTextDefault>&ndash;&gt;
        &lt;!&ndash;</v-card-text>&ndash;&gt;
        &lt;!&ndash;</v-card>&ndash;&gt;
        &lt;!&ndash;</v-tab-item>&ndash;&gt;
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p class="text">{{__('Banner size')}} 250x250</p>
              <img class="img " src="/newfrontend/b/250-250.gif" alt="">
              <div class="material-text">
                <p class="text">{{__('HTML Code')}}</p>
                <span>{{__('Can be used to display banner on websites or blogs.')}}</span>
              </div>
              <InputLabelTextDefault readonly :value="'<a target=\'_blank\' href=\''+portfolio.ref_url+'\'><img width=\'250\' height=\'250\' src=\'https://Media.network/newfrontend/b/250-250.gif\'/></a>'" copy></InputLabelTextDefault>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p class="text">{{__('Banner size')}} 160x600</p>
              <img class="img " src="/newfrontend/b/160-600.gif" alt="">
              <div class="material-text">
                <p class="text">{{__('HTML Code')}}</p>
                <span>{{__('Can be used to display banner on websites or blogs.')}}</span>
              </div>
              <InputLabelTextDefault readonly :value="'<a target=\'_blank\' href=\''+portfolio.ref_url+'\'><img width=\'160\' height=\'600\' src=\'https://Media.network/newfrontend/b/160-600.gif\'/></a>'" copy></InputLabelTextDefault>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p class="text">{{__('Banner size')}} 120x600</p>
              <img class="img " src="/newfrontend/b/120-600.gif" alt="">
              <div class="material-text">
                <p class="text">{{__('HTML Code')}}</p>
                <span>{{__('Can be used to display banner on websites or blogs.')}}</span>
              </div>
              <InputLabelTextDefault readonly :value="'<a target=\'_blank\' href=\''+portfolio.ref_url+'\'><img width=\'120\' height=\'600\' src=\'https://Media.network/newfrontend/b/120-600.gif\'/></a>'" copy></InputLabelTextDefault>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p class="text">{{__('Banner size')}} 240x400</p>
              <img class="img " src="/newfrontend/b/240-400.gif" alt="">
              <div class="material-text">
                <p class="text">{{__('HTML Code')}}</p>
                <span>{{__('Can be used to display banner on websites or blogs.')}}</span>
              </div>
              <InputLabelTextDefault readonly :value="'<a target=\'_blank\' href=\''+portfolio.ref_url+'\'><img width=\'240\' height=\'400\' src=\'https://Media.network/newfrontend/b/240-400.gif\'/></a>'" copy></InputLabelTextDefault>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p class="text">{{__('Banner size')}} 125x125</p>
              <img class="img " src="/newfrontend/b/125-125.gif" alt="">
              <div class="material-text">
                <p class="text">{{__('HTML Code')}}</p>
                <span>{{__('Can be used to display banner on websites or blogs.')}}</span>
              </div>
              <InputLabelTextDefault readonly :value="'<a target=\'_blank\' href=\''+portfolio.ref_url+'\'><img width=\'125\' height=\'125\' src=\'https://Media.network/newfrontend/b/125-125.gif\'/></a>'" copy></InputLabelTextDefault>
            </v-card-text>
          </v-card>
        </v-tab-item>-->
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import DropdownDefault from "../modules/DropdownDefault";
    export default {
        name: 'SettingsPromo',
      data () {
        return {
          banner_offline: null,
          banners_offline: null,
          lang: null,
          banners: null,
          languages: [
              {
                  "id": 1,
                  "lang": "en",
                  "full_lang": "English",
                  "active": 1
              },
              {
                  "id": 2,
                  "lang": "ru",
                  "full_lang": "Russian",
                  "active": 1
              },
              {
                  "id": 3,
                  "lang": "hi",
                  "full_lang": "हिन्दी",
                  "active": 1
              },
              {
                  "id": 4,
                  "lang": "es",
                  "full_lang": "Español",
                  "active": 1
              },
              {
                  "id": 5,
                  "lang": "it",
                  "full_lang": "Italiano",
                  "active": 1
              },
              // {
              //     "id": 6,
              //     "lang": "uz",
              //     "full_lang": "Оʻzbek",
              //     "active": 1
              // },
              // {
              //     "id": 7,
              //     "lang": "kk",
              //     "full_lang": "қазақ",
              //     "active": 1
              // },
              {
                  "id": 8,
                  "lang": "tr",
                  "full_lang": "Türkçe",
                  "active": 1
              },
              {
                  "id": 9,
                  "lang": "pt",
                  "full_lang": "Português",
                  "active": 1
              },
              // {
              //     "id": 10,
              //     "lang": "ina",
              //     "full_lang": "Indonesia",
              //     "active": 1
              // },
              {
                  "id": 11,
                  "lang": "de",
                  "full_lang": "Deutsch",
                  "active": 1
              },
              // {
              //     "id": 12,
              //     "lang": "th",
              //     "full_lang": "ภาษาไทย",
              //     "active": 1
              // },
              // {
              //     "id": 13,
              //     "lang": "pl",
              //     "full_lang": "Polski",
              //     "active": 1
              // },
              // {
              //     "id": 15,
              //     "lang": "zh",
              //     "full_lang": "中文",
              //     "active": 1
              // },
              // {
              //     "id": 16,
              //     "lang": "ua",
              //     "full_lang": "Українська",
              //     "active": 1
              // },
              {
                  "id": 17,
                  "lang": "vi",
                  "full_lang": "Tiếng Việt",
                  "active": 1
              },
              // {
              //     "id": 14,
              //     "lang": "ar",
              //     "full_lang": "اللغة العربية",
              //     "active": 0
              // },
              {
                  "id": 33,
                  "lang": "fr",
                  "full_lang": "France",
                  "active": 0
              },
              {
                  "id": 34,
                  "lang": "zh",
                  "full_lang": "中文",
                  "active": 0
              },
          ],
          tabs: null,
          items: [
            { tab: '160х600', content: 'Tab 1 Content' },
            { tab: '250х250', content: 'Tab 2 Content' },
            { tab: '300х250', content: 'Tab 3 Content' },
            // { tab: '300х600', content: 'Tab 4 Content' },
            { tab: '320х50', content: 'Tab 5 Content' },
            { tab: '336х280', content: 'Tab 6 Content' },
            { tab: '468х60', content: 'Tab 7 Content' },
            { tab: '728х90', content: 'Tab 8 Content' },
            // { tab: '125х125', content: 'Tab 9 Content' },
          ],
        }
      },
      watch: {
        language(val) {
            this.banner_offline= this.banners_offline[val][0].value;
        }
      },
      asyncComputed:{
        asyncPortfolio() {
            let portfolio= this.portfolio;

            if(portfolio && !this.lang) {
                this.lang= portfolio.lang;
            }
            return true;
        }
      },
      created() {
        this.$http.get('ajax/promo')
            .then(response => {
                return response.json()
            })
            .then((response) => {
                this.$store.dispatch('asyncResp', response.resp_tickets);
                this.banners_offline= response.data;
                this.banner_offline= this.banners_offline[this.language][0].value;
                this.banners = response.data.banners;
            }).catch((err) => {
            this.httpHandler(err);
        });
      },
      computed: {
        portfolio() {
          return this.$store.state.auth.portfolio
        },
        language() {
          return this.$store.state.language.lang
        },
        bannersKey() {
          if (this.banners) {
              let tabs = [];
              let keys = Object.keys(this.banners);
              keys.forEach( i => {
                  let item = {};
                  item.tab = i;
                  tabs.push(item);
              })
              return tabs;
          } else {
              return true
          }
        }
      },
      props: {

      },
      components:{
        DropdownDefault,
        InputLabelTextDefault
      }
    }
</script>
