<template>
  <div class="verification" :class="personal_info.google_fa">

    <div class="top">
      <div class="block">
        <div class="img img-1">
          <img src="/newfrontend/images/new-2fa.svg" alt="">
        </div>
        <div class="img img-enable">
          <img src="/newfrontend/images/pass-img.svg" alt="">
        </div>
        <div>
          <p class="semi">{{__('Two-factor authentication')}}</p>
          <p class="text">{{__('Protect the account additionally by adding two-stage authentication.')}}</p>
        </div>
      </div>

        <div v-on:click="modal_2fa= true" class="switch-site">
          <v-btn class="btn-white-new" v-if="personal_info.google_fa.enable" disabled>
            {{__('Disable 2FA')}}
          </v-btn>
          <v-btn class="btn-white-new" v-else disabled>
            {{__('Enable 2fa')}}
          </v-btn>
        </div>
    </div>
    <ModalDefault v-if="modal_2fa" v-bind:modal_cancel.sync="modal_2fa">
      <div class="window-site google-window">
        <div class="top-block">
          <h2>{{__('Google 2fa')}}</h2>
        </div>
        <div class="verif-right" v-if="personal_info.google_fa.enable">
          <div>
            <p class="semi">{{__('Enter the 6-digit key for disable 2FA')}}</p>
          </div>
          <div>
            <InputLabelTextDefault :placeholder="__('Confirm password')" class="mt-3" value="form.email" v-model="secret.two_fa" v-bind:errors="formValidate.two_fa" @change="updateValidate('two_fa', false,'secret')"></InputLabelTextDefault>
          </div>
          <div class="btn-right">
            <v-btn v-on:click="onSecurity" :loading="securityDisabled" class="btn-white-new">
              {{__('Disable 2FA')}}
            </v-btn>
          </div>
        </div>
        <div class="verif-enable" v-else>
          <div >
            <p class="right-text">{{__('If you want to turn on 2FA, use Google Authenticator app to scan code.')}}</p>
          </div>
          <div class="security-qr">
            <VueQrcode :value="'otpauth://totp/'+$APP_NAME+':'+portfolio.email+'?secret='+personal_info.google_fa.secret+'&issuer='+$APP_NAME+'&algorithm=SHA1&digits=6&period=30'" width="160"/>
            <p>
              {{__('If you cannot scan the QR code, enter the code manually into the application.')}}
            </p>
            <InputLabelTextDefault copy="copy code" :value="personal_info.google_fa.secret"></InputLabelTextDefault>

          </div>
          <div class="block">
            <InputLabelTextDefault :placeholder="__('Enter 6-digit code provided by app')" value="form.email" v-model="secret.two_fa" v-bind:errors="formValidate.two_fa" @change="updateValidate('two_fa', false,'secret')"></InputLabelTextDefault>
          </div>
          <div class="btn-right">
            <span v-on:click="modal_2fa= false" class="btn-white">{{__('cancel')}}</span>
            <v-btn v-on:click="onSecurity" :loading="securityDisabled" class="btn-green-new">
              {{__('enable 2fa')}}
            </v-btn>
          </div>
        </div>
      </div>
    </ModalDefault>
  </div>

</template>

<script>
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    import ModalDefault from "../../modules/ModalDefault";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {required, maxLength, minLength, numeric} from "vuelidate/lib/validators";
export default {
    data () {
        return {
            response_upload: null,
            modal_2fa: false,
            secret: {
                two_fa: null,
            },
            formValidate: {
                two_fa: null
            },
            profileValidate: {
                name: null
            },
            modal_success: false,
            submitDisabled: false,
            profileDisabled: false,
            securityDisabled: false,
            user: this.personal_info.personal,
            profile: {
                name: null
            }
        }
    },
    props: {
        personal_info: {
            type: Object
        }
    },
    created: function () {

    },
    validations: {
        secret: {
            two_fa: {
                required,
                numeric,
                maxLength: maxLength(6),
                minLength: minLength(6),
            }
        }
    },
    computed: {
        portfolio() {
            return this.$store.state.auth.portfolio
        },
    },
    methods:{
        updateValidate: function(type, validate= false, key= 'form') {
            if(validate) {
                let formValidate= this.formValidate;
                formValidate[type]= [];

                if(!this.$v.form[type][validate]) {
                    let msg= this.__(this.$validateMessage[validate]);
                    formValidate[type].push(msg);

                }

                this.formValidate= formValidate;
            } else {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v[key][type], type);
            }
        },

        onSecurity: function () {
            let valid= this.$v.secret;

            if(valid.$invalid) {
                let massages= {
                    'two_fa': [],
                };
                this.formValidate= this.$formValidate(massages, valid);
            } else {
                this.securityDisabled= true;
                let response= {
                    'code': this.secret.two_fa
                };

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/setting/security/2fa', response)
                    .then(response=> {
                        return response.json()
                    })
                    .then((response) => {
                        this.$store.dispatch('asyncResp', response.resp_tickets);
                        this.modal_2fa= false;
                        let personal_info= this.$cleanObject(this.personal_info);
                        personal_info.google_fa.enable= response.data.enable;
                        this.$emit('update:personal_info', personal_info);
                        this.securityDisabled= false;
                        this.secret.two_fa= null;
                    }).catch(err => {
                    this.securityDisabled= false;
                    let response= this.httpHandler(err);
                    if(response.error) {
                        this.formValidate = response.error.message;

                    }
                });
            }
        }
    },
    watch: {

    },
    components: {
        InputLabelTextDefault,
        ModalDefault,
        VueQrcode
    }
}
</script>

<style scoped>

</style>
