<template>
    <div class="finance">
        <div class="all-block" v-if="wallets && payment">
          <div class="fin-operation">
            <div class="dep-step1">
              <WithdrawCoinInfo v-bind:wallets="wallets" v-bind:payment.sync="payment"></WithdrawCoinInfo>
            </div>
          </div>
        </div>
<!--        <div class="top" v-else-if="wallets">-->
<!--            <h2>Мои доступные балансы</h2>-->
<!--            <ul>-->
<!--                <Payment class="all-block" tag="li" v-bind:payment_id="item.payment_id" v-for="(item, key) in wallets.payment" :key="key">-->
<!--                    <template v-slot="payment">-->
<!--                      <div class="list-top">-->
<!--                        <div class="img" :class="payment.name_class"></div>-->
<!--                        <p>{{payment.name}}</p>-->
<!--                        <span class="color" :class="payment.name_class">$ {{$primNum(payment.course_usd)}}</span>-->
<!--                      </div>-->
<!--                      <div class="list-btn">-->
<!--                        <a class="btn-white" v-on:click="withdraw= {payment: payment, wallet: item}">Вывести</a>-->
<!--                      </div>-->
<!--                    </template>-->
<!--                </Payment>-->
<!--            </ul>-->
<!--        </div>-->
        <div v-else class="loading">
            <loaderMc></loaderMc>
            {{__('Loading')}}
        </div>
    </div>
</template>

<script>

import WithdrawCoinInfo from './Finance/WithdrawCoinInfo'
import loaderMc from "../SvgComponents/loaderMc";
// import Payment from "../modules/Payment";

export default {
  name: 'finance',
    data: function() {
      return {
          withdraw: null,
          transactions: null,
          params: {},
          wallets: null,
          payment: null
      }
  },
  watch: {

  },
  methods:{

  },
  computed: {
      payments() {
          return this.$store.state.auth.payments
      },
  },
  asyncComputed: {
      async_payments() {
          let payments= this.payments;

          if(payments && !this.payment) {
              this.payment= payments[0];
          }

          return true;
      }
  },
  created() {
      // this.$http.get('ajax/payments')
      //     .then(response => {
      //         return response.json()
      //     })
      //     .then(response => {
      //         response= response.data;
      //         let payment= response.payment;
      //
      //         let portfolio= this.$cleanObject(this.portfolio);
      //         portfolio['balance_usd']= response['balance_usd'];
      //         this.$store.dispatch('asyncPortfolio', portfolio);
      //         this.$store.dispatch('asyncPayments', payment);
      //     }).catch((err) => {
      //     this.httpHandler(err);
      // });
      this.$http.get('ajax/withdraw_coin/info', this.params)
          .then(response => {
              return response.json()
          })
          .then((response) => {
              this.$store.dispatch('asyncResp', response.resp_tickets);
              response= response.data;
              this.wallets= response;
          }).catch((err) => {
          this.httpHandler(err);
      });
      // this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/finance')
      //     .then(response => {
      //         return response.json()
      //     })
      //     .then(response => {
      //         response= response.data;
      //
      //         this.$emit('update:transactions', response)
      //     }).catch((err) => {
      //     this.httpHandler(err);
      // });
  },
  mounted() {

  },
  components: {
      loaderMc,
      WithdrawCoinInfo,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
