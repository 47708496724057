<template>
  <ModalDefault v-if="dialog" v-bind:close="false" width="470">
    <div class="window-site withdraw-window">
      <div class="top">
        <h1>{{__('Withdraw confirmation')}}</h1>
        <p>{{__('Check the data correctness and confirm the application application')}}</p>
      </div>
      <div class="list">
        <div>
          <p class="semi">{{__('Amount')}}</p>
          <p>MC {{$primNum(withdraw.amount) | formatThousands}}</p>
        </div>
        <div>
          <p class="semi">{{__('Charity:')}}</p>
          <p class="text">{{$primNum(comission_free, 2) | formatThousands}} USD</p>
        </div>
        <div>
          <p class="semi">{{__('Sum:')}}</p>
          <p>{{$primNum(withdraw.amount_coin-(comission_free), 2) | formatThousands}} USD</p>
        </div>
      </div>
      <div class="address">
        <div>
          <p class="semi">{{__('Address:')}}</p>
          <p class="text">{{form.address}}</p>
        </div>
      </div>
      <div class="withdraw-btn">
        <v-btn class="btn-white" v-on:click="modalCancel">{{__('Cancel')}}</v-btn>
        <v-btn class="btn-site" v-on:click="onSubmit" :loading="submitDisabled">{{__('Confirm the output')}}</v-btn>
      </div>
    </div>
  </ModalDefault>
</template>

<script>
  import ModalDefault from '../../modules/ModalDefault';

  export default {
    data () {
      return {
        dialog: true,
        submitDisabled: false
      }
    },
    props: {
        withdraw: {
            type: Object
        },
        form: {
            type: Object
        },
        comission_free: {
            type: Number
        }
    },
    methods:{
        modalCancel() {
            this.$emit('update:withdraw', null);
        },
        onSubmit() {
            this.$emit('updateDeposit');
        }
    },
    created: function () {

    },
    watch: {

    },
    computed: {

    },
    components: {
      ModalDefault
    }
  }
</script>

<style scoped>

</style>
